import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteAllRecords } from "../Records/RecordsActions";
import { getNotification } from "../../components/header/notificationActions";
import { format } from "date-fns";
import { CustomerId } from "src/app/config";
import "./RecycleBin.css";
import * as Icon from "react-feather";
import ConfirmationModal from "src/app/components/ConfirmationModal/ConfirmationModal";
import {
  callDeletedLiverecords,
  retrieveSingleLiveRecord,
} from "../LiveRecords/liveRecordActions";

const LiveRecordRecycleBin = () => {
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [listDeletedLiveRecord, setListDeletedLiveRecord] = useState();
  const [reload, setReload] = useState(false);
  const dispatch = useDispatch();
  const [sortParams, setSortParams] = useState({
    sortBy: "DESC",
    sortTitle: "updatedAt",
  });
  const [selectedRecord, setselectedRecord] = useState("");
  const recordDetailsList = useSelector((state) => state.recordData);

  useEffect(() => {
    window.onbeforeunload = null;
    getRecordDetailsList();
    getNotificationsList();
  }, [reload]);

  const getNotificationsList = () => {
    const data = {
      numPerPage: 100,
      page: 1,
      customerId: CustomerId,
      userId: localStorage.getItem("userId"),
    };
    dispatch(getNotification(data));
  };

  const getRecordDetailsList = (page = 1, pageSize = 25) => {
    const data = {
      numPerPage: pageSize,
      page: page == 0 ? 1 : page,
      userId: localStorage.getItem("userId"),
      sortKey: sortParams.sortTitle,
      sortType: sortParams.sortBy,
      searchKey: "",
    };
    dispatch(callDeletedLiverecords(data)).then((res) => {
      setListDeletedLiveRecord(res?.data?.data);
    });
  };

  const onPageChanged = (data) => {
    if (data && data.currentPage == currentPageNumber) {
      return;
    }
    if (data && data.currentPage) {
      const { currentPage } = data;
      setCurrentPageNumber(currentPage);
      getRecordDetailsList(currentPage);
    }
  };

  const sortTable = (title) => {
    var sortByOrder = "ASC";

    if (title == sortParams.sortTitle) {
      sortByOrder = sortParams.sortBy == "DESC" ? "ASC" : "DESC";
      setSortParams({ sortBy: sortByOrder, sortTitle: title });
    } else {
      setSortParams({ sortBy: "ASC", sortTitle: title });
    }

    // Call Api here for sorting
    const data = {
      numPerPage: 100,
      page: currentPageNumber,
      userId: localStorage.getItem("userId"),
      sortKey: title,
      sortType: sortByOrder,
      searchKey: "",
    };
    dispatch(callDeletedLiverecords(data)).then((res) => {
      setListDeletedLiveRecord(res?.data?.data);
    });
  };
  const handleRetrieveLiveRecord = (data) => {
    setselectedRecord(data);
  };

  const retrieveRecord = () => {
    dispatch(retrieveSingleLiveRecord(selectedRecord.id));
    setReload(!reload);
  };

  const clearRecycleBin = () => {
    dispatch(deleteAllRecords());
  };

  return (
    <div>
      <div class="row">
        <div class="col-lg-12">
          <div class="table-wraper table-responsive mnt mt-3">
            <table class="table job-list">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col" className="mx-width">
                    Doc ID
                  </th>
                  <th
                    scope="col"
                    onClick={() => {
                      sortTable("recipient");
                    }}
                  >
                    <div className="d-flex justify-content-start">
                      <div>Record Name</div>
                      <div className="sort-wrp ml-2">
                        <i
                          className={
                            sortParams.sortTitle == "recipient" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-asc fa-lg active sort_up"
                              : "fa fa-sort-asc fa-lg sort_fade_down"
                          }
                        ></i>
                        <i
                          className={
                            sortParams.sortTitle == "recipient" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-desc fa-lg sort_fade_down"
                              : sortParams.sortTitle == "recipient" &&
                                sortParams.sortBy == "DESC"
                              ? "fa fa-sort-desc fa-lg active sort_up"
                              : "fa fa-sort-desc fa-lg sort_fade_down"
                          }
                        ></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col" className="mx-width">
                    Template Name
                  </th>

                  <th
                    scope="col"
                    onClick={() => {
                      sortTable("updatedAt");
                    }}
                  >
                    <div class="d-flex justify-content-start">
                      <div>Date</div>
                      <div class="sort-wrp ml-2">
                        <i
                          class={
                            sortParams.sortTitle == "updatedAt" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-asc fa-lg active sort_up"
                              : "fa fa-sort-asc fa-lg sort_fade_down"
                          }
                        ></i>
                        <i
                          class={
                            sortParams.sortTitle == "updatedAt" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-desc fa-lg sort_fade_down"
                              : sortParams.sortTitle == "updatedAt" &&
                                sortParams.sortBy == "DESC"
                              ? "fa fa-sort-desc fa-lg active sort_up"
                              : "fa fa-sort-desc fa-lg sort_fade_down"
                          }
                        ></i>
                      </div>
                    </div>
                  </th>

                  <th className="text-center" scope="col">
                    Retrieve
                  </th>
                </tr>
              </thead>
              <tbody>
                {listDeletedLiveRecord &&
                  listDeletedLiveRecord.map((record, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td className="mx-width">
                        {record?.LiveTemplate?.liveTemplateRefId}
                      </td>
                      <td>
                        {record?.liveRecordName
                          ? record?.liveRecordName
                          : "Template Data"}
                      </td>
                      <td>{record?.LiveTemplate?.liveTemplateName}</td>

                      <td>
                        {record?.liveRecordDate
                          ? format(
                              new Date(record?.liveRecordDate),
                              "yyyy-MM-dd : hh.mm aa"
                            )
                          : "-"}
                      </td>
                      <td class="text-center">
                        <a
                          className="a-removed-href"
                          data-toggle="modal"
                          data-target="#retrieveConfirmationModalLive"
                          onClick={() => {
                            handleRetrieveLiveRecord(record);
                          }}
                        >
                          <Icon.FolderPlus />
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {recordDetailsList?.deletedRecordsList.data &&
              recordDetailsList?.deletedRecordsList.data.length <= 0 && (
                <p class="text-center font-italic font-weight-bold">
                  No data available!
                </p>
              )}
            {/* <div className="d-flex flex-row py-4 align-items-center float-right">
              {recordDetailsList.deletedRecordsList.recordCount > 0 && (
                <Pagination
                  totalRecords={"totalCount"}
                  pageLimit={100}
                  pageNeighbours={0}
                  onPageChanged={onPageChanged}
                />
              )}
            </div> */}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="retrieveConfirmationModalLive"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <ConfirmationModal
          message={
            "Are you sure you want to retrieve the record " +
            selectedRecord?.liveRecordName +
            " ?"
          }
          performAction={retrieveRecord}
        />
      </div>
      <div
        className="modal fade"
        id="deleteConfirmationModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <ConfirmationModal
          message={"Are you sure you want to empty the recycle bin ?"}
          actionType="reConfirmationModalLive"
        />
      </div>
      <div
        className="modal fade"
        id="reConfirmationModalLive"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <ConfirmationModal
          message={
            "Warning!!! This will delete the record permanently and all your data will be lost. Are you sure you want to empty the recycle bin ?"
          }
          performAction={clearRecycleBin}
        />
      </div>
    </div>
  );
};

export default LiveRecordRecycleBin;
