// TEMPLATE LISTING
export const LIST_TEMPLATES_REQUEST = "LIST_TEMPLATES_REQUEST";
export const LIST_TEMPLATES_SUCCESS = "LIST_TEMPLATES_SUCCESS";
export const LIST_TEMPLATES_FAILURE = "LIST_TEMPLATES_FAILURE";

// GET TEMPLATE FOR CUSTOMER
export const GET_TEMPLATE_FOR_CUSTOMER_REQUEST =
  "GET_TEMPLATE_FOR_CUSTOMER_REQUEST";
export const GET_TEMPLATE_FOR_CUSTOMER_SUCCESS =
  "GET_TEMPLATE_FOR_CUSTOMER_SUCCESS";
export const GET_TEMPLATE_FOR_CUSTOMER_FAILURE =
  "GET_TEMPLATE_FOR_CUSTOMER_FAILURE";

//ADD TEMPLATE DATA
export const ADD_TEMPLATE_DATA_REQUEST = "ADD_TEMPLATE_DATA_REQUEST";
export const ADD_TEMPLATE_DATA_SUCCESS = "ADD_TEMPLATE_DATA_SUCCESS";
export const ADD_TEMPLATE_DATA_FAILURE = "ADD_TEMPLATE_DATA_FAILURE";

//RESET
export const RESET_TEMPLATE_DETAILS = "RESET_TEMPLATE_DETAILS";

// QR
export const SET_QR_IMAGE_URL = "SET_QR_IMAGE_URL";
