import React from 'react';
import './loader.css';
const LoadingIndicator = (props) => {
  return props.promiseInProgress && 
  <div>
   <div
     className="ldr-algnmnt"
   >
        <div className="spinner-border text-primary" style={{ width: "4rem", height: "4rem" }} role="status">
                <span className="sr-only">Loading...</span>
            </div>
     {/* <Loader type="TailSpin" secondaryColor="#2C2F9A" color="#1862EE" height="100" width="100" /> */}

   </div>
  </div>

};

export default LoadingIndicator