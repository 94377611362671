import * as Ticket from './JobTicketConstants';

const initialState = {
  loading: false,
  ticketsData: [],
  categoryList: [],
  ticketsList: [],
  ticketDetails: [],
  error: null
};

function JobTicketReducer(state = initialState, action={}) {
  switch (action.type) {

    case Ticket.LIST_CATEGORY_REQUEST:
    case Ticket.ADD_JOB_TICKET_REQUEST:
    case Ticket.LIST_TICKETS_REQUEST:
    case Ticket.UPDATE_JOB_TICKET_REQUEST:
    case Ticket.GET_TICKET_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case Ticket.LIST_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        categoryList: action.payload,
        error: null,
      };
    case Ticket.LIST_CATEGORY_FAILURE:
    case Ticket.ADD_JOB_TICKET_FAILURE:
    case Ticket.UPDATE_JOB_TICKET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case Ticket.ADD_JOB_TICKET_SUCCESS:
      return {
        ...state,
        loading: false,
        ticketsData: action.payload,
        error: null,
      };
    case Ticket.LIST_TICKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        ticketsList: action.payload,
        error: null,
      };
    case Ticket.LIST_TICKETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        ticketsList: []
      };

    case Ticket.UPDATE_JOB_TICKET_SUCCESS:
      return {
        ...state,
        loading: false,
        updatedData: action.payload,
        error: null,
      };
    case Ticket.GET_TICKET_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        ticketDetails: action.payload,
        error: null,
      };
    case Ticket.GET_TICKET_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        ticketDetails: []

      };
    case Ticket.RESET_TICKET_DETAILS:
      return {
        ...initialState,

      };

    default:
      return state;
  }
}

export default JobTicketReducer;