import * as Record from './RecordsConstants';

const initialState = {
  loading: false,
  recordsList: [],
  yearsList: [],
  recordDetails: [],
  archiveAdded: [],
  deletedRecordsList: [],
  error: null,
};

function RecordsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case Record.LIST_RECORDS_REQUEST:
    case Record.GET_RECORD_DETAILS_REQUEST:
    case Record.DELETE_SINGLE_RECORD_REQUEST:
    case Record.RETRIEVE_SINGLE_RECORD_REQUEST:
    case Record.DELETE_ALL_RECORDS_REQUEST:
    case Record.ARCHIVE_ALL_RECORDS_REQUEST:
    case Record.LIST_ALL_DELETED_RECORDS_REQUEST:
    case Record.LIST_YEARS_REQUEST:
    case Record.MAKE_ARCHIVES_REQUEST:

      return {
        ...state,
        loading: true,
        error: null,
      };
    case Record.LIST_RECORDS_SUCCESS:
    case Record.ARCHIVE_ALL_RECORDS_SUCCESS:

      return {
        ...state,
        loading: false,
        recordsList: action.payload,
        error: null,
      };
      case Record.DELETE_ALL_RECORDS_SUCCESS:
      return {
        ...state,
        loading: false,
        deletedRecordsList:[],
        error: null,
      };
    case Record.LIST_ALL_DELETED_RECORDS_SUCCESS:
      return {
        ...state,
        loading: false,
        deletedRecordsList: action.payload,
        error: null,
      };
    case Record.RETRIEVE_SINGLE_RECORD_SUCCESS:
      return {
        ...state,
        loading: false,
        deletedRecordsList: { ...state.deletedRecordsList, data: state.deletedRecordsList.data.filter(record => record.id !== action.payload.id) },
        error: null,
      };
    case Record.LIST_RECORDS_FAILURE:
    case Record.DELETE_ALL_RECORDS_FAILURE:
    case Record.ARCHIVE_ALL_RECORDS_FAILURE:

      return {
        ...state,
        loading: false,
        error: action.payload.error,
        recordsList: []

      };
    case Record.LIST_ALL_DELETED_RECORDS_FAILURE:
    case Record.RETRIEVE_SINGLE_RECORD_FAILURE:

      return {
        ...state,
        loading: false,
        error: action.payload.error,
        deletedRecordsList: []

      };

    case Record.GET_RECORD_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        recordDetails: action.payload,
        error: null,
      };
    case Record.DELETE_SINGLE_RECORD_SUCCESS:

      return {
        ...state,
        loading: false,
        recordDetails: { ...state.recordDetails, data: state.recordDetails.data.filter(record => record.id !== action.payload.id) },
        error: null,
      };
    case Record.GET_RECORD_DETAILS_FAILURE:
    case Record.DELETE_SINGLE_RECORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        recordDetails: []

      };

    case Record.LIST_YEARS_SUCCESS:
      return {
        ...state,
        loading: false,
        yearsList: action.payload,
        error: null,
      };
    case Record.LIST_YEARS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        yearsList: []

      };

    case Record.MAKE_ARCHIVES_SUCCESS:
      return {
        ...state,
        loading: false,
        archiveAdded: action.payload,
        recordsList: { ...state.recordsList, data: state.recordsList.data.filter(record => record.id !== action.payload.id) },
        error: null,
      };
    case Record.MAKE_ARCHIVES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        archiveAdded: [],
      };

    case Record.RESET_RECORD_DETAILS:
      return {
        ...initialState,

      };

    default:
      return state;
  }
}

export default RecordsReducer;