import { ApiService } from "../../services/api.service";
import * as Record from "./RecordsConstants";
import { showAlert } from "../alert/showAlert";
import { trackPromise } from "react-promise-tracker";
import { alertActions } from "../alert/alertActions";

// Record Listing Actions

export function listRecords(data) {
  return (dispatch) => {
    dispatch(listRecordsRequested());
    trackPromise(
      ApiService.callListRecords(data).then((res) => {
        dispatch(listRecordsSuccess(res.data));
      })
    ).catch((err) => {
      dispatch(listRecordsFailure(err.message));
      showAlert(dispatch, err);
    });
  };
}

const listRecordsRequested = () => ({
  type: Record.LIST_RECORDS_REQUEST,
});

const listRecordsSuccess = (data) => ({
  type: Record.LIST_RECORDS_SUCCESS,
  payload: {
    ...data,
  },
});

const listRecordsFailure = (error) => ({
  type: Record.LIST_RECORDS_FAILURE,
  payload: {
    error,
  },
});

// Get Record Details Actions

export function getRecordDetails(data) {
  return (dispatch) => {
    dispatch(getRecordDetailsRequested());
    trackPromise(
      ApiService.callGetRecordDetails(data).then((res) => {
        dispatch(getRecordDetailsSuccess(res.data));
      })
    ).catch((err) => {
      dispatch(getRecordDetailsFailure(err.message));
      showAlert(dispatch, err);
    });
  };
}

const getRecordDetailsRequested = () => ({
  type: Record.GET_RECORD_DETAILS_REQUEST,
});

const getRecordDetailsSuccess = (data) => ({
  type: Record.GET_RECORD_DETAILS_SUCCESS,
  payload: {
    ...data,
  },
});

const getRecordDetailsFailure = (error) => ({
  type: Record.GET_RECORD_DETAILS_FAILURE,
  payload: {
    error,
  },
});

// Delete All records Actions

export function deleteAllRecords(data) {
  return (dispatch) => {
    dispatch(deleteAllRecordsRequested());
    trackPromise(
      ApiService.callDeleteAllRecords(data).then((res) => {
        dispatch(deleteAllRecordsSuccess(res.data));
        dispatch(alertActions.success(res.data.message));
      })
    ).catch((err) => {
      dispatch(deleteAllRecordsFailure(err.message));
      showAlert(dispatch, err);
    });
  };
}

const deleteAllRecordsRequested = () => ({
  type: Record.DELETE_ALL_RECORDS_REQUEST,
});

const deleteAllRecordsSuccess = (data) => ({
  type: Record.DELETE_ALL_RECORDS_SUCCESS,
  payload: {
    ...data,
  },
});

const deleteAllRecordsFailure = (error) => ({
  type: Record.DELETE_ALL_RECORDS_FAILURE,
  payload: {
    error,
  },
});

// Delete single record Actions

export function deleteSingleRecord(data) {
  return (dispatch) => {
    dispatch(deleteSingleRecordRequested());
    trackPromise(
      ApiService.callDeleteSingleRecord(data).then((res) => {
        dispatch(deleteSingleRecordSuccess(res.data, data));
        dispatch(alertActions.success(res.data.message));
      })
    ).catch((err) => {
      dispatch(deleteSingleRecordFailure(err.message));
      showAlert(dispatch, err);
    });
  };
}

const deleteSingleRecordRequested = () => ({
  type: Record.DELETE_SINGLE_RECORD_REQUEST,
});

const deleteSingleRecordSuccess = (data, id) => ({
  type: Record.DELETE_SINGLE_RECORD_SUCCESS,
  payload: {
    data: data,
    id: id,
  },
});

const deleteSingleRecordFailure = (error) => ({
  type: Record.DELETE_SINGLE_RECORD_FAILURE,
  payload: {
    error,
  },
});

// Retrieve single record Actions

export function retrieveSingleRecord(data) {
  return (dispatch) => {
    dispatch(retrieveSingleRecordRequested());
    trackPromise(
      ApiService.callRetrieveSingleRecord(data).then((res) => {
        dispatch(retrieveSingleRecordSuccess(res.data, data));
        dispatch(alertActions.success(res.data.message));
      })
    ).catch((err) => {
      dispatch(retrieveSingleRecordFailure(err.message));
      showAlert(dispatch, err);
    });
  };
}

const retrieveSingleRecordRequested = () => ({
  type: Record.RETRIEVE_SINGLE_RECORD_REQUEST,
});

const retrieveSingleRecordSuccess = (data, id) => ({
  type: Record.RETRIEVE_SINGLE_RECORD_SUCCESS,
  payload: {
    data: data,
    id: id,
  },
});

const retrieveSingleRecordFailure = (error) => ({
  type: Record.RETRIEVE_SINGLE_RECORD_FAILURE,
  payload: {
    error,
  },
});

// Archive all records Actions

export function archiveAllRecords(data) {
  return (dispatch) => {
    dispatch(archiveAllRecordsRequested());
    trackPromise(
      ApiService.callListAllDeletedRecords(data).then((res) => {
        dispatch(archiveAllRecordsSuccess(res.data));
        dispatch(alertActions.success(res.data.message));
      })
    ).catch((err) => {
      dispatch(archiveAllRecordsFailure(err.message));
      showAlert(dispatch, err);
    });
  };
}

const archiveAllRecordsRequested = () => ({
  type: Record.ARCHIVE_ALL_RECORDS_REQUEST,
});

const archiveAllRecordsSuccess = (data) => ({
  type: Record.ARCHIVE_ALL_RECORDS_SUCCESS,
  payload: {
    ...data,
  },
});

const archiveAllRecordsFailure = (error) => ({
  type: Record.ARCHIVE_ALL_RECORDS_FAILURE,
  payload: {
    error,
  },
});

// List all deleted records Actions

export function listAllDeletedRecords(data) {
  return (dispatch) => {
    dispatch(listAllDeletedRecordsRequested());
    return trackPromise(
      ApiService.callListAllDeletedRecords(data).then((res) => {
        dispatch(listAllDeletedRecordsSuccess(res.data));
        dispatch(alertActions.success(res.data.message));
        return res;
      })
    ).catch((err) => {
      dispatch(listAllDeletedRecordsFailure(err.message));
      showAlert(dispatch, err);
      throw err;
    });
  };
}

const listAllDeletedRecordsRequested = () => ({
  type: Record.LIST_ALL_DELETED_RECORDS_REQUEST,
});

const listAllDeletedRecordsSuccess = (data) => ({
  type: Record.LIST_ALL_DELETED_RECORDS_SUCCESS,
  payload: {
    ...data,
  },
});

const listAllDeletedRecordsFailure = (error) => ({
  type: Record.LIST_ALL_DELETED_RECORDS_FAILURE,
  payload: {
    error,
  },
});

//Years listing action
export function listYears(data) {
  return (dispatch) => {
    dispatch(listYearsRequested());
    trackPromise(
      ApiService.callListYears(data).then((res) => {
        dispatch(listYearsSuccess(res.data));
      })
    ).catch((err) => {
      dispatch(listYearsFailure(err.message));
      showAlert(dispatch, err);
    });
  };
}

const listYearsRequested = () => ({
  type: Record.LIST_YEARS_REQUEST,
});

const listYearsSuccess = (data) => ({
  type: Record.LIST_YEARS_SUCCESS,
  payload: {
    ...data,
  },
});

const listYearsFailure = (error) => ({
  type: Record.LIST_YEARS_FAILURE,
  payload: {
    error,
  },
});

//Make Archive action
export function makeArchives(data) {
  return (dispatch) => {
    dispatch(makeArchivesRequested());
    trackPromise(
      ApiService.callMakeArchives(data).then((res) => {
        dispatch(makeArchivesSuccess(res.data, data));
      })
    ).catch((err) => {
      dispatch(makeArchivesFailure(err.message));
      showAlert(dispatch, err);
    });
  };
}

const makeArchivesRequested = () => ({
  type: Record.MAKE_ARCHIVES_REQUEST,
});

const makeArchivesSuccess = (data, id) => ({
  type: Record.MAKE_ARCHIVES_SUCCESS,
  payload: {
    data: data,
    id: id,
  },
});

const makeArchivesFailure = (error) => ({
  type: Record.MAKE_ARCHIVES_FAILURE,
  payload: {
    error,
  },
});

//Reset

export function resetRecordDetails() {
  return { type: Record.RESET_RECORD_DETAILS };
}
