import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Template.css";
import { getTemplateQrImage } from "./TemplateActions";

const QRAlert = ({ selectedTempID, setOnOpenCloseHandler }) => {
  const [selectedFile, setselectedFile] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const QRImageExist = useSelector(
    (state) => state.onlineTemplateData?.QRImageURL
  );

  useEffect(() => {
    setLoading(true);
    dispatch(getTemplateQrImage(selectedTempID)).then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setselectedFile(QRImageExist);
  }, [QRImageExist]);

  const getImageUrl = (url) => {
    if (url) {
      return url;
    } else {
      return "/images/no_Image_icon.png";
    }
  };

  return (
    <div class="AlertBox">
      {!loading ? (
        <div class="ImageDiv">
          <img
            alt="QR Image"
            // src={imgurl}
            src={getImageUrl(selectedFile)}
            height="200rem"
            width="200rem"
          />
        </div>
      ) : (
        <div class="ImageDiv1"> Loding...</div>
      )}

      <div class="btn-div">
        <button
          class="cancel-btn"
          //   className="btn btn-secondary"
          type="button"
          data-dismiss="modal"
          onClick={setOnOpenCloseHandler}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default QRAlert;
