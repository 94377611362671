import { ApiService } from "../../services/api.service";
import * as profile from "./profileConstants";
import { history } from "../../../helpers/history";
import { alertActions } from "../alert/alertActions";
import { showAlert } from "../alert/showAlert";
import { trackPromise } from "react-promise-tracker";

// Get Profile
export function getProfile(data) {
  return (dispatch) => {
    dispatch(getProfileRequested());
    trackPromise(
      ApiService.ServiceCallForGetProfile(data).then((res) => {
        dispatch(getProfileSuccess(res.data));
      })
    ).catch((err) => {
      dispatch(getProfileFailure(err.message));
      showAlert(dispatch, err);
    });
  };
}

const getProfileRequested = () => ({
  type: profile.GET_PROFILE_REQUEST,
});

const getProfileSuccess = (data) => ({
  type: profile.GET_PROFILE_SUCCESS,
  payload: {
    ...data,
  },
});

const getProfileFailure = (error) => ({
  type: profile.GET_PROFILE_FAILURE,
  payload: {
    error,
  },
});

// Update profile
export function updateProfile(data, userId) {
  return (dispatch) => {
    dispatch(updateProfileRequested());
    trackPromise(
      ApiService.ServiceCallForUpdateSatff(data, userId).then((res) => {
        if (res.data.status == "success") {
          history.push("/home/profile");
        }
        dispatch(alertActions.success("Profile updated successfully"));
        dispatch(updateProfileSuccess(res.data));
      })
    ).catch((err) => {
      dispatch(updateProfileFailure(err.message));
      showAlert(dispatch, err);
    });
  };
}

const updateProfileRequested = () => ({
  type: profile.UPDATE_PROFILE_REQUEST,
});

const updateProfileSuccess = (data) => ({
  type: profile.UPDATE_PROFILE_SUCCESS,
  payload: {
    ...data,
  },
});

const updateProfileFailure = (error) => ({
  type: profile.UPDATE_PROFILE_FAILURE,
  payload: {
    error,
  },
});
