import React, { useState, useEffect } from "react";
import { login, otpVerification } from "./loginActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import validator from "validator";
import Cookies from "js-cookie";
import { companyLogo, CustomerId } from "../../config/index";
import "./login.css";

const Login = () => {
  const [inputs, setInputs] = useState({
    email: Cookies.get("email"),
    password: Cookies.get("password"),
    customerId: CustomerId,
  });
  const [submittedOTP, setSubmittedOTP] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [rememberMe, setRememberMe] = useState(
    Cookies.get("email") && Cookies.get("email").length > 1
  );
  const [visible, setVisibility] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [otp, setOtp] = useState("");
  var currDate = new Date();
  var year = currDate.getFullYear();
  const { email, password } = inputs;

  const status = useSelector((state) => state.loginData.data.status);

  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    window.onbeforeunload = null;
    if (location.pathname == "/admin-login") {
      setIsAdmin(true);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (e.target.name == "otp") {
      setOtp(value);
    } else {
      setInputs((inputs) => ({ ...inputs, [name]: value }));
    }
  };

  const handleCheckbox = () => {
    setRememberMe(!rememberMe);
  };

  const showPassword = () => {
    setVisibility(!visible);
  };

  const saveCredentialsInStorage = () => {
    // Cookies.set('email', inputs.email, { expires: 7, path: '/login', secure: true, domain:'' });// https only

    Cookies.set("email", inputs.email, { expires: 7 });
    Cookies.set("password", inputs.password, { expires: 7 });
  };

  const clearCredentialsFromStorage = () => {
    Cookies.remove("email");
    Cookies.remove("password");
  };

  const handleSubmit = (e) => {
    if (e.key === "Enter") {
      handleLogin(e);
    }
  };

  const handleOTP = (e) => {
    e.preventDefault();
    setSubmittedOTP(true);

    if (otp && isAdmin == true) {
      const data = {
        otp: otp,
      };
      dispatch(otpVerification(data));
    }
  };

  const handleLogin = (e) => {
    setSubmitted(true);
    if (rememberMe == true) {
      saveCredentialsInStorage();
    } else {
      clearCredentialsFromStorage();
    }
    if (email && password && validator.isEmail(email) && isAdmin == "false") {
      dispatch(login(inputs));
    } else {
      const data = {
        email: inputs.email,
        password: inputs.password,
        customerId: inputs.customerId,
        isAdmin: isAdmin,
      };
      dispatch(login(data));
    }
  };

  return (
    <div className="container-fluidcst">
      <div className="row">
        <div className="d-none d-lg-block col-lg-6 no-padding ipod">
          <div className="log-bg"></div>
        </div>
        <div className="col-lg-6">
          <div
            className="content-wrap"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="login-wrapp">
              {/* <!-- login area --> */}

              <div className="login-outer">
                <div className="lo-wrap">
                  <div className="text-center">
                    <img src={companyLogo} width="164" />
                    <h1 className="h4 text-gray-900 mb-4 wl-tx pt-3 pb-3">
                      Welcome back! Please login to continue
                    </h1>
                  </div>
                  {status == "success" && isAdmin ? (
                    <form className="user" onSubmit={handleOTP}>
                      {" "}
                      <div className="group">
                        <input
                          type="number"
                          required="required"
                          name="otp"
                          value={otp}
                          onChange={handleChange}
                          className={
                            submittedOTP && !otp
                              ? "brd-4 form-control is-invalid"
                              : ""
                          }
                          id="exampleInputOtp"
                          aria-describedby="otpHelp"
                        />
                        <span className="highlight"></span>
                        <label>OTP</label>
                        {submittedOTP && !otp && (
                          <div className="invalid-feedback">
                            OTP is required
                          </div>
                        )}
                      </div>{" "}
                      <a
                        onClick={handleOTP}
                        className="brd-4 btn btn-primary btn-user btn-block a-cancel mt-5"
                      >
                        Send
                      </a>
                    </form>
                  ) : (
                    <form className="user">
                      <div className="group">
                        <input
                          type="email"
                          required="required"
                          name="email"
                          value={email}
                          onChange={handleChange}
                          className={
                            submitted && !email
                              ? "brd-4 form-control is-invalid"
                              : submitted && !validator.isEmail(email)
                              ? "brd-4 form-control is-invalid"
                              : ""
                          }
                          id="exampleInputEmail"
                          aria-describedby="emailHelp"
                        />
                        <span className="highlight"></span>
                        <label>User Email</label>
                        {submitted && !email && (
                          <div className="invalid-feedback">
                            Email id is required
                          </div>
                        )}
                        {submitted && email && !validator.isEmail(email) && (
                          <div className="invalid-feedback">
                            Invalid Email id
                          </div>
                        )}
                      </div>
                      <div className="group">
                        <div className="d-flex align-items-center">
                          <span className="txt-fld">
                            <input
                              type={visible == false ? "password" : "text"}
                              name="password"
                              value={password}
                              onKeyPress={handleSubmit}
                              onChange={handleChange}
                              className={
                                submitted && !password
                                  ? "brd-4 form-control is-invalid"
                                  : ""
                              }
                              id="pass_log_id"
                              required="required"
                            />
                            <span className="highlight"></span>
                            <label>Password</label>
                            {submitted && !password && (
                              <div className="invalid-feedback">
                                Password is required
                              </div>
                            )}
                          </span>
                          <span
                            className={
                              submitted && !password
                                ? "icn-pstn-invalid"
                                : "icn-pstn"
                            }
                            onClick={showPassword}
                          >
                            <i
                              className={
                                visible == false
                                  ? "fa fa-eye"
                                  : "fa fa-eye-slash"
                              }
                              id="togglePassword"
                            ></i>
                          </span>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="custom-control custom-checkbox small pl-0">
                          <input
                            type="checkbox"
                            name="rememberMe"
                            value="rememberMe"
                            checked={rememberMe == true}
                            onChange={handleCheckbox}
                            className="custom-control-input"
                            id="customCheck"
                          />
                          <label
                            className="custom-control-label small-fg w-25"
                            htmlFor="customCheck"
                          >
                            Remember Me
                          </label>
                          {isAdmin != true && (
                            <a
                              href="/forgotPassword"
                              className="small-fg"
                              style={{ float: "right" }}
                            >
                              Forgot Password?
                            </a>
                          )}
                        </div>
                      </div>

                      <a
                        onClick={handleLogin}
                        className="brd-4 btn btn-primary btn-user btn-block a-cancel mt-5"
                      >
                        Login
                      </a>
                      <p className="text-center">or</p>
                      {isAdmin != true ? (
                        <a
                          href="/admin-login"
                          className="text-center a-removed-href-dashed"
                        >
                          {" "}
                          <p className="text-center">Login as Admin</p>
                        </a>
                      ) : (
                        <a
                          href="/login"
                          className="text-center a-removed-href-dashed"
                        >
                          {" "}
                          <p className="text-center">
                            Go back to Customer Login
                          </p>
                        </a>
                      )}

                      <p className="ftr-login-txt">
                        © {year} Digiplatform. All rights reserved{" "}
                      </p>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
