import React from "react";
// import logo from './logo.svg';
// Import the Routes component, which contains our Route setup
import Routes from "./routes";

// Provider component is a react-redux component for setting up the Redux Store
import { Provider } from "react-redux";

import { ConnectedRouter } from "connected-react-router";

// Import the ConfigureStore that holds the initial Configuration
import { configureStore, history } from "./store/configure.store";
import i18n from "./i18n/i18n";
// Create a Store from the Configuration, we can pass a Initial State here
const { store } = configureStore();

const App = () => {
  return (
    //Provider needs to contain all the Containers/Components it will give access to the Store
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Routes />
      </ConnectedRouter>
    </Provider>
  );
};

export default App;
