import { ApiService } from '../../services/api.service';
import * as Flowchart from './FlowchartConstants';
import { showAlert } from '../alert/showAlert';
import { trackPromise } from 'react-promise-tracker';

// Flowchart Listing Actions

export function listFlowcharts(data) {
  return dispatch => {
    dispatch(listFlowchartsRequested());
    trackPromise(
      ApiService.callListFlowcharts(data).then(res => {
      dispatch(listFlowchartsSuccess(res.data));
    }))
      .catch(err => {
        dispatch(listFlowchartsFailure(err.message));
        showAlert(dispatch, err);

      });
  };
}


const listFlowchartsRequested = () => ({
  type: Flowchart.LIST_FLOWCHARTS_REQUEST
});

const listFlowchartsSuccess = data => ({
  type: Flowchart.LIST_FLOWCHARTS_SUCCESS,
  payload: {
    ...data
  }
});

const listFlowchartsFailure = error => ({
  type: Flowchart.LIST_FLOWCHARTS_FAILURE,
  payload: {
    error
  }
});



//Archive listing for flowchart
export function listFlowchartArchives(data) {
  return dispatch => {
    dispatch(listFlowchartArchivesRequested());
    trackPromise(
      ApiService.callListFlowchartArchives(data).then(res => {
      dispatch(listFlowchartArchivesSuccess(res.data));
    }))
      .catch(err => {
        dispatch(listFlowchartArchivesFailure(err.message));
        showAlert(dispatch,err);

      });
  };
}


const listFlowchartArchivesRequested = () => ({
  type: Flowchart.LIST_FLOWCHART_ARCHIVE_REQUEST
});

const listFlowchartArchivesSuccess = data => ({
  type: Flowchart.LIST_FLOWCHART_ARCHIVE_SUCCESS,
  payload: {
    ...data
  }
});

const listFlowchartArchivesFailure = error => ({
  type: Flowchart.LIST_FLOWCHART_ARCHIVE_FAILURE,
  payload: {
    error
  }
});


// Get Flowchart for customer  Actions

export function getFlowchartForCustomer(data) {
  return dispatch => {
    dispatch(getFlowchartForCustomerRequested());
    trackPromise(
      ApiService.callGetFlowchartForCustomer(data).then(res => {
      dispatch(getFlowchartForCustomerSuccess(res.data));
    }))
      .catch(err => {
        dispatch(getFlowchartForCustomerFailure(err.message));
        showAlert(dispatch, err);

      });
  };
}


const getFlowchartForCustomerRequested = () => ({
  type: Flowchart.GET_FLOWCHART_FOR_CUSTOMER_REQUEST
});

const getFlowchartForCustomerSuccess = data => ({
  type: Flowchart.GET_FLOWCHART_FOR_CUSTOMER_SUCCESS,
  payload: {
    ...data
  }
});

const getFlowchartForCustomerFailure = error => ({
  type: Flowchart.GET_FLOWCHART_FOR_CUSTOMER_FAILURE,
  payload: {
    error
  }
});


export function resetFlowchartDetails(){
  return { type: Flowchart.RESET_FLOWCHART_DETAILS };
}