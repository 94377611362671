import React, { useState } from "react";

const CheckboxField = ({ attribs }) => {
  const [value, setValue] = useState(
    attribs["data-checked"] === "true" || false
  );

  return (
    <input
      type="checkbox"
      {...attribs}
      required={attribs.required === "" ? true : false}
      data-checked={value}
      checked={value}
      onChange={(e) => setValue(e.target.checked || false)}
    />
  );
};

export default CheckboxField;
