import React, { useState, useEffect } from "react";
import {
  listCategory,
  getTicketDetails,
  resetTicketDetails,
  addInternalJobTicket,
  CallCustomerUsers,
  CallInternalTicketCategoryList,
  callUpdateInternalJobTicket,
} from "./JobTicketActions";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Icon from "react-feather";
import PageTopHeader from "src/app/components/PageTopHeader";
import { CustomerId, companyLogo } from "src/app/config";

const CreateInternalJobTicket = (props) => {
  const editJobTicketData = props?.location?.state;

  const [inputFields, setInputFields] = useState(
    editJobTicketData?.relatedLinks || [""]
  );

  const handleAddField = () => {
    setInputFields([...inputFields, ""]);
  };

  const handleRemoveField = (index) => {
    const newInputFields = [...inputFields];
    newInputFields.splice(index, 1);
    setInputFields(newInputFields);
  };

  const handleLinkInputChange = (index, event) => {
    const newInputFields = [...inputFields];
    newInputFields[index] = event.target.value;
    setInputFields(newInputFields);
  };
  const [selectedStatus, setSelectedStatus] = useState(
    props?.location?.state?.ticketStatus || "notstarted"
  );
  const [submitted, setSubmitted] = useState(false);
  const [categoryList, setCategoryList] = useState();

  const [inputs, setInputs] = useState({
    jobId: "",
    completionDate: "",
    ticketDetails: "",
    relatedLinks: "",
    categoryName: "",
    customerId: "",
    assignedToUserId: "",
  });
  const [docId] = useState(
    props.location && props.location.state && props.location.state.id
      ? props.location.state.id
      : ""
  );
  const [selectedFile, setselectedFile] = useState([]);
  const [deletedFile, setDeletedFile] = useState([]);
  const [filePath, setFilePath] = useState([]);
  const [internalUsers, setInternalUsers] = useState([]);
  const { jobId, ticketDetails } = inputs;
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    setInputs((inputs) => ({ ...inputs }));
    dispatch(listCategory());
    const search = new URLSearchParams(location.search);
    const id = search.get("id");
    dispatch(CallCustomerUsers(CustomerId)).then((res) => {
      setInternalUsers(res?.data?.data);
    });
    dispatch(CallInternalTicketCategoryList()).then((res) => {
      setCategoryList(res?.data?.data);
    });
    if (id != null) {
      setIsEdit(true);
      dispatch(getTicketDetails(id));
    }
  }, []);
  useEffect(() => {
    setInputs({
      jobId: editJobTicketData?.jobTicketId,
      completionDate: editJobTicketData?.completionDate,
      categoryName: editJobTicketData?.categoryId,
      relatedUrl: editJobTicketData?.relatedUrl,
      ticketDetails: editJobTicketData?.ticketDetails,
      relatedLinks: editJobTicketData?.relatedLinks,
      assignedToUserId: editJobTicketData?.assignedToUserId,
    });
    setFilePath(editJobTicketData?.InternalTicketAttachments);
  }, [editJobTicketData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  // multiple file upload

  const onFileChange = (event) => {
    var newArray = selectedFile;
    newArray.push(event.target.files[0]);
    setselectedFile([...newArray]);
  };
  const deleteList = (e, index) => {
    e.preventDefault();
    var delArray = selectedFile;
    delArray.splice(index, 1);
    setselectedFile([...delArray]);
  };

  const deleteExistingList = (e, val, index) => {
    e.preventDefault();
    var delArray = filePath;
    delArray.splice(index, 1);
    setFilePath([...delArray]);
    var newArray = deletedFile;
    newArray.push(val);
    setDeletedFile([...newArray]);
  };

  const fileData = () => {
    if (filePath?.length > 0 && isEdit == true) {
      return (
        <div>
          <ol>
            {filePath &&
              filePath?.map((val, index) => (
                <li>
                  <div
                    id="output"
                    className="file-clr d-flex justify-content-between"
                  >
                    <a href={val.url} className="a-removed-href">
                      {val.name}
                    </a>
                    <a
                      className="trash-btn a-removed-href"
                      onClick={(e) => deleteExistingList(e, val, index)}
                    >
                      <Icon.X className="trash-icn" />
                    </a>
                  </div>
                </li>
              ))}
          </ol>
        </div>
      );
    } else {
      return <div></div>;
    }
  };
  const pageUrl = window.location.origin + "/home";

  const createTicket = () => {
    setSubmitted(true);

    if (inputs.ticketDetails && inputs.categoryName && inputs.completionDate) {
      const formData = new FormData();
      if (selectedFile) {
        for (var x = 0; x < selectedFile.length; x++) {
          formData.append("formFiles", selectedFile[x], selectedFile[x].name);
          formData.append("uploadFileName", selectedFile[x].name);
        }
      }

      formData.append("ticketDetails", ticketDetails);
      formData.append("categoryId", inputs.categoryName);
      formData.append("completionDate", inputs.completionDate);
      formData.append("customerId", CustomerId);
      formData.append("relatedLinks", inputFields?.join(","));
      formData.append("assignedToUserId", inputs.assignedToUserId);
      formData.append("customerLogo", companyLogo);
      formData.append("applicationUrl", pageUrl);
      deletedFile && formData.append("deleteList", JSON.stringify(deletedFile));
      if (isEdit == false) {
        dispatch(addInternalJobTicket(formData));
      } else {
        formData.append("ticketStatus", selectedStatus);

        var editedData = {
          id: editJobTicketData?.id,
        };
        dispatch(resetTicketDetails());

        dispatch(callUpdateInternalJobTicket(editedData, formData));
      }
    }
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  return (
    <div className="outer-wrap">
      <PageTopHeader
        rightTitle={"Back To Home"}
        url={"/home"}
        Righticon={<Icon.ArrowLeft />}
      />
      <div className="container-wrap internalTicket-Creation">
        <div className="card p-2">
          <div className="card-body">
            <form className="user">
              <div className="form-group text-center pb-3">
                <div className="st-tl">
                  <h1 className="mh-head-txt">
                    {" "}
                    {isEdit
                      ? "Update Internal Job Ticket"
                      : "Create a Internal Job Ticket"}
                  </h1>
                </div>
              </div>

              <div className="form-row">
                <div className="col">
                  <div className="group">
                    <div
                      className={
                        isEdit || docId ? "select disabled-cst" : "select"
                      }
                    >
                      <select
                        value={inputs.categoryName}
                        disabled={isEdit || docId}
                        className={
                          submitted && !inputs.categoryName
                            ? " select-text form-control is-invalid "
                            : "select-text"
                        }
                        required
                        name="categoryName"
                        onChange={handleChange}
                      >
                        <option value="" disabled defaultValue></option>
                        {categoryList &&
                          categoryList
                            .filter((catg) => catg.id != 8)
                            .map((category, index) => (
                              <option key={index} value={category.id}>
                                {category.categoryName}
                              </option>
                            ))}
                      </select>
                      <label className="select-label">Category</label>
                      {submitted && !inputs.categoryName && (
                        <div className="invalid-feedback">
                          Category is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className={jobId ? "group disabled-cst" : "group"}>
                    <input
                      type="text"
                      disabled={true}
                      name="jobId"
                      value={jobId}
                      onChange={handleChange}
                    />
                    <span className="highlight"></span>
                    <label>Job#</label>
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="col">
                  <div className="group">
                    <div className="select">
                      <select
                        value={inputs.assignedToUserId}
                        className={
                          submitted && !inputs.assignedToUserId
                            ? " select-text form-control is-invalid "
                            : "select-text"
                        }
                        required
                        name="assignedToUserId"
                        onChange={handleChange}
                      >
                        <option value="" disabled defaultValue></option>
                        {internalUsers?.map((users, index) => (
                          <option key={index} value={users.id}>
                            {users.firstName + " " + users.lastName}
                          </option>
                        ))}
                      </select>
                      <label className="select-label">Assign To</label>
                      {submitted && !inputs.assignedToUserId && (
                        <div className="invalid-feedback">
                          Assign a user is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="group">
                    <input
                      type="date"
                      className={
                        submitted && !inputs.completionDate
                          ? "brd-4 form-control is-invalid"
                          : ""
                      }
                      aria-label="Text input with segmented dropdown button"
                      name="completionDate"
                      value={inputs.completionDate}
                      onChange={handleChange}
                      required="required"
                    ></input>
                    <span className="highlight"></span>
                    <label>Desired Completion Date</label>
                    {submitted && !inputs.completionDate && (
                      <div className="invalid-feedback">Date is required</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="form__group">
                <textarea
                  id="message"
                  className={
                    submitted && !ticketDetails
                      ? "form__field  form-control is-invalid"
                      : "form__field"
                  }
                  name="ticketDetails"
                  value={ticketDetails}
                  onChange={handleChange}
                  placeholder="Your Message"
                  rows="8"
                ></textarea>
                <label for="message" className="form__label">
                  Ticket Details
                </label>
                {submitted && !ticketDetails && (
                  <div className="invalid-feedback">Details is required</div>
                )}
              </div>
              <div className="form__group">
                <div>
                  {inputFields?.map((inputField, index) => (
                    <div
                      key={index + 1}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                      }}
                      className="mb-2"
                    >
                      <input
                        type="text"
                        class="form-control"
                        aria-label="Default"
                        id="relatedUrl"
                        name="relatedUrl"
                        value={inputField}
                        onChange={(event) =>
                          handleLinkInputChange(index, event)
                        }
                        placeholder={
                          inputFields?.length > 1 && index !== 0
                            ? "Paste another link related to the assigning task"
                            : "Paste any link related to the assigning task"
                        }
                      />
                      {inputFields?.length > 1 ? (
                        <button
                          type="button"
                          onClick={() => handleRemoveField(index)}
                          className="btn btn-danger ml-2"
                        >
                          <Icon.Trash
                            style={{ height: "20px", width: "20px" }}
                          />
                        </button>
                      ) : (
                        <div>
                          <button
                            type="button"
                            onClick={handleAddField}
                            className="btn btn-primary ml-2"
                          >
                            <Icon.Plus
                              style={{ height: "20px", width: "20px" }}
                            />
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                  {inputFields?.length > 1 && (
                    <div style={{ textAlign: "right" }}>
                      <button
                        type="button"
                        onClick={handleAddField}
                        className="btn btn-primary "
                      >
                        <Icon.Plus style={{ height: "20px", width: "20px" }} />
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <h2 className="tab-head pt-5 pb-3"> Upload Details</h2>
              {filePath?.length > 0 && isEdit == true && fileData()}

              <div>
                {isEdit == true && filePath?.length > 0 ? (
                  <h2 className="tab-head pt-3 pb-3">Add new files:</h2>
                ) : (
                  ""
                )}
                <input type="file" onChange={onFileChange} />
              </div>
              <br />
              <ol>
                {selectedFile.map((val, index) => {
                  return (
                    <li>
                      <div
                        id="output"
                        className="file-clr d-flex justify-content-between"
                      >
                        <a href={val?.url} className="a-removed-href">
                          {val?.name}
                        </a>

                        <a
                          className="trash-btn a-removed-href"
                          onClick={(e) => deleteList(e, index)}
                        >
                          <Icon.X className="trash-icn" />
                        </a>
                      </div>
                    </li>
                  );
                })}
              </ol>
              <h2 className="tab-head pt-3 pb-3">Ticket Status</h2>
              <div className="form__group d-flex align-items-center">
                <input
                  type="radio"
                  name="status"
                  value="notstarted"
                  checked={selectedStatus === "notstarted"}
                  onChange={handleStatusChange}
                  style={{ marginRight: "5px", height: "20px", width: "20px" }}
                />
                Not Started
                <input
                  type="radio"
                  name="status"
                  value="completed"
                  checked={selectedStatus === "completed"}
                  onChange={handleStatusChange}
                  style={{
                    marginRight: "5px",
                    marginLeft: "20px",
                    height: "20px",
                    width: "20px",
                  }}
                />
                Completed
                <input
                  type="radio"
                  name="status"
                  value="rejected"
                  checked={selectedStatus === "rejected"}
                  onChange={handleStatusChange}
                  style={{
                    marginRight: "5px",
                    marginLeft: "20px",
                    height: "20px",
                    width: "20px",
                  }}
                />
                Rejected
              </div>

              <a
                onClick={createTicket}
                className="brd-4 btn btn-primary btn-user btn-block mt-5 a-cancel"
              >
                {isEdit ? "Update Ticket" : "Create Ticket"}
              </a>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateInternalJobTicket;
