import { ApiService } from "../../services/api.service";
import * as Ticket from "./JobTicketConstants";
import { history } from "../../../helpers/history";
import { alertActions } from "../alert/alertActions";
import { showAlert } from "../alert/showAlert";
import { trackPromise } from "react-promise-tracker";

//Add job ticket

export function addJobTicket(data) {
  return (dispatch) => {
    dispatch(addJobTicketRequested());
    trackPromise(
      ApiService.callAddJobTicket(data).then((res) => {
        dispatch(addJobTicketSuccess(res.data));
        if (res.data.status == "success") {
          history.push("/home");
        }
        dispatch(alertActions.success(res.data.message));
      })
    ).catch((err) => {
      dispatch(addJobTicketFailure(err.message));
      showAlert(dispatch, err);
    });
  };
}
export function addInternalJobTicket(data) {
  return (dispatch) => {
    dispatch(addJobTicketRequested());
    trackPromise(
      ApiService.callAddInternalJobTicket(data).then((res) => {
        dispatch(addJobTicketSuccess(res.data));
        if (res.data.status == "success") {
          history.push("/home");
        }
        dispatch(alertActions.success(res.data.message));
      })
    ).catch((err) => {
      dispatch(addJobTicketFailure(err.message));
      showAlert(dispatch, err);
    });
  };
}

const addJobTicketRequested = () => ({
  type: Ticket.ADD_JOB_TICKET_REQUEST,
});

const addJobTicketSuccess = (data) => ({
  type: Ticket.ADD_JOB_TICKET_SUCCESS,
  payload: {
    ...data,
  },
});

const addJobTicketFailure = (error) => ({
  type: Ticket.ADD_JOB_TICKET_FAILURE,
  payload: {
    error,
  },
});

//List Categories

export function listCategory(data) {
  return (dispatch) => {
    dispatch(listCategoryRequested());
    trackPromise(
      ApiService.callListCategory(data).then((res) => {
        dispatch(listCategorySuccess(res.data));
      })
    ).catch((err) => {
      dispatch(listCategoryFailure(err.message));
      showAlert(dispatch, err);
    });
  };
}

const listCategoryRequested = () => ({
  type: Ticket.LIST_CATEGORY_REQUEST,
});

const listCategorySuccess = (data) => ({
  type: Ticket.LIST_CATEGORY_SUCCESS,
  payload: {
    ...data,
  },
});

const listCategoryFailure = (error) => ({
  type: Ticket.LIST_CATEGORY_FAILURE,
  payload: {
    error,
  },
});

// Job Ticket Listing Actions

export function listJobTickets(data) {
  return (dispatch) => {
    dispatch(listticketsRequested());
    trackPromise(
      ApiService.callListJobTickets(data).then((res) => {
        dispatch(listticketsSuccess(res.data));
      })
    ).catch((err) => {
      dispatch(listticketsFailure(err.message));
      showAlert(dispatch, err);
    });
  };
}

export function listInternalJobTickets(data) {
  return (dispatch) => {
    dispatch(listticketsRequested());
    trackPromise(
      ApiService.callListInternalJobTickets(data).then((res) => {
        dispatch(listticketsSuccess(res.data));
      })
    ).catch((err) => {
      dispatch(listticketsFailure(err.message));
      showAlert(dispatch, err);
    });
  };
}

export function CallCustomerUsers(data) {
  return (dispatch) => {
    return trackPromise(
      ApiService.CallCustomerUsers(data).then((res) => {
        return res;
      })
    ).catch((err) => {
      dispatch(listticketsFailure(err.message));
      throw err;
    });
  };
}
export function CallInternalTicketCategoryList() {
  return (dispatch) => {
    return trackPromise(
      ApiService.CallInternalTicketCategory().then((res) => {
        return res;
      })
    ).catch((err) => {
      dispatch(listticketsFailure(err.message));
      throw err;
    });
  };
}

export function callViewJobTicket(data) {
  return (dispatch) => {
    return trackPromise(
      ApiService.callViewJobTicket(data).then((res) => {
        return res;
      })
    ).catch((err) => {
      dispatch(listticketsFailure(err.message));
      throw err;
    });
  };
}

const listticketsRequested = () => ({
  type: Ticket.LIST_TICKETS_REQUEST,
});

const listticketsSuccess = (data) => ({
  type: Ticket.LIST_TICKETS_SUCCESS,
  payload: {
    ...data,
  },
});

const listticketsFailure = (error) => ({
  type: Ticket.LIST_TICKETS_FAILURE,
  payload: {
    error,
  },
});

// Update Job Ticket

export function updateJobTicket(data, formData) {
  return (dispatch) => {
    dispatch(updateJobTicketRequested());
    trackPromise(
      ApiService.callUpdateJobTicket(data, formData).then((res) => {
        dispatch(updateJobTicketSuccess(res.data));
        if (res.data.status == "success") {
          history.push("/home");
        }
        dispatch(alertActions.success(res.data.message));
      })
    ).catch((err) => {
      dispatch(updateJobTicketFailure(err.message));
      showAlert(dispatch, err);
    });
  };
}

export function callUpdateInternalJobTicket(data, formData) {
  return (dispatch) => {
    dispatch(updateJobTicketRequested());
    trackPromise(
      ApiService.callUpdateInternalJobTicket(data, formData).then((res) => {
        dispatch(updateJobTicketSuccess(res.data));
        if (res.data.status == "success") {
          history.push("/home");
        }
        dispatch(alertActions.success(res.data.message));
      })
    ).catch((err) => {
      dispatch(updateJobTicketFailure(err.message));
      showAlert(dispatch, err);
    });
  };
}

const updateJobTicketRequested = () => ({
  type: Ticket.UPDATE_JOB_TICKET_REQUEST,
});

const updateJobTicketSuccess = (data) => ({
  type: Ticket.UPDATE_JOB_TICKET_SUCCESS,
  payload: {
    ...data,
  },
});

const updateJobTicketFailure = (error) => ({
  type: Ticket.UPDATE_JOB_TICKET_FAILURE,
  payload: {
    error,
  },
});

// Get Ticket Details Actions

export function getTicketDetails(data) {
  return (dispatch) => {
    dispatch(getTicketDetailsRequested());
    trackPromise(
      ApiService.callGetTicketDetails(data).then((res) => {
        dispatch(getTicketDetailsSuccess(res.data));
      })
    ).catch((err) => {
      dispatch(getTicketDetailsFailure(err.message));
      showAlert(dispatch, err);
    });
  };
}

const getTicketDetailsRequested = () => ({
  type: Ticket.GET_TICKET_DETAILS_REQUEST,
});

const getTicketDetailsSuccess = (data) => ({
  type: Ticket.GET_TICKET_DETAILS_SUCCESS,
  payload: {
    ...data,
  },
});

const getTicketDetailsFailure = (error) => ({
  type: Ticket.GET_TICKET_DETAILS_FAILURE,
  payload: {
    error,
  },
});

export function resetTicketDetails() {
  return { type: Ticket.RESET_TICKET_DETAILS };
}
