import { alertActions } from "../alert/alertActions";
import { history } from "../../../helpers/history";

export const showAlert = (dispatch, err) => {
  const currentPath = history.location.pathname;

  // Show error message based on the error
  if (err.response && err.response.status === 401) {
    // If error is 401, redirect to login page
    if (
      currentPath === "/external-livetemplate/login" ||
      "/external-user-livetemplate"
    ) {
      // Redirect to another login page
      history.push("/external-livetemplate/login");
    } else {
      history.push("/login");
      dispatch(alertActions.error("Please login again"));
    }
  } else {
    // Show error message
    dispatch(alertActions.error(err.message));
  }
};
