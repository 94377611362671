import React, { useState, useEffect } from "react";
import { history } from "../../../helpers/history";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { getNotification } from "../../components/header/notificationActions";
import Pagination from "../../components/Pagination/Pagination";
import { format } from "date-fns";
import { CustomerId, companyLogo } from "src/app/config";
import $ from "jquery";
import * as Icon from "react-feather";
import ConfirmationModal from "src/app/components/ConfirmationModal/ConfirmationModal";
import { ApiService } from "src/app/services/api.service";
import { trackPromise } from "react-promise-tracker";
import { showAlert } from "../alert/showAlert";
import {
  callGetLiveRecordShareByEmail,
  deleteSingleLiveRecord,
  deleteSingleLiveRecordPdf,
} from "./liveRecordActions";
import Cookies from "js-cookie";
import Select from "react-select";
import { callListProjects } from "../userManagement/actions";
import SearchBox from "src/app/components/SearchBox";

const LiveRecordDetails = () => {
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const dispatch = useDispatch();
  const [sortParams, setSortParams] = useState({
    sortBy: "DESC",
    sortTitle: "updatedAt",
  });
  const userRole = decodeURIComponent(Cookies.get("userRole"));
  const [selectedProject, setSelectedProject] = useState("");
  const [projectOptions, setProjectOptions] = useState();
  const [templateId, setTemplateId] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [tempIdForLinkShare, setTempIdForLinkShare] = useState();
  const [months] = useState([
    { label: "All Month", value: "0" },
    { label: "Jan", value: "1" },
    { label: "Feb", value: "2" },
    { label: "Mar", value: "3" },
    { label: "Apr", value: "4" },
    { label: "May", value: "5" },
    { label: "Jun", value: "6" },
    { label: "Jul", value: "7" },
    { label: "Aug", value: "8" },
    { label: "Sep", value: "9" },
    { label: "Oct", value: "10" },
    { label: "Nov", value: "11" },
    { label: "Dec", value: "12" },
  ]);
  const [yearSelected, setYearSelected] = useState();
  const [monthSelected, setMonthSelected] = useState("0");
  const [isArrowDown, setIsArrowDown] = useState(false);
  const [recordDetailsListData, setRecordDetailsListData] = useState();
  const [selectedRecord, setselectedRecord] = useState("");
  const [selectedRecordPdf, setSelectedRecordPdf] = useState("");
  const [accordianId, setAccordianId] = useState();
  const [isPdfDelete, setIsPdfDelete] = useState(false);
  const [isPdfDeleteModalOpen, setIsPdfDeleteModalOpen] = useState(false);
  const location = useLocation();
  const [shareEmailOpen, setShareEmailOpen] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [emailArray, setEmailArray] = useState([]);
  const [singleTempEmailShare, setSingleTempEmailShare] = useState();

  useEffect(() => {
    dispatch(callListProjects(CustomerId)).then((res) => {
      if (res?.data?.data) {
        const newProjectoptions = res?.data?.data?.map((item) => ({
          ["value"]: item?.id,
          ["label"]: item?.projectName,
        }));
        if (newProjectoptions) {
          setProjectOptions(() => [
            { value: "", label: "All Projects" },
            ...newProjectoptions,
          ]);
        }
      } else {
        return null;
      }
    });

    window.onbeforeunload = null;
    // Listing Record Details
    const search = new URLSearchParams(location.search);
    const id = search.get("id");
    const year = search.get("year");
    if (id != null) {
      setTemplateId(id);
      setYearSelected(year);
    }
    getNotificationsList();
  }, []);

  useEffect(() => {
    if (templateId) {
      getLiveRecordDetailsList();
    }
  }, [selectedProject, monthSelected, templateId, searchValue, isPdfDelete]);

  const getNotificationsList = () => {
    const data = {
      numPerPage: 10,
      page: 1,
      customerId: CustomerId,
      userId: localStorage.getItem("userId"),
    };
    dispatch(getNotification(data));
  };

  const getLiveRecordDetailsList = (page = 1, pageSize = 100) => {
    const data = {
      numPerPage: pageSize,
      page: page == 0 ? 1 : page,
      userId: localStorage.getItem("userId"),
      sortKey: sortParams.sortTitle,
      sortType: sortParams.sortBy,
      searchKey: searchValue,
      liveTemplateId: templateId,
      month: monthSelected?.value ? Number(monthSelected?.value) : "0",
      projectId: selectedProject?.value ? Number(selectedProject?.value) : "",
      year: yearSelected,
    };
    trackPromise(
      ApiService.callGetLiveRecordDetails(data).then((res) => {
        setRecordDetailsListData(res?.data);
        setTemplateName(res?.data?.data[0]?.LiveTemplate?.liveTemplateName);
      })
    ).catch((err) => {
      showAlert(dispatch, err);
    });
  };

  const onPageChanged = (data) => {
    if (data && data.currentPage == currentPageNumber) {
      return;
    }
    if (data && data.currentPage) {
      const { currentPage } = data;
      setCurrentPageNumber(currentPage);
      getLiveRecordDetailsList(currentPage);
    }
  };

  const sortTable = (title) => {
    var sortByOrder = "ASC";

    if (title == sortParams.sortTitle) {
      sortByOrder = sortParams.sortBy == "DESC" ? "ASC" : "DESC";
      setSortParams({ sortBy: sortByOrder, sortTitle: title });
    } else {
      setSortParams({ sortBy: "ASC", sortTitle: title });
    }

    // Call Api here for sorting
    const data = {
      numPerPage: 100,
      page: currentPageNumber,
      userId: localStorage.getItem("userId"),
      sortKey: title,
      sortType: sortByOrder,
      searchKey: searchValue,
      liveTemplateId: templateId,
      month: monthSelected?.value ? Number(monthSelected?.value) : "0",
      year: yearSelected,
      projectId: selectedProject?.value ? Number(selectedProject?.value) : "",
    };
    trackPromise(
      ApiService.callGetLiveRecordDetails(data).then((res) => {
        setRecordDetailsListData(res?.data);
        setTemplateName(res?.data?.data[0]?.LiveTemplate?.liveTemplateName);
      })
    ).catch((err) => {
      showAlert(dispatch, err);
    });
  };

  const OpenInNewTabHandler = (data) => {
    const url = data;
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(url).select();
    document.execCommand("copy");
    $temp.remove();
    window.open(url, "_blank", "noreferrer");
  };

  const handleDeleteRecord = (data) => {
    setselectedRecord(data);
  };
  const handleDeleteRecordPdf = (data) => {
    setIsPdfDeleteModalOpen(true);
    setSelectedRecordPdf(data);
  };

  const deleteRecord = () => {
    dispatch(deleteSingleLiveRecord(selectedRecord?.id));
    getLiveRecordDetailsList();
  };
  const deleteRecordPdf = () => {
    dispatch(deleteSingleLiveRecordPdf(selectedRecordPdf?.id));
    getLiveRecordDetailsList();
    setIsPdfDelete(!isPdfDelete);
    setIsPdfDeleteModalOpen(false);
  };
  const gotoEditLiveTemplate = (tempId) => {
    history.push(`/home/editableLiveRecord/${tempId}`);
  };

  const handleAccordianArrow = (selectedId) => {
    setAccordianId(selectedId);
    setIsArrowDown(!isArrowDown);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  function validateEmail(email) {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return regex.test(email);
  }

  const [newEmail, setNewEmail] = useState("");
  const [error, setError] = useState("");

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const value = newEmail.trim();

      if (!value) {
        setError("The email must be a valid email address.");
      } else if (!validateEmail(value)) {
        setError("Invalid email format");
      } else if (emailArray.includes(value)) {
        setError(
          "Adding the same email address more than once is not necessary."
        );
      } else {
        setEmailArray([...emailArray, value]);
        setNewEmail("");
        setError("");
      }
    }
  };

  const removeTag = (index) => {
    const updatedEmailArray = emailArray.filter((_, i) => i !== index);
    setEmailArray(updatedEmailArray);
    setSingleTempEmailShare();
  };

  const handleEmailCancel = () => {
    setEmailArray([]);
    setCheckedItems([]);
    setError("");
    setNewEmail("");
  };
  const handleEmailSend = () => {
    let params = {};
    if (singleTempEmailShare) {
      params = {
        Data: [singleTempEmailShare],
        emails: emailArray,
        customerLogo: companyLogo,
      };
    } else {
      params = {
        Data: checkedItems,
        emails: emailArray,
        customerLogo: companyLogo,
      };
    }

    dispatch(callGetLiveRecordShareByEmail(params));
    setShareEmailOpen(!shareEmailOpen);
    setEmailArray([]);
    setCheckedItems([]);
    setError("");
    setNewEmail("");
    setSingleTempEmailShare();
  };
  const handleCheckboxChange = (selectedTemp) => {
    const existingItem = checkedItems.find(
      (item) => item.id === selectedTemp.id
    );

    if (existingItem) {
      setCheckedItems((prevCheckedItems) =>
        prevCheckedItems.filter((item) => item.id !== existingItem.id)
      );
    } else {
      setCheckedItems((prevCheckedItems) => [
        ...prevCheckedItems,
        selectedTemp,
      ]);
    }
  };

  return (
    <div>
      <div class="d-flex justify-content-between">
        <div class="st-tl">
          <h1 class="mh-head-txt pt-3">{templateName}</h1>
        </div>
        <div class="aad-usr pt-3">
          <a className="a-removed-href-create" onClick={() => history.goBack()}>
            Go back to Live Records
          </a>
        </div>
      </div>
      <hr class="pb-3" />

      <div class="d-flex justify-content-between mt-5 align-items-center">
        <div class="col-md-4 p-0 d-flex justify-content-end align-items-center">
          <SearchBox onSearch={handleSearch} />
        </div>
        <div class="col-md-8 p-0 d-flex justify-content-end align-items-center">
          <div class="d-flex col-md-12 d-flex justify-content-end pr-0 align-items-center">
            <div class="col-md-6 p-0">
              <Select
                name="projects"
                value={selectedProject}
                onChange={setSelectedProject}
                placeholder={"Select Project"}
                options={projectOptions}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    padding: "0.4rem",
                  }),
                }}
              />
            </div>

            <div class="col-md-3 p-0">
              <Select
                name="projects"
                value={monthSelected}
                onChange={setMonthSelected}
                placeholder={"Month"}
                options={months}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    padding: "0.4rem",
                    marginLeft: "15px",
                  }),
                }}
              />
            </div>
            <div class="col-md-3 p-0 d-flex justify-content-end">
              <button
                disabled={checkedItems.length <= 0}
                className={`btn btn-dark ${
                  checkedItems.length <= 0 ? "disabled" : ""
                } p-2 `}
                data-toggle="modal"
                data-target="#exampleModalCenterForRecord"
                onClick={() => setShareEmailOpen(true)}
                title={checkedItems.length <= 0 ? "select Records" : ""}
                styles={{
                  padding: "0.4rem",
                }}
              >
                Share via Email
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="table-wraper table-responsive mnt mt-3">
            <table class="table job-list">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">No</th>
                  <th scope="col">Name</th>
                  <th scope="col">Description</th>
                  <th
                    scope="col"
                    onClick={() => {
                      sortTable("updatedAt");
                    }}
                  >
                    <div class="d-flex justify-content-start">
                      <div>Date</div>
                      <div class="sort-wrp ml-2">
                        <i
                          class={
                            sortParams.sortTitle == "updatedAt" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-asc fa-lg active sort_up"
                              : "fa fa-sort-asc fa-lg sort_fade_down"
                          }
                        ></i>
                        <i
                          class={
                            sortParams.sortTitle == "updatedAt" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-desc fa-lg sort_fade_down"
                              : sortParams.sortTitle == "updatedAt" &&
                                sortParams.sortBy == "DESC"
                              ? "fa fa-sort-desc fa-lg active sort_up"
                              : "fa fa-sort-desc fa-lg sort_fade_down"
                          }
                        ></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col">Updated by</th>
                  <th scope="col" className="text-center">
                    Update
                  </th>
                  {userRole !== "User" && (
                    <th scope="col" className="text-center">
                      Delete
                    </th>
                  )}
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {recordDetailsListData &&
                  !recordDetailsListData.loading &&
                  recordDetailsListData.error && (
                    <span className="text-danger">
                      ERROR: {recordDetailsListData.error}
                    </span>
                  )}
                {recordDetailsListData &&
                  recordDetailsListData?.data?.map((record, index, array) => (
                    <React.Fragment key={index + 1}>
                      <tr key={index + 1}>
                        <td>
                          <div class="form-check"></div>
                        </td>
                        <td>{array.length - index}</td>
                        <td>{record?.liveRecordName}</td>
                        <td>{record?.liveRecordDescription}</td>
                        <td>
                          {record.updatedAt
                            ? format(
                                new Date(record.updatedAt),
                                "yyyy-MM-dd : hh.mm aa"
                              )
                            : "-"}
                        </td>

                        <td>{record?.customerUpdatedBy?.firstName}</td>
                        {userRole !== "User" ? (
                          <td className="text-center">
                            <div>
                              <a
                                className="a-removed-href"
                                onClick={() => gotoEditLiveTemplate(record.id)}
                              >
                                <Icon.Edit />
                              </a>
                            </div>
                          </td>
                        ) : (
                          <td class="text-center">-</td>
                        )}

                        {userRole !== "User" && (
                          <td class="text-center">
                            <a
                              className="a-removed-href"
                              data-toggle="modal"
                              data-target="#deleteConfirmationModal"
                              onClick={() => {
                                handleDeleteRecord(record);
                              }}
                            >
                              <Icon.Trash />
                            </a>
                          </td>
                        )}
                        <td
                          className="text-center"
                          data-toggle="collapse"
                          data-target={`#collapse_${record?.id}`}
                          aria-expanded="false"
                          aria-controls={`collapse_${record?.id}`}
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => handleAccordianArrow(record?.id)}
                        >
                          {accordianId === record?.id && isArrowDown ? (
                            <Icon.ChevronUp />
                          ) : (
                            <Icon.ChevronDown />
                          )}
                        </td>
                      </tr>
                      {record?.SavedLiveRecords.length > 0 ? (
                        record?.SavedLiveRecords?.slice()
                          .sort(
                            (a, b) =>
                              new Date(b.updatedAt) - new Date(a.updatedAt)
                          )
                          .map((recordPdf, index) => (
                            <tr
                              className="collapse"
                              id={`collapse_${record?.id}`}
                              style={{
                                background: "rgb(179 179 179)",
                              }}
                              key={index + 1}
                            >
                              <td>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    onChange={() =>
                                      handleCheckboxChange({
                                        id: recordPdf?.id,
                                        recordName: record?.liveRecordName,
                                        url: recordPdf?.pdfFileName
                                          ?.shareableUrl,

                                        description:
                                          recordPdf?.savedLiveRecordDescription !==
                                          "No Description"
                                            ? recordPdf?.savedLiveRecordDescription
                                            : "No Description",
                                      })
                                    }
                                    id="flexCheckDefault"
                                    checked={
                                      checkedItems.length > 0 &&
                                      checkedItems.some(
                                        (item) => item.id === recordPdf?.id
                                      )
                                    }
                                  />
                                </div>
                              </td>

                              <td>{index + 1}</td>
                              <td>{recordPdf?.savedLiveRecordName}</td>
                              <td>{recordPdf?.savedLiveRecordDescription} </td>

                              <td>
                                {" "}
                                {recordPdf.updatedAt
                                  ? format(
                                      new Date(recordPdf.updatedAt),
                                      "yyyy-MM-dd : hh.mm aa"
                                    )
                                  : "-"}
                              </td>
                              <td></td>
                              <td class="text-center">
                                <a
                                  className="a-removed-href"
                                  data-placement="right"
                                  title="Link Open in tab"
                                  onClick={() => {
                                    setShareEmailOpen(false);
                                    setTempIdForLinkShare(record?.fileName);
                                    setSingleTempEmailShare({
                                      id: recordPdf?.id,
                                      recordName: record?.liveRecordName,
                                      url: recordPdf?.pdfFileName?.fileUrl,
                                      description:
                                        recordPdf?.savedLiveRecordDescription !==
                                        "No Description"
                                          ? recordPdf?.savedLiveRecordDescription
                                          : "No Description",
                                    });
                                  }}
                                  data-toggle="modal"
                                  data-target="#exampleModalCenterForRecord"
                                >
                                  <Icon.Share style={{ color: "black" }} />
                                </a>
                              </td>

                              {userRole !== "User" && (
                                <td class="text-center">
                                  <a
                                    className="a-removed-href"
                                    data-toggle="modal"
                                    data-target="#deleteConfirmationModal"
                                    onClick={() => {
                                      handleDeleteRecordPdf(recordPdf);
                                    }}
                                  >
                                    <Icon.Trash style={{ color: "black" }} />
                                  </a>
                                </td>
                              )}
                              <td> </td>
                            </tr>
                          ))
                      ) : (
                        <tr
                          style={{ padding: "10px !important" }}
                          className="collapse"
                          id={`collapse_${record?.id}`}
                        >
                          <td style={{ paddingTop: 0, paddingBottom: 0 }}></td>
                          <td style={{ paddingTop: 0, paddingBottom: 0 }}></td>
                          <td
                            style={{
                              paddingTop: "12px",
                              paddingBottom: "12px",
                            }}
                          >
                            No Records
                          </td>
                          <td style={{ paddingTop: 0, paddingBottom: 0 }}></td>
                          <td style={{ paddingTop: 0, paddingBottom: 0 }}></td>
                          <td style={{ paddingTop: 0, paddingBottom: 0 }}></td>
                          <td style={{ paddingTop: 0, paddingBottom: 0 }}></td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
            {recordDetailsListData?.recordCount <= 0 && (
              <p class="text-center font-italic font-weight-bold">
                No data available!
              </p>
            )}
            <div className="d-flex flex-row py-4 align-items-center float-right">
              {recordDetailsListData?.recordCount > 0 && (
                <Pagination
                  totalRecords={recordDetailsListData?.recordCount}
                  pageLimit={100}
                  pageNeighbours={0}
                  onPageChanged={onPageChanged}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {isPdfDeleteModalOpen ? (
        <div
          className="modal fade"
          id="deleteConfirmationModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <ConfirmationModal
            message={`Are you sure you want to delete the record  ${
              selectedRecordPdf.savedLiveRecordName
                ? selectedRecordPdf?.savedLiveRecordName
                : ""
            } ?`}
            performAction={deleteRecordPdf}
          />
        </div>
      ) : (
        <div
          className="modal fade"
          id="deleteConfirmationModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <ConfirmationModal
            message={`Are you sure you want to delete the record  ${
              selectedRecord?.liveRecordName
                ? selectedRecord.liveRecordName
                : ""
            }  ?`}
            performAction={deleteRecord}
          />
        </div>
      )}

      <div
        class="modal fade"
        id="exampleModalCenterForRecord"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Share Record
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleEmailCancel}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              class="modal-body"
              style={{
                textAlign: "center",
                paddingRight: "1rem",
                paddingLeft: "1rem",
                marginTop: "0.8rem",
                marginBottom: "0.8rem",
              }}
            >
              {!shareEmailOpen && (
                <button
                  type="button"
                  class="btn btn-secondary"
                  style={{ padding: "10px", margin: "1rem" }}
                  onClick={() => setShareEmailOpen(true)}
                >
                  Share via Email
                  <Icon.Mail style={{ marginLeft: "6px" }} />
                </button>
              )}

              {!shareEmailOpen && (
                <button
                  type="button"
                  class="btn btn-secondary"
                  style={{ padding: "10px", margin: "1rem" }}
                  onClick={() => OpenInNewTabHandler(singleTempEmailShare.url)}
                >
                  Open in new tab
                  <Icon.ArrowUpRight style={{ marginLeft: "6px" }} />
                </button>
              )}
              {shareEmailOpen && (
                <div style={{ marginTop: "1rem" }}>
                  <div className="tags-input-container">
                    {emailArray.map((email, index) => (
                      <div className="tag-item" key={index}>
                        <span className="text">{email}</span>
                        <span
                          className="close"
                          onClick={() => removeTag(index)}
                        >
                          &times;
                        </span>
                      </div>
                    ))}
                    <input
                      onKeyDown={handleKeyDown}
                      type="text"
                      className="tags-input"
                      placeholder={
                        emailArray.length > 0
                          ? "Type another email"
                          : "Type emails here.."
                      }
                      value={newEmail}
                      onChange={(e) => {
                        setNewEmail(e.target.value);
                        setError(""); // Clear any previous error when the input changes
                      }}
                    />
                  </div>
                  <div>
                    {error && (
                      <p className="email-error-text noteText">{error}</p>
                    )}

                    {!error && (
                      <p className="noteText">
                        Press the 'Enter' key to add another email...
                      </p>
                    )}
                  </div>
                  <div class="input-group-append">
                    <button
                      className="cancelButton"
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleEmailCancel}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleEmailSend}
                      disabled={emailArray.length === 0}
                      title={
                        emailArray.length === 0 ? "Enter email to send" : ""
                      }
                      className={`mailSendButton ${
                        emailArray.length <= 0 ? "disabled" : ""
                      }`}
                    >
                      Send
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveRecordDetails;
