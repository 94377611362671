import * as Template from "./TemplateConstants";

const initialState = {
  loading: false,
  templatesList: [],
  customerTemplate: "",
  error: null,
  QRImageURL: "",
};

function TemplateReducer(state = initialState, action = {}) {
  switch (action.type) {
    case Template.LIST_TEMPLATES_REQUEST:
    case Template.GET_TEMPLATE_FOR_CUSTOMER_REQUEST:
    case Template.ADD_TEMPLATE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case Template.LIST_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        templatesList: action.payload,
        error: null,
      };
    case Template.LIST_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        templatesList: [],
      };

    case Template.GET_TEMPLATE_FOR_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        customerTemplate: action.payload,
        error: null,
      };
    case Template.GET_TEMPLATE_FOR_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        customerTemplate: "",
      };

    case Template.ADD_TEMPLATE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case Template.ADD_TEMPLATE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case Template.RESET_TEMPLATE_DETAILS:
      return {
        ...initialState,
      };
    case Template.SET_QR_IMAGE_URL:
      return {
        ...initialState,
        QRImageURL: action.payload,
      };

    default:
      return state;
  }
}

export default TemplateReducer;
