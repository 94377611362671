import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  listAllDeletedRecords,
  retrieveSingleRecord,
  // deleteAllRecords,
} from "../Records/RecordsActions";
import { getNotification } from "../../components/header/notificationActions";
import { format } from "date-fns";
import { CustomerId } from "src/app/config";
import "./RecycleBin.css";
import * as Icon from "react-feather";
import ConfirmationModal from "src/app/components/ConfirmationModal/ConfirmationModal";
// import Cookies from "js-cookie";

const TempRecordRecycleBin = () => {
  const [currentPageNumber] = useState(1);
  const dispatch = useDispatch();
  const [listDeletedRecord, setListDeletedRecord] = useState();
  const [reload, setReload] = useState(false);
  // const [isAdmin] = useState(localStorage.getItem("isAdmin"));
  // const userRole = decodeURIComponent(Cookies.get("userRole"));
  const [sortParams, setSortParams] = useState({
    sortBy: "DESC",
    sortTitle: "updatedAt",
  });
  const [selectedRecord, setselectedRecord] = useState("");

  useEffect(() => {
    window.onbeforeunload = null;
    // Listing Record Details
    getRecordDetailsList();
    getNotificationsList();
  }, [reload]);

  const getNotificationsList = () => {
    const data = {
      numPerPage: 10,
      page: 1,
      customerId: CustomerId,

      userId: localStorage.getItem("userId"),
    };
    dispatch(getNotification(data));
  };

  const getRecordDetailsList = (page = 1, pageSize = 100) => {
    const data = {
      numPerPage: pageSize,
      page: page == 0 ? 1 : page,
      userId: localStorage.getItem("userId"),
      sortKey: sortParams.sortTitle,
      sortType: sortParams.sortBy,
      searchKey: "",
    };
    dispatch(listAllDeletedRecords(data)).then((res) => {
      setListDeletedRecord(res?.data?.data);
    });
  };

  // const onPageChanged = (data) => {
  //   if (data && data.currentPage == currentPageNumber) {
  //     return;
  //   }
  //   if (data && data.currentPage) {
  //     const { currentPage } = data;
  //     setCurrentPageNumber(currentPage);
  //     getRecordDetailsList(currentPage);
  //   }
  // };

  const sortTable = (title) => {
    var sortByOrder = "ASC";

    if (title == sortParams.sortTitle) {
      sortByOrder = sortParams.sortBy == "DESC" ? "ASC" : "DESC";
      setSortParams({ sortBy: sortByOrder, sortTitle: title });
    } else {
      setSortParams({ sortBy: "ASC", sortTitle: title });
    }

    // Call Api here for sorting
    const data = {
      numPerPage: 100,
      page: currentPageNumber,
      userId: localStorage.getItem("userId"),
      sortKey: title,
      sortType: sortByOrder,
      searchKey: "",
    };
    dispatch(listAllDeletedRecords(data));
  };
  const handleRetrieveRecord = (data) => {
    setselectedRecord(data);
  };

  const retrieveRecord = () => {
    dispatch(retrieveSingleRecord(selectedRecord.id));
    setReload(!reload);
  };

  return (
    <div>
      <div class="row">
        <div class="col-lg-12">
          <div class="table-wraper table-responsive mnt mt-3">
            <table class="table job-list">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col" className="mx-width">
                    Doc ID
                  </th>
                  <th
                    scope="col"
                    onClick={() => {
                      sortTable("recipient");
                    }}
                  >
                    <div className="d-flex justify-content-start">
                      <div>Record Name</div>
                      <div className="sort-wrp ml-2">
                        <i
                          className={
                            sortParams.sortTitle == "recipient" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-asc fa-lg active sort_up"
                              : "fa fa-sort-asc fa-lg sort_fade_down"
                          }
                        ></i>
                        <i
                          className={
                            sortParams.sortTitle == "recipient" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-desc fa-lg sort_fade_down"
                              : sortParams.sortTitle == "recipient" &&
                                sortParams.sortBy == "DESC"
                              ? "fa fa-sort-desc fa-lg active sort_up"
                              : "fa fa-sort-desc fa-lg sort_fade_down"
                          }
                        ></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col" className="mx-width">
                    Template Name
                  </th>

                  <th scope="col">Download Files</th>
                  <th
                    scope="col"
                    onClick={() => {
                      sortTable("updatedAt");
                    }}
                  >
                    <div class="d-flex justify-content-start">
                      <div>Date</div>
                      <div class="sort-wrp ml-2">
                        <i
                          class={
                            sortParams.sortTitle == "updatedAt" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-asc fa-lg active sort_up"
                              : "fa fa-sort-asc fa-lg sort_fade_down"
                          }
                        ></i>
                        <i
                          class={
                            sortParams.sortTitle == "updatedAt" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-desc fa-lg sort_fade_down"
                              : sortParams.sortTitle == "updatedAt" &&
                                sortParams.sortBy == "DESC"
                              ? "fa fa-sort-desc fa-lg active sort_up"
                              : "fa fa-sort-desc fa-lg sort_fade_down"
                          }
                        ></i>
                      </div>
                    </div>
                  </th>

                  <th className="text-center" scope="col">
                    Retrieve
                  </th>
                </tr>
              </thead>
              <tbody>
                {listDeletedRecord?.length > 0 &&
                  listDeletedRecord?.map((record, index) => (
                    <tr key={index + 1}>
                      <td>{index + 1}</td>
                      <td className="mx-width">
                        {record?.Template?.templateRefId}
                      </td>
                      <td>
                        {record?.recipient
                          ? record?.recipient
                          : "Template Data"}
                      </td>
                      <td>{record?.Template.templateName}</td>
                      <td>
                        <div>
                          <a href={record?.fileName} className="a-removed-href">
                            {record?.recipient
                              ? record?.recipient
                              : "Template Data"}
                          </a>
                          {record?.OnlineRecordsAttachments &&
                            record?.OnlineRecordsAttachments.map(
                              (file, index) => (
                                <div className="mt-3">
                                  <a
                                    href={file.fileName}
                                    className="a-removed-href"
                                  >
                                    Attachment {index + 1}
                                  </a>
                                </div>
                              )
                            )}
                        </div>
                      </td>
                      <td>
                        {record?.updatedAt
                          ? format(
                              new Date(record?.updatedAt),
                              "yyyy-MM-dd : hh.mm aa"
                            )
                          : "-"}
                      </td>
                      <td class="text-center">
                        <a
                          className="a-removed-href"
                          data-toggle="modal"
                          data-target="#retrieveConfirmationModal1"
                          onClick={() => {
                            handleRetrieveRecord(record);
                          }}
                        >
                          <Icon.FolderPlus />
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {listDeletedRecord?.length <= 0 && (
              <p class="text-center font-italic font-weight-bold">
                No data available!
              </p>
            )}
            {/* <div className="d-flex flex-row py-4 align-items-center float-right">
              {listDeletedRecord.deletedRecordsList.recordCount > 0 && <Pagination
                totalRecords={totalCount}
                pageLimit={25}
                pageNeighbours={0}
                onPageChanged={onPageChanged}
              />}
            </div> */}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="retrieveConfirmationModal1"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <ConfirmationModal
          message={
            "Are you sure you want to retrieve the record " +
            selectedRecord.recipient +
            "?"
          }
          fileName={
            selectedRecord.recipient
              ? selectedRecord.recipient
              : "Template Data"
          }
          performAction={retrieveRecord}
        />
      </div>
    </div>
  );
};

export default TempRecordRecycleBin;
