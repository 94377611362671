import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";
import PageTopHeader from "src/app/components/PageTopHeader";
import { callForListCustomerDepartments } from "../userManagement/actions";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";

const DepartmentManagement = () => {
  const [dipartmentList, setDipartmentList] = useState();
  const dispatch = useDispatch();
  const userRole = decodeURIComponent(Cookies.get("userRole"));

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(callForListCustomerDepartments()).then((res) => {
      setDipartmentList(res?.data?.data);
    });
  }, []);
  return (
    <>
      <PageTopHeader
        leftTitle={"Departments"}
        rightTitle={"Back to home"}
        url={"/home"}
        Righticon={<Icon.Home />}
      />
      <div className="row">
        {dipartmentList &&
          dipartmentList.map((dept, index) => (
            <div className="col-lg-3 text-center" key={index + 1}>
              <div className="card-deck mb-3 h-100 pb-3">
                <div className="card">
                  <div className="card-body">
                    <img
                      className="rounded-circle mx-auto d-block"
                      src={
                        dept.image ? dept.image : "/images/department-icon.png"
                      }
                      width="100"
                      height="100"
                    />
                  </div>
                  <div class="card-footer" style={{ color: "black" }}>
                    {userRole === "Prime Admin"
                      ? dept?.departmentName
                      : dept?.Department?.departmentName}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div
        className="modal fade"
        id="deleteConfirmationModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Digiplatform
              </h5>
              <button
                className="close"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              Do you want to remove{" "}
              {/* {selectedUser.firstName + " " + selectedUser.lastName}? */}
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-secondary"
                type="button"
                data-dismiss="modal"
              >
                No
              </button>
              <a
                className="btn btn-primary a-cancel"
                data-dismiss="modal"
                onClick={() => {
                  //   deleteUser();
                }}
              >
                Yes
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepartmentManagement;
