const ConvertDateFormatForPDF = () => {
  var dateInputs = document.querySelectorAll('input[type="date"]');
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  dateInputs.forEach(function (input) {
    var dateValue = input.value;
    if (dateValue) {
      var parts = dateValue.split("-");
      var formattedDate =
        parts[2] + " " + months[parseInt(parts[1], 10) - 1] + ", " + parts[0];

      var newInput = document.createElement("input");

      newInput.type = "text";
      newInput.defaultValue = formattedDate;
      // Copy styles from the original input to the new input
      newInput.style.width = input.offsetWidth + "px";
      newInput.style.height = input.offsetHeight + "px";
      newInput.style.padding = window.getComputedStyle(input).padding;
      newInput.style.fontSize = window.getComputedStyle(input).fontSize;
      input.parentNode.replaceChild(newInput, input);
    }
  });
};

export default ConvertDateFormatForPDF;
