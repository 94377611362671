import React, { useState } from "react";

const parseStyles = (styles) =>
  styles
    .split(";")
    .filter((style) => style.split(":")[0] && style.split(":")[1])
    .map((style) => [
      style
        .split(":")[0]
        .trim()
        .replace(/-./g, (c) => c.substr(1).toUpperCase()),
      style.split(":")[1].trim(),
    ])
    .reduce(
      (styleObj, style) => ({
        ...styleObj,
        [style[0]]: style[1],
      }),
      {}
    );

const TextField = ({ attribs, defaultValue }) => {
  const [value, setValue] = useState(defaultValue || "");
  const { style = "", ...rest } = attribs;

  return (
    <textarea
      {...rest}
      style={parseStyles(style)}
      value={value}
      onChange={(e) => setValue(e.target.value || "")}
    />
  );
};

export default TextField;
