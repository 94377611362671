import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";
import { history } from "../../../helpers/history";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotification,
  viewNotification,
  resetNotification,
} from "../header/notificationActions";
import { getProfile } from "../../pages/profile/profileActions";
import "./notification.css";
import { brandLogo, CustomerId } from "../../config";
import { UseTranslation, useTranslation } from "react-i18next";
const Header = (props) => {
  const { i18n } = useTranslation();
  const [isAdmin] = useState(localStorage.getItem("isAdmin"));
  const dispatch = useDispatch();
  const notificationsDetails = useSelector((state) => state.notificationData);
  const userProfile = useSelector((state) => state.profileData);
  const profileImage =
    userProfile &&
    userProfile.profileData &&
    userProfile.profileData.data &&
    userProfile.profileData.data.image;
  const viewed =
    notificationsDetails &&
    notificationsDetails.viewed &&
    notificationsDetails.viewed.status;
  const viewCount =
    notificationsDetails &&
    notificationsDetails.notificationData &&
    notificationsDetails.notificationData.viewCount;
  const notificationList =
    notificationsDetails &&
    notificationsDetails.notificationData &&
    notificationsDetails.notificationData.notification;

  useEffect(() => {
    getNotificationsList();
    {
      isAdmin == "false" &&
        dispatch(getProfile(localStorage.getItem("userId")));
    }
  }, []);

  const getNotificationsList = () => {
    const data = {
      numPerPage: 10,
      page: 1,
      customerId: CustomerId,
      userId: localStorage.getItem("userId"),
    };
    dispatch(getNotification(data));
  };

  useEffect(() => {
    if (viewed == "success") {
      {
        isAdmin == "false" && getNotificationsList();
      }
      {
        isAdmin == "false" && dispatch(resetNotification());
      }
    }
  }, [viewed]);

  const viewUpdatedItem = (updatedItem) => {
    const requestData = {
      id: updatedItem.id,
    };
    dispatch(viewNotification(requestData));
    if (updatedItem.type == "Template") {
      history.push("/home/onlineTemplates");
    } else if (updatedItem.type == "Ticket") {
      history.push("/home/editJobTicket");
    } else if (updatedItem.type == "Flowchart") {
      history.push("/home/flowcharts");
    } else if (updatedItem.type == "Business Organisation") {
      history.push("/home/businessOrganisation");
    } else if (updatedItem.type == "Manual") {
      history.push("/home/manual");
    } else if (updatedItem.type == "Procedure") {
      history.push("/home/procedures");
    } else if (updatedItem.type == "Policy") {
      history.push("/home/policies");
    }
  };

  const profileClick = () => {
    history.push("/home/profile");
  };

  const expanssionToggle = () => {
    props.onDrawerToggle();
  };

  const userManagementClick = () => {
    history.push("/home/usermanagement");
  };
  const projectmanagementClick = () => {
    history.push("/home/projectManagement");
  };

  const departManagementClick = () => {
    history.push("/home/departmentmanagement");
  };

  const setEnglish = () => {
    i18n.changeLanguage("en");
  };
  const setSerbian = () => {
    i18n.changeLanguage("fr");
  };

  return (
    <nav className="d-flex justify-content-between navbar navbar-expand navbar-light bg-white topbar fixed-top">
      <div className="brand">
        <a className="sidebar-brand d-flex align-items-center justify-content-center">
          <div
            className="sidebar-brand-icon"
            id="sidebarToggle"
            onClick={() => expanssionToggle()}
          >
            <Icon.AlignJustify />
          </div>
          <div className="sidebar-brand-text mx-3">
            <img
              className="brand-logo"
              src={brandLogo}
              onClick={() => history.push("/home")}
            />
          </div>
        </a>
      </div>

      <div className="notifications-wrap">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown no-arrow d-sm-none">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="searchDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-search fa-fw"></i>
            </a>
          </li>

          <li className="nav-item dropdown no-arrow mx-1">
            <a
              className="nav-link dropdown-toggle"
              id="alertsDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <Icon.Settings />
            </a>
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >
              <a
                className="dropdown-item"
                onClick={() => {
                  userManagementClick();
                }}
              >
                User Management
              </a>
              <hr className="headerhr" />
              <a
                className="dropdown-item"
                onClick={() => {
                  departManagementClick();
                }}
              >
                Department Management
              </a>
              <hr className="headerhr" />

              <a
                className="dropdown-item"
                onClick={() => {
                  projectmanagementClick();
                }}
              >
                Project Management
              </a>
            </div>
          </li>

          {/* <li className="nav-item dropdown no-arrow mx-1">
            <a
              className="nav-link dropdown-toggle"
              id="languageDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <Icon.Globe />
            </a>
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="languageDropdown"
            >
              <a
                className="dropdown-item"
                onClick={() => {
                  setEnglish();
                }}
              >
                English
              </a>
              <a
                className="dropdown-item"
                onClick={() => {
                  setSerbian();
                }}
              >
                Serbian
              </a>
            </div>
          </li> */}

          {isAdmin == "false" && (
            <li className="nav-item dropdown no-arrow mx-1">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="alertsDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <Icon.Bell />
                {viewCount != 0 && (
                  <span className="badge badge-danger badge-counter">
                    +{viewCount}
                  </span>
                )}
              </a>

              <div
                className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in overflow-auto notifHeight"
                aria-labelledby="alertsDropdown"
              >
                <h6 className="dropdown-header">Alerts Center</h6>
                {notificationList &&
                  notificationList.map((notification, index) => (
                    <a
                      key={index}
                      onClick={() => viewUpdatedItem(notification)}
                      className="dropdown-item d-flex align-items-center"
                      href="#"
                    >
                      <div className="mr-3">
                        {notification.type == "Ticket" && (
                          <div className="icon-circle bg-primary">
                            <i className="fa fa-ticket text-white"></i>
                          </div>
                        )}
                        {notification.type == "Business Organisation" && (
                          <div className="icon-circle bg-info">
                            <i className="fa fa-sitemap text-white"></i>
                          </div>
                        )}
                        {notification.type == "Flowchart" && (
                          <div className="icon-circle bg-success">
                            <i className="fa fa-line-chart text-white"></i>
                          </div>
                        )}
                        {notification.type == "Template" && (
                          <div className="icon-circle bg-danger">
                            <i className="fa fa-file-text text-white"></i>
                          </div>
                        )}
                        {(notification.type == "Procedure" ||
                          notification.type == "Manual" ||
                          notification.type == "Policy") && (
                          <div className="icon-circle bg-warning">
                            <i className="fa fa-book text-white"></i>
                          </div>
                        )}
                      </div>
                      <div>
                        <div className="small text-gray-500">
                          {notification.updatedAt.split("T")[0]}
                        </div>
                        <span
                          className={
                            notification.isViewed == false
                              ? "font-weight-bold"
                              : ""
                          }
                        >
                          {notification.message}
                        </span>
                      </div>
                    </a>
                  ))}
                {notificationList && notificationList.length <= 0 && (
                  <p className="text-center font-italic alert-no-data">
                    There are no notifications to show!
                  </p>
                )}

                {notificationList && notificationList.length >= 7 && (
                  <a
                    className="dropdown-item text-center small text-gray-500"
                    onClick={() => {
                      history.push("/home/notifications");
                    }}
                  >
                    Show All Alerts
                  </a>
                )}
              </div>
            </li>
          )}

          <div className="topbar-divider d-none d-sm-block"></div>

          <li className="nav-item dropdown no-arrow">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                className="img-profile rounded-circle"
                src={
                  profileImage
                    ? profileImage
                    : "/images/default-user-placeholder.png"
                }
              />
            </a>

            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >
              {isAdmin == "false" && (
                <a
                  className="dropdown-item"
                  onClick={() => {
                    profileClick();
                  }}
                >
                  <i className="fa fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                  Profile
                </a>
              )}

              <div className="dropdown-divider"></div>
              <a
                className="dropdown-item"
                data-toggle="modal"
                data-target="#logoutModal"
              >
                <i className="fa fa-sign-out fa-sm fa-fw mr-2 text-gray-400"></i>
                Logout
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;
