import React, { useState, useEffect } from "react";
import {
  listCategory,
  addJobTicket,
  updateJobTicket,
  getTicketDetails,
  resetTicketDetails,
} from "./JobTicketActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Icon from "react-feather";
import PageTopHeader from "src/app/components/PageTopHeader";
import { companyLogo } from "src/app/config";

const CreateNewJobTicket = (props) => {
  const jobTicketData = useSelector((state) => state.jobTicketData);
  const ticketData =
    jobTicketData &&
    jobTicketData.ticketDetails &&
    jobTicketData.ticketDetails.data;
  // const categoryList = jobTicketData && jobTicketData.categoryList && jobTicketData.categoryList.data;

  let categoryList = [];
  var isPrime = localStorage.getItem("isPrime");
  var beforeFilterCategories =
    jobTicketData &&
    jobTicketData.categoryList &&
    jobTicketData.categoryList.data;
  if (isPrime != "true" && beforeFilterCategories) {
    categoryList = beforeFilterCategories.filter(
      (category) => category.id != 7
    );
  } else {
    categoryList = beforeFilterCategories;
  }

  const [submitted, setSubmitted] = useState(false);

  const [inputs, setInputs] = useState({
    jobId: "",
    completionDate: "",
    recordDistribution: "Internal",
    categoryName:
      props.location && props.location.state && props.location.state.type
        ? props.location.state.type
        : "",
    ticketDetails: "",
    noteToAdmin: "",
    noteToUser: "",
  });
  const [docId] = useState(
    props.location && props.location.state && props.location.state.id
      ? props.location.state.id
      : ""
  );
  const [archivedJobId] = useState(
    props.location && props.location.state && props.location.state.jobId
      ? props.location.state.jobId
      : ""
  );

  const [selectedFile, setselectedFile] = useState([]);
  const [deletedFile, setDeletedFile] = useState([]);
  const [filePath, setFilePath] = useState([]);
  const { jobId, ticketDetails } = inputs;
  const [isEdit, setIsEdit] = useState(false);
  const [ticketStatus, setTicketStatus] = useState("notstarted");
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    setInputs((inputs) => ({ ...inputs }));
    dispatch(listCategory());
    const search = new URLSearchParams(location.search);
    const id = search.get("id");
    if (id != null) {
      setIsEdit(true);
      dispatch(getTicketDetails(id));
    }
  }, []);

  useEffect(() => {
    if (ticketData != undefined && isEdit == true) {
      var tempDate =
        ticketData.completionDate && ticketData.completionDate.split("T")[0];

      setInputs({
        jobId: ticketData.id,
        completionDate: tempDate,
        recordDistribution: ticketData.recordDistribution,
        categoryName: ticketData.categoryId,
        ticketDetails: ticketData.ticketDetails,
        noteToAdmin: ticketData.noteToAdmin,
        noteToUser: ticketData.noteToUser,
      });
      setTicketStatus("notstarted");
      setFilePath(ticketData.TicketAttachments);
    }
  }, [jobTicketData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  // multiple file upload

  const onFileChange = (event) => {
    var newArray = selectedFile;
    newArray.push(event.target.files[0]);
    setselectedFile([...newArray]);
  };

  const deleteList = (e, index) => {
    e.preventDefault();
    var delArray = selectedFile;
    delArray.splice(index, 1);
    setselectedFile([...delArray]);
  };

  const deleteExistingList = (e, val, index) => {
    e.preventDefault();
    var delArray = filePath;
    delArray.splice(index, 1);
    setFilePath([...delArray]);
    var newArray = deletedFile;
    newArray.push(val);
    setDeletedFile([...newArray]);
  };

  const fileData = () => {
    if (filePath.length > 0 && isEdit == true) {
      return (
        <div>
          <ol>
            {filePath &&
              filePath.map((val, index) => (
                <li>
                  <div
                    id="output"
                    className="file-clr d-flex justify-content-between"
                  >
                    <a href={val.url} className="a-removed-href">
                      {val.name}
                    </a>
                    <a
                      className="trash-btn a-removed-href"
                      onClick={(e) => deleteExistingList(e, val, index)}
                    >
                      <Icon.X className="trash-icn" />
                    </a>
                  </div>
                </li>
              ))}
          </ol>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const createTicket = () => {
    setSubmitted(true);

    if (inputs.ticketDetails && inputs.categoryName && inputs.completionDate) {
      const formData = new FormData();
      if (selectedFile) {
        for (var x = 0; x < selectedFile?.length; x++) {
          formData.append("formFiles", selectedFile[x], selectedFile[x].name);
          formData.append("uploadFileName", selectedFile[x].name);
        }
      }

      formData.append("ticketDetails", ticketDetails);
      formData.append("categoryId", inputs.categoryName);
      formData.append("completionDate", inputs.completionDate);
      formData.append("recordDistribution", inputs.recordDistribution);
      docId && formData.append("documentId", docId);
      archivedJobId && formData.append("jobId", archivedJobId);
      formData.append("customerLogo", companyLogo);
      deletedFile && formData.append("deleteList", JSON.stringify(deletedFile));
      if (isEdit == false || docId) {
        dispatch(addJobTicket(formData));
      } else {
        formData.append("ticketStatus", ticketStatus);

        var editedData = {
          id: jobId,
        };
        dispatch(resetTicketDetails());

        dispatch(updateJobTicket(editedData, formData));
      }
    }
  };

  return (
    <div className="outer-wrap">
      <PageTopHeader
        rightTitle={"Back To Home"}
        url={"/home"}
        Righticon={<Icon.ArrowLeft />}
      />
      <div className="container-wrap">
        <div className="card p-2">
          <div className="card-body">
            <form className="user">
              <div className="form-group text-center pb-3">
                <div className="st-tl">
                  <h1 className="mh-head-txt">
                    {" "}
                    {isEdit
                      ? "Update Job Ticket"
                      : "Create External Job Ticket"}
                  </h1>
                </div>
              </div>

              <div className="form-row">
                <div className="col">
                  <div className="group">
                    <div
                      className={
                        isEdit || docId ? "select disabled-cst" : "select"
                      }
                    >
                      <select
                        value={inputs.categoryName}
                        disabled={isEdit || docId}
                        className={
                          submitted && !inputs.categoryName
                            ? " select-text form-control is-invalid "
                            : "select-text"
                        }
                        required
                        name="categoryName"
                        onChange={handleChange}
                      >
                        <option value="" disabled defaultValue></option>
                        {categoryList &&
                          categoryList
                            .filter((catg) => catg.id != 8)
                            .map((category, index) => (
                              <option key={index} value={category.id}>
                                {category.categoryName}
                              </option>
                            ))}
                      </select>
                      <label className="select-label">Category</label>
                      {submitted && !inputs.categoryName && (
                        <div className="invalid-feedback">
                          Category is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className={jobId ? "group disabled-cst" : "group"}>
                    <input
                      type="text"
                      disabled={true}
                      name="jobId"
                      value={jobId}
                      onChange={handleChange}
                    />
                    <span className="highlight"></span>
                    <label>Job#</label>
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="col">
                  <div className="group">
                    <input
                      type="date"
                      className={
                        submitted && !inputs.completionDate
                          ? "brd-4 form-control is-invalid"
                          : ""
                      }
                      name="completionDate"
                      value={inputs.completionDate}
                      onChange={handleChange}
                      required="required"
                    />
                    <span className="highlight"></span>
                    <label>Desired Completion Date</label>
                    {submitted && !inputs.completionDate && (
                      <div className="invalid-feedback">Date is required</div>
                    )}
                  </div>
                </div>
                <div className="col">
                  <div className="group">
                    {inputs.categoryName == "6" ? (
                      <div class={"select"}>
                        <select
                          class="select-text"
                          value={inputs.recordDistribution}
                          name="recordDistribution"
                          onChange={handleChange}
                          required
                        >
                          <option value="Internal">Internal</option>
                          <option value="External">External</option>
                          <option value="All">Internal and External</option>
                        </select>
                        <label class="select-label">Record Distribution</label>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="form__group">
                <textarea
                  id="message"
                  className={
                    submitted && !ticketDetails
                      ? "form__field  form-control is-invalid"
                      : "form__field"
                  }
                  name="ticketDetails"
                  value={ticketDetails}
                  onChange={handleChange}
                  placeholder="Your Message"
                  rows="8"
                ></textarea>
                <label for="message" className="form__label">
                  Ticket Details
                </label>
                {submitted && !ticketDetails && (
                  <div className="invalid-feedback">Details is required</div>
                )}
              </div>

              <h2 className="tab-head pt-5 pb-3"> Upload Details</h2>
              {filePath.length > 0 && isEdit == true && fileData()}

              <div>
                {isEdit == true && filePath.length > 0 ? (
                  <h2 className="tab-head pt-3 pb-3">Add new files:</h2>
                ) : (
                  ""
                )}
                <input type="file" onChange={onFileChange} />
              </div>
              <br />
              <ol>
                {selectedFile.map((val, index) => {
                  return (
                    <li>
                      <div
                        id="output"
                        className="file-clr d-flex justify-content-between"
                      >
                        <a href={val.url} className="a-removed-href">
                          {val.name}
                        </a>

                        <a
                          className="trash-btn a-removed-href"
                          onClick={(e) => deleteList(e, index)}
                        >
                          <Icon.X className="trash-icn" />
                        </a>
                      </div>
                    </li>
                  );
                })}
              </ol>

              <a
                onClick={createTicket}
                className="brd-4 btn btn-primary btn-user btn-block mt-5 a-cancel"
              >
                {isEdit ? "Update Ticket" : "Create Ticket"}
              </a>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewJobTicket;
