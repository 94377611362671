import * as Flowchart from './FlowchartConstants';

const initialState = {
  loading: false,
  flowchartsList: [],
  customerFlowchart: '',
  error: null,
};

function FlowchartReducer(state = initialState, action={}) {
  switch (action.type) {
    case Flowchart.LIST_FLOWCHARTS_REQUEST:
    case Flowchart.LIST_FLOWCHART_ARCHIVE_REQUEST:
    case Flowchart.GET_FLOWCHART_FOR_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case Flowchart.LIST_FLOWCHARTS_SUCCESS:
    case Flowchart.LIST_FLOWCHART_ARCHIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        flowchartsList: action.payload,
        error: null,
      };
    case Flowchart.LIST_FLOWCHARTS_FAILURE:
    case Flowchart.LIST_FLOWCHART_ARCHIVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        flowchartsList: []

      };
    case Flowchart.GET_FLOWCHART_FOR_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        customerFlowchart: action.payload,
        error: null,
      };
    case Flowchart.GET_FLOWCHART_FOR_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        customerFlowchart: ''

      };

    case Flowchart.RESET_FLOWCHART_DETAILS:
      return {
        ...initialState,

      };

    default:
      return state;
  }
}

export default FlowchartReducer;