import { ApiService } from "../../services/api.service";
import * as Template from "./TemplateConstants";
import { showAlert } from "../alert/showAlert";
import { alertActions } from "../alert/alertActions";
import { trackPromise } from "react-promise-tracker";
import { history } from "../../../helpers/history";

// Template Listing Actions

export function listTemplates(data) {
  return (dispatch) => {
    dispatch(listTemplatesRequested());
    trackPromise(
      ApiService.callListTemplates(data).then((res) => {
        dispatch(listTemplatesSuccess(res.data));
      })
    ).catch((err) => {
      dispatch(listTemplatesFailure(err.message));
      showAlert(dispatch, err);
    });
  };
}

const listTemplatesRequested = () => ({
  type: Template.LIST_TEMPLATES_REQUEST,
});

const listTemplatesSuccess = (data) => ({
  type: Template.LIST_TEMPLATES_SUCCESS,
  payload: {
    ...data,
  },
});

const listTemplatesFailure = (error) => ({
  type: Template.LIST_TEMPLATES_FAILURE,
  payload: {
    error,
  },
});

//QR image Get
export function getTemplateQrImage(selectedTempID) {
  return (dispatch) => {
    return trackPromise(
      ApiService.getTemplateQrImage(selectedTempID).then((res) => {
        dispatch(getTemplateQRImageSuccess(res.data));
      })
    ).catch((err) => {
      showAlert(dispatch, err);
    });
  };
}

const getTemplateQRImageSuccess = (data) => ({
  type: Template.SET_QR_IMAGE_URL,
  payload: data?.data,
});

// Get Template for customer  Actions

export function getTemplateForCustomer(data, custId) {
  return (dispatch) => {
    dispatch(getTemplateForCustomerRequested());
    trackPromise(
      ApiService.callGetTemplateForCustomer(data, custId).then((res) => {
        dispatch(getTemplateForCustomerSuccess(res.data));
      })
    ).catch((err) => {
      dispatch(getTemplateForCustomerFailure(err.message));
      showAlert(dispatch, err);
    });
  };
}

const getTemplateForCustomerRequested = () => ({
  type: Template.GET_TEMPLATE_FOR_CUSTOMER_REQUEST,
});

const getTemplateForCustomerSuccess = (data) => ({
  type: Template.GET_TEMPLATE_FOR_CUSTOMER_SUCCESS,
  payload: {
    ...data,
  },
});

const getTemplateForCustomerFailure = (error) => ({
  type: Template.GET_TEMPLATE_FOR_CUSTOMER_FAILURE,
  payload: {
    error,
  },
});

// Add template Data
export function addTemplateData(data) {
  return (dispatch) => {
    dispatch(addTemplateDataRequested());
    trackPromise(
      ApiService.callAddTemplateData(data).then((res) => {
        dispatch(alertActions.success(res.data.message));
        dispatch(addTemplateDataSuccess(res.data));
        history.push("/external-user-thankyou");
      })
    ).catch((err) => {
      dispatch(addTemplateDataFailure(err.message));
      showAlert(dispatch, err);
    });
  };
}

const addTemplateDataRequested = () => ({
  type: Template.ADD_TEMPLATE_DATA_REQUEST,
});

const addTemplateDataSuccess = (data) => ({
  type: Template.ADD_TEMPLATE_DATA_SUCCESS,
  payload: {
    ...data,
  },
});

const addTemplateDataFailure = (error) => ({
  type: Template.ADD_TEMPLATE_DATA_FAILURE,
  payload: {
    error,
  },
});

export function resetTemplateDetails() {
  return { type: Template.RESET_TEMPLATE_DETAILS };
}

export function callGetShareByEmail(data) {
  return (dispatch) => {
    trackPromise(
      ApiService.callShareByEmail(data).then((res) => {
        dispatch(alertActions.success(res.data.message));
      })
    ).catch((err) => {
      showAlert(dispatch, err);
    });
  };
}

export function callGetRecordShareByEmail(data) {
  return (dispatch) => {
    trackPromise(
      ApiService.callRecordShareByEmail(data).then((res) => {
        dispatch(alertActions.success(res.data.message));
      })
    ).catch((err) => {
      showAlert(dispatch, err);
    });
  };
}
export function callTemplateSequelNo(data) {
  return () => {
    return ApiService.callTemplateSequelNo(data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
}
