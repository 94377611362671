// MANUAL LISTING
export const LIST_MANUALS_REQUEST = 'LIST_MANUALS_REQUEST';
export const LIST_MANUALS_SUCCESS = 'LIST_MANUALS_SUCCESS';
export const LIST_MANUALS_FAILURE = 'LIST_MANUALS_FAILURE';

//LIST ARCHIVES
export const LIST_MANUAL_ARCHIVE_REQUEST = 'LIST_MANUAL_ARCHIVE_REQUEST';
export const LIST_MANUAL_ARCHIVE_SUCCESS = 'LIST_MANUAL_ARCHIVE_SUCCESS';
export const LIST_MANUAL_ARCHIVE_FAILURE = 'LIST_MANUAL_ARCHIVE_FAILURE';

//RESET
export const RESET_MANUAL_DETAILS = 'RESET_MANUAL_DETAILS';


