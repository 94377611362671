import React from "react";
import { Router, Switch, Redirect } from "react-router-dom";
import PrivateRoute from "./app/route/private-route";
import PublicRoute from "./app/route/public-route";
import { history } from "../src/helpers/history";
import Login from "./app/pages/Login/login";
import Dashboard from "./app/pages/dashboard/dashboard";
import ForgotPassword from "./app/pages/ForgotPassword/forgotPassword";
import ResetPassword from "./app/pages/Password/ResetPassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Template from "./app/pages/OnlineTemplates/Template";
import PreviewFlowchart from "./app/pages/Flowcharts/PreviewFlowchart";
import LoadingIndicator from "./app/components/loader/LoadingIndicator";
import { usePromiseTracker } from "react-promise-tracker";
import ExternalTempUserLogin from "./app/pages/externalLiveTemplateUser.js/ExternalTempUserLogin";
import ExternalLiveRecord from "./app/pages/externalLiveTemplateUser.js/ExternalLiveRecord";
import ThankYouPage from "./app/pages/externalLiveTemplateUser.js/ThankYouPage";

const Routes = () => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    <div>
      <LoadingIndicator promiseInProgress={promiseInProgress} />

      <div className={promiseInProgress == true ? "ldr-fade" : ""}>
        <ToastContainer />
        <Router history={history}>
          <Switch>
            <Redirect exact from="/" to="login" />
            <PublicRoute path="/login" component={Login} />
            <PublicRoute path="/admin-login" component={Login} />
            <PublicRoute
              path="/external-livetemplate/login"
              component={ExternalTempUserLogin}
            />
            <PublicRoute
              path="/external-user-thankyou"
              component={ThankYouPage}
            />
            <PrivateRoute
              path="/external-user-livetemplate"
              component={ExternalLiveRecord}
              isExternal={true}
            />

            <PrivateRoute path="/home" component={Dashboard} />
            <PublicRoute path="/forgotPassword" component={ForgotPassword} />
            <PublicRoute path="/resetPassword" component={ResetPassword} />
            <PublicRoute path="/template" component={Template} />
            <PublicRoute
              path="/previewFlowchart"
              component={PreviewFlowchart}
            />
          </Switch>
        </Router>
      </div>
    </div>
  );
};

export default Routes;
