export const APIPath = "https://isonlinez-api.cabotprojects.com"; //development url
// export const APIPath = "https://api.digiplatform.co"; //staging url
// export const APIPath = 'https://localhost:8000'; //to run locally

// export const CustomerId=process.env.REACT_APP_CUSTOMER_ID;
export const CustomerId = 1; // Cabot
// export const CustomerId=2 //Digiplatform

// export const CompanyName=process.env.REACT_APP_COMPANY_NAME;
export const CompanyName = "Cabot"; // Cabot
// export const CompanyName="Digiplatform" //Digiplatform

// export const companyLogo=process.env.REACT_APP_COMPANY_LOGO;
export const companyLogo =
  "https://www.cabotsolutions.com/src/images/cabot-logo.svg"; // Cabot
// export const companyLogo="https://isonlinez-media.s3.eu-west-2.amazonaws.com/Logo/isonlinez-logo-AW%402x.png" //Isonlinez

// export const brandLogo=process.env.REACT_APP_BRAND_LOGO;
export const brandLogo = "/images/Logo-text@2x.png"; // Cabot
// export const brandLogo="https://isonlinez-media.s3.eu-west-2.amazonaws.com/Logo/Logo-text%402x.png" //Isonlinez

export const versionNumber = "4.0.0";
