import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { listUser } from "./actions";

const AdminUsers = () => {
  const dispatch = useDispatch();
  const [userList, setUserList] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    const data = {
      userType: "admin",
      departments: "",
    };
    dispatch(listUser(data)).then((res) => {
      setUserList(res?.data?.data);
    });
  }, []);

  return (
    <>
      <div className="st-tl">
        <h1 className="mh-head-txt pt-3 mb-3">Admin Users</h1>
      </div>
      <div className="row">
        {userList?.length <= 0 && (
          <div
            style={{
              height: "10vh",
              width: "100%",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
              fontSize: "15px",
            }}
          >
            No Users
          </div>
        )}
        {userList &&
          userList?.map((user, index) => (
            <div className="col-lg-3 text-center" key={index + 1}>
              <div className="card-deck mb-3 h-100 pb-3">
                <div className="card">
                  <div className="card-body">
                    {user?.UserRoles[0]?.Role?.roleName && (
                      <div class="tag-pstn  mb-2 text-right">
                        <span class="badge badge-dark">
                          {" "}
                          {user?.UserRoles[0]?.Role?.roleName}
                        </span>
                      </div>
                    )}
                    <img
                      className="rounded-circle mx-auto d-block"
                      src={
                        user.image
                          ? user.image
                          : "/images/default-user-placeholder.png"
                      }
                      width="100"
                      height="100"
                    />

                    <h4 className="card-title pt-2">
                      {user?.firstName + " " + user?.lastName}
                    </h4>
                    <h6 className="mb-2">{user?.email}</h6>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div
        className="modal fade"
        id="deleteConfirmationModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Digiplatform
              </h5>
              <button
                className="close"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              Do you want to remove{" "}
              {/* {selectedUser.firstName + " " + selectedUser.lastName}? */}
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-secondary"
                type="button"
                data-dismiss="modal"
              >
                No
              </button>
              <a
                className="btn btn-primary a-cancel"
                data-dismiss="modal"
                onClick={() => {
                  //   deleteUser();
                }}
              >
                Yes
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminUsers;
