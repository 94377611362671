import React, { useEffect, useState } from "react";
import PageTopHeader from "src/app/components/PageTopHeader";
import * as Icon from "react-feather";
import "./isonlinez.css";
import {
  callUpdateInternalJobTicket,
  callViewJobTicket,
} from "../JobTicket/JobTicketActions";
import { useDispatch } from "react-redux";
import { CustomerId } from "src/app/config";
import FormatTicketDueDate from "src/app/components/FormatTicketDueDate";

const ViewJobTicket = (props) => {
  const [editJobTicketData, setEditJobTicketData] = useState();
  const [selectedStatus, setSelectedStatus] = useState(
    props?.location?.state?.ticketStatus || "notstarted"
  );
  const dispatch = useDispatch();
  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };
  useEffect(() => {
    dispatch(callViewJobTicket(props?.location?.state?.id)).then((res) => {
      setEditJobTicketData(res?.data?.data);
    });
  }, []);

  const handleSave = () => {
    const formData = new FormData();
    formData.append("ticketDetails", editJobTicketData?.ticketDetails);
    formData.append("categoryId", editJobTicketData?.categoryId);
    formData.append("completionDate", editJobTicketData?.completionDate);
    formData.append("customerId", CustomerId);
    formData.append("relatedLinks", editJobTicketData?.relatedLinks?.join(","));
    formData.append("assignedToUserId", editJobTicketData?.assignedToUserId);
    formData.append("ticketStatus", selectedStatus);
    var editedData = {
      id: editJobTicketData?.id,
    };
    dispatch(callUpdateInternalJobTicket(editedData, formData));
  };

  const getFormattedDate = (completionDate) =>
    FormatTicketDueDate(completionDate);

  return (
    <div className="outer-wrap">
      <PageTopHeader
        rightTitle={"Back To Home"}
        url={"/home"}
        Righticon={<Icon.ArrowLeft />}
      />

      <div className="viewCardBody">
        <table className="ticketViewtable">
          <tbody>
            <tr>
              <td
                colSpan="2"
                className="text-center font-weight-bold vewFirstTableColumn"
              >
                Job Ticket Details
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold vewFirstTableColumn">Job Id</td>
              <td>{editJobTicketData?.jobTicketId}</td>
            </tr>
            <tr>
              <td className="font-weight-bold vewFirstTableColumn">Category</td>
              <td>
                {editJobTicketData?.InternalTicketsCategory?.categoryName}
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold vewFirstTableColumn">
                Completion Date
              </td>
              <td>{getFormattedDate(editJobTicketData?.completionDate)}</td>
            </tr>
            <tr>
              <td className="font-weight-bold vewFirstTableColumn p-2">
                Ticket Details
              </td>
              <td>
                <div className="pt-2 pb-2">
                  {editJobTicketData?.ticketDetails}
                </div>
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold vewFirstTableColumn">
                Related Link
              </td>
              <td className="pt-3 pb-3">
                {editJobTicketData?.relatedLinks?.map((item, index) => (
                  <React.Fragment key={index + 1}>
                    <a href={item}>Link- {index + 1}</a>
                    {index !== editJobTicketData?.relatedLinks.length - 1 && (
                      <hr />
                    )}
                  </React.Fragment>
                ))}
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold vewFirstTableColumn">
                Attachments
              </td>
              <td className="pt-3 pb-3">
                {editJobTicketData?.InternalTicketAttachments?.map(
                  (item, index) => (
                    <React.Fragment key={index + 1}>
                      <a href={item?.url}> {item?.name}</a>
                      {index !==
                        editJobTicketData?.InternalTicketAttachments?.length -
                          1 && <hr />}
                    </React.Fragment>
                  )
                )}
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold vewFirstTableColumn">Status</td>
              <td>
                <div className="d-flex align-items-center p-2">
                  <input
                    type="radio"
                    name="status"
                    value="notstarted"
                    checked={selectedStatus === "notstarted"}
                    onChange={handleStatusChange}
                    style={{
                      marginRight: "5px",
                      height: "20px",
                      width: "20px",
                      color: "red",
                    }}
                  />
                  Not Started
                  <input
                    type="radio"
                    name="status"
                    value="completed"
                    checked={selectedStatus === "completed"}
                    onChange={handleStatusChange}
                    style={{
                      marginLeft: "1rem",
                      marginRight: "5px",
                      height: "20px",
                      width: "20px",
                      color: "red",
                    }}
                  />
                  Completed
                  <input
                    type="radio"
                    name="status"
                    value="rejected"
                    checked={selectedStatus === "rejected"}
                    onChange={handleStatusChange}
                    style={{
                      marginLeft: "1.5rem",
                      marginRight: "5px",
                      height: "20px",
                      width: "20px",
                      color: "red",
                    }}
                  />
                  Rejected
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan="2" className="text-center">
                <button
                  className="btn btn-primary"
                  disabled={!selectedStatus}
                  onClick={handleSave}
                >
                  Save
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewJobTicket;
