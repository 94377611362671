import { ApiService } from '../../services/api.service';
import * as BusinessOrganisation from './BusinessOrganisationConstants';
import { showAlert } from '../alert/showAlert';
import { trackPromise } from 'react-promise-tracker';

// Business Organisation Listing Actions

export function listBusinessOrganisations(data) {
  return dispatch => {
    dispatch(listBusinessOrganisationsRequested());
    trackPromise(
      ApiService.callListBusinessOrganisations(data).then(res => {
      dispatch(listBusinessOrganisationsSuccess(res.data));
    }))
      .catch(err => {
        dispatch(listBusinessOrganisationsFailure(err.message));
        showAlert(dispatch,err);

      });
  };
}


const listBusinessOrganisationsRequested = () => ({
  type: BusinessOrganisation.LIST_BUSINESS_ORGANISATIONS_REQUEST
});

const listBusinessOrganisationsSuccess = data => ({
  type: BusinessOrganisation.LIST_BUSINESS_ORGANISATIONS_SUCCESS,
  payload: {
    ...data
  }
});

const listBusinessOrganisationsFailure = error => ({
  type: BusinessOrganisation.LIST_BUSINESS_ORGANISATIONS_FAILURE,
  payload: {
    error
  }
});


//Archive listing for BusinessOrganisations
export function listBusinessOrganisationArchives(data) {
  return dispatch => {
    dispatch(listBusinessOrganisationArchivesRequested());
    trackPromise(
      ApiService.callListBusinessOrganisationArchives(data).then(res => {
      dispatch(listBusinessOrganisationArchivesSuccess(res.data));
    }))
      .catch(err => {
        dispatch(listBusinessOrganisationArchivesFailure(err.message));
        showAlert(dispatch,err);

      });
  };
}


const listBusinessOrganisationArchivesRequested = () => ({
  type: BusinessOrganisation.LIST_BUSINESS_ORGANISATION_ARCHIVE_REQUEST
});

const listBusinessOrganisationArchivesSuccess = data => ({
  type: BusinessOrganisation.LIST_BUSINESS_ORGANISATION_ARCHIVE_SUCCESS,
  payload: {
    ...data
  }
});

const listBusinessOrganisationArchivesFailure = error => ({
  type: BusinessOrganisation.LIST_BUSINESS_ORGANISATION_ARCHIVE_FAILURE,
  payload: {
    error
  }
});


export function resetBusinessOrgDetails(){
  return { type: BusinessOrganisation.RESET_BUSINESS_ORG_DETAILS };
}