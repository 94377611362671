import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";
import { history } from "../../../helpers/history";
import { useDispatch, useSelector } from "react-redux";
import {
  callGetShareByEmail,
  listTemplates,
} from "../OnlineTemplates/TemplateActions";
import { getNotification } from "../../components/header/notificationActions";
import Pagination from "../../components/Pagination/Pagination";
import $ from "jquery";
// import { alertActions } from "../alert/alertActions";
import "./Template.css";
import { CustomerId, companyLogo } from "../../config";
import QRAlert from "./QrAlert";
import { callForListCustomerDepartments } from "../userManagement/actions";
import SearchBox from "src/app/components/SearchBox";

const OnlineTemplates = () => {
  const [isAdmin] = useState(localStorage.getItem("isAdmin"));
  // const [selectedTemplate, setselectedTemplate] = useState("");
  const [shareEmailOpen, setShareEmailOpen] = useState(false);
  const [onOpenClose, setOnOpenClose] = useState(false);
  const [tempIdForLinkShare, setTempIdForLinkShare] = useState();
  const [selectedTempID, setSelectedTempID] = useState();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [singleTempEmailShare, setSingleTempEmailShare] = useState();
  const [departmentList, setDepartmentList] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const dispatch = useDispatch();
  const [sortParams, setSortParams] = useState({
    sortBy: "ASC",
    sortTitle: "templateRefId",
  });

  const onlineTempData = useSelector((state) => state.onlineTemplateData);
  const templateList =
    onlineTempData &&
    onlineTempData.templatesList &&
    onlineTempData.templatesList.data;
  const totalCount =
    onlineTempData &&
    onlineTempData.templatesList &&
    onlineTempData.templatesList.templateCount;

  useEffect(() => {
    window.onbeforeunload = null;
    // Listing Templates
    if (!onOpenClose) {
      getTemplateList();
    }
    getNotificationsList();
  }, [searchValue, onOpenClose, selectedDepartment]);

  const getNotificationsList = () => {
    const data = {
      numPerPage: 10,
      page: 1,
      customerId: CustomerId,
      userId: localStorage.getItem("userId"),
    };
    dispatch(getNotification(data));
  };

  const getTemplateList = (page = 1, pageSize = 100) => {
    const data = {
      numPerPage: pageSize,
      page: page == 0 ? 1 : page,
      sortKey: sortParams.sortTitle,
      sortType: sortParams.sortBy,
      searchKey: searchValue,
    };
    dispatch(listTemplates(data));
  };
  // copy the shared url to clipboard
  const copyToClipboard = (id) => {
    const url =
      window.location.origin +
      "/template?templateId=" +
      id +
      "&customerId=" +
      CustomerId;
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(url).select();
    document.execCommand("copy");
    window.open(url, "_blank", "noreferrer");
    $temp.remove();
  };

  const onPageChanged = (data) => {
    if (data && data.currentPage == currentPageNumber) {
      return;
    }
    if (data && data.currentPage) {
      const { currentPage } = data;
      setCurrentPageNumber(currentPage);
      getTemplateList(currentPage);
    }
  };

  const goToEditTemplate = (data) => {
    history.push("/home/addJobTicket", {
      type: 6,
      id: data.id,
      jobId: data.Template.Ticket.id,
    });
  };

  const sortTable = (title) => {
    var sortByOrder = "ASC";

    if (title == sortParams.sortTitle) {
      sortByOrder = sortParams.sortBy == "DESC" ? "ASC" : "DESC";
      setSortParams({ sortBy: sortByOrder, sortTitle: title });
    } else {
      setSortParams({ sortBy: "ASC", sortTitle: title });
    }

    // Call Api here for sorting
    const data = {
      numPerPage: 100,
      page: currentPageNumber,
      sortKey: title,
      sortType: sortByOrder,
      searchKey: searchValue,
    };
    dispatch(listTemplates(data));
  };
  const goToCreateTemplate = (e) => {
    history.push("/home/addJobTicket", { type: 6 });
  };

  const QRAlertOpenClose = (id) => {
    setOnOpenClose(!onOpenClose);
    setSelectedTempID(id);
  };

  const setOnOpenCloseHandler = () => {
    setOnOpenClose(!onOpenClose);
  };
  const [checkedItems, setCheckedItems] = useState([]);
  const [emailArray, setEmailArray] = useState([]);

  function validateEmail(email) {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return regex.test(email);
  }

  const [newEmail, setNewEmail] = useState("");
  const [error, setError] = useState("");

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const value = newEmail.trim();

      if (!value) {
        setError("The email must be a valid email address.");
      } else if (!validateEmail(value)) {
        setError("Invalid email format");
      } else if (emailArray.includes(value)) {
        setError(
          "Adding the same email address more than once is not necessary."
        );
      } else {
        setEmailArray([...emailArray, value]);
        setNewEmail("");
        setError(""); // Clear any previous error
      }
    }
  };

  const removeTag = (index) => {
    const updatedEmailArray = emailArray.filter((_, i) => i !== index);
    setEmailArray(updatedEmailArray);
    setSingleTempEmailShare();
  };

  const handleEmailCancel = () => {
    // setShareEmailOpen(!shareEmailOpen);
    setEmailArray([]);
    setCheckedItems([]);
    setError("");
    setNewEmail("");
  };
  const handleEmailSend = () => {
    let params = {};
    if (singleTempEmailShare) {
      params = {
        Data: [singleTempEmailShare],
        emails: emailArray,
        customerLogo: companyLogo,
      };
    } else {
      params = {
        Data: checkedItems,
        emails: emailArray,
        customerLogo: companyLogo,
      };
    }

    dispatch(callGetShareByEmail(params));
    // setShareEmailOpen(!shareEmailOpen);
    setEmailArray([]);
    setCheckedItems([]);
    setError("");
    setNewEmail("");
    setSingleTempEmailShare();
  };

  //Function to handle checkbox change
  const handleCheckboxChange = (selectedTemp) => {
    // Check if the templateRefId is already in the checkedItems array
    const existingItem = checkedItems.find(
      (item) => item.id === selectedTemp.id
    );

    if (existingItem) {
      // If it's already checked, uncheck it (remove from the array)
      setCheckedItems((prevCheckedItems) =>
        prevCheckedItems.filter((item) => item.id !== existingItem.id)
      );
    } else {
      // If it's not checked, check it (add to the array)
      setCheckedItems((prevCheckedItems) => [
        ...prevCheckedItems,
        selectedTemp,
      ]);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(callForListCustomerDepartments()).then((res) => {
      if (res?.data?.data) {
        const newDepartmentOptions = res?.data?.data?.map((item) => ({
          ["value"]: item?.Department?.id,
          ["label"]: item?.Department?.departmentName,
        }));

        setDepartmentList(() => [...newDepartmentOptions]);
      } else {
        return null;
      }
    });
  }, []);

  const handleSearch = (value) => {
    setSearchValue(value);
  };
  return (
    <div>
      {!onOpenClose && (
        <div>
          <div className="d-flex justify-content-between">
            <div className="st-tl">
              <h1 className="mh-head-txt pt-3">Online Templates</h1>
            </div>
            {isAdmin == "false" && (
              <div className="aad-usr pt-3">
                <Icon.PlusCircle />
                <a
                  className="a-removed-href-create"
                  onClick={goToCreateTemplate}
                >
                  Create new Job Ticket for Template
                </a>
              </div>
            )}
          </div>
          <hr className="pb-3" />
          <div class="d-flex justify-content-between mt-5 row">
            <div class="input-group st-tl col-md-4">
              <SearchBox onSearch={handleSearch} />
            </div>
            <div class="coli-md-6"></div>
            <div class="coli-md-6 pr-2">
              <button
                disabled={checkedItems.length <= 0}
                className={`btn btn-dark ${
                  checkedItems.length <= 0 ? "disabled" : ""
                } ml-3 p-2`}
                data-toggle="modal"
                data-target="#exampleModalCenter"
                onClick={() => setShareEmailOpen(true)}
                title={checkedItems.length <= 0 ? "select template" : ""}
              >
                Share via Email
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="table-wraper table-responsive mnt mt-3">
                <table className="table job-list">
                  <thead>
                    <tr>
                      <th></th>
                      <th
                        scope="col"
                        className="mx-width"
                        onClick={() => {
                          sortTable("templateRefId");
                        }}
                      >
                        <div class="d-flex justify-content-start">
                          <div>Doc ID</div>
                          <div class="sort-wrp ml-2">
                            <i
                              class={
                                sortParams.sortTitle == "templateRefId" &&
                                sortParams.sortBy == "ASC"
                                  ? "fa fa-sort-asc fa-lg active sort_up"
                                  : "fa fa-sort-asc fa-lg sort_fade_down"
                              }
                            ></i>
                            <i
                              class={
                                sortParams.sortTitle == "templateRefId" &&
                                sortParams.sortBy == "ASC"
                                  ? "fa fa-sort-desc fa-lg sort_fade_down"
                                  : sortParams.sortTitle == "templateRefId" &&
                                    sortParams.sortBy == "DESC"
                                  ? "fa fa-sort-desc fa-lg active sort_up"
                                  : "fa fa-sort-desc fa-lg sort_fade_down"
                              }
                            ></i>
                          </div>
                        </div>
                      </th>{" "}
                      <th
                        scope="col"
                        onClick={() => {
                          sortTable("templateName");
                        }}
                      >
                        <div className="d-flex justify-content-start">
                          <div>Template Name</div>
                          <div className="sort-wrp ml-2">
                            <i
                              className={
                                sortParams.sortTitle == "templateName" &&
                                sortParams.sortBy == "ASC"
                                  ? "fa fa-sort-asc fa-lg active sort_up"
                                  : "fa fa-sort-asc fa-lg sort_fade_down"
                              }
                            ></i>
                            <i
                              className={
                                sortParams.sortTitle == "templateName" &&
                                sortParams.sortBy == "ASC"
                                  ? "fa fa-sort-desc fa-lg sort_fade_down"
                                  : sortParams.sortTitle == "templateName" &&
                                    sortParams.sortBy == "DESC"
                                  ? "fa fa-sort-desc fa-lg active sort_up"
                                  : "fa fa-sort-desc fa-lg sort_fade_down"
                              }
                            ></i>
                          </div>
                        </div>
                      </th>
                      <th
                        scope="col text-center"
                        onClick={() => {
                          sortTable("revision");
                        }}
                      >
                        <div class="d-flex justify-content-start">
                          <div>Revision</div>
                          <div class="sort-wrp ml-2">
                            <i
                              class={
                                sortParams.sortTitle == "revision" &&
                                sortParams.sortBy == "ASC"
                                  ? "fa fa-sort-asc fa-lg active sort_up"
                                  : "fa fa-sort-asc fa-lg sort_fade_down"
                              }
                            ></i>
                            <i
                              class={
                                sortParams.sortTitle == "revision" &&
                                sortParams.sortBy == "ASC"
                                  ? "fa fa-sort-desc fa-lg sort_fade_down"
                                  : sortParams.sortTitle == "revision" &&
                                    sortParams.sortBy == "DESC"
                                  ? "fa fa-sort-desc fa-lg active sort_up"
                                  : "fa fa-sort-desc fa-lg sort_fade_down"
                              }
                            ></i>
                          </div>
                        </div>
                      </th>{" "}
                      <th
                        scope="col"
                        onClick={() => {
                          sortTable("updatedAt");
                        }}
                      >
                        <div className="d-flex justify-content-start">
                          <div>Date</div>
                          <div className="sort-wrp ml-2">
                            <i
                              className={
                                sortParams.sortTitle == "updatedAt" &&
                                sortParams.sortBy == "ASC"
                                  ? "fa fa-sort-asc fa-lg active sort_up"
                                  : "fa fa-sort-asc fa-lg sort_fade_down"
                              }
                            ></i>
                            <i
                              className={
                                sortParams.sortTitle == "updatedAt" &&
                                sortParams.sortBy == "ASC"
                                  ? "fa fa-sort-desc fa-lg sort_fade_down"
                                  : sortParams.sortTitle == "updatedAt" &&
                                    sortParams.sortBy == "DESC"
                                  ? "fa fa-sort-desc fa-lg active sort_up"
                                  : "fa fa-sort-desc fa-lg sort_fade_down"
                              }
                            ></i>
                          </div>
                        </div>
                      </th>
                      <th className="text-center" scope="col">
                        QR
                      </th>
                      <th className="text-center" scope="col">
                        Share
                      </th>
                      {isAdmin == "false" && (
                        <th className="text-center" scope="col">
                          Change Request
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {onlineTempData &&
                      !onlineTempData.loading &&
                      onlineTempData.error && (
                        <span className="text-danger">
                          ERROR: {onlineTempData.error}
                        </span>
                      )}
                    {templateList &&
                      templateList.map((template, index) => (
                        <tr key={index + 1}>
                          <td>
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                onChange={() =>
                                  handleCheckboxChange({
                                    id: template?.templateRefId,
                                    templateName: template?.templateName,
                                    url:
                                      window.location.origin +
                                      "/template?templateId=" +
                                      template?.id +
                                      "&customerId=" +
                                      CustomerId,
                                  })
                                }
                                id="flexCheckDefault"
                                checked={
                                  checkedItems.length > 0 &&
                                  checkedItems.some(
                                    (item) =>
                                      item.id === template?.templateRefId
                                  )
                                }
                              />
                            </div>
                          </td>
                          <td className="mx-width">{template.templateRefId}</td>
                          <td>{template.templateName}</td>

                          <td className="text-center">{template.revision}</td>
                          <td>
                            {template.updatedAt
                              ? template.updatedAt.split("T")[0]
                              : "Not Approved"}
                          </td>
                          <td className="text-center">
                            {template?.Template?.qrImage ? (
                              <a>
                                <img
                                  src="/images/QR-Image.png"
                                  width="25"
                                  height="25"
                                  onClick={() => QRAlertOpenClose(template?.id)}
                                ></img>
                              </a>
                            ) : (
                              <a>
                                <img
                                  src="/images/no_Image_icon.png"
                                  width="25"
                                  height="25"
                                  onClick={() => QRAlertOpenClose(template?.id)}
                                ></img>
                              </a>
                            )}
                          </td>

                          <td className="text-center">
                            <a
                              className="a-removed-href"
                              onClick={() => {
                                setShareEmailOpen(false);
                                setTempIdForLinkShare(template.id);
                                setSingleTempEmailShare({
                                  id: template?.templateRefId,
                                  templateName: template?.templateName,
                                  url:
                                    window.location.origin +
                                    "/template?templateId=" +
                                    template?.id,
                                });
                              }}
                              data-toggle="modal"
                              data-target="#exampleModalCenter"
                            >
                              <Icon.Share />
                            </a>
                          </td>
                          {isAdmin == "false" && (
                            <td className="text-center">
                              <a
                                className="a-removed-href"
                                onClick={() => goToEditTemplate(template)}
                              >
                                Raise Ticket
                              </a>
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
                {onlineTempData.templatesList.templateCount <= 0 && (
                  <p className="text-center font-italic font-weight-bold">
                    No data available!
                  </p>
                )}
                {onlineTempData.templatesList.templateCount > 0 && (
                  <div className="d-flex flex-row py-4 align-items-center float-left">
                    Total Count: {onlineTempData.templatesList.templateCount}
                  </div>
                )}

                <div className="d-flex flex-row py-4 align-items-center float-right">
                  {onlineTempData.templatesList.templateCount > 0 && (
                    <Pagination
                      totalRecords={totalCount}
                      pageLimit={100}
                      pageNeighbours={0}
                      onPageChanged={onPageChanged}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {onOpenClose && (
        <div class="mainDiv">
          <QRAlert
            selectedTempID={selectedTempID}
            setOnOpenCloseHandler={setOnOpenCloseHandler}
          />
        </div>
      )}

      <div
        class="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Share Template
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleEmailCancel}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              class="modal-body"
              style={{
                textAlign: "center",
                paddingRight: "1rem",
                paddingLeft: "1rem",
                marginTop: "0.8rem",
                marginBottom: "0.8rem",
              }}
            >
              {!shareEmailOpen && (
                <button
                  type="button"
                  class="btn btn-secondary"
                  style={{ padding: "10px", margin: "1rem" }}
                  onClick={() => setShareEmailOpen(true)}
                >
                  Share via Email
                  <Icon.Mail style={{ marginLeft: "6px" }} />
                </button>
              )}

              {!shareEmailOpen && (
                <button
                  type="button"
                  class="btn btn-secondary"
                  style={{ padding: "10px", margin: "1rem" }}
                  onClick={() => copyToClipboard(tempIdForLinkShare)}
                >
                  Open in new tab
                  <Icon.ArrowUpRight style={{ marginLeft: "6px" }} />
                </button>
              )}
              {shareEmailOpen && (
                <div style={{ marginTop: "1rem" }}>
                  <div className="tags-input-container">
                    {emailArray.map((email, index) => (
                      <div className="tag-item" key={index}>
                        <span className="text">{email}</span>
                        <span
                          className="close"
                          onClick={() => removeTag(index)}
                        >
                          &times;
                        </span>
                      </div>
                    ))}
                    <input
                      onKeyDown={handleKeyDown}
                      type="text"
                      className="tags-input"
                      placeholder={
                        emailArray.length > 0
                          ? "Type another email"
                          : "Type emails here.."
                      }
                      value={newEmail}
                      onChange={(e) => {
                        setNewEmail(e.target.value);
                        setError(""); // Clear any previous error when the input changes
                      }}
                    />
                  </div>
                  <div>
                    {error && (
                      <p className="email-error-text noteText">{error}</p>
                    )}

                    {!error && (
                      <p className="noteText">
                        Press the 'Enter' key to add another email...
                      </p>
                    )}
                  </div>
                  <div class="input-group-append">
                    <button
                      className="cancelButton"
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleEmailCancel}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleEmailSend}
                      disabled={emailArray.length === 0}
                      title={
                        emailArray.length === 0 ? "Enter email to send" : ""
                      }
                      className={`mailSendButton ${
                        emailArray.length <= 0 ? "disabled" : ""
                      }`}
                    >
                      Send
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnlineTemplates;
