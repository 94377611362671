import * as notification from './notificationConstants';

const initialState = {
  loading: false,
  notificationData: [],
  error: null
};

function NotificationReducer(state = initialState, action={}) {
  switch (action.type) {
    case notification.GET_NOTIFICATIONS_REQUEST:
    case notification.VIEW_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case notification.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        notificationData: action.payload,
        error: null,
      };
    case notification.GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        notificationData: [],
        error: action.payload.error,
      };
    case notification.VIEW_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        viewed: action.payload,

      };
    case notification.VIEW_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        viewed: "",


      };

    case notification.NOTIFICATION_CLEAR_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export default NotificationReducer;