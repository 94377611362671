const SelectHandlerForPDF = () => {
  // Get all select elements with the class "select"
  const selectElements = document.querySelectorAll(".select");

  selectElements.forEach((selectElement) => {
    // Get the value from data-value attribute
    let dataValue;
    let options;
    if (selectElement) {
      dataValue = selectElement.getAttribute("data-value");
      options = selectElement.querySelectorAll("option");
    }

    // Set the selected attribute for the corresponding option
    if (options) {
      options.forEach((option) => {
        if (option.value === dataValue) {
          option.setAttribute("selected", "selected");
        }
      });
    }
  });
};

export default SelectHandlerForPDF;
