// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Translations
const resources = {
  en: {
    translation: {
      greeting: "Hello, World!",
    },
  },
  fr: {
    translation: {
      greeting: "Bonjour le monde !",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // default language
  fallbackLng: "en", // fallback language
  interpolation: {
    escapeValue: false, // React already sanitizes for XSS
  },
});

export default i18n;
