import * as BusinessOrganisation from './BusinessOrganisationConstants';

const initialState = {
  loading: false,
  businessOrganisationsList: [],
  error: null,
};

function BusinessOrganisationReducer(state = initialState, action={}) {
  switch (action.type) {
    case BusinessOrganisation.LIST_BUSINESS_ORGANISATIONS_REQUEST:
    case BusinessOrganisation.LIST_BUSINESS_ORGANISATION_ARCHIVE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case BusinessOrganisation.LIST_BUSINESS_ORGANISATIONS_SUCCESS:
    case BusinessOrganisation.LIST_BUSINESS_ORGANISATION_ARCHIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        businessOrganisationsList: action.payload,
        error: null,
      };
    case BusinessOrganisation.LIST_BUSINESS_ORGANISATIONS_FAILURE:
    case BusinessOrganisation.LIST_BUSINESS_ORGANISATION_ARCHIVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        businessOrganisationsList: []

      };

    case BusinessOrganisation.RESET_BUSINESS_ORG_DETAILS:
      return {
        ...initialState,

      };

    default:
      return state;
  }
}

export default BusinessOrganisationReducer;