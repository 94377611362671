import React from "react";
import { versionNumber } from "../../config";
import "./footer.css";

const Footer = () => {
  var currDate = new Date();
  var year = currDate.getFullYear();
  return (
    <div className="ftr-style">
      {/* <img className="logo-size" src={brandLogo} /><br/> */}
      <span>© {year} Digiplatform. All rights reserved </span>
      <br />
      <span>v{versionNumber}</span>
    </div>
  );
};

export default Footer;
