import { evaluate } from "mathjs";
import { useEffect } from "react";

function useCalc(htmlData) {
  const callCalculation = () => {
    const userForm = document.querySelectorAll(
      "input:not([type='radio']):not([type='checkbox'])"
    );

    const resultElements = document.querySelectorAll('input[name^="result"]');

    if (userForm?.length)
      userForm.forEach(function (el) {
        el.oninput = function (e) {
          el.value = e.target.value;
          el.defaultValue = e.target.value;

          if (el.name?.startsWith("cell")) {
            resultElements.forEach(function (resultEl) {
              let [, calcPlaceholder] = resultEl.name.split("=");
              const cellKeys = calcPlaceholder
                .split(/[*+-/()]/)
                .filter((cellKey) => cellKey && isNaN(cellKey));

              const cellValueMap = {};

              cellKeys.forEach((cellKey) => {
                if (!cellValueMap[cellKey]) {
                  const item = document.querySelector(
                    `input[name^="${cellKey}"]`
                  );

                  if (item) {
                    cellValueMap[cellKey] = item.value
                      ? Number(item.value).toFixed(2)
                      : 0.0;
                  } else {
                    throw new Error(
                      `document.querySelector(input[name="${cellKey}"] -- ${cellKey} not found!`
                    );
                  }
                }
              });

              Object.keys(cellValueMap).forEach((cellValueKey) => {
                calcPlaceholder = calcPlaceholder.replaceAll(
                  new RegExp("\\b" + cellValueKey + "\\b", "g"),
                  cellValueMap[cellValueKey]
                );
              });

              const calculatedValue = evaluate(calcPlaceholder);

              resultEl.value = calculatedValue.toFixed(2);
              resultEl.defaultValue = calculatedValue.toFixed(2);
            });
          }
        };
      });
  };
  useEffect(() => {
    callCalculation();
    const cellElements = document.querySelectorAll('input[name^="cell"]');
    cellElements.forEach((cellEle) =>
      cellEle.addEventListener("input", callCalculation)
    );
  }, [htmlData]);
}

export default useCalc;
