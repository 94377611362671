import React, { useState } from "react";
import Header from "../../components/header/header";
import LeftDrawer from "../../components/left-drawer/left-drawer";
import Subroutes from "./Subroutes";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";

import { logout } from "./logoutActions";
import Footer from "../../components/footer/footer";
import { useIdleTimer } from "react-idle-timer";
import CustomAlert from "src/app/components/CustomAlert/CustomAlert";

const Dashboard = () => {
  const [isExpand, setIsExpand] = useState(true);
  const [isActive, setIsActive] = useState("Digiplatform");

  const dispatch = useDispatch();

  const handleOnIdle = () => {
    getLastActiveTime();
    logoutCall();
    CustomAlert("Session expired. Please login again");
  };

  const handleOnActive = () => {
    getRemainingTime();
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500,
  });

  const logoutCall = () => {
    const id = localStorage.getItem("userId");
    dispatch(logout(id));
  };

  const trigerDrawerToggle = () => {
    setIsExpand(!isExpand);
  };

  const updateActiveTab = (activeTab) => {
    setIsActive(activeTab);
  };

  return (
    <div id="page-top">
      <div id="wrapper">
        <LeftDrawer isExpand={isExpand} isActiveTab={updateActiveTab} />
        <div id="content-wrapper" className="d-flex flex-column mt-5 pt-4">
          <div id="content">
            <Header onDrawerToggle={trigerDrawerToggle} />
            <div className="container-fluid pt-4 pb-5 cst-bg h-100">
              <Subroutes />
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fa fa-angle-up"></i>
      </a>
      <div
        className="modal fade"
        id="logoutModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Ready to Leave?
              </h5>
              <button
                className="close"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">Are you sure you want to logout?</div>
            <div className="modal-footer">
              <button
                className="btn btn-secondary"
                type="button"
                data-dismiss="modal"
              >
                No
              </button>
              <a
                className="btn btn-primary a-cancel"
                data-dismiss="modal"
                onClick={() => {
                  logoutCall();
                }}
              >
                Yes
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, null, null, { forwardRef: true })(Dashboard);
