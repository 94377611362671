import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFlowchartForCustomer } from '../Flowcharts/FlowchartActions';
import { useLocation } from 'react-router-dom';
import $ from 'jquery';

const PreviewFlowchart = () => {


    const dispatch = useDispatch();
    const location = useLocation();
    const flowchartData = useSelector(state => state.flowchartDataReducer);
    const customerFlowchartData = flowchartData && flowchartData.customerFlowchart;

    useEffect(() => {
        const search = new URLSearchParams(location.search);
        const flowchartId = search.get("flowchartId");
        if (flowchartId != null) {
            dispatch(getFlowchartForCustomer(flowchartId));
        }
    }, []);


    useEffect(() => {
        if (customerFlowchartData && customerFlowchartData.data && customerFlowchartData.data.id) {
            // addNeededItemsInFormTag();  
            $("#preview-content").empty();           
                $("#preview-content").append(`<div className="container-fluid"><div className="row"><div  className="col-md-8 mx-auto">${customerFlowchartData.data.headerData} <div style="padding:20px">${customerFlowchartData.data.bodyData} </div>${customerFlowchartData.data.footerData}</div></div></div>`);
                $('.joint-paper').css({
                    left: '0px',
                    right: '0px',
                    top: '0px',
                    bottom: '0px'
                    
                })
                $('.joint-paper-scroller').css({
                    
                })            
        }
        var clicked = false, base = 0;

        $('.paper-scroller-background').on({
            mousemove: function(e) {
                clicked && function(xAxis) {
                    var _this = $(this);
                    if(base > xAxis) {
                        base = xAxis;
                        _this.css('margin-left', '-=1px');
                    }
                    if(base < xAxis) {
                        base = xAxis;
                        _this.css('margin-left', '+=1px');
                    }
                }.call($(this), e.pageX);
            },
            mousedown: function(e) {
                clicked = true;
                base = e.pageX;
            },
            mouseup: function(e) {
                clicked = false;
                base = 0;
            }
        });

        }, [customerFlowchartData]);


    return <div className="container-fluid">
        <div className="row">
            <div className="col-md-8 mx-auto" >

                {
                    customerFlowchartData.data && customerFlowchartData.data.id &&
                    <div id="preview-content">
                    </div>

                }
            </div>
        </div>
    </div>

}

export default (PreviewFlowchart);