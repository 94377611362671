import React, { useState } from "react";
import validator from "validator";
import { forgotPassword } from "./forgotPasswordActions";
import { useDispatch } from "react-redux";
import { CustomerId } from "../../config";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const handleSubmit = (e) => {
    setSubmitted(true);
    if (email && validator.isEmail(email)) {
      dispatch(forgotPassword({ email: email, customerId: CustomerId }));
    }
  };

  return (
    <div class="container-fluidcst">
      <div class="row">
        <div class="d-none d-lg-block col-lg-6 no-padding ipod">
          <div class="log-bg"></div>
        </div>
        <div class="col-lg-6">
          <div class="content-wrap">
            <div class="login-wrapp">
              <div class="login-outer">
                <div class="p-5">
                  <div class="text-center">
                    <h1 class="h4 text-gray-900 mb-4">
                      Forgot your password
                      <br />
                      <br />
                      <small>
                        Enter your email address below and we'll get you back on
                        track
                      </small>
                    </h1>
                  </div>

                  <form class="user">
                    <div class="group">
                      <input
                        type="email"
                        name="email"
                        value={email}
                        class={
                          submitted && !email
                            ? "brd-4 form-control form-control-user is-invalid"
                            : submitted && !validator.isEmail(email)
                            ? "brd-4 form-control form-control-use is-invalid"
                            : ""
                        }
                        onChange={handleChange}
                        required="required"
                      />
                      {submitted && !email && (
                        <div className="invalid-feedback">
                          Email id is required
                        </div>
                      )}
                      {submitted && email && !validator.isEmail(email) && (
                        <div className="invalid-feedback">Invalid Email id</div>
                      )}
                      <span class="highlight"></span>
                      <label>Enter email</label>
                    </div>

                    <div>
                      <button
                        type="button"
                        onClick={handleSubmit}
                        class="btn btn-primary btn-lg btn-block mb-3"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                  <div style={{ textAlign: "center" }}>
                    <a href="/login">Back to Login</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
