import { ApiService } from '../../services/api.service';
import * as Procedure from './ProcedureConstants';
import { showAlert } from '../alert/showAlert';
import { trackPromise } from 'react-promise-tracker';

// Procedure Listing Actions

export function listProcedures(data) {
  return dispatch => {
    dispatch(listProceduresRequested());
    trackPromise(
      ApiService.callListProcedures(data).then(res => {
        dispatch(listProceduresSuccess(res.data));
      }))
      .catch(err => {
        dispatch(listProceduresFailure(err.message));
        showAlert(dispatch, err);

      });
  };
}


const listProceduresRequested = () => ({
  type: Procedure.LIST_PROCEDURES_REQUEST
});

const listProceduresSuccess = data => ({
  type: Procedure.LIST_PROCEDURES_SUCCESS,
  payload: {
    ...data
  }
});

const listProceduresFailure = error => ({
  type: Procedure.LIST_PROCEDURES_FAILURE,
  payload: {
    error
  }
});


//Archive listing for procedures
export function listProcedureArchives(data) {
  return dispatch => {
    dispatch(listProcedureArchivesRequested());
    trackPromise(
      ApiService.callListProcedureArchives(data).then(res => {
        dispatch(listProcedureArchivesSuccess(res.data));
      }))
      .catch(err => {
        dispatch(listProcedureArchivesFailure(err.message));
        showAlert(dispatch, err);

      });
  };
}


const listProcedureArchivesRequested = () => ({
  type: Procedure.LIST_PROCEDURE_ARCHIVE_REQUEST
});

const listProcedureArchivesSuccess = data => ({
  type: Procedure.LIST_PROCEDURE_ARCHIVE_SUCCESS,
  payload: {
    ...data
  }
});

const listProcedureArchivesFailure = error => ({
  type: Procedure.LIST_PROCEDURE_ARCHIVE_FAILURE,
  payload: {
    error
  }
});

//Reset

export function resetProcedureDetails() {
  return { type: Procedure.RESET_PROCEDURE_DETAILS };
}