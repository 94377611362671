import React, { useState, useEffect } from "react";
import {
  callForListCustomerDepartments,
  callListRoles,
  createUser,
  updateUser,
} from "./actions";
import * as Icon from "react-feather";
import { useDispatch } from "react-redux";
import validator from "validator";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
// import Breadcrumbs from "src/app/components/breadcrumbs/breadcrumbs";
import Select from "react-select";
import "./userManagement.css";
import PageTopHeader from "src/app/components/PageTopHeader";
import Cookies from "js-cookie";
import { history } from "../../../helpers/history";
import { CustomerId } from "src/app/config";

const AddUser = (props) => {
  const userRole = Cookies.get("userRole");
  const [departmentOptions, setDepartmentOptions] = useState();
  const [optionsForRole, setOptionsForRole] = useState();
  const editFlowDepartmentOptions =
    props?.location?.state?.UserDepartments?.map((item) => ({
      ["value"]: item?.departmentId,
      ["label"]: item?.Department?.departmentName,
    }));

  const [selectedUserDepartments, setSelectedUserDepartments] = useState(
    editFlowDepartmentOptions ? [...editFlowDepartmentOptions] : []
  );

  const [selectedRoles, setSelectedRoles] = useState(
    props?.location?.state?.UserRoles
      ? {
          value: props.location.state.UserRoles[0]?.roleId,
          label: props.location.state.UserRoles[0]?.Role.roleName,
        }
      : ""
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(callForListCustomerDepartments()).then((res) => {
      const newDepartmentOptions = res?.data?.data.map((item) => ({
        ["value"]: userRole === "Prime Admin" ? item?.id : item?.Department?.id,
        ["label"]:
          userRole === "Prime Admin"
            ? item?.departmentName
            : item?.Department?.departmentName,
      }));
      if (newDepartmentOptions) {
        setDepartmentOptions(() => [...newDepartmentOptions]);
      }
    });
    dispatch(callListRoles()).then((res) => {
      const newRoleOptions = res?.data?.data.map((item) => ({
        ["value"]: item.id,
        ["label"]: item.roleName,
      }));
      if (newRoleOptions) {
        setOptionsForRole(() => [...newRoleOptions]);
      }
    });
  }, []);

  const [inputs, setInputs] = useState({
    firstName:
      props.location.state && props.location.state.firstName
        ? props.location.state.firstName
        : "",
    lastName:
      props.location.state && props.location.state.lastName
        ? props.location.state.lastName
        : "",

    departmentsArray: selectedUserDepartments,

    roleId: selectedRoles,
    email:
      props.location.state && props.location.state.email
        ? props.location.state.email
        : "",
    customerId:
      props.location.state && props.location.state.customerId
        ? props.location.state.customerId
        : "",
  });
  const [isActive, setIsActive] = useState(
    props.location.state && props.location.state.isPrimeUser
      ? props.location.state.isPrimeUser
      : false
  );

  const [selectedUserId] = useState(
    props.location.state && props.location.state.id
      ? props.location.state.id
      : ""
  );
  const [showCropPotion, setShowCropPortion] = useState(false);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 30,
    aspect: 1,
  });
  const [selectedFile, setselectedFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(
    props.location.state ? props.location.state.image : ""
  );

  const [profile_pic] = useState();
  const [src, setSrc] = useState();
  const [imageRef, setImageRef] = useState();

  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();

  // Input field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  // Action Save Trigger
  const onSaveClick = () => {
    setSubmitted(true);
    if (
      inputs.firstName &&
      inputs.lastName &&
      inputs.email &&
      validator.isEmail(inputs.email) &&
      selectedUserDepartments?.length > 0 &&
      selectedRoles?.value
    ) {
      const formData = new FormData();
      if (fileName && selectedFile) {
        formData.append("formFiles", croppedImageUrl, fileName);
      }
      formData.append("firstName", inputs.firstName);
      formData.append("lastName", inputs.lastName);
      formData.append("email", inputs.email);
      formData.append(
        "departmentsArray",
        selectedUserDepartments.map((item) => item.value).join(",")
      );
      formData.append("roleId", selectedRoles?.value);

      dispatch(createUser(CustomerId, formData)).then((res) => {
        if (res.data.status == "success") {
          history.push("/home/usermanagement");
        }
      });
    }
  };

  // Action Edit Trigger
  const onEditClick = () => {
    setSubmitted(true);
    if (
      inputs.firstName &&
      inputs.lastName &&
      inputs.email &&
      validator.isEmail(inputs.email) &&
      selectedUserDepartments?.length > 0 &&
      selectedRoles?.value
    ) {
      const formData = new FormData();
      if (fileName && selectedFile) {
        formData.append("formFiles", croppedImageUrl, fileName);
      }
      formData.append("firstName", inputs?.firstName);
      formData.append("lastName", inputs?.lastName);
      formData.append("department", "");
      formData.append("email", inputs?.email);
      formData.append("userId", props.location.state?.id);
      formData.append(
        "departmentsArray",
        selectedUserDepartments?.map((item) => item.value).join(",")
      );
      formData.append("roleId", selectedRoles?.value);
      formData.append("isPrimeUser", isActive);

      dispatch(updateUser(formData, CustomerId)).then((res) => {
        if (res.data.status == "success") {
          history.push("/home/usermanagement");
        }
      });
    }
  };

  const onImageLoaded = (image) => {
    setImageRef(image);
    setCrop({
      unit: "%",
      width: 30,
      aspect: 1,
    });
    return false;
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop, percentCrop) => {
    setCrop(crop);
  };

  const makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef,
        crop,
        "newFile.jpeg"
      );
      setCroppedImageUrl(croppedImageUrl);
    }
  };

  const doCrop = () => {
    setShowCropPortion(false);
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          blob.name = fileName;
          resolve(blob);
        },
        "image/jpeg",
        1
      );
    });
  };
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setSrc(reader.result);
        setShowCropPortion(true);
      });
      reader.readAsDataURL(e.target.files[0]);
      setselectedFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
      e.target.value = null;
    }
  };
  return (
    <div class="outer-wrap ">
      <PageTopHeader
        leftTitle={selectedUserId ? "Edit User" : "Add New User"}
        rightTitle={"Back to User List"}
        url={"/home/usermanagement"}
        Righticon={<Icon.ArrowLeft />}
      />
      <div class="container-wrap AddUserBox">
        <div class="card p-5">
          <div className="card-body">
            <form class="user">
              <div class="form-group text-center pb-3">
                <div class="st-tl">
                  <h1 class="mh-head-txt">
                    {selectedUserId == "" ? "Add New User" : "Edit User"}
                  </h1>
                </div>
              </div>

              <div class="form-group pb-3 text-center">
                <img
                  alt="Crop"
                  className="rounded-circle mx-auto d-block pb-2"
                  src={
                    croppedImageUrl
                      ? croppedImageUrl.constructor.name === "Blob"
                        ? URL.createObjectURL(croppedImageUrl)
                        : croppedImageUrl
                      : "/images/default-user-placeholder.png"
                  }
                  width="150"
                  height="150"
                />
              </div>
              <div class="group text-center">
                {src && showCropPotion && (
                  <ReactCrop
                    src={src}
                    crop={crop}
                    ruleOfThirds
                    onImageLoaded={onImageLoaded}
                    onComplete={onCropComplete}
                    onChange={onCropChange}
                  />
                )}
                {showCropPotion && (
                  <div>
                    <span
                      className="badge badge-primary mt-3 text-center done-btn"
                      onClick={() => doCrop()}
                    >
                      Done
                    </span>
                  </div>
                )}
              </div>

              <div class="group cst-browse">
                <input
                  type="file"
                  onChange={onSelectFile}
                  value={profile_pic}
                  class="custom-file-input cstm-grp-inp"
                  id="customFile"
                />
                <label class="custom-file-label cstm-grp-lbl" for="customFile">
                  {fileName ? fileName : "Choose image"}
                </label>
              </div>
              <div class="group">
                <input
                  type="text"
                  required="required"
                  className={
                    submitted && !inputs.firstName
                      ? "brd-4 form-control is-invalid"
                      : ""
                  }
                  id="firstName"
                  name="firstName"
                  value={inputs.firstName}
                  onChange={handleChange}
                />
                <span class="highlight"></span>
                <label>First Name</label>
                {submitted && !inputs.firstName && (
                  <div className="invalid-feedback">First name is required</div>
                )}
              </div>

              <div className="group">
                <input
                  type="text"
                  required="required"
                  className={
                    submitted && !inputs.lastName
                      ? "brd-4 form-control is-invalid"
                      : ""
                  }
                  id="lastName"
                  name="lastName"
                  value={inputs.lastName}
                  onChange={handleChange}
                />
                <span class="highlight"></span>
                <label>Last Name</label>
                {submitted && !inputs.lastName && (
                  <div className="invalid-feedback">Last name is required</div>
                )}
              </div>
              <div className="group">
                <input
                  type="email"
                  required="required"
                  name="email"
                  disabled={selectedUserId != ""}
                  value={inputs.email}
                  onChange={handleChange}
                  className={
                    submitted && !inputs.email
                      ? "brd-4 form-control is-invalid"
                      : submitted && !validator.isEmail(inputs.email)
                      ? "brd-4 form-control is-invalid"
                      : ""
                  }
                  id="email"
                  aria-describedby="emailHelp"
                />
                <span class="highlight"></span>
                {selectedUserId == "" && <label>Email Address</label>}
                {submitted && !inputs.email && (
                  <div className="invalid-feedback">Email id is required</div>
                )}
                {submitted &&
                  inputs.email &&
                  !validator.isEmail(inputs.email) && (
                    <div className="invalid-feedback">Invalid Email id</div>
                  )}
              </div>

              <div className="group" style={{ marginBottom: "10px" }}>
                <Select
                  required="required"
                  name="UserDepartments"
                  isMulti
                  closeMenuOnSelect={false}
                  value={selectedUserDepartments}
                  onChange={setSelectedUserDepartments}
                  placeholder={"Select Other Departments"}
                  options={departmentOptions}
                />
                <span class="highlight"></span>
                {submitted && selectedUserDepartments.length === 0 && (
                  <div class="react-select-valid-msg">
                    Department is required
                  </div>
                )}
              </div>
              {!submitted && (
                <p style={{ fontSize: "11px" }}>
                  You can select multilple departments !
                </p>
              )}
              <div className="group">
                <Select
                  required="required"
                  name="UserRoles"
                  value={selectedRoles}
                  onChange={setSelectedRoles}
                  placeholder={"Select Role"}
                  options={optionsForRole}
                  style={{ border: "2px solid red" }}
                />

                <span class="highlight"></span>

                {submitted && selectedRoles.length === 0 && (
                  <div class="react-select-valid-msg">Role is required</div>
                )}
              </div>

              {selectedUserId == "" && (
                <a
                  onClick={() => {
                    onSaveClick();
                  }}
                  className="brd-4 btn btn-primary btn-user btn-block mt-5 a-cancel"
                >
                  Add User
                </a>
              )}
              {props?.location?.state?.id && (
                <a
                  onClick={() => {
                    onEditClick();
                  }}
                  className="brd-4 btn btn-primary btn-user btn-block mt-5 a-cancel"
                >
                  Save User
                </a>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
