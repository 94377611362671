import React, { useState } from "react";
import { updateProfile } from "./profileActions";
import { useDispatch } from "react-redux";
import validator from "validator";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { CustomerId } from "../../config";
import "./profile.css";

const EditProfile = (props) => {
  const [selectedUserId] = useState(
    props.location.state ? props.location.state.id : ""
  );
  const [inputs, setInputs] = useState({
    firstName: props.location.state ? props.location.state.firstName : "",
    lastName: props.location.state ? props.location.state.lastName : "",
    email: props.location.state ? props.location.state.email : "",
    department: props.location.state ? props.location.state.department : "",
  });
  const [showCropPotion, setShowCropPortion] = useState(false);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 30,
    aspect: 1,
  });
  const [selectedFile, setselectedFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(
    props.location.state ? props.location.state.image : ""
  );
  const [profile_pic] = useState();
  const [src, setSrc] = useState();
  const [imageRef, setImageRef] = useState();

  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();

  // Input field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  // Action Edit Trigger
  const onEditClick = (e) => {
    setSubmitted(true);
    const formData = new FormData();
    if (fileName && selectedFile) {
      formData.append("formFiles", croppedImageUrl, fileName);
    }
    formData.append("firstName", inputs.firstName);
    formData.append("lastName", inputs.lastName);
    formData.append("department", inputs.department);
    formData.append("userId", selectedUserId);

    if (
      inputs.firstName &&
      inputs.lastName &&
      inputs.department &&
      inputs.email &&
      validator.isEmail(inputs.email)
    ) {
      dispatch(updateProfile(formData, CustomerId));
    }
  };

  const onImageLoaded = (image) => {
    setImageRef(image);
    setCrop({
      unit: "%",
      width: 30,
      aspect: 1,
    });
    return false;
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop, percentCrop) => {
    setCrop(crop);
  };

  const makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef,
        crop,
        "newFile.jpeg"
      );
      setCroppedImageUrl(croppedImageUrl);
    }
  };

  const doCrop = () => {
    setShowCropPortion(false);
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          blob.name = fileName;
          resolve(blob);
        },
        "image/jpeg",
        1
      );
    });
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setSrc(reader.result);
        setShowCropPortion(true);
      });
      reader.readAsDataURL(e.target.files[0]);
      setselectedFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
      e.target.value = null;
    }
  };

  return (
    <div className="outer-wrap">
      <div className="container-wrap">
        <div className="card p-2">
          <div className="card-body">
            <form className="user">
              <div className="form-group text-center pb-3">
                <div className="st-tl">
                  <h1 className="mh-head-txt">Edit Profile</h1>
                </div>
              </div>
              <div className="form-group text-center">
                <img
                  alt="Crop"
                  className="rounded-circle mx-auto d-block pb-2"
                  src={
                    croppedImageUrl
                      ? croppedImageUrl.constructor.name === "Blob"
                        ? URL.createObjectURL(croppedImageUrl)
                        : croppedImageUrl
                      : "/images/default-user-placeholder.png"
                  }
                  width="150"
                  height="150"
                />
                <div className="group cst-browse">
                  <input
                    type="file"
                    onChange={onSelectFile}
                    value={profile_pic}
                    className="custom-file-input cstm-grp-inp"
                    id="customFile"
                  />
                  <label
                    className="custom-file-label cstm-grp-lbl"
                    for="customFile"
                  >
                    Choose image
                  </label>
                </div>
              </div>
              <div class="group text-center">
                {src && showCropPotion && (
                  <ReactCrop
                    src={src}
                    crop={crop}
                    ruleOfThirds
                    onImageLoaded={onImageLoaded}
                    onComplete={onCropComplete}
                    onChange={onCropChange}
                  />
                )}
                {showCropPotion && (
                  <div>
                    <span
                      className="badge badge-primary mt-3 text-center done-btn"
                      onClick={() => doCrop()}
                    >
                      Done
                    </span>
                  </div>
                )}
              </div>

              <div className="group ">
                <input
                  type="text"
                  required="required"
                  className={
                    submitted && !inputs.firstName
                      ? "brd-4 form-control is-invalid"
                      : ""
                  }
                  id="firstName"
                  name="firstName"
                  value={inputs.firstName}
                  onChange={handleChange}
                />
                {submitted && !inputs.firstName && (
                  <div className="invalid-feedback">First name is required</div>
                )}
                <span className="highlight"></span>
                <label>First Name</label>
              </div>

              <div className="group ">
                <input
                  type="text"
                  required="required"
                  className={
                    submitted && !inputs.lastName
                      ? "brd-4 form-control is-invalid"
                      : ""
                  }
                  id="lastName"
                  name="lastName"
                  value={inputs.lastName}
                  onChange={handleChange}
                />
                {submitted && !inputs.lastName && (
                  <div className="invalid-feedback">Last name is required</div>
                )}
                <span className="highlight"></span>
                <label>Last Name</label>
              </div>

              <div className="group disabled-cst">
                <input
                  type="email"
                  required="required"
                  name="email"
                  disabled={selectedUserId != ""}
                  value={inputs.email}
                  onChange={handleChange}
                  className={
                    submitted && !inputs.email
                      ? " is-invalid"
                      : submitted && !validator.isEmail(inputs.email)
                      ? " is-invalid"
                      : ""
                  }
                  id="email"
                  aria-describedby="emailHelp"
                />
                {submitted && !inputs.email && (
                  <div className="invalid-feedback">Email id is required</div>
                )}
                {submitted &&
                  inputs.email &&
                  !validator.isEmail(inputs.email) && (
                    <div className="invalid-feedback">Invalid Email id</div>
                  )}
                <span className="highlight"></span>
                <label>Email</label>
              </div>

              <div className="group ">
                <input
                  type="text"
                  required="required"
                  className={
                    submitted && !inputs.department
                      ? "brd-4 form-control is-invalid"
                      : ""
                  }
                  id="department"
                  name="department"
                  value={inputs.department}
                  onChange={handleChange}
                />
                {submitted && !inputs.department && (
                  <div className="invalid-feedback">
                    Department name is required
                  </div>
                )}
                <span className="highlight"></span>
                <label>Department Name</label>
              </div>

              <a
                onClick={() => {
                  onEditClick();
                }}
                className="brd-4 btn btn-primary btn-user btn-block mt-5  a-cancel"
              >
                Save
              </a>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
