import React, { useState, useEffect } from "react";
import { history } from "../../../helpers/history";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotification,
  viewNotification,
  resetNotification,
} from "../header/notificationActions";
import Pagination from "../../components/Pagination/Pagination";
import { CustomerId } from "src/app/config";
import "./notification.css";

const Notifications = () => {
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const dispatch = useDispatch();
  const notificationsDetails = useSelector((state) => state.notificationData);
  const viewed =
    notificationsDetails &&
    notificationsDetails.viewed &&
    notificationsDetails.viewed.status;
  const notificationCount =
    notificationsDetails &&
    notificationsDetails.notificationData &&
    notificationsDetails.notificationData.notificationCount;
  const notificationList =
    notificationsDetails &&
    notificationsDetails.notificationData &&
    notificationsDetails.notificationData.notification;

  const getNotificationsList = (page = 1, pageSize = 10) => {
    const data = {
      numPerPage: pageSize,
      page: page == 0 ? 1 : page,
      userId: localStorage.getItem("userId"),
      sortKey: "createdAt",
      sortType: "DESC",
      searchKey: "",
      customerId: CustomerId,
    };
    dispatch(getNotification(data));
  };

  const onPageChanged = (data) => {
    if (data && data.currentPage == currentPageNumber) {
      return;
    }
    if (data && data.currentPage) {
      const { currentPage } = data;
      setCurrentPageNumber(currentPage);
      getNotificationsList(currentPage);
    }
  };

  useEffect(() => {
    getNotificationsList();
  }, []);

  useEffect(() => {
    if (viewed == "success") {
      getNotificationsList();
      dispatch(resetNotification());
    }
  }, [viewed]);
  const viewUpdatedItem = (updatedItem) => {
    const requestData = {
      id: updatedItem.id,
    };
    dispatch(viewNotification(requestData));
    if (updatedItem.type == "Template") {
      history.push("/home/onlineTemplates");
    } else if (updatedItem.type == "Ticket") {
      history.push("/home/editJobTicket");
    } else if (updatedItem.type == "Flowchart") {
      history.push("/home/flowcharts");
    } else if (updatedItem.type == "Business Organisation") {
      history.push("/home/businessOrganisation");
    } else if (updatedItem.type == "Manual") {
      history.push("/home/manual");
    } else if (updatedItem.type == "Procedure") {
      history.push("/home/procedures");
    } else if (updatedItem.type == "Policy") {
      history.push("/home/policies");
    }
  };

  return (
    <div>
      <div class="d-flex justify-content-between">
        <div class="st-tl">
          <h1 class="mh-head-txt pt-3">Notifications</h1>
        </div>
      </div>
      <hr class="pb-3" />

      <div class="row">
        <div class="col-lg-12">
          <div class="table-wraper table-responsive mnt mt-3">
            <table class="table job-list">
              <thead></thead>
              <tbody>
                {notificationList &&
                  notificationList.map((notification, index) => (
                    <tr>
                      <td>
                        <a
                          onClick={() => viewUpdatedItem(notification)}
                          className="dropdown-item d-flex align-items-center"
                          href="#"
                        >
                          <div className="mr-3">
                            {notification.type == "Ticket" && (
                              <div className="icon-circle bg-primary">
                                <i className="fa fa-ticket text-white"></i>
                              </div>
                            )}
                            {notification.type == "Business Organisation" && (
                              <div className="icon-circle bg-info">
                                <i className="fa fa-sitemap text-white"></i>
                              </div>
                            )}
                            {notification.type == "Flowchart" && (
                              <div className="icon-circle bg-success">
                                <i className="fa fa-line-chart text-white"></i>
                              </div>
                            )}
                            {notification.type == "Template" && (
                              <div className="icon-circle bg-danger">
                                <i className="fa fa-file-text text-white"></i>
                              </div>
                            )}
                            {(notification.type == "Procedure" ||
                              notification.type == "Manual" ||
                              notification.type == "Policy") && (
                              <div className="icon-circle bg-warning">
                                <i className="fa fa-book text-white"></i>
                              </div>
                            )}
                          </div>
                          <div class="notiMsg-div">
                            <div className="small text-gray-500">
                              {notification.updatedAt.split("T")[0]}
                            </div>
                            <span
                              className={
                                notification.isViewed == false
                                  ? "font-weight-bold"
                                  : ""
                              }
                            >
                              {notification.message}
                            </span>
                          </div>
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {notificationCount && notificationCount <= 0 && (
              <p class="text-center font-italic font-weight-bold">
                No data available!
              </p>
            )}
            <div className="d-flex flex-row py-4 align-items-center float-right">
              {notificationCount && notificationCount > 0 && (
                <Pagination
                  totalRecords={notificationCount}
                  pageLimit={10}
                  pageNeighbours={0}
                  onPageChanged={onPageChanged}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
