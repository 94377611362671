const HandleCheckboxCheckedForPDF = () => {
  const checkboxes = document.querySelectorAll(".checkbox");

  // Loop through each checkbox
  checkboxes.forEach((checkbox) => {
    // Get the value of 'data-checked' attribute
    const dataChecked = checkbox.getAttribute("data-checked");

    // Convert the 'data-checked' value to a boolean
    const isChecked = dataChecked === "true";

    // Set the 'checked' attribute based on the boolean value
    if (isChecked) {
      checkbox.setAttribute("checked", "checked");
    }
  });
};

export default HandleCheckboxCheckedForPDF;
