import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";
import { history } from "../../../helpers/history";
import { useDispatch, useSelector } from "react-redux";
import { listInternalJobTickets } from "../JobTicket/JobTicketActions";
import { getNotification } from "../../components/header/notificationActions";
import Pagination from "../../components/Pagination/Pagination";
import "./isonlinez.css";
import { CustomerId } from "src/app/config";
import GetColorForTicketDueDate from "src/app/components/GetColorForTicketDueDate";
import FormatTicketDueDate from "src/app/components/FormatTicketDueDate";

const InternalJobTicket = (props) => {
  const [isAdmin] = useState(localStorage.getItem("isAdmin"));
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [filter, setFilter] = useState({
    ticketStatus: "",
    userName: "",
  });
  const [sortParams, setSortParams] = useState({
    sortBy: "DESC",
    sortTitle: "jobId",
  });
  const dispatch = useDispatch();

  const jobTicketsData = useSelector((state) => state.jobTicketData);
  const jobTickets =
    jobTicketsData &&
    jobTicketsData.ticketsList &&
    jobTicketsData.ticketsList.ticket;

  const userId = localStorage.getItem("userId");

  useEffect(() => {
    // Listing Job Tickets
    getJobTicketsList();
    getNotificationsList();
  }, [props.filterValue]);

  const getNotificationsList = () => {
    const data = {
      numPerPage: 10,
      page: 1,
      customerId: CustomerId,
      userId,
    };
    dispatch(getNotification(data));
  };

  const getJobTicketsList = (page = 1, pageSize = 100) => {
    const data = {
      numPerPage: pageSize,
      page: page == 0 ? 1 : page,
      sortKey: sortParams.sortTitle,
      sortType: sortParams.sortBy,
      searchKey: "",
      searchDetails: JSON.stringify(props.filterValue),
    };
    dispatch(listInternalJobTickets(data));
  };

  const onPageChanged = (data) => {
    if (data && data.currentPage == currentPageNumber) {
      return;
    }
    if (data && data.currentPage) {
      const { currentPage } = data;
      setCurrentPageNumber(currentPage);
      getJobTicketsList(currentPage);
    }
  };

  const sortTable = (title) => {
    var sortByOrder = "ASC";

    if (title == sortParams.sortTitle) {
      sortByOrder = sortParams.sortBy == "DESC" ? "ASC" : "DESC";
      setSortParams({ sortBy: sortByOrder, sortTitle: title });
    } else {
      setSortParams({ sortBy: "ASC", sortTitle: title });
    }

    // Call Api here for sorting
    const data = {
      numPerPage: 100,
      page: currentPageNumber,
      sortKey: title,
      sortType: sortByOrder,
      searchKey: "",
      searchDetails: JSON.stringify(props.filterValue),
    };
    dispatch(listInternalJobTickets(data));
  };

  const goToEditTicket = (data) => {
    history.push("/home/editInternalJobTicket?id=" + data.id, data);
  };
  const goToViewTicket = (data) => {
    history.push("/home/viewJobTicket?id=" + data.id, data);
  };

  const getStatus = (status) => {
    var updatedStatus = "";
    if (status == "approved") {
      updatedStatus = "Approved";
    } else if (status == "rejected") {
      updatedStatus = "Rejected";
    } else if (status == "completed") {
      updatedStatus = "Completed";
    } else if (status == "notstarted") {
      updatedStatus = "Not started";
    }
    return updatedStatus;
  };
  let messageForDueDate;

  const getFormattedDate = (completionDate) =>
    FormatTicketDueDate(completionDate);
  const getColor = (completionDate, ticketCurrentStatus) =>
    GetColorForTicketDueDate(
      completionDate,
      ticketCurrentStatus,
      messageForDueDate
    );

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="table-wraper table-responsive mnt mt-3">
            <table className="table job-list">
              <thead>
                <tr>
                  <th
                    className="mx-width"
                    scope="col"
                    onClick={() => {
                      sortTable("jobId");
                    }}
                  >
                    <div className="d-flex justify-content-start">
                      <div>Job Id</div>
                      <div className="sort-wrp ml-2">
                        <i
                          className={
                            sortParams.sortTitle == "jobId" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-asc fa-lg active sort_up"
                              : "fa fa-sort-asc fa-lg sort_fade_down"
                          }
                        ></i>
                        <i
                          className={
                            sortParams.sortTitle == "jobId" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-desc fa-lg sort_fade_down"
                              : sortParams.sortTitle == "jobId" &&
                                sortParams.sortBy == "DESC"
                              ? "fa fa-sort-desc fa-lg active sort_up"
                              : "fa fa-sort-desc fa-lg sort_fade_down"
                          }
                        ></i>
                      </div>
                    </div>
                  </th>
                  <th
                    scope="col"
                    onClick={() => {
                      sortTable("category");
                    }}
                  >
                    <div className="d-flex justify-content-start">
                      <div>Category</div>
                      <div className="sort-wrp ml-2">
                        <i
                          className={
                            sortParams.sortTitle == "category" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-asc fa-lg active sort_up"
                              : "fa fa-sort-asc fa-lg sort_fade_down"
                          }
                        ></i>
                        <i
                          className={
                            sortParams.sortTitle == "category" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-desc fa-lg sort_fade_down"
                              : sortParams.sortTitle == "category" &&
                                sortParams.sortBy == "DESC"
                              ? "fa fa-sort-desc fa-lg active sort_up"
                              : "fa fa-sort-desc fa-lg sort_fade_down"
                          }
                        ></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col">Details</th>
                  <th scope="col" className="mn-width text-center">
                    Due Date
                  </th>
                  <th scope="col" className="mn-width">
                    Status
                  </th>
                  {isAdmin == "false" && (
                    <th scope="col">
                      <p className="text-center p-0 m-0">Edit | View</p>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {jobTicketsData &&
                  !jobTicketsData.loading &&
                  jobTicketsData.error && (
                    <span className="text-danger">
                      ERROR: {jobTicketsData.error}
                    </span>
                  )}
                {jobTickets &&
                  jobTickets != "No Tickets" &&
                  jobTickets.map((ticket, index) => (
                    <tr
                      className={ticket.isArchived == true ? "disabledLi" : ""}
                      key={index}
                    >
                      <td className="mx-width">{ticket.id}</td>
                      <td>{ticket?.InternalTicketsCategory?.categoryName}</td>
                      <td>{ticket?.ticketDetails}</td>
                      <td className="mn-width text-center">
                        {/* {ticket.User ? ticket.User.UserDetail && "Yes" : "No"} */}
                        <span
                          style={{
                            background: getColor(
                              ticket?.completionDate,
                              ticket.ticketStatus
                            ).color,
                            padding: "8px",
                            borderRadius: "10px",
                            fontSize: "15px",
                          }}
                          title={
                            getColor(
                              ticket?.completionDate,
                              ticket.ticketStatus
                            ).message
                          }
                        >
                          {getFormattedDate(ticket?.completionDate)}
                        </span>
                        {ticket.User && ticket.User.userStatus == "deleted" && (
                          <span style={{ color: "#f03737" }}>
                            <br />
                            Deleted
                          </span>
                        )}
                      </td>
                      <td className="mn-width">
                        <span
                          className={
                            ticket.ticketStatus == "completed" ||
                            ticket.ticketStatus == "approved"
                              ? "completed"
                              : ticket.ticketStatus == "notstarted" ||
                                ticket.ticketStatus == "rejected"
                              ? "not-completed"
                              : "inprogress"
                          }
                        ></span>
                        <span>{getStatus(ticket.ticketStatus)}</span>
                      </td>
                      {ticket.createdBy == userId ? (
                        <td className="text-center">
                          <a
                            className="a-removed-href"
                            onClick={() => {
                              goToEditTicket(ticket);
                            }}
                          >
                            <Icon.Edit />
                          </a>
                        </td>
                      ) : (
                        <td className="text-center">
                          <a
                            className="a-removed-href"
                            onClick={() => {
                              goToViewTicket(ticket);
                            }}
                          >
                            View
                          </a>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
            {jobTicketsData.ticketsList.ticketCount <= 0 && (
              <p className="text-center font-italic font-weight-bold">
                No data available!
              </p>
            )}
            <div className="d-flex flex-row py-4 align-items-center float-right">
              {jobTicketsData.ticketsList.ticketCount > 0 && (
                <Pagination
                  totalRecords={jobTicketsData.ticketsList.ticketCount}
                  pageLimit={100}
                  pageNeighbours={0}
                  onPageChanged={onPageChanged}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InternalJobTicket;
