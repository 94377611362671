import { ApiService } from "../../services/api.service";
import { history } from "../../../helpers/history";
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  OTP_VERIFICATION_REQUEST,
  OTP_VERIFICATION_SUCCESS,
  OTP_VERIFICATION_FAILURE,
} from "./loginConstants";
import { alertActions } from "../alert/alertActions";
import { showAlert } from "../alert/showAlert";
import Cookies from "js-cookie";
import { trackPromise } from "react-promise-tracker";

export function login(data) {
  return (dispatch) => {
    dispatch(loginRequested());
    trackPromise(
      ApiService.callLogin(data).then((res) => {
        dispatch(loginSuccess(res.data));
        if (res.data.status == "success") {
          if (res.data.data && res.data.data.authToken) {
            Cookies.set("Token", res.data.data.authToken);
            Cookies.set("userRole", res?.data?.data?.userRole);
            history.push("/home");
          }
          dispatch(alertActions.success(res.data.message));
        } else {
          dispatch(alertActions.error(res.data.message));
        }
      })
    ).catch((err) => {
      dispatch(loginFailure(err.message));
      showAlert(dispatch, err);
    });
  };
}

const loginRequested = () => ({
  type: LOGIN_REQUEST,
});

const loginSuccess = (data) => ({
  type: LOGIN_SUCCESS,
  payload: {
    ...data,
  },
});

const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: {
    error,
  },
});

//OTP Verification

export function otpVerification(data) {
  return (dispatch) => {
    dispatch(otpVerificationRequested());
    trackPromise(
      ApiService.callOtpVerification(data).then((res) => {
        dispatch(otpVerificationSuccess(res.data));
        if (res.data.status == "success") {
          if (res.data.data && res.data.data.authToken) {
            Cookies.set("Token", res.data.data.authToken);
            Cookies.set("userRole", res?.data?.data?.userType);
            history.push("/home");
          }

          dispatch(alertActions.success(res.data.message));
        } else {
          dispatch(alertActions.error(res.data.message));
        }
      })
    ).catch((err) => {
      dispatch(otpVerificationFailure(err.message));
      showAlert(dispatch, err);
    });
  };
}

const otpVerificationRequested = () => ({
  type: OTP_VERIFICATION_REQUEST,
});

const otpVerificationSuccess = (data) => ({
  type: OTP_VERIFICATION_SUCCESS,
  payload: {
    ...data,
  },
});

const otpVerificationFailure = (error) => ({
  type: OTP_VERIFICATION_FAILURE,
  payload: {
    error,
  },
});
