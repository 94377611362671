import { LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE } from './logoutConstants';
const initialState = {
  loading: false,
  data: [],
  error: null
};

function LogoutReducer(state = initialState, action={}) {
  switch (action.type) {
    case LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LOGOUT_SUCCESS:
     
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

export default LogoutReducer;