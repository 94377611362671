import * as PDF from './pdfConstants';

const initialState = {
  loading: false
};

function PdfReducer(state = initialState, action={}) {
  switch (action.type) {
    case PDF.PDF_GENERATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PDF.PDF_GENERATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PDF.PDF_GENERATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

export default PdfReducer;