import { HttpClient } from "./http.service";
import { APIPath } from "../config";
import Cookies from "js-cookie";

const BASE_URL = `${APIPath}`;
const headersConfig = () => ({
  Authorization: Cookies.get("Token"),
  "content-type": "application/json",
});

const headersConfigForExternalTempUser = () => ({
  Authorization: Cookies.get("externalUserToken"),

  "content-type": "application/json",
});
const headersConfigForExternalTempUserOTPVerfication = () => ({
  Authorization: Cookies.get("externalTempUserToken"),

  "content-type": "application/json",
});
const headersConfigForFormData = () => ({
  "content-type": "multipart/form-data",
});

const headersConfigForFormDataWithAuthorization = () => ({
  Authorization: Cookies.get("Token"),
  "content-type": "multipart/form-data",
});

//login
const callLogin = (data) => {
  return HttpClient.post(`${BASE_URL}/customer/login`, data, {
    headers: headersConfig(),
  });
};

//otp verification
const callOtpVerification = (data) => {
  return HttpClient.post(`${BASE_URL}/customer/adminlogin`, data, {
    headers: headersConfig(),
  });
};

//forgot password
const callForgotPassword = (data) => {
  return HttpClient.post(`${BASE_URL}/forgotpassword`, data, {
    headers: headersConfig(),
  });
};

//update password
const callverifyPasswordLink = (data) => {
  return HttpClient.post(`${BASE_URL}/checkresetpassword`, data, {
    headers: headersConfig(),
  });
};

//update password
const callResetPassword = (data) => {
  return HttpClient.post(`${BASE_URL}/updatepassword`, data, {
    headers: headersConfig(),
  });
};

//change password
const callChangePassword = (data) => {
  return HttpClient.put(`${BASE_URL}/customer/changepassword`, data, {
    headers: headersConfig(),
  });
};

//logout
const callLogout = (data) => {
  return HttpClient.post(`${BASE_URL}/logout/${data}`, "", {
    headers: headersConfig(),
  });
};

//Job Ticket Section

//List Category

const callListCategory = () => {
  return HttpClient.get(`${BASE_URL}/listCatagory`, {
    headers: headersConfig(),
  });
};

// Customer Section

//List Customers
const callListCustomer = () => {
  return HttpClient.get(`${BASE_URL}/customerlist`, {
    headers: headersConfig(),
  });
};

//Create New Job Ticket
const callAddJobTicket = (data) => {
  return HttpClient.post(`${BASE_URL}/createTicket`, data, {
    headers: headersConfigForFormDataWithAuthorization(),
  });
};
const callAddInternalJobTicket = (data) => {
  return HttpClient.post(`${BASE_URL}/createCustomerInternalTicket`, data, {
    headers: headersConfigForFormDataWithAuthorization(),
  });
};

//Job Tickets Listing
const callListJobTickets = (data) => {
  return HttpClient.post(`${BASE_URL}/listTicket`, data, {
    headers: headersConfig(),
  });
};

//Internal Job Tickets Listing
const callListInternalJobTickets = (data) => {
  return HttpClient.post(`${BASE_URL}/customer/listInternalTickets`, data, {
    headers: headersConfig(),
  });
};

//Update Job Tickets
const callUpdateJobTicket = (data, formData) => {
  return HttpClient.put(`${BASE_URL}/updateTicket/${data.id}`, formData, {
    headers: headersConfigForFormDataWithAuthorization(),
  });
};

//Update Job Tickets
const callUpdateInternalJobTicket = (data, formData) => {
  return HttpClient.put(
    `${BASE_URL}/customer/updateInternalTicket/${data.id}`,
    formData,
    {
      headers: headersConfigForFormDataWithAuthorization(),
    }
  );
};

//Get Job Ticket Details
const callGetTicketDetails = (data) => {
  return HttpClient.get(`${BASE_URL}/ticket/${data}`, {
    headers: headersConfig(),
  });
};

// Update profile
const ServiceCallForUpdateSatff = (data, userId) => {
  return HttpClient.put(`${BASE_URL}/customeruser/${userId}`, data, {
    headers: headersConfigForFormDataWithAuthorization(),
  });
};

// get profile
const ServiceCallForGetProfile = (id) => {
  return HttpClient.get(`${BASE_URL}/customer/profile`, {
    headers: headersConfig(),
  });
};

//Templates Listing
const callListTemplates = (data) => {
  return HttpClient.post(`${BASE_URL}/customer/template`, data, {
    headers: headersConfig(),
  });
};

//Get Template For Customer
const callGetTemplateForCustomer = (id, custId) => {
  return HttpClient.get(`${BASE_URL}/getCustomerTemplate/${id}/${custId}`, {
    headers: headersConfig(),
  });
};

//Add Template Data in drive
const callAddTemplateData = (data) => {
  return HttpClient.post(`${BASE_URL}/formSubmit`, data, {
    headers: headersConfigForFormData(),
  });
};

//Notification Listing
const callGetNotifications = (data) => {
  return HttpClient.post(`${BASE_URL}/listNotifications`, data, {
    headers: headersConfig(),
  });
};

//View notification
const callViewNotification = (data) => {
  return HttpClient.get(`${BASE_URL}/viewNotification/${data.id}`, {
    headers: headersConfig(),
  });
};

//Flowcharts Listing
const callListFlowcharts = (data) => {
  return HttpClient.post(`${BASE_URL}/customer/flowchart`, data, {
    headers: headersConfig(),
  });
};

//Flowchart Archives Listing
const callListFlowchartArchives = (data) => {
  return HttpClient.post(`${BASE_URL}/flowchartarchives`, data, {
    headers: headersConfig(),
  });
};

//Get Flowchart For Customer
const callGetFlowchartForCustomer = (id) => {
  return HttpClient.get(`${BASE_URL}/getflowchart/${id}`, {
    headers: headersConfig(),
  });
};

//Business Organisations Listing
const callListBusinessOrganisations = (data) => {
  return HttpClient.post(`${BASE_URL}/customer/businessorg`, data, {
    headers: headersConfig(),
  });
};

//Business Org Archives Listing
const callListBusinessOrganisationArchives = (data) => {
  return HttpClient.post(`${BASE_URL}/businessorgarchives`, data, {
    headers: headersConfig(),
  });
};

//Manual Listing
const callListManuals = (data) => {
  return HttpClient.post(`${BASE_URL}/customer/manual`, data, {
    headers: headersConfig(),
  });
};

//Manual Archives Listing
const callListManualArchives = (data) => {
  return HttpClient.post(`${BASE_URL}/manualarchives`, data, {
    headers: headersConfig(),
  });
};

//Procedure Listing
const callListProcedures = (data) => {
  return HttpClient.post(`${BASE_URL}/customer/procedure`, data, {
    headers: headersConfig(),
  });
};

//Procedure Archives Listing
const callListProcedureArchives = (data) => {
  return HttpClient.post(`${BASE_URL}/procedurearchives`, data, {
    headers: headersConfig(),
  });
};

//Policies Listing
const callListPolicies = (data) => {
  return HttpClient.post(`${BASE_URL}/customer/policy`, data, {
    headers: headersConfig(),
  });
};

//Policy Archives Listing
const callListPolicyArchives = (data) => {
  return HttpClient.post(`${BASE_URL}/policyarchives`, data, {
    headers: headersConfig(),
  });
};

//List Records
const callListRecords = (data) => {
  return HttpClient.post(`${BASE_URL}/latestRecords`, data, {
    headers: headersConfig(),
  });
};

//Get Records Details
const callGetRecordDetails = (data) => {
  return HttpClient.post(`${BASE_URL}/getRecords`, data, {
    headers: headersConfig(),
  });
};

//Delete All Records
const callDeleteAllRecords = () => {
  return HttpClient.delete(`${BASE_URL}/clearrecords`, {
    headers: headersConfig(),
  });
};

//Delete single Record
const callDeleteSingleRecord = (data) => {
  return HttpClient.delete(`${BASE_URL}/deleterecord/${data}`, {
    headers: headersConfig(),
  });
};

//Retrieve single Record
const callRetrieveSingleRecord = (data) => {
  return HttpClient.get(`${BASE_URL}/retrieverecord/${data}`, {
    headers: headersConfig(),
  });
};

//Retrieve single Live Record
const callRetrieveSingleLiveRecord = (data) => {
  return HttpClient.get(`${BASE_URL}/retrieveLiveRecord/${data}`, {
    headers: headersConfig(),
  });
};

const callDeletedLiverecords = (data) => {
  return HttpClient.post(`${BASE_URL}/deletedLiverecords`, data, {
    headers: headersConfig(),
  });
};

//Archive all Records
const callArchiveAllRecords = (data) => {
  return HttpClient.get(`${BASE_URL}/archiveyear/${data}`, {
    headers: headersConfig(),
  });
};

//List Delete all Records
const callListAllDeletedRecords = (data) => {
  return HttpClient.post(`${BASE_URL}/deletedrecords`, data, {
    headers: headersConfig(),
  });
};

//List Archives
const callListArchives = (data) => {
  return HttpClient.post(`${BASE_URL}/archiveRecords`, data, {
    headers: headersConfig(),
  });
};

//List Years
const callListYears = () => {
  return HttpClient.get(`${BASE_URL}/archiveYears`, {
    headers: headersConfig(),
  });
};

//Make Archives
const callMakeArchives = (data) => {
  return HttpClient.get(`${BASE_URL}/makeAsArchive/${data}`, {
    headers: headersConfig(),
  });
};

//Generate PDF
const generatePdf = (data) => {
  return HttpClient.post(`${BASE_URL}/pdfgeneration`, data, {
    headers: headersConfig(),
  });
};
const getGeneratedpdf = (data) => {
  return HttpClient.post(`${BASE_URL}/getGeneratedpdf`, data, {
    headers: headersConfig(),
    responseType: "blob",
  });
};

//getTemplateQrImage

const getTemplateQrImage = (selectedTempID) => {
  return HttpClient.get(`${BASE_URL}/getTemplateQrImage/${selectedTempID}`, {
    headers: headersConfig(),
  });
};

//Live Templates Listing
const callListLiveTemplates = (data) => {
  return HttpClient.post(`${BASE_URL}/customer/listLiveTemplate`, data, {
    headers: headersConfig(),
  });
};

//Get Live Template For Customer
const callGetLiveTemplateForCustomer = (id, custId) => {
  return HttpClient.get(`${BASE_URL}/getCustomerLiveTemplate/${id}/${custId}`, {
    headers: headersConfig(),
  });
};

//Add Live Template Data submit
const callAddLiveTemplateData = (data) => {
  return HttpClient.post(`${BASE_URL}/liveTemplateFormSubmit`, data, {
    headers: headersConfigForFormDataWithAuthorization(),
  });
};

//List Records
const callListLiveRecords = (data) => {
  return HttpClient.post(`${BASE_URL}/latestLiveRecords`, data, {
    headers: headersConfig(),
  });
};

//Get Records Details
const callGetLiveRecordDetails = (data) => {
  return HttpClient.post(`${BASE_URL}/getLiveRecords`, data, {
    headers: headersConfig(),
  });
};

//Add Live Record Data in drive
const callLiveRecordFormSubmit = (data) => {
  return HttpClient.post(`${BASE_URL}/liveRecordFormSubmit`, data, {
    headers: headersConfigForFormDataWithAuthorization(),
  });
};

//Add Live Record Data in drive
const callExternalLiveRecordFormSubmit = (data) => {
  return HttpClient.post(`${BASE_URL}/externalLiveRecordFormSubmit`, data, {
    headers: headersConfigForExternalTempUser(),
  });
};

const callDeleteSingleLiveRecord = (data) => {
  return HttpClient.delete(`${BASE_URL}/deleteLiverecord/${data}`, {
    headers: headersConfig(),
  });
};

const callDeleteSingleLiveRecordPdf = (data) => {
  return HttpClient.delete(`${BASE_URL}/deleteSavedLiverecord/${data}`, {
    headers: headersConfig(),
  });
};

const callGetLiveRecordTemplate = (data) => {
  return HttpClient.get(`${BASE_URL}/getLiveRecordTemplate/${data}`, {
    headers: headersConfig(),
  });
};
const callShareByEmail = (data) => {
  return HttpClient.post(`${BASE_URL}/shareByEmail`, data, {
    headers: headersConfig(),
  });
};

const callRecordShareByEmail = (data) => {
  return HttpClient.post(`${BASE_URL}/recordShareByEmail`, data, {
    headers: headersConfig(),
  });
};

const callGetLiveRecordShareByEmail = (data) => {
  return HttpClient.post(`${BASE_URL}/liveRecordShareByEmail`, data, {
    headers: headersConfig(),
  });
};

// Listing Departments
const ServiceCallForListCustomerDepartments = () => {
  return HttpClient.get(`${BASE_URL}/customer/listCustomerDepartments`, {
    headers: headersConfig(),
  });
};
// Listing User
const ServiceCallForListingUser = (data) => {
  return HttpClient.post(`${BASE_URL}/customer/usersLists`, data, {
    headers: headersConfig(),
  });
};
const ServiceCallForCreateUser = (customerId, formData) => {
  return HttpClient.post(`${BASE_URL}/addcustomer/${customerId}`, formData, {
    headers: headersConfigForFormDataWithAuthorization(),
  });
};
//delete Department user
const callForDeleteUser = (data) => {
  return HttpClient.post(`${BASE_URL}/customer/userDelete`, data, {
    headers: headersConfig(),
  });
};

// Update User
const ServiceCallForUpdateUser = (data, customerid) => {
  return HttpClient.put(`${BASE_URL}/customeruser/${customerid}`, data, {
    headers: headersConfigForFormDataWithAuthorization(),
  });
};

const CallCustomerUsers = (customerid) => {
  return HttpClient.get(`${BASE_URL}/customerusers/${customerid}`, {
    headers: headersConfigForFormDataWithAuthorization(),
  });
};

const CallInternalTicketCategory = () => {
  return HttpClient.get(`${BASE_URL}/customer/internalTicketsCatagory`, {
    headers: headersConfigForFormDataWithAuthorization(),
  });
};

const callListRoles = () => {
  return HttpClient.get(`${BASE_URL}/listRoles`, {
    headers: headersConfig(),
  });
};

const callListProjects = (customerId) => {
  return HttpClient.get(`${BASE_URL}/listCustomerProjects/${customerId}`, {
    headers: headersConfig(),
  });
};

//get sequancial no for each template(if it applicable)
export const callTemplateSequelNo = (tempId) => {
  return HttpClient.get(`${BASE_URL}/getSequelNumber/${tempId}`, {
    headers: headersConfig(),
  });
};

//get sequancial no for each template(if it applicable)
const callLiveTemplateSequelNo = (tempId) => {
  return HttpClient.get(`${BASE_URL}/getLiveRecordSequelNumber/${tempId}`, {
    headers: headersConfig(),
  });
};

const callViewJobTicket = (ticketId) => {
  return HttpClient.get(`${BASE_URL}/internalTicket/${ticketId}`, {
    headers: headersConfig(),
  });
};

const getOtpVerification = (data) => {
  return HttpClient.post(`${BASE_URL}/getOtpVerification`, data, {
    headers: headersConfigForExternalTempUserOTPVerfication(),
  });
};

const getExternalLiveRecordTemplate = () => {
  return HttpClient.get(`${BASE_URL}/getExternalLiveRecordTemplate`, {
    headers: headersConfigForExternalTempUser(),
  });
};

export const ApiService = {
  callLogin,
  callLogout,
  callListCustomer,
  ServiceCallForUpdateSatff,
  ServiceCallForGetProfile,
  callListCategory,
  callAddJobTicket,
  callGetTicketDetails,
  callForgotPassword,
  callResetPassword,
  callChangePassword,
  callListJobTickets,
  callUpdateJobTicket,
  callverifyPasswordLink,
  callListTemplates,
  callGetTemplateForCustomer,
  callAddTemplateData,
  callGetNotifications,
  callViewNotification,
  callListFlowcharts,
  callListFlowchartArchives,
  callGetFlowchartForCustomer,
  callListBusinessOrganisations,
  callListBusinessOrganisationArchives,
  callListManuals,
  callListManualArchives,
  callListProcedures,
  callListProcedureArchives,
  callListPolicies,
  callListPolicyArchives,
  callListRecords,
  callGetRecordDetails,
  callDeleteAllRecords,
  callDeleteSingleRecord,
  callRetrieveSingleRecord,
  callArchiveAllRecords,
  callListAllDeletedRecords,
  callListArchives,
  callListYears,
  callMakeArchives,
  getGeneratedpdf,
  generatePdf,
  callOtpVerification,
  getTemplateQrImage,
  callListLiveTemplates,
  callGetLiveTemplateForCustomer,
  callAddLiveTemplateData,
  callListLiveRecords,
  callGetLiveRecordDetails,
  callLiveRecordFormSubmit,
  callDeleteSingleLiveRecord,
  callGetLiveRecordTemplate,
  callShareByEmail,
  ServiceCallForListCustomerDepartments,
  ServiceCallForListingUser,
  ServiceCallForCreateUser,
  ServiceCallForUpdateUser,
  callListRoles,
  callForDeleteUser,
  callListProjects,
  callDeleteSingleLiveRecordPdf,
  callDeletedLiverecords,
  callRetrieveSingleLiveRecord,
  callAddInternalJobTicket,
  CallCustomerUsers,
  callListInternalJobTickets,
  CallInternalTicketCategory,
  callUpdateInternalJobTicket,
  callTemplateSequelNo,
  callRecordShareByEmail,
  callViewJobTicket,
  callLiveTemplateSequelNo,
  callGetLiveRecordShareByEmail,
  getOtpVerification,
  getExternalLiveRecordTemplate,
  callExternalLiveRecordFormSubmit,
};
