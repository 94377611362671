import { ApiService } from "../../services/api.service";
import * as PDF from "./pdfConstants";
import { showAlert } from "../alert/showAlert";
import { alertActions } from "../alert/alertActions";
import { trackPromise } from "react-promise-tracker";

export function PdfGeneration(data, type) {
  return (dispatch) => {
    dispatch(pdfRequested());
    trackPromise(
      ApiService.generatePdf(data).then((res) => {
        const pdfdata = {
          fileName: res?.data,
        };
        dispatch(getGeneratedpdf(pdfdata, type));
      })
    ).catch((err) => {
      showAlert(dispatch, err);
    });
  };
}

export function getGeneratedpdf(data, type) {
  return (dispatch) => {
    dispatch(pdfRequested());
    trackPromise(
      ApiService.getGeneratedpdf(data).then((res) => {
        const file = new Blob([res.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        if (type != "preview") {
          window.open(fileURL);
        }
        dispatch(pdfGenerationSuccess());
        dispatch(alertActions.success(res.data.message));
      })
    ).catch((err) => {
      dispatch(pdfGenerationFailure());
      showAlert(dispatch, err);
    });
  };
}

const pdfRequested = () => ({
  type: PDF.PDF_GENERATION_REQUEST,
});

const pdfGenerationSuccess = () => ({
  type: PDF.PDF_GENERATION_SUCCESS,
});

const pdfGenerationFailure = () => ({
  type: PDF.PDF_GENERATION_FAILURE,
});
