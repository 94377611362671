import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";
import { history } from "../../../helpers/history";
import { useDispatch, useSelector } from "react-redux";
import {
  listManuals,
  listManualArchives,
  resetManualDetails,
} from "../Manual/ManualActions";
import { getNotification } from "../../components/header/notificationActions";
import Pagination from "../../components/Pagination/Pagination";
import $ from "jquery";
import "./Manual.css";
import { PdfGeneration } from "../../pages/pdfGeneration/pdfActions";
import { CustomerId } from "src/app/config";
import SearchBox from "src/app/components/SearchBox";

const Manual = () => {
  const [isAdmin] = useState(localStorage.getItem("isAdmin"));
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const dispatch = useDispatch();
  const [sortParams, setSortParams] = useState({
    sortBy: "ASC",
    sortTitle: "manualRefId",
  });
  const [showArchives, setShowArchives] = useState(false);
  const manualList = useSelector((state) => state.manualData);
  const manualListData =
    manualList && manualList.manualsList && manualList.manualsList.data;
  const totalCount =
    manualList && manualList.manualsList && manualList.manualsList.manualCount;
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    window.onbeforeunload = null;
    // Listing Manuals
    getManualList();
    getNotificationsList();
  }, [searchValue]);

  const generatePdf = (id) => {
    const data = {
      type: "manual",
      id: id,
    };
    dispatch(PdfGeneration(data));
  };

  const getNotificationsList = () => {
    const data = {
      numPerPage: 10,
      page: 1,
      customerId: CustomerId,

      userId: localStorage.getItem("userId"),
    };
    dispatch(getNotification(data));
  };

  const copyToClipboard = (data) => {
    const url = data;
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(url).select();
    document.execCommand("copy");
    $temp.remove();
    window.open(url, "_blank", "noreferrer");
  };

  const getManualList = (page = 1, pageSize = 100) => {
    const data = {
      numPerPage: pageSize,
      page: page == 0 ? 1 : page,
      sortKey: sortParams.sortTitle,
      sortType: sortParams.sortBy,
      searchKey: searchValue,
    };
    dispatch(listManuals(data));
  };

  const getArchivesList = (page = 1, pageSize = 100) => {
    const data = {
      numPerPage: pageSize,
      page: page == 0 ? 1 : page,
      sortKey: sortParams.sortTitle,
      sortType: sortParams.sortBy,
      searchKey: searchValue,
    };
    dispatch(listManualArchives(data));
  };

  const onPageChanged = (data) => {
    if (data && data.currentPage == currentPageNumber) {
      return;
    }
    if (data && data.currentPage) {
      const { currentPage } = data;
      setCurrentPageNumber(currentPage);
      if (showArchives == false) {
        getManualList(currentPage);
      } else if (showArchives == true) {
        getArchivesList(currentPage);
      }
    }
  };

  const goToEditManual = (data) => {
    history.push("/home/addJobTicket", {
      type: 2,
      id: data.id,
      jobId: data.Manual.Ticket.id,
    });
  };

  const sortTable = (title) => {
    var sortByOrder = "ASC";

    if (title == sortParams.sortTitle) {
      sortByOrder = sortParams.sortBy == "DESC" ? "ASC" : "DESC";
      setSortParams({ sortBy: sortByOrder, sortTitle: title });
    } else {
      setSortParams({ sortBy: "ASC", sortTitle: title });
    }

    // Call Api here for sorting
    const data = {
      numPerPage: 100,
      page: currentPageNumber,
      sortKey: title,
      sortType: sortByOrder,
      searchKey: searchValue,
    };
    if (showArchives == false) {
      dispatch(listManuals(data));
    } else if (showArchives == true) {
      dispatch(listManualArchives(data));
    }
  };
  const goToCreateManual = () => {
    history.push("/home/addJobTicket", { type: 2 });
  };

  const goToArchives = () => {
    dispatch(resetManualDetails());
    getArchivesList();
    setShowArchives(true);
  };

  const goToManuals = () => {
    dispatch(resetManualDetails());
    getManualList();
    setShowArchives(false);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };
  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="st-tl">
          <h1 className="mh-head-txt pt-3">
            {showArchives == false ? "High Level Documents" : "Archives"}
          </h1>
        </div>
        {showArchives == false && isAdmin == "false" && (
          <div className="aad-usr pt-3">
            <Icon.PlusCircle />
            <a className="a-removed-href-create" onClick={goToCreateManual}>
              Create new Job Ticket for Manual
            </a>
          </div>
        )}
      </div>
      <hr className="pb-3" />

      <div className="d-flex justify-content-between mt-5 row">
        <div class="input-group st-tl col-md-4">
          <SearchBox onSearch={handleSearch} />
        </div>
        <div className="aad-usr pt-3 pr-3">
          {showArchives == false ? (
            <div>
              {" "}
              <Icon.Calendar />
              <a className="a-removed-href-create" onClick={goToArchives}>
                Archives
              </a>
            </div>
          ) : (
            <a className="a-removed-href-create" onClick={goToManuals}>
              Go back to Manuals
            </a>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="table-wraper table-responsive mnt mt-3">
            <table className="table job-list">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="mx-width"
                    onClick={() => {
                      sortTable("manualRefId");
                    }}
                  >
                    <div class="d-flex justify-content-start">
                      <div>Doc ID</div>
                      <div class="sort-wrp ml-2">
                        <i
                          class={
                            sortParams.sortTitle == "manualRefId" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-asc fa-lg active sort_up"
                              : "fa fa-sort-asc fa-lg sort_fade_down"
                          }
                        ></i>
                        <i
                          class={
                            sortParams.sortTitle == "manualRefId" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-desc fa-lg sort_fade_down"
                              : sortParams.sortTitle == "manualRefId" &&
                                sortParams.sortBy == "DESC"
                              ? "fa fa-sort-desc fa-lg active sort_up"
                              : "fa fa-sort-desc fa-lg sort_fade_down"
                          }
                        ></i>
                      </div>
                    </div>
                  </th>{" "}
                  <th
                    scope="col"
                    onClick={() => {
                      sortTable("manualName");
                    }}
                  >
                    <div className="d-flex justify-content-start">
                      <div>Manual Name</div>
                      <div className="sort-wrp ml-2">
                        <i
                          className={
                            sortParams.sortTitle == "manualName" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-asc fa-lg active sort_up"
                              : "fa fa-sort-asc fa-lg sort_fade_down"
                          }
                        ></i>
                        <i
                          className={
                            sortParams.sortTitle == "manualName" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-desc fa-lg sort_fade_down"
                              : sortParams.sortTitle == "manualName" &&
                                sortParams.sortBy == "DESC"
                              ? "fa fa-sort-desc fa-lg active sort_up"
                              : "fa fa-sort-desc fa-lg sort_fade_down"
                          }
                        ></i>
                      </div>
                    </div>
                  </th>
                  <th
                    scope="col text-center"
                    onClick={() => {
                      sortTable("revision");
                    }}
                  >
                    <div class="d-flex justify-content-start">
                      <div>Revision</div>
                      <div class="sort-wrp ml-2">
                        <i
                          class={
                            sortParams.sortTitle == "revision" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-asc fa-lg active sort_up"
                              : "fa fa-sort-asc fa-lg sort_fade_down"
                          }
                        ></i>
                        <i
                          class={
                            sortParams.sortTitle == "revision" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-desc fa-lg sort_fade_down"
                              : sortParams.sortTitle == "revision" &&
                                sortParams.sortBy == "DESC"
                              ? "fa fa-sort-desc fa-lg active sort_up"
                              : "fa fa-sort-desc fa-lg sort_fade_down"
                          }
                        ></i>
                      </div>
                    </div>
                  </th>{" "}
                  <th
                    scope="col"
                    onClick={() => {
                      sortTable("updatedAt");
                    }}
                  >
                    <div className="d-flex justify-content-start">
                      <div>Date</div>
                      <div className="sort-wrp ml-2">
                        <i
                          className={
                            sortParams.sortTitle == "updatedAt" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-asc fa-lg active sort_up"
                              : "fa fa-sort-asc fa-lg sort_fade_down"
                          }
                        ></i>
                        <i
                          className={
                            sortParams.sortTitle == "updatedAt" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-desc fa-lg sort_fade_down"
                              : sortParams.sortTitle == "updatedAt" &&
                                sortParams.sortBy == "DESC"
                              ? "fa fa-sort-desc fa-lg active sort_up"
                              : "fa fa-sort-desc fa-lg sort_fade_down"
                          }
                        ></i>
                      </div>
                    </div>
                  </th>
                  <th className="text-center" scope="col">
                    Download
                  </th>
                  {showArchives == false && isAdmin == "false" && (
                    <th className="text-center" scope="col">
                      Share
                    </th>
                  )}
                  {showArchives == false && isAdmin == "false" && (
                    <th className="text-center" scope="col">
                      Change Request
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {manualList && !manualList.loading && manualList.error && (
                  <span className="text-danger">ERROR: {manualList.error}</span>
                )}
                {manualListData &&
                  manualListData.map((manual, index) => (
                    <tr key={manual?.updatedAt}>
                      <td className="mx-width">{manual.manualRefId}</td>
                      <td>{manual.manualName}</td>

                      <td className="text-center">{manual.revision}</td>
                      <td>
                        {manual.updatedAt
                          ? manual.updatedAt.split("T")[0]
                          : "Not Approved"}{" "}
                      </td>
                      <td class="text-center">
                        {manual.manualType == "upload" ? (
                          <a href={manual.fileName} className="a-removed-href">
                            <Icon.Download />
                          </a>
                        ) : (
                          <a
                            className="a-removed-href"
                            onClick={() => generatePdf(manual.id)}
                          >
                            <Icon.Download />
                          </a>
                        )}
                      </td>
                      {showArchives == false && isAdmin == "false" && (
                        <td className="text-center">
                          <a
                            className="a-removed-href"
                            onClick={() => copyToClipboard(manual.fileName)}
                          >
                            <Icon.Share />
                          </a>
                        </td>
                      )}
                      {showArchives == false && isAdmin == "false" && (
                        <td className="text-center">
                          <a
                            className="a-removed-href"
                            onClick={() => goToEditManual(manual)}
                          >
                            Raise Ticket
                          </a>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
            {manualList.manualsList.manualCount <= 0 && (
              <p className="text-center font-italic font-weight-bold">
                No data available!
              </p>
            )}
            {manualList.manualsList.manualCount > 0 && (
              <div className="d-flex flex-row py-4 align-items-center float-left">
                Total Count: {manualList.manualsList.manualCount}
              </div>
            )}

            <div className="d-flex flex-row py-4 align-items-center float-right">
              {manualList.manualsList.manualCount > 0 && (
                <Pagination
                  totalRecords={totalCount}
                  pageLimit={100}
                  pageNeighbours={0}
                  onPageChanged={onPageChanged}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Manual;
