import React, { useState } from "react";

const SelectField = ({ attribs, options }) => {
  const [value, setValue] = useState(
    attribs["data-value"] || attribs.value || ""
  );

  return (
    <select
      {...attribs}
      required={attribs.required === "" ? true : false}
      data-value={value}
      value={value}
      onChange={(e) => setValue(e.target.value || "")}
    >
      {options?.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default SelectField;
