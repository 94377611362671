import * as Manual from './ManualConstants';

const initialState = {
  loading: false,
  manualsList: [],
  error: null,
};

function ManualReducer(state = initialState, action={}) {
  switch (action.type) {
    case Manual.LIST_MANUALS_REQUEST:
    case Manual.LIST_MANUAL_ARCHIVE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case Manual.LIST_MANUALS_SUCCESS:
    case Manual.LIST_MANUAL_ARCHIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        manualsList: action.payload,
        error: null,
      };
    case Manual.LIST_MANUALS_FAILURE:
    case Manual.LIST_MANUAL_ARCHIVE_FAILURE:

      return {
        ...state,
        loading: false,
        error: action.payload.error,
        manualsList: []

      };

    case Manual.RESET_MANUAL_DETAILS:
      return {
        ...initialState,

      };

    default:
      return state;
  }
}

export default ManualReducer;