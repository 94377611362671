import { ApiService } from '../../services/api.service';
import * as Manual from './ManualConstants';
import { showAlert } from '../alert/showAlert';
import { trackPromise } from 'react-promise-tracker';

// Manual Listing Actions

export function listManuals(data) {
  return dispatch => {
    dispatch(listManualsRequested());
    trackPromise(
      ApiService.callListManuals(data).then(res => {
      dispatch(listManualsSuccess(res.data));
    }))
      .catch(err => {
        dispatch(listManualsFailure(err.message));
        showAlert(dispatch,err);

      });
  };
}


const listManualsRequested = () => ({
  type: Manual.LIST_MANUALS_REQUEST
});

const listManualsSuccess = data => ({
  type: Manual.LIST_MANUALS_SUCCESS,
  payload: {
    ...data
  }
});

const listManualsFailure = error => ({
  type: Manual.LIST_MANUALS_FAILURE,
  payload: {
    error
  }
});


//Archive listing for manuals
export function listManualArchives(data) {
  return dispatch => {
    dispatch(listManualArchivesRequested());
    trackPromise(
      ApiService.callListManualArchives(data).then(res => {
      dispatch(listManualArchivesSuccess(res.data));
    }))
      .catch(err => {
        dispatch(listManualArchivesFailure(err.message));
        showAlert(dispatch,err);

      });
  };
}


const listManualArchivesRequested = () => ({
  type: Manual.LIST_MANUAL_ARCHIVE_REQUEST
});

const listManualArchivesSuccess = data => ({
  type: Manual.LIST_MANUAL_ARCHIVE_SUCCESS,
  payload: {
    ...data
  }
});

const listManualArchivesFailure = error => ({
  type: Manual.LIST_MANUAL_ARCHIVE_FAILURE,
  payload: {
    error
  }
});


//Reset

export function resetManualDetails(){
  return { type: Manual.RESET_MANUAL_DETAILS };
}