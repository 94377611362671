import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { useDispatch } from "react-redux";
import { callListProjects } from "../userManagement/actions";
import { CustomerId } from "src/app/config";
import { history } from "../../../helpers/history";

const ProjectManagement = () => {
  const [projectList, setProjectList] = useState([1, 23, 4, 4, 5]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(callListProjects(CustomerId)).then((res) => {
      setProjectList(res?.data?.data);
    });
  }, []);

  const goToCreateJobTicket = () => {
    history.push("/home/addJobTicket", { type: 10 });
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="st-tl">
          <h1 className="mh-head-txt pt-3">Projects</h1>
        </div>
        <div className="aad-usr pt-3">
          <Icon.PlusCircle />
          <a className="a-removed-href-create" onClick={goToCreateJobTicket}>
            Create Job Ticket For New Project
          </a>
        </div>
      </div>
      <hr className="pb-3" />
      <div className="row">
        {projectList?.length <= 0 && (
          <div
            style={{
              height: "10vh",
              width: "100%",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
              fontSize: "15px",
            }}
          >
            No Users
          </div>
        )}
        {projectList &&
          projectList?.map((project, index) => (
            <div className="col-lg-3 text-center" key={index + 1}>
              <div className="card-deck mb-3 h-100 pb-3">
                <div className="card">
                  <div className="card-body">
                    <img
                      className="rounded-circle mx-auto d-block"
                      src={"/images/department-icon.jpg"}
                      width="100"
                      height="100"
                    />

                    <h4 className="card-title pt-2">{project?.projectName}</h4>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default ProjectManagement;
