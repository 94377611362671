import React, { useState } from "react";
import * as Icon from "react-feather";
import { history } from "../../../helpers/history";
import InternalJobTicket from "./InternalJobTicket";
import ExternalJobTicket from "./ExternalJobTicket";

const Isonlinez = () => {
  const [activeTab, setActiveTab] = useState("external");
  const [isAdmin] = useState(localStorage.getItem("isAdmin"));
  const [filter, setFilter] = useState({
    ticketStatus: "",
    userName: "",
  });
  const [filterValue, setFilterValue] = useState({
    ticketStatus: "",
    userName: "",
  });

  const tabsHandler = (tab) => {
    setActiveTab(tab);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilter((filter) => ({ ...filter, [name]: value }));
  };
  const handleFilterApply = () => {
    setFilterValue(filter);
  };

  return (
    <>
      <div className="alert alert-custom hero-banner" role="alert">
        <div className="d-flex">
          <div className="row" style={{ width: "100%" }}>
            <div className="p-2 flex-fill im-holder col-lg-5"></div>
            <div className="p-2 pl-5 flex-fill col-lg-7">
              <h4 className="alert-heading alert-heading-cst">
                Hi,
                <b>
                  {isAdmin == "false"
                    ? " " +
                      localStorage.getItem("firstName") +
                      " " +
                      localStorage.getItem("lastName")
                    : " Admin"}
                </b>
              </h4>
              <p className="cnt-head" style={{ fontSize: "23px" }}>
                We hope you have a good day!
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <nav>
          <div
            className="nav nav-tabs"
            id="nav-tab"
            role="tablist"
            style={{ zIndex: 0 }}
          >
            <button
              className={`nav-link ${activeTab === "external" ? "active" : ""}`}
              id="nav-external-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-external"
              type="button"
              role="tab"
              aria-controls="nav-external"
              aria-selected={activeTab === "external" ? "true" : "false"}
              onClick={() => tabsHandler("external")}
              style={{ outline: "none", background: "white" }}
            >
              External Job Tickets
            </button>
            <button
              className={`nav-link ${activeTab === "internal" ? "active" : ""}`}
              id="nav-internal-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-internal"
              type="button"
              role="tab"
              aria-controls="nav-internal"
              aria-selected={activeTab === "internal" ? "true" : "false"}
              onClick={(e) => {
                e.stopPropagation();
                tabsHandler("internal");
              }}
              style={{ outline: "none", background: "white" }}
            >
              Internal Job Tickets
            </button>
          </div>
        </nav>
        <div className="d-flex">
          <button
            type="button"
            onClick={() => {
              {
                activeTab === "external"
                  ? history.push("/home/addJobTicket")
                  : history.push("/home/addInternalJobTicket");
              }
            }}
            className={
              activeTab === "external" ? "btn btn-primary" : "btn btn-dark"
            }
          >
            {activeTab === "external"
              ? "Create External Job Ticket"
              : "Create Internal Job Ticket"}
          </button>

          <div className="p-2 clr ">
            <a
              className="a-removed-href"
              data-toggle="modal"
              data-target="#externalFilterModal"
            >
              <Icon.Filter />
            </a>
          </div>
        </div>
      </div>

      <div className="tab-content mt-3" id="nav-tabContent">
        <div
          className={`tab-pane fade ${
            activeTab === "external" ? "show active" : ""
          }`}
          id="nav-external"
          role="tabpanel"
          aria-labelledby="nav-external-tab"
        >
          {activeTab === "external" && (
            <ExternalJobTicket filterValue={filterValue} />
          )}
        </div>
        <div
          className={`tab-pane fade ${
            activeTab === "internal" ? "show active" : ""
          }`}
          id="nav-internal"
          role="tabpanel"
          aria-labelledby="nav-internal-tab"
        >
          {activeTab === "internal" && (
            <InternalJobTicket filterValue={filterValue} />
          )}
        </div>
      </div>

      <div
        className="modal fade"
        id="externalFilterModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="ticketModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="ticketModalLabel">
                Filter
              </h5>
              <button
                className="close"
                type="button"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="d-flex mb-2">
                <div className="p-2 col-lg-4">Status</div>
                <div className="p-2 col-lg-4">
                  <select
                    value={filter.ticketStatus}
                    className="filter-clr"
                    name="ticketStatus"
                    onChange={handleChange}
                    required
                  >
                    <option value="" defaultValue>
                      None
                    </option>
                    <option value="notstarted">Not Started</option>
                    {activeTab === "external" && (
                      <option value="inprogress">In Progress</option>
                    )}
                    <option value="completed">Completed</option>
                    {activeTab === "external" && (
                      <option value="onhold">On hold</option>
                    )}
                    {activeTab === "external" && (
                      <option value="approved">Approved</option>
                    )}
                    <option value="rejected">Rejected</option>
                  </select>
                </div>
              </div>

              <div className="d-flex mb-2">
                <div className="p-2 col-lg-4">Assigned to</div>
                <div className="p-2 col-lg-4">
                  <input
                    type="text"
                    required="required"
                    className="filter-clr"
                    name="userName"
                    value={filter.userName}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-secondary"
                type="button"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <a
                className="a-cancel btn btn-primary"
                data-dismiss="modal"
                onClick={handleFilterApply}
              >
                Apply Filter
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Isonlinez;
