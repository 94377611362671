import { ApiService } from "../../services/api.service";
import { showAlert } from "../alert/showAlert";
import { trackPromise } from "react-promise-tracker";
import { alertActions } from "../alert/alertActions";

export function getCallGetLiveRecordTemplate(data) {
  return (dispatch) => {
    return trackPromise(
      ApiService.callGetLiveRecordTemplate(data).then((res) => {
        return res;
      })
    ).catch((err) => {
      showAlert(dispatch, err);
      throw err;
    });
  };
}

export function deleteSingleLiveRecord(data) {
  return (dispatch) => {
    trackPromise(
      ApiService.callDeleteSingleLiveRecord(data).then((res) => {
        dispatch(alertActions.success(res.data.message));
      })
    ).catch((err) => {
      showAlert(dispatch, err);
    });
  };
}
export function deleteSingleLiveRecordPdf(data) {
  return (dispatch) => {
    trackPromise(
      ApiService.callDeleteSingleLiveRecordPdf(data).then((res) => {
        dispatch(alertActions.success(res.data.message));
      })
    ).catch((err) => {
      showAlert(dispatch, err);
    });
  };
}

export function getCallLiveRecordFormSubmit(data) {
  return (dispatch) => {
    return trackPromise(
      ApiService.callLiveRecordFormSubmit(data).then((res) => {
        // dispatch(alertActions.success(res.data.message));
        return res;
      })
    ).catch((err) => {
      // showAlert(dispatch, err);
      return err;
    });
  };
}

export function callDeletedLiverecords(data) {
  return () => {
    return trackPromise(
      ApiService.callDeletedLiverecords(data).then((res) => {
        return res;
      })
    ).catch((err) => {
      return err;
    });
  };
}
export function callGetLiveRecordShareByEmail(data) {
  return (dispatch) => {
    trackPromise(
      ApiService.callGetLiveRecordShareByEmail(data).then((res) => {
        dispatch(alertActions.success(res.data.message));
      })
    ).catch((err) => {
      showAlert(dispatch, err);
    });
  };
}

export function retrieveSingleLiveRecord(data) {
  return (dispatch) => {
    return trackPromise(
      ApiService.callRetrieveSingleLiveRecord(data).then((res) => {
        dispatch(alertActions.success(res.data.message));
        return res;
      })
    ).catch((err) => {
      showAlert(dispatch, err);
      throw err;
    });
  };
}
