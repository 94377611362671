import React, { useEffect } from "react";
import { history } from "../../../helpers/history";
import { getProfile } from "./profileActions";
import { useDispatch, useSelector } from "react-redux";
import PageTopHeader from "src/app/components/PageTopHeader";
import * as Icon from "react-feather";

const Profile = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profileData);
  const profileData = profile?.profileData?.data;

  // Call Get Profile API on initial rendering of componet
  useEffect(() => {
    dispatch(getProfile(localStorage.getItem("userId")));
  }, []);

  // Edit a user
  const editUser = (user) => {
    history.push("/home/editprofile", profileData);
  };

  return (
    <div className="outer-wrap">
      <PageTopHeader
        rightTitle={"Back To Home"}
        url={"/home"}
        Righticon={<Icon.ArrowLeft />}
      />
      <div className="container-wrap">
        <div className="card p-2">
          <div className="card-body">
            <form className="user">
              <div className="form-group text-center pb-3">
                <div className="st-tl">
                  <h1 className="mh-head-txt">My Profile</h1>
                </div>
              </div>
              <div className="form-group pb-3 text-center">
                <img
                  className="rounded-circle mx-auto d-block"
                  src={
                    profileData.image
                      ? profileData.image
                      : "/images/default-user-placeholder.png"
                  }
                  width="150"
                  height="150"
                />
              </div>
              <div className="form-group">
                <h4 className="text-center">
                  {profileData.firstName + " " + profileData.lastName}
                </h4>
              </div>
              <div className="form-group">
                <h6 className="text-center">
                  {profileData && profileData.email}
                </h6>
              </div>
              <div className="form-group">
                <h6 className="text-center">
                  {profileData && profileData.department}
                </h6>
              </div>
              <div className="form-group text-center">
                <a href="/home/changePassword">Change password</a>
              </div>
              <div className="d-flex mt-5 justify-content-center">
                <div>
                  <a
                    onClick={() => {
                      editUser();
                    }}
                    className="btn btn-primary btn-sm btn-cstm a-cancel"
                  >
                    Edit Profile
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
