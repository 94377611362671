import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";
import { history } from "../../../helpers/history";
import { useDispatch, useSelector } from "react-redux";
import {
  listRecords,
  listYears,
  resetRecordDetails,
} from "../Records/RecordsActions";
import { getNotification } from "../../components/header/notificationActions";
import Pagination from "../../components/Pagination/Pagination";
import { format } from "date-fns";
import { CustomerId } from "src/app/config";
import "./records.css";
import SearchBox from "src/app/components/SearchBox";
const Records = () => {
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const dispatch = useDispatch();
  const [sortParams, setSortParams] = useState({
    sortBy: "ASC",
    sortTitle: "templateRefId",
  });
  const [showArchives, setShowArchives] = useState(false);
  var currDate = new Date();
  var year = currDate.getFullYear();
  const [currentYear] = useState(year);
  const [yearSelected, setYearSelected] = useState();
  const recordList = useSelector((state) => state.recordData);
  const recordListData =
    recordList && recordList.recordsList && recordList.recordsList.data;
  const totalCount =
    recordList &&
    recordList.recordsList &&
    recordList.recordsList.templateCount;
  const yearArray =
    recordList && recordList.yearsList && recordList.yearsList.data;
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    window.onbeforeunload = null;
    getNotificationsList();
  }, []);

  useEffect(() => {
    getRecordList();
  }, [searchValue, yearSelected, showArchives]);

  useEffect(() => {
    if (yearArray && yearArray.length >= 1) {
      setYearSelected(yearArray.slice(0).reverse()[0].year);
    }
  }, [yearArray]);

  const getNotificationsList = () => {
    const data = {
      numPerPage: 10,
      page: 1,
      customerId: CustomerId,
      userId: localStorage.getItem("userId"),
    };
    dispatch(getNotification(data));
  };

  const getRecordList = (page = 1, pageSize = 100) => {
    // var year = showArchives == true && yearSelected;
    const data = {
      numPerPage: pageSize,
      page: page == 0 ? 1 : page,
      userId: localStorage.getItem("userId"),
      sortKey: sortParams.sortTitle,
      sortType: sortParams.sortBy,
      searchKey: searchValue,
      year: showArchives ? yearSelected : "",
    };
    if (year) {
      dispatch(listRecords(data));
    }
  };

  const onPageChanged = (data) => {
    if (data && data.currentPage == currentPageNumber) {
      return;
    }
    if (data && data.currentPage) {
      const { currentPage } = data;
      setCurrentPageNumber(currentPage);
      getRecordList(currentPage);
    }
  };

  const goToDetails = (data) => {
    var year = showArchives == false ? currentYear : yearSelected;
    history.push("/home/recordDetails?id=" + data.id + "&year=" + year);
  };

  const sortTable = (title) => {
    var sortByOrder = "ASC";

    if (title == sortParams.sortTitle) {
      sortByOrder = sortParams.sortBy == "DESC" ? "ASC" : "DESC";
      setSortParams({ sortBy: sortByOrder, sortTitle: title });
    } else {
      setSortParams({ sortBy: "ASC", sortTitle: title });
    }
    // var year = showArchives == true && yearSelected;

    const data = {
      numPerPage: 100,
      page: currentPageNumber,
      userId: localStorage.getItem("userId"),
      sortKey: title,
      sortType: sortByOrder,
      searchKey: searchValue,
      year: showArchives ? yearSelected : "",
    };
    dispatch(listRecords(data));
  };

  const goToArchives = () => {
    dispatch(resetRecordDetails());
    dispatch(listYears());
    setShowArchives(true);
  };
  const goToRecords = () => {
    dispatch(resetRecordDetails());
    setShowArchives(false);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setYearSelected(value);
  };
  const handleSearch = (value) => {
    setSearchValue(value);
  };

  return (
    <div>
      <div class="d-flex justify-content-between">
        <div class="st-tl">
          <h1 class="mh-head-txt pt-3">
            {showArchives == false ? " Template Records" : " Archives"}
          </h1>
        </div>
        <div class="aad-usr pt-3">
          {showArchives == false ? (
            <div>
              <a className="a-removed-href-create" onClick={goToArchives}>
                <Icon.Calendar /> Archives
              </a>
            </div>
          ) : (
            <a className="a-removed-href-create" onClick={goToRecords}>
              Go back to Records
            </a>
          )}
        </div>
      </div>
      <hr class="pb-3" />

      <div class="d-flex justify-content-between mt-5 row">
        <div class="input-group st-tl col-md-4">
          <SearchBox onSearch={handleSearch} />
        </div>
        <div class="mb-0 align-self-end"></div>

        <div class="group mb-0 align-self-end">
          {showArchives == true && yearArray && yearArray.length >= 1 && (
            <div class="select">
              <select
                value={yearSelected}
                required
                name="yearSelected"
                onChange={handleChange}
                class="select-text"
              >
                {/* {yearArray && yearArray.length == 0 && <option value={yearSelected} selected>None</option>} */}
                {yearArray &&
                  yearArray
                    .slice(0)
                    .reverse()
                    .map((data, index) => (
                      <option value={data.year}>{data.year}</option>
                    ))}
              </select>
              <label class="select-label">Year</label>
            </div>
          )}
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="table-wraper table-responsive mnt mt-3">
            <table class="table job-list">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="mx-width"
                    onClick={() => {
                      sortTable("templateRefId");
                    }}
                  >
                    <div class="d-flex justify-content-start">
                      <div>Doc ID</div>
                      <div class="sort-wrp ml-2">
                        <i
                          class={
                            sortParams.sortTitle == "templateRefId" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-asc fa-lg active sort_up"
                              : "fa fa-sort-asc fa-lg sort_fade_down"
                          }
                        ></i>
                        <i
                          class={
                            sortParams.sortTitle == "templateRefId" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-desc fa-lg sort_fade_down"
                              : sortParams.sortTitle == "templateRefId" &&
                                sortParams.sortBy == "DESC"
                              ? "fa fa-sort-desc fa-lg active sort_up"
                              : "fa fa-sort-desc fa-lg sort_fade_down"
                          }
                        ></i>
                      </div>
                    </div>
                  </th>
                  <th
                    scope="col"
                    onClick={() => {
                      sortTable("templateName");
                    }}
                  >
                    <div class="d-flex justify-content-start">
                      <div>Template Name</div>
                      <div class="sort-wrp ml-2">
                        <i
                          class={
                            sortParams.sortTitle == "templateName" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-asc fa-lg active sort_up"
                              : "fa fa-sort-asc fa-lg sort_fade_down"
                          }
                        ></i>
                        <i
                          class={
                            sortParams.sortTitle == "templateName" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-desc fa-lg sort_fade_down"
                              : sortParams.sortTitle == "templateName" &&
                                sortParams.sortBy == "DESC"
                              ? "fa fa-sort-desc fa-lg active sort_up"
                              : "fa fa-sort-desc fa-lg sort_fade_down"
                          }
                        ></i>
                      </div>
                    </div>
                  </th>
                  <th
                    scope="col text-center"
                    onClick={() => {
                      sortTable("revision");
                    }}
                  >
                    <div class="d-flex justify-content-start">
                      <div>Revision</div>
                      <div class="sort-wrp ml-2">
                        <i
                          class={
                            sortParams.sortTitle == "revision" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-asc fa-lg active sort_up"
                              : "fa fa-sort-asc fa-lg sort_fade_down"
                          }
                        ></i>
                        <i
                          class={
                            sortParams.sortTitle == "revision" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-desc fa-lg sort_fade_down"
                              : sortParams.sortTitle == "revision" &&
                                sortParams.sortBy == "DESC"
                              ? "fa fa-sort-desc fa-lg active sort_up"
                              : "fa fa-sort-desc fa-lg sort_fade_down"
                          }
                        ></i>
                      </div>
                    </div>
                  </th>
                  <th
                    scope="col"
                    onClick={() => {
                      sortTable("updatedAt");
                    }}
                  >
                    <div class="d-flex justify-content-start">
                      <div>Date</div>
                      <div class="sort-wrp ml-2">
                        <i
                          class={
                            sortParams.sortTitle == "updatedAt" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-asc fa-lg active sort_up"
                              : "fa fa-sort-asc fa-lg sort_fade_down"
                          }
                        ></i>
                        <i
                          class={
                            sortParams.sortTitle == "updatedAt" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-desc fa-lg sort_fade_down"
                              : sortParams.sortTitle == "updatedAt" &&
                                sortParams.sortBy == "DESC"
                              ? "fa fa-sort-desc fa-lg active sort_up"
                              : "fa fa-sort-desc fa-lg sort_fade_down"
                          }
                        ></i>
                      </div>
                    </div>
                  </th>
                  <th class="text-center" scope="col">
                    View Records
                  </th>
                </tr>
              </thead>
              <tbody>
                {recordList && !recordList.loading && recordList.error && (
                  <span className="text-danger">ERROR: {recordList.error}</span>
                )}
                {recordListData &&
                  recordListData.map((record) => (
                    <tr>
                      <td>{record.templateRefId}</td>
                      <td>{record.templateName}</td>
                      <td className="text-center">{record.revision}</td>

                      <td>
                        {record.updatedAt
                          ? format(
                              new Date(record.updatedAt),
                              "yyyy-MM-dd : hh.mm aa"
                            )
                          : "-"}
                      </td>
                      <td className="text-center">
                        <a
                          className="a-removed-href"
                          onClick={() => {
                            goToDetails(record);
                          }}
                        >
                          View
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {(recordList.recordsList.length <= 0 ||
              recordList.recordsList.templateCount <= 0) && (
              <p class="text-center font-italic font-weight-bold">
                No data available!
              </p>
            )}

            {recordList.recordsList.templateCount > 0 && (
              <div className="d-flex flex-row py-4 align-items-center float-left">
                Total Count: {recordList.recordsList.templateCount}
              </div>
            )}

            <div className="d-flex flex-row py-4 align-items-center float-right">
              {recordList.recordsList.templateCount > 0 && (
                <Pagination
                  totalRecords={totalCount}
                  pageLimit={100}
                  pageNeighbours={0}
                  onPageChanged={onPageChanged}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Records;
