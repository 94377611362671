import { ApiService } from "../../services/api.service";
import { showAlert } from "../alert/showAlert";
import { trackPromise } from "react-promise-tracker";

// Template Listing Actions

export function getListLiveTemplates(data) {
  return (dispatch) => {
    return trackPromise(
      ApiService.callListLiveTemplates(data).then((res) => {
        return res;
      })
    ).catch((err) => {
      showAlert(dispatch, err);
      throw err;
    });
  };
}
export function callLiveTemplateSequelNo(data) {
  return (dispatch) => {
    return trackPromise(
      ApiService.callLiveTemplateSequelNo(data).then((res) => {
        return res;
      })
    ).catch((err) => {
      showAlert(dispatch, err);
      throw err;
    });
  };
}
export function getLiveTemplateForCustomer(tempId, CustomerId) {
  return (dispatch) => {
    // Return the result of the API call as a Promise.
    return trackPromise(
      ApiService.callGetLiveTemplateForCustomer(tempId, CustomerId)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          // Dispatch error action or handle the error.
          // dispatch(errorAction(err));

          showAlert(dispatch, err);

          throw err;
        })
    );
  };
}

export function getCallAddLiveTemplateData(data) {
  return (dispatch) => {
    // Return the result of the API call as a Promise.
    return trackPromise(
      ApiService.callAddLiveTemplateData(data)
        .then((res) => {
          // setTimeout(() => window.location.reload(), 2000);

          return res;
        })
        .catch((err) => {
          showAlert(dispatch, err);
          throw err;
        })
    );
  };
}
