import * as Policy from './PolicyConstants';

const initialState = {
  loading: false,
  policiesList: [],
  error: null,
};

function PolicyReducer(state = initialState, action={}) {
  switch (action.type) {
    case Policy.LIST_POLICIES_REQUEST:
    case Policy.LIST_POLICY_ARCHIVE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case Policy.LIST_POLICIES_SUCCESS:
    case Policy.LIST_POLICY_ARCHIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        policiesList: action.payload,
        error: null,
      };
    case Policy.LIST_POLICIES_FAILURE:
    case Policy.LIST_POLICY_ARCHIVE_FAILURE:

      return {
        ...state,
        loading: false,
        error: action.payload.error,
        policiesList: []

      };


    case Policy.RESET_POLICY_DETAILS:
      return {
        ...initialState,

      };

    default:
      return state;
  }
}

export default PolicyReducer;