import React from "react";

const RadioField = ({ onChange, attribs, checked }) => {
  return (
    <input
      type="radio"
      {...attribs}
      required={attribs.required === "" ? true : false}
      data-checked={checked}
      checked={checked}
      onChange={(e) => {
        onChange(attribs.name, e.target.value);
      }}
    />
  );
};

export default RadioField;
