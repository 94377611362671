// PROCEDURE LISTING
export const LIST_PROCEDURES_REQUEST = 'LIST_PROCEDURES_REQUEST';
export const LIST_PROCEDURES_SUCCESS = 'LIST_PROCEDURES_SUCCESS';
export const LIST_PROCEDURES_FAILURE = 'LIST_PROCEDURES_FAILURE';

//LIST ARCHIVES
export const LIST_PROCEDURE_ARCHIVE_REQUEST = 'LIST_PROCEDURE_ARCHIVE_REQUEST';
export const LIST_PROCEDURE_ARCHIVE_SUCCESS = 'LIST_PROCEDURE_ARCHIVE_SUCCESS';
export const LIST_PROCEDURE_ARCHIVE_FAILURE = 'LIST_PROCEDURE_ARCHIVE_FAILURE';

//RESET
export const RESET_PROCEDURE_DETAILS = 'RESET_PROCEDURE_DETAILS';


