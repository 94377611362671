import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import PageTopHeader from "src/app/components/PageTopHeader";
import AdminUsers from "./AdminUsers";
import DepartmentUsers from "./DepartmentUsers";
import { callForListCustomerDepartments } from "./actions";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";

const UserManagement = () => {
  const [activeTab, setActiveTab] = useState("admin");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [departmentList, setDepartmentList] = useState([]);
  const dispatch = useDispatch();
  const userRole = decodeURIComponent(Cookies.get("userRole"));

  useEffect(() => {
    dispatch(callForListCustomerDepartments()).then((res) => {
      setDepartmentList(res?.data?.data);
    });
  }, []);
  const tabsHandler = (tab) => {
    setActiveTab(tab);
  };
  const handleSelectChange = (e) => {
    setSelectedDepartment(e.target.value);
  };
  return (
    <>
      {userRole !== "User" && "No Role" && (
        <PageTopHeader
          leftTitle={"User Management"}
          rightTitle={"Add New User"}
          url={"/home/addUser"}
          Righticon={<Icon.PlusCircle />}
        />
      )}
      {userRole === "User" && "No Role" && (
        <PageTopHeader leftTitle={"User Management"} />
      )}

      <div className="d-flex justify-content-between align-items-center">
        <nav>
          <div
            className="nav nav-tabs"
            id="nav-tab"
            role="tablist"
            style={{ zIndex: 0 }}
          >
            <button
              className={`nav-link ${activeTab === "admin" ? "active" : ""}`}
              id="nav-admin-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-admin"
              type="button"
              role="tab"
              aria-controls="nav-admin"
              aria-selected={activeTab === "admin" ? "true" : "false"}
              onClick={() => tabsHandler("admin")}
              style={{ outline: "none", background: "white" }}
            >
              Admin Users
            </button>
            <button
              className={`nav-link ${activeTab === "dept" ? "active" : ""}`}
              id="nav-dept-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-dept"
              type="button"
              role="tab"
              aria-controls="nav-dept"
              aria-selected={activeTab === "dept" ? "true" : "false"}
              onClick={(e) => {
                e.stopPropagation();
                tabsHandler("dept");
              }}
              style={{ outline: "none", background: "white" }}
            >
              Department Wise Users
            </button>
          </div>
        </nav>
        <div>
          {activeTab === "dept" && (
            <select
              className="form-select form-select-lg p-2"
              aria-label=".form-select-lg example"
              style={{ borderRadius: "5px" }}
              value={selectedDepartment}
              onChange={handleSelectChange}
            >
              <option value="" selected>
                All Departments
              </option>
              {departmentList?.map((dept, index) => (
                <option
                  key={index + 1}
                  value={
                    userRole === "Prime Admin" ? dept?.id : dept.Department?.id
                  }
                >
                  {userRole === "Prime Admin"
                    ? dept?.departmentName
                    : dept?.Department?.departmentName}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>

      <div className="tab-content mt-3" id="nav-tabContent">
        <div
          className={`tab-pane fade ${
            activeTab === "admin" ? "show active" : ""
          }`}
          id="nav-admin"
          role="tabpanel"
          aria-labelledby="nav-admin-tab"
        >
          <AdminUsers />
        </div>
        <div
          className={`tab-pane fade ${
            activeTab === "dept" ? "show active" : ""
          }`}
          id="nav-dept"
          role="tabpanel"
          aria-labelledby="nav-dept-tab"
        >
          <DepartmentUsers
            title={selectedDepartment}
            selectedDepartment={selectedDepartment}
          />
        </div>
      </div>
    </>
  );
};

export default UserManagement;
