import React from 'react';
import { CompanyName } from 'src/app/config';

const ConfirmationModal = (props) => {

  return (
      
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{CompanyName}</h5>
              <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              {props.message}</div>
            <div className="modal-footer">
              <button className="btn btn-secondary" type="button" data-dismiss="modal">No</button>
              <a className="btn btn-primary a-cancel" data-dismiss="modal"
               data-toggle={props.actionType?"modal":""}              
              data-target={props.actionType?"#reConfirmationModal":""} onClick={props.performAction}>Yes</a>
            </div>
          </div>
        </div>
  );
}

export default ConfirmationModal;