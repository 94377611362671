import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { history } from '../../../helpers/history';
import OrgChart from './orgChart'
import './BusinessOrganisation.css';
import { jsPDF } from "jspdf";


const AddBusinessOrganisation = () => {
 
  const [html, setHtml] = useState({
    header: '',
    body: '',
    footer: ''
  });
  const [height, setHeight] = useState({
    header: 0,
    footer: 0
  })
  const [isLoading, setIsLoading] = useState(false);
 
  // const [chartData, setChartData] = useState([])
  const [imageData, setImageData] = useState('')
  
  const [inputs, setInputs] = useState({
    customerName: '',
    docId: '',
    organizationName: '',
    status: '',
    organizationDate: '',
    revision: '1',
    jobId: '',
    type: '',
    marginTop: '',
    marginBottom: '',
    marginLeft: '',
    marginRight: '',
    pageLayout: '',
    pageSize: '',
  });

  const [reportingArray, setReportingArray] = useState(JSON.parse(JSON.stringify([{

  }])))

  const location = useLocation();

  let pageWidth='auto'
  let pageHeight='auto'

  



  useEffect(() => {
    // window.onbeforeunload = null;
    if (location.state != null) {
      var orgDate = location.state.organizationDate && location.state.organizationDate.split('T')[0]
      var paddingTop = location.state.margin && location.state.margin.split('-')[0]
      var paddingRight = location.state.margin && location.state.margin.split('-')[1]
      var paddingBottom = location.state.margin && location.state.margin.split('-')[2]
      var paddingLeft = location.state.margin && location.state.margin.split('-')[3]
      setReportingArray(
        location.state.data
      );    
      setHtml({
        header: location.state.headerData,
        footer: location.state.footerData
      });
      setHeight({
        header: location.state.headerHeight,
        footer: location.state.footerHeight
      })
      setInputs({
        organizationName: location.state.organizationName,
        type: location.state.type,
        customerName: location.state.customerId,
        docId: location.state.organizationRefId,
        organizationDate: orgDate,
        revision: location.state.revision,
        jobId: location.state.ticketId,
        marginTop: paddingTop,
        marginRight: paddingRight,
        marginBottom: paddingBottom,
        marginLeft: paddingLeft,
        pageLayout: location.state.layout,
        pageSize: location.state.pageSize,
      });
    
    }
   


  }, []);


  const updateChartData = ( imageData) => {
    // setChartData(data)
    setImageData(imageData)
  }

  const goToBack = () => {
    history.push('/home/businessOrganisation');

  }

 

  const generatePdf = e => {
    e.preventDefault()
    setIsLoading(true)
 
    let doc = new jsPDF("portrait","pt");
    if (inputs.pageSize == 'A4' && inputs.pageLayout == 'landscape'){
      pageWidth=1120
      pageHeight=780;
          doc.internal.pageSize.setHeight(790)
          doc.internal.pageSize.setWidth(1123)

          //marginTop    -   setHeight:+80 =~ 2cm
          switch(inputs.marginTop)
          {
            case "2cm":doc.internal.pageSize.setHeight(870);        break;
            case "1.5cm":doc.internal.pageSize.setHeight(847);      break;
            case "1cm":doc.internal.pageSize.setHeight(831);        break;
            case "0.5cm": doc.internal.pageSize.setHeight(811);     break;
          }
          //marginBottom - pageHeight:-74 =~ 2cm
          switch(inputs.marginBottom)
          {
            case "2cm":pageHeight=705;      break;
            case "1.5cm":pageHeight=728;    break;
            case "1cm":pageHeight=746;      break;
            case "0.5cm": pageHeight=764;   break;
          }
          //marginLeft - pagewidth : -
          switch(inputs.marginLeft)
          {
            case "2cm":pageWidth=pageWidth-50;      break;
            case "1.5cm":pageWidth=pageWidth-40;    break;
            case "1cm":pageWidth=pageWidth-29;      break;
            case "0.5cm": pageWidth=pageWidth-19;   break;
          }
          //marginRight 
          switch(inputs.marginRight)
          {
            case "2cm":pageWidth=pageWidth-96;      break;
            case "1.5cm":pageWidth=pageWidth-77;    break;
            case "1cm":pageWidth=pageWidth-60;      break;
            case "0.5cm": pageWidth=pageWidth-47;   break;
          }
          pageWidth=pageWidth+'px'
        }
       if (inputs.pageLayout == 'landscape' && inputs.pageSize == 'A3') {
        pageWidth=1584
        pageHeight=1110
          doc.internal.pageSize.setHeight(1123)
          doc.internal.pageSize.setWidth(1587)

          //margin: Bottom || Top - pageHeight:-
          switch(inputs.marginTop)
          {
            case "3cm":  pageHeight = pageHeight-112;       break;
            case "2.5cm":pageHeight = pageHeight-93;       break;
            case "2cm":  pageHeight = pageHeight-73;       break;
            case "1.5cm":pageHeight = pageHeight-55;       break;
            case "1cm":  pageHeight = pageHeight-40;       break;
            case "0.5cm":pageHeight = pageHeight-20;       break;
          }
          switch(inputs.marginBottom)
          {
            case "3cm":  pageHeight = pageHeight-112;       break;
            case "2.5cm":pageHeight = pageHeight-93;       break;
            case "2cm":  pageHeight = pageHeight-73;       break;
            case "1.5cm":pageHeight = pageHeight-55;       break;
            case "1cm":  pageHeight = pageHeight-40;       break;
            case "0.5cm":pageHeight = pageHeight-20;       break;
          }
          //marginLeft ,Right : pageWidth -
          switch(inputs.marginLeft)
          {
            case "3cm":pageWidth=pageWidth-112;break;
            case "2.5cm":pageWidth=pageWidth-95;break;
            case "2cm":pageWidth=pageWidth-76;break;
            case "1.5cm":pageWidth=pageWidth-57;break;
            case "1cm":pageWidth=pageWidth-38;break;
            case "0.5cm":pageWidth= pageWidth-19;break;
          }
          switch(inputs.marginRight)
          {
            case "3cm":pageWidth=pageWidth-112;break;
            case "2.5cm":pageWidth=pageWidth-95;break;
            case "2cm":pageWidth=pageWidth-76;break;
            case "1.5cm":pageWidth=pageWidth-57;break;
            case "1cm":pageWidth=pageWidth-38;break;
            case "0.5cm":pageWidth= pageWidth-19;break;
          }
          pageWidth = pageWidth + 'px';
       }
      if (inputs.pageLayout == 'portrait' && inputs.pageSize == 'A4') {
        pageHeight=1113;
        pageWidth=826
         doc.internal.pageSize.setWidth(830);
         doc.internal.pageSize.setHeight(1123)

         //marginTop : pageHeight -
         switch(inputs.marginTop)
          {
            case "2cm":  pageHeight = pageHeight-76;       break;
            case "1.5cm":pageHeight = pageHeight-57;       break;
            case "1cm":  pageHeight = pageHeight-38;       break;
            case "0.5cm":pageHeight = pageHeight-19;       break;
          }
          //marginBottom : pageHeight -
          switch(inputs.marginBottom)
          {
            case "2cm":  pageHeight = pageHeight-76;       break;
            case "1.5cm":pageHeight = pageHeight-57;       break;
            case "1cm":  pageHeight = pageHeight-38;       break;
            case "0.5cm":pageHeight = pageHeight-19;       break;
          }
          //marginLeft : pageWidth -
          switch(inputs.marginLeft)
          {
            case "2cm":  pageWidth = pageWidth-76;       break;
            case "1.5cm":pageWidth = pageWidth-57;       break;
            case "1cm":  pageWidth = pageWidth-38;       break;
            case "0.5cm":pageWidth = pageWidth-19;       break;
          }
          //marginRight : pageWidth -
          switch(inputs.marginRight)
          {
            case "2cm":  pageWidth = pageWidth-76;       break;
            case "1.5cm":pageWidth = pageWidth-57;       break;
            case "1cm":  pageWidth = pageWidth-38;       break;
            case "0.5cm":pageWidth = pageWidth-19;       break;
          }
          pageWidth = pageWidth + 'px';
      }
      if (inputs.pageLayout == 'portrait' && inputs.pageSize == 'A3') {
        pageWidth=1119
        pageHeight=986  
        doc.internal.pageSize.setWidth(1123);
        doc.internal.pageSize.setHeight(996)

        //marginTop : pageHeight -
        switch(inputs.marginTop)
        {
          case "3cm": pageHeight=pageHeight-114;        break;
          case "2.5cm": pageHeight=pageHeight-95;      break;
          case "2cm":  pageHeight=pageHeight-76;       break;
          case "1.5cm": pageHeight=pageHeight-57;      break;
          case "1cm": pageHeight=pageHeight-38;        break;
          case "0.5cm": pageHeight=pageHeight-19;      break;
        }
         //marginBottom : pageHeight -
         switch(inputs.marginBottom)
         {
           case "3cm": pageHeight=pageHeight-114;        break;
           case "2.5cm": pageHeight=pageHeight-95;      break;
           case "2cm":  pageHeight=pageHeight-76;       break;
           case "1.5cm": pageHeight=pageHeight-57;      break;
           case "1cm": pageHeight=pageHeight-38;        break;
           case "0.5cm": pageHeight=pageHeight-19;      break;
         }
          //marginLeft : pageWidth -
          switch(inputs.marginLeft)
          {
            case "3cm": pageWidth=pageWidth-114;        break;
            case "2.5cm": pageWidth=pageWidth-95;      break;
            case "2cm":  pageWidth=pageWidth-76;       break;
            case "1.5cm": pageWidth=pageWidth-57;      break;
            case "1cm": pageWidth=pageWidth-38;        break;
            case "0.5cm": pageWidth=pageWidth-19;      break;
          }
           //marginRight : pageWidth -
           switch(inputs.marginRight)
           {
             case "3cm": pageWidth=pageWidth-114;        break;
             case "2.5cm": pageWidth=pageWidth-95;      break;
             case "2cm":  pageWidth=pageWidth-76;       break;
             case "1.5cm": pageWidth=pageWidth-57;      break;
             case "1cm": pageWidth=pageWidth-38;        break;
             case "0.5cm": pageWidth=pageWidth-19;      break;
           }
          pageWidth=pageWidth+'px';
     }
imageData&& imageData.toPNG(data => {
let a = document.getElementById('DUMMYIMAGE')
a.innerHTML = `<div style="height:${pageHeight};width:${pageWidth};">
<div>${html.header}</div>
  <div style="
          display: flex;
          justify-content: center;
          align-items: center;
         
          height: ${pageHeight - height.header - height.footer}px
          ">
          <img src=${data} style="max-width:100%; max-height: ${pageHeight - height.header - height.footer}px"></img>
          </div>
          <div>${html.footer}</div> </div>`
doc.html(a, {
callback: function () {
  a.innerHTML = ``
  doc.save(inputs.organizationName + '.pdf');
  setIsLoading(false)

      }
    });
  }, 
  {
    padding:{
      left:50,
      top:50,
      bottom:50,
      right:45
    },
    height: `${pageHeight - height.header - height.footer}px`
}
)
}

  return (

    <div>
        <div class="d-flex justify-content-between">
        <div class="st-tl">
          <h1 class="mh-head-txt pt-3 pb-2">Organization Chart</h1>
        </div>
      </div>
      <div class="tab-wrap">
        <div class="tab-content" id="myTabContent">

             <div id="chart" role="tabpanel" aria-labelledby="chart-tab">
            <OrgChart reportingArray={reportingArray} updateChartData={updateChartData} isEdit={true} rolesAndResponsibilityDetails={reportingArray} />
          </div>

     
        </div>

        <div className={isLoading==true?"btn-fade bt-wrap":"bt-wrap"}>
          <div class="d-flex flex-row-reverse ">
            <div class="p-2">
              <a data-toggle="modal" class="a-cancel brd-4 btn btn-primary btn-user btn-block mt-5" onClick={generatePdf}>Download</a>
            </div>
          
            <div class="p-2">
              <a onClick={goToBack} class="brd-4 btn btn-outline-secondary btn-user btn-block mt-5 btn-cancel a-cancel">
                Cancel
              </a>
            </div>
          </div>
        </div>
      </div>
      <div style={{position:'relative'}}>
       <div id="DUMMYIMAGE" style={{position:'absolute', zIndex:'-999',  width:`${pageWidth}`,paddingTop:`${inputs.marginTop}`,paddingLeft:`${inputs.marginLeft}`,paddingRight:`${inputs.marginRight}` }}></div>
       </div>
    </div>
  );
}

export default AddBusinessOrganisation;