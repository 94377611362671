import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
const CustomAlert = (message) => {
  const options = {
    message: message,
    buttons: [
      {
        label: "OK",
        onClick: () => {
          // Just dismiss the alert
        },
      },
    ],
    closeOnClickOutside: false,
  };
  confirmAlert(options);
};
export default CustomAlert;
