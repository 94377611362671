import React, { useEffect } from "react";
import * as joint from "rappid";
import $ from "jquery";

const OrgChart = ({
  updateChartData,
  isEdit,
  rolesAndResponsibilityDetails,
}) => {
  useEffect(() => {
    joint.setTheme("modern");
    joint.shapes.org.Member.prototype.markup = [
      '<g class="rotatable">',
      '<g class="scalable">',
      '<rect class="card"/><image/>',
      "</g>",
      '<text class="rank"/><text class="name"/>',
      '<g class="btn edit"><rect class="edit"/><text class="edit">VIEW</text></g>',
      "</g>",
    ].join("");

    // A helper to create a member model
    var member = function (
      rank,
      name,
      image,
      background,
      textColor,
      borderColor
    ) {
      textColor = textColor || "#000";

      var element = new joint.shapes.org.Member({
        size: { width: 260, height: 90 },
        attrs: {
          ".card": { fill: background, stroke: borderColor, "stroke-width": 2 },
          image: { "xlink:href": image, "ref-y": 10, opacity: 0.7 },
          ".rank": {
            fill: textColor,
            text: "",
            "font-size": 13,
            "text-decoration": "none",
            "ref-x": 0.95,
            "ref-y": 0.5,
            "y-alignment": "middle",
            "word-spacing": "-1px",
            "letter-spacing": 0,
          },
          ".name": {
            fill: textColor,
            text: "",
            "ref-x": 0.95,
            "ref-y": 0.7,
            "font-family": "Arial",
          },
          ".btn.add": {
            "ref-dx": -15,
            "ref-y": 15,
            ref: ".card",
            event: "element:add",
          },
          ".btn.del": {
            "ref-dx": -45,
            "ref-y": 15,
            ref: ".card",
            event: "element:delete",
          },
          ".btn.edit": {
            "ref-dx": -140,
            "ref-y": 5,
            ref: ".card",
            event: "element:edit",
          },
          ".btn>circle": {
            r: 10,
            fill: "transparent",
            stroke: "#333",
            "stroke-width": 1,
          },
          ".btn>rect": {
            height: 20,
            width: 45,
            rx: 2,
            ry: 2,
            fill: "transparent",
            "stroke-width": 1,
          },
          ".btn.add>text": {
            fill: textColor,
            "font-size": 23,
            "font-weight": 800,
            stroke: "#000",
            x: -6.5,
            y: 8,
            "font-family": "Times New Roman",
          },
          ".btn.del>text": {
            fill: textColor,
            "font-size": 28,
            "font-weight": 500,
            stroke: "#000",
            x: -4.5,
            y: 6,
            "font-family": "Times New Roman",
          },
          ".btn.edit>text": {
            fill: textColor,
            "font-size": 15,
            "font-weight": 500,
            stroke: "#000",
            x: 5,
            y: 15,
            "font-family": "Sans Serif",
          },
        },
      })
        .on({
          "change:name": function (cell, name) {
            cell.attr(
              ".name/text",
              joint.util.breakText(
                name,
                { width: 160, height: 45 },
                cell.attr(".name")
              )
            );
          },
          "change:rank": function (cell, rank) {
            cell.attr(
              ".rank/text",
              joint.util.breakText(
                rank,
                { width: 165, height: 45 },
                cell.attr(".rank")
              )
            );
          },
        })
        .set({
          name: name,
          rank: rank,
        });

      return element;
    };

    var members = [
      member(
        "Founder & Chairman",
        "Pierre Omidyar",
        "/images/male.png",
        "#ffffff",
        "#000",
        "#000"
      ),
    ];

    var connections = [];

    var graph = new joint.dia.Graph({}, { cellNamespace: joint.shapes });
    var paper = new joint.dia.Paper({
      model: graph,
      cellViewNamespace: joint.shapes,
      width: 1,
      height: 1,
      gridSize: 1,
      defaultLink: new joint.shapes.org.Arrow(),
    });
    var paperScroller = new joint.ui.PaperScroller({
      paper: paper,
      autoResizePaper: true,
    });

    var treeLayout = new joint.layout.TreeLayout({
      graph: graph,
      direction: "R",
    });
    $("#paper-container").empty();
    paperScroller.$el
      .css({ width: "100%", height: "100%" })
      .appendTo("#paper-container");
    if (
      rolesAndResponsibilityDetails &&
      rolesAndResponsibilityDetails.includes("cells") &&
      isEdit
    ) {
      graph.fromJSON(JSON.parse(rolesAndResponsibilityDetails));
      graph.on("change:name", function (cell, name) {
        cell.attr(
          ".name/text",
          joint.util.breakText(
            name,
            { width: 160, height: 45 },
            cell.attr(".name")
          )
        );
      });
      graph.on("change:rank", function (cell, rank) {
        cell.attr(
          ".rank/text",
          joint.util.breakText(
            rank,
            { width: 165, height: 45 },
            cell.attr(".rank")
          )
        );
      });
      updateChartData(paper);
    } else {
      graph.resetCells(members.concat(connections));
    }
    treeLayout.layout();
    paperScroller.zoom(-0.2);
    paperScroller.centerContent();

    paper.on("blank:pointerdown", paperScroller.startPanning);

    paper.on("element:edit", function (elementView, evt, x, y) {
      evt.stopPropagation();
      // A member edit
      var inspector = new joint.ui.Inspector({
        cellView: elementView,
        live: false,
        textarea: {
          abc: {
            type: "text",
            label: "Position",
            index: 1,
          },
        },
        inputs: {
          rank: {
            type: "text",
            label: "Position",
            index: 1,
          },
          name: {
            type: "text",
            label: "Name",
            index: 2,
          },
          department: {
            type: "text",
            label: "Department",
            index: 4,
          },
          // 'attrs/image/xlink:href': {
          //     type: 'select-box',
          //     target: '.joint-dialog .fg',
          //     width: 210,
          //     label: 'Sex',
          //     options: [
          //         { value: '/images/male.png', content: 'Male' },
          //         { value: '/images/female.png', content: 'Female' }
          //     ],
          //     index: 3
          // },
          responsibilities: {
            type: "textarea",
            label: "Responsibilities",
            index: 4,
          },
          authorities: {
            type: "textarea",
            label: "Authorities ",
            index: 4,
          },
          deputy: {
            type: "textarea",
            label: "Deputy",
            index: 4,
          },
          "attrs/.card/fill": {
            type: "color-palette",
            target: ".joint-dialog .fg",
            label: "Color",
            index: 7,
            options: [
              { content: "#ffffff" },
              { content: "#EA497A" },
              { content: "#DEDB21" },
              { content: "#D4DA97" },
              { content: "#31d0c6" },
              { content: "#7c68fc" },
              { content: "#fe854f" },
              { content: "#feb663" },
              { content: "#c6c7e2" },
              { content: "#f06666" },
              { content: "#fbc94a" },
              { content: "#6bea6b" },
              { content: "#bf9692" },
              { content: "#528cff" },
            ],
          },
          "attrs/.card/stroke": {
            type: "color-palette",
            target: ".joint-dialog .fg",
            label: "Border",
            index: 7,
            options: [
              { content: "#ffffff" },
              { content: "#EA497A" },
              { content: "#DEDB21" },
              { content: "#D4DA97" },
              { content: "#31d0c6" },
              { content: "#7c68fc" },
              { content: "#fe854f" },
              { content: "#feb663" },
              { content: "#c6c7e2" },
              { content: "#f06666" },
              { content: "#fbc94a" },
              { content: "#6bea6b" },
              { content: "#bf9692" },
              { content: "#528cff" },
              { content: "#000000" },
            ],
          },
          "attrs/.card/stroke-width": {
            type: "select-box",
            target: ".joint-dialog .fg",
            label: "Border-Width",
            index: 7,
            options: [
              { content: "1" },
              { content: "2" },
              { content: "3" },
              { content: "4" },
              { content: "5" },
            ],
          },
        },
      });

      var dialog = new joint.ui.Dialog({
        type: "inspector-dialog",
        width: 1000,
        title: "View Member",
        closeButton: true,
        content: inspector.render().el,
        buttons: [
          {
            content: "Cancel",
            action: "cancel",
          },
          //     , {
          //         content: 'Apply',
          //         action: 'apply'
          //     }
        ],
      });

      dialog.on({
        "action:cancel": function () {
          inspector.remove();
          dialog.close();
        },
        "action:apply": function () {
          inspector.updateCell();
          inspector.remove();
          updateChartData(paper);
          dialog.close();
        },
      });
      dialog.open();
      updateChartData(paper);
    });

    // Tree Layout Rank Selection
    var directionPicker = new joint.ui.SelectBox({
      width: 150,
      options: [
        { value: "L", content: "Right-Left" },
        { value: "R", content: "Left-Right", selected: true },
        { value: "T", content: "Bottom-Top" },
        { value: "B", content: "Top-Bottom" },
      ],
    });

    directionPicker.on("option:select", function (option) {
      treeLayout.set("direction", option.value);
      treeLayout.layout();
      paperScroller.centerContent();
      updateChartData(paper);
    });
    $("#orgchart-direction").empty();
    directionPicker.render().$el.appendTo("#orgchart-direction");
    var commandManager = new joint.dia.CommandManager({ graph: graph });
    $("#undo-button").click(function () {
      commandManager.undo();
    });
    $("#redo-button").click(function () {
      commandManager.redo();
    });

    $("#hide-controls").click(function () {
      $(".edit").hide();
      $(".del").hide();
      $(".add").hide();
      updateChartData(graph.toJSON(), paper);
    });

    $("#show-controls").click(function () {
      $(".edit").show();
      $(".del").show();
      $(".add").show();
      updateChartData(graph.toJSON(), paper);
    });
    // new joint.ui.TreeLayoutView({
    //     paper: paper,
    //     model: treeLayout,
    //     previewAttrs: {
    //         parent: { rx: 10, ry: 10 }
    //     }
    // });
  }, [rolesAndResponsibilityDetails]);

  return (
    <div>
      <div
        class="joint-toolbar-group joint-theme-modern undo-redo"
        data-group="undo-redo"
      >
        <button
          class="joint-widget joint-theme-modern controls-button"
          id="hide-controls"
        >
          <span>Hide Controls</span>
        </button>
        <button
          class="joint-widget joint-theme-modern controls-button"
          id="show-controls"
        >
          <span>Show Controls</span>
        </button>
      </div>
      <div id="paper-container"></div>
      <p>
        Show the ORG Chart in <span id="orgchart-direction"></span> direction.
      </p>
    </div>
  );
};

export default OrgChart;
