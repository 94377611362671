import { ApiService } from "../../services/api.service";
import { history } from "../../../helpers/history";
import {
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
} from "./logoutConstants";
import { showAlert } from "../alert/showAlert";
import Cookies from "js-cookie";
import { trackPromise } from "react-promise-tracker";

export function logout(data) {
  return (dispatch) => {
    dispatch(logoutRequested());
    trackPromise(
      ApiService.callLogout(data).then((res) => {
        dispatch(logoutSuccess(res.data));
        if (res.data.status == "success") {
          localStorage.clear();
          Cookies.remove("Token");
          history.push("/login");
        }
      })
    ).catch((err) => {
      dispatch(logoutFailure(err.message));
      showAlert(dispatch, err);
    });
  };
}

const logoutRequested = () => ({
  type: LOGOUT_REQUEST,
});

const logoutSuccess = (data) => ({
  type: LOGOUT_SUCCESS,
  payload: {
    ...data,
  },
});

const logoutFailure = (error) => ({
  type: LOGOUT_FAILURE,
  payload: {
    error,
  },
});
