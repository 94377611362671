import React from 'react';


const InteractiveTemplate = () => {

    return (
        <div >
            <h1>Interactive Template</h1>
            <em>This module is currently being developed and will be available for your use soon</em>
        </div>
    );
}

export default InteractiveTemplate;
