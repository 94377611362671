function usePdfDimensions(liveTemplateData) {
  const margin = liveTemplateData
    ? liveTemplateData?.margin?.split("-")
    : "0-0-0-0";

  const pdfType = liveTemplateData?.pageSize;
  const pdfLayout = liveTemplateData?.layout;
  let pageWidth = "auto";
  if (pdfType == "A4" && pdfLayout == "portrait") {
    pageWidth = "794px";
  } else if (pdfType == "A3" && pdfLayout == "portrait") {
    pageWidth = "1123px";
  } else if (pdfType == "A4" && pdfLayout == "landscape") {
    pageWidth = "1123px";
  } else if (pdfType == "A3" && pdfLayout == "landscape") {
    pageWidth = "1587px";
  }
  return { margin, pageWidth, pdfType, pdfLayout };
}

export default usePdfDimensions;
