import { ApiService } from '../../services/api.service';
import { history } from '../../../helpers/history';
import {alertActions} from '../alert/alertActions';
import { showAlert } from '../alert/showAlert';
import * as PasswordConstants from './PasswordConstants';
import { trackPromise } from 'react-promise-tracker';

export function changePassword(data) {
  return dispatch => {
    dispatch(changePasswordRequested());
    trackPromise(
      ApiService.callChangePassword(data).then(res => {
      dispatch(changePasswordSuccess(res.data));
      if (res.data.status == "success") {
        history.push('/home/profile')
      }
      dispatch(alertActions.success(res.data.message));
     
    }))
      .catch(err => {
        dispatch(changePasswordFailure(err.message));
        showAlert(dispatch,err);

      });
  };
}

const changePasswordRequested = () => ({
  type: PasswordConstants.CHANGE_PASSWORD_REQUEST
});

const changePasswordSuccess = data => ({
  type: PasswordConstants.CHANGE_PASSWORD_SUCCESS,
  payload: {
    ...data
  }
});

const changePasswordFailure = error => ({
  type: PasswordConstants.CHANGE_PASSWORD_FAILURE,
  payload: {
    error
  }
});

export function verifyPasswordLink(data) {
  return dispatch => {
    dispatch(verifyPasswordLinkRequested());
    trackPromise(
      ApiService.callverifyPasswordLink(data).then(res => {
      dispatch(verifyPasswordLinkSuccess(res.data));
      dispatch(alertActions.success(res.data.message));
     
    }))
      .catch(err => {
        dispatch(verifyPasswordLinkFailure(err.message));
        showAlert(dispatch,err);

      });
  };
}

const verifyPasswordLinkRequested = () => ({
  type: PasswordConstants.VERIFY_PASSWORD_REQUEST
});

const verifyPasswordLinkSuccess = data => ({
  type: PasswordConstants.VERIFY_PASSWORD_SUCCESS,
  payload: {
    ...data
  }
});

const verifyPasswordLinkFailure = error => ({
  type: PasswordConstants.VERIFY_PASSWORD_FAILURE,
  payload: {
    error
  }
});


export function resetPassword(data) {
  return dispatch => {
    dispatch(resetPasswordRequested());
    trackPromise(
      ApiService.callResetPassword(data).then(res => {
      dispatch(resetPasswordSuccess(res.data));
      if (res.data.status == "success") {
        
        history.push('/login')
        
      }
      dispatch(alertActions.success(res.data.message));
     
    }))
      .catch(err => {
        dispatch(resetPasswordFailure(err.message));
        showAlert(dispatch,err);

      });
  };
}

const resetPasswordRequested = () => ({
  type: PasswordConstants.RESET_PASSWORD_REQUEST
});

const resetPasswordSuccess = data => ({
  type: PasswordConstants.RESET_PASSWORD_SUCCESS,
  payload: {
    ...data
  }
});

const resetPasswordFailure = error => ({
  type: PasswordConstants.RESET_PASSWORD_FAILURE,
  payload: {
    error
  }
});

