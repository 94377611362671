import { ApiService } from '../../services/api.service';
import * as Policy from './PolicyConstants';
import { showAlert } from '../alert/showAlert';
import { trackPromise } from 'react-promise-tracker';

// Policy Listing Actions

export function listPolicies(data) {
  return dispatch => {
    dispatch(listPoliciesRequested());
    trackPromise(
      ApiService.callListPolicies(data).then(res => {
        dispatch(listPoliciesSuccess(res.data));
      }))
      .catch(err => {
        dispatch(listPoliciesFailure(err.message));
        showAlert(dispatch, err);

      });
  };
}


const listPoliciesRequested = () => ({
  type: Policy.LIST_POLICIES_REQUEST
});

const listPoliciesSuccess = data => ({
  type: Policy.LIST_POLICIES_SUCCESS,
  payload: {
    ...data
  }
});

const listPoliciesFailure = error => ({
  type: Policy.LIST_POLICIES_FAILURE,
  payload: {
    error
  }
});


//Archive listing for policies
export function listPolicyArchives(data) {
  return dispatch => {
    dispatch(listPolicyArchivesRequested());
    trackPromise(
      ApiService.callListPolicyArchives(data).then(res => {
        dispatch(listPolicyArchivesSuccess(res.data));
      }))
      .catch(err => {
        dispatch(listPolicyArchivesFailure(err.message));
        showAlert(dispatch, err);

      });
  };
}


const listPolicyArchivesRequested = () => ({
  type: Policy.LIST_POLICY_ARCHIVE_REQUEST
});

const listPolicyArchivesSuccess = data => ({
  type: Policy.LIST_POLICY_ARCHIVE_SUCCESS,
  payload: {
    ...data
  }
});

const listPolicyArchivesFailure = error => ({
  type: Policy.LIST_POLICY_ARCHIVE_FAILURE,
  payload: {
    error
  }
});


//Reset

export function resetPolicyDetails() {
  return { type: Policy.RESET_POLICY_DETAILS };
}