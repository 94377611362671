// FLOWCHART LISTING
export const LIST_FLOWCHARTS_REQUEST = 'LIST_FLOWCHARTS_REQUEST';
export const LIST_FLOWCHARTS_SUCCESS = 'LIST_FLOWCHARTS_SUCCESS';
export const LIST_FLOWCHARTS_FAILURE = 'LIST_FLOWCHARTS_FAILURE';

// GET FLOWCHART FOR CUSTOMER
export const GET_FLOWCHART_FOR_CUSTOMER_REQUEST = 'GET_FLOWCHART_FOR_CUSTOMER_REQUEST';
export const GET_FLOWCHART_FOR_CUSTOMER_SUCCESS = 'GET_FLOWCHART_FOR_CUSTOMER_SUCCESS';
export const GET_FLOWCHART_FOR_CUSTOMER_FAILURE = 'GET_FLOWCHART_FOR_CUSTOMER_FAILURE';

//LIST ARCHIVES
export const LIST_FLOWCHART_ARCHIVE_REQUEST = 'LIST_FLOWCHART_ARCHIVE_REQUEST';
export const LIST_FLOWCHART_ARCHIVE_SUCCESS = 'LIST_FLOWCHART_ARCHIVE_SUCCESS';
export const LIST_FLOWCHART_ARCHIVE_FAILURE = 'LIST_FLOWCHART_ARCHIVE_FAILURE';


//RESET
export const RESET_FLOWCHART_DETAILS = 'RESET_FLOWCHART_DETAILS';

