import React, { useState } from 'react';
import { changePassword } from './PasswordActions';
import { useDispatch } from 'react-redux';

const ChangePassword = () => {
  const [inputs, setPasswords] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const { oldPassword, newPassword, confirmPassword } = inputs;
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setError] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswords(inputs => ({ ...inputs, [name]: value }));
  }

  const showPassword = (type) => {
    if (type == "new") {
      setShowNewPassword(!showNewPassword)

    }
    else if (type == "old") {
      setShowOldPassword(!showOldPassword)
    }
    else {
      setShowConfirmPassword(!showConfirmPassword)

    }
  }

  const isPasswordValid = () => {
    var isFormValid = true;
    var errors = ""

    const value = inputs.newPassword;

    const hasNumber = value => {
      return new RegExp(/[0-9]/).test(value);
    }
    if (!hasNumber(value)) {
      isFormValid = false;
      errors = "The password should contain atleast 1 number";
    }
    const hasMixed = value => {
      return new RegExp(/[a-z]/).test(value) &&
        new RegExp(/[A-Z]/).test(value);
    }
    if (!hasMixed(value)) {
      isFormValid = false;
      errors = "The password should have atleast 1 uppercase and 1 lowercase letter";
    }

    const hasSpecial = value => {
      return new RegExp(/[!#@$%^&*)(+=._-]/).test(value);
    }

    if (!hasSpecial(value)) {
      isFormValid = false;
      errors = "The password should have atleast 1 special character";
    }

    const hasMinCharacters = value => {
      return (value.length >= 8);
    }
    if (!hasMinCharacters(value)) {
      isFormValid = false;
      errors = "The password length should be mininum of 8 characters";
    }

    setError(errors)
    return isFormValid;

  }

  const handleSubmit = (e) => {
    setSubmitted(true);

    if (isPasswordValid()) {
      if (oldPassword && newPassword && confirmPassword && newPassword == confirmPassword) {
        const data = {
          "oldPassword": oldPassword,
          "newPassword": newPassword,
        }

        dispatch(changePassword(data));
      }

    }

  }

  return (
    <div class="outer-wrap">

      <div class="container-wrap">
        <div class="card p-2">
          <div class="card-body">

            <form class="user">
              <h1 class="mh-head-txt text-center mb-5 pb-2">Change password</h1>
              <div class="group">
                <div class="d-flex align-items-center">
                  <span class="txt-fld">
                    <input type={showOldPassword == false ? "password" : "text"} name="oldPassword" value={oldPassword} onChange={handleChange} className={(submitted && !oldPassword ? 'brd-4 form-control is-invalid' : '')} required="required" />
                    <span class="highlight"></span>
                    <label>Old Password</label>
                    {submitted && !oldPassword &&
                      <div className="invalid-feedback">Password is required</div>
                    }
                  </span>
                  <span className={submitted && !oldPassword ? "icn-pstn-invalid" : "icn-pstn"} onClick={() => showPassword("old")}>
                    <i class={showOldPassword == false ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                  </span>
                </div>
              </div>
              <div class="group">
                <div class="d-flex align-items-center">
                  <span class="txt-fld">
                    <input type={showNewPassword == false ? "password" : "text"} name="newPassword" value={newPassword} onChange={handleChange} onBlur={isPasswordValid} className={((submitted && !newPassword) || errorMessage.length > 0 ? 'brd-4 form-control is-invalid' : '')} required="required" />
                    <span class="highlight"></span>
                    <label>New Password</label>
                    {submitted && !newPassword &&
                      <div className="invalid-feedback">Password is required</div>
                    }
                    {newPassword && errorMessage.length > 0 && (
                      <div className="invalid-feedback">{errorMessage}</div>
                    )}
                  </span>
                  <span className={(submitted && !newPassword) || errorMessage.length > 0 ? "icn-pstn-invalid" : "icn-pstn"} onClick={() => showPassword("new")}>
                    <i class={showNewPassword == false ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                  </span>
                </div>
              </div>
              <div class="group">
                <div class="d-flex align-items-center">
                  <span class="txt-fld">
                    <input type={showConfirmPassword == false ? "password" : "text"} name="confirmPassword" value={confirmPassword} onChange={handleChange} className={(submitted && (!confirmPassword || confirmPassword != newPassword) ? 'brd-4 form-control is-invalid' : '')} required="required" />
                    <span class="highlight"></span>
                    <label>Re-enter Password</label>
                    {submitted && !confirmPassword &&
                      <div className="invalid-feedback">Password is required</div>
                    }
                    {submitted && confirmPassword && (confirmPassword != newPassword) &&
                      <div className="invalid-feedback">Passwords doesn't match</div>
                    }
                  </span>
                  <span className={(submitted && (!confirmPassword || confirmPassword != newPassword)) ? "icn-pstn-invalid" : "icn-pstn"} onClick={() => showPassword("confirm")}>
                    <i class={showConfirmPassword == false ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                  </span>
                </div>
              </div>

              <a onClick={handleSubmit} class="brd-4 btn btn-primary btn-user btn-block mt-5 a-cancel">
                Change password
                </a>




            </form>



          </div>
        </div>
      </div>
    </div>

  );
}

export default (ChangePassword);










