// ADD TICKETS
export const ADD_JOB_TICKET_REQUEST = 'ADD_JOB_TICKET_REQUEST';
export const ADD_JOB_TICKET_SUCCESS = 'ADD_JOB_TICKET_SUCCESS';
export const ADD_JOB_TICKET_FAILURE = 'ADD_JOB_TICKET_FAILURE';

// CATEGORY LISTING
export const LIST_CATEGORY_REQUEST = 'LIST_CATEGORY_REQUEST';
export const LIST_CATEGORY_SUCCESS = 'LIST_CATEGORY_SUCCESS';
export const LIST_CATEGORY_FAILURE = 'LIST_CATEGORY_FAILURE';

// TICKETS LISTING
export const LIST_TICKETS_REQUEST = 'LIST_TICKETS_REQUEST';
export const LIST_TICKETS_SUCCESS = 'LIST_TICKETS_SUCCESS';
export const LIST_TICKETS_FAILURE = 'LIST_TICKETS_FAILURE';

// UPDATE TICKETS
export const UPDATE_JOB_TICKET_REQUEST = 'UPDATE_JOB_TICKET_REQUEST';
export const UPDATE_JOB_TICKET_SUCCESS = 'UPDATE_JOB_TICKET_SUCCESS';
export const UPDATE_JOB_TICKET_FAILURE = 'UPDATE_JOB_TICKET_FAILURE';

// GET TICKET DETAILS
export const GET_TICKET_DETAILS_REQUEST = 'GET_TICKET_DETAILS_REQUEST';
export const GET_TICKET_DETAILS_SUCCESS = 'GET_TICKET_DETAILS_SUCCESS';
export const GET_TICKET_DETAILS_FAILURE = 'GET_TICKET_DETAILS_FAILURE';

//RESET
export const RESET_TICKET_DETAILS = 'RESET_TICKET_DETAILS';
