import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";
import { history } from "../../../helpers/history";
import { useDispatch, useSelector } from "react-redux";
import {
  listProcedures,
  listProcedureArchives,
  resetProcedureDetails,
} from "../Procedure/ProcedureActions";
import { getNotification } from "../../components/header/notificationActions";
import Pagination from "../../components/Pagination/Pagination";
import "./Procedure.css";
import $ from "jquery";
import { PdfGeneration } from "../../pages/pdfGeneration/pdfActions";
import { CustomerId } from "src/app/config";
import SearchBox from "src/app/components/SearchBox";

const Procedures = () => {
  const [isAdmin] = useState(localStorage.getItem("isAdmin"));
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const dispatch = useDispatch();
  const [sortParams, setSortParams] = useState({
    sortBy: "ASC",
    sortTitle: "procedureRefId",
  });

  const [showArchives, setShowArchives] = useState(false);

  const procedureList = useSelector((state) => state.procedureData);
  const procedureListData =
    procedureList &&
    procedureList.proceduresList &&
    procedureList.proceduresList.data;
  const totalCount =
    procedureList &&
    procedureList.proceduresList &&
    procedureList.proceduresList.procedureCount;
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    window.onbeforeunload = null;
    // Listing Procedures
    getProcedureList();
    getNotificationsList();
  }, [searchValue]);

  const generatePdf = (id) => {
    const data = {
      type: "procedure",
      id: id,
    };
    dispatch(PdfGeneration(data));
  };

  const getNotificationsList = () => {
    const data = {
      numPerPage: 10,
      page: 1,
      customerId: CustomerId,

      userId: localStorage.getItem("userId"),
    };
    dispatch(getNotification(data));
  };

  const getProcedureList = (page = 1, pageSize = 100) => {
    const data = {
      numPerPage: pageSize,
      page: page == 0 ? 1 : page,
      sortKey: sortParams.sortTitle,
      sortType: sortParams.sortBy,
      searchKey: searchValue,
    };
    dispatch(listProcedures(data));
  };

  const onPageChanged = (data) => {
    if (data && data.currentPage == currentPageNumber) {
      return;
    }
    if (data && data.currentPage) {
      const { currentPage } = data;
      setCurrentPageNumber(currentPage);
      if (showArchives == false) {
        getProcedureList(currentPage);
      } else if (showArchives == true) {
        getArchivesList(currentPage);
      }
    }
  };

  const goToEditProcedure = (data) => {
    history.push("/home/addJobTicket", {
      type: 4,
      id: data.id,
      jobId: data.Procedure.Ticket.id,
    });
  };

  const sortTable = (title) => {
    var sortByOrder = "ASC";

    if (title == sortParams.sortTitle) {
      sortByOrder = sortParams.sortBy == "DESC" ? "ASC" : "DESC";
      setSortParams({ sortBy: sortByOrder, sortTitle: title });
    } else {
      setSortParams({ sortBy: "ASC", sortTitle: title });
    }

    // Call Api here for sorting
    const data = {
      numPerPage: 100,
      page: currentPageNumber,
      sortKey: title,
      sortType: sortByOrder,
      searchKey: searchValue,
    };

    if (showArchives == false) {
      dispatch(listProcedures(data));
    } else if (showArchives == true) {
      dispatch(listProcedureArchives(data));
    }
  };

  const copyToClipboard = (data) => {
    const url = data;
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(url).select();
    document.execCommand("copy");
    $temp.remove();
    window.open(url, "_blank", "noreferrer");
  };

  const goToCreateProcedure = () => {
    history.push("/home/addJobTicket", { type: 4 });
  };

  const getArchivesList = (page = 1, pageSize = 100) => {
    const data = {
      numPerPage: pageSize,
      page: page == 0 ? 1 : page,
      sortKey: sortParams.sortTitle,
      sortType: sortParams.sortBy,
      searchKey: "",
    };
    dispatch(listProcedureArchives(data));
  };

  const goToArchives = () => {
    dispatch(resetProcedureDetails());
    getArchivesList();
    setShowArchives(true);
  };

  const goToProcedures = () => {
    dispatch(resetProcedureDetails());
    getProcedureList();
    setShowArchives(false);
  };
  const handleSearch = (value) => {
    setSearchValue(value);
  };
  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="st-tl">
          <h1 className="mh-head-txt pt-3">
            {showArchives == false ? "Procedures" : "Archives"}
          </h1>
        </div>
        {showArchives == false && isAdmin == "false" && (
          <div className="aad-usr pt-3">
            <Icon.PlusCircle />
            <a className="a-removed-href-create" onClick={goToCreateProcedure}>
              Create new Job Ticket for Procedure
            </a>
          </div>
        )}
      </div>
      <hr className="pb-3" />

      <div className="d-flex justify-content-between mt-5 row">
        <div class="input-group st-tl col-md-4">
          <SearchBox onSearch={handleSearch} />
        </div>
        <div className="aad-usr pt-3 pr-3">
          {showArchives == false ? (
            <div>
              {" "}
              <Icon.Calendar />
              <a className="a-removed-href-create" onClick={goToArchives}>
                Archives
              </a>
            </div>
          ) : (
            <a className="a-removed-href-create" onClick={goToProcedures}>
              Go back to Procedures
            </a>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="table-wraper table-responsive mnt mt-3">
            <table className="table job-list">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="mx-width"
                    onClick={() => {
                      sortTable("procedureRefId");
                    }}
                  >
                    <div class="d-flex justify-content-start">
                      <div>Doc ID</div>
                      <div class="sort-wrp ml-2">
                        <i
                          class={
                            sortParams.sortTitle == "procedureRefId" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-asc fa-lg active sort_up"
                              : "fa fa-sort-asc fa-lg sort_fade_down"
                          }
                        ></i>
                        <i
                          class={
                            sortParams.sortTitle == "procedureRefId" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-desc fa-lg sort_fade_down"
                              : sortParams.sortTitle == "procedureRefId" &&
                                sortParams.sortBy == "DESC"
                              ? "fa fa-sort-desc fa-lg active sort_up"
                              : "fa fa-sort-desc fa-lg sort_fade_down"
                          }
                        ></i>
                      </div>
                    </div>
                  </th>{" "}
                  <th
                    scope="col"
                    onClick={() => {
                      sortTable("procedureName");
                    }}
                  >
                    <div className="d-flex justify-content-start">
                      <div>Procedure Name</div>
                      <div className="sort-wrp ml-2">
                        <i
                          className={
                            sortParams.sortTitle == "procedureName" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-asc fa-lg active sort_up"
                              : "fa fa-sort-asc fa-lg sort_fade_down"
                          }
                        ></i>
                        <i
                          className={
                            sortParams.sortTitle == "procedureName" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-desc fa-lg sort_fade_down"
                              : sortParams.sortTitle == "procedureName" &&
                                sortParams.sortBy == "DESC"
                              ? "fa fa-sort-desc fa-lg active sort_up"
                              : "fa fa-sort-desc fa-lg sort_fade_down"
                          }
                        ></i>
                      </div>
                    </div>
                  </th>
                  <th
                    scope="col text-center"
                    onClick={() => {
                      sortTable("revision");
                    }}
                  >
                    <div class="d-flex justify-content-start">
                      <div>Revision</div>
                      <div class="sort-wrp ml-2">
                        <i
                          class={
                            sortParams.sortTitle == "revision" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-asc fa-lg active sort_up"
                              : "fa fa-sort-asc fa-lg sort_fade_down"
                          }
                        ></i>
                        <i
                          class={
                            sortParams.sortTitle == "revision" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-desc fa-lg sort_fade_down"
                              : sortParams.sortTitle == "revision" &&
                                sortParams.sortBy == "DESC"
                              ? "fa fa-sort-desc fa-lg active sort_up"
                              : "fa fa-sort-desc fa-lg sort_fade_down"
                          }
                        ></i>
                      </div>
                    </div>
                  </th>{" "}
                  <th
                    scope="col"
                    onClick={() => {
                      sortTable("updatedAt");
                    }}
                  >
                    <div className="d-flex justify-content-start">
                      <div>Date</div>
                      <div className="sort-wrp ml-2">
                        <i
                          className={
                            sortParams.sortTitle == "updatedAt" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-asc fa-lg active sort_up"
                              : "fa fa-sort-asc fa-lg sort_fade_down"
                          }
                        ></i>
                        <i
                          className={
                            sortParams.sortTitle == "updatedAt" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-desc fa-lg sort_fade_down"
                              : sortParams.sortTitle == "updatedAt" &&
                                sortParams.sortBy == "DESC"
                              ? "fa fa-sort-desc fa-lg active sort_up"
                              : "fa fa-sort-desc fa-lg sort_fade_down"
                          }
                        ></i>
                      </div>
                    </div>
                  </th>
                  <th className="text-center" scope="col">
                    Download
                  </th>
                  {showArchives == false && isAdmin == "false" && (
                    <th className="text-center" scope="col">
                      Share
                    </th>
                  )}
                  {showArchives == false && isAdmin == "false" && (
                    <th className="text-center" scope="col">
                      Change Request
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {procedureList &&
                  !procedureList.loading &&
                  procedureList.error && (
                    <span className="text-danger">
                      ERROR: {procedureList.error}
                    </span>
                  )}
                {procedureListData &&
                  procedureListData.map((procedure, index) => (
                    <tr>
                      <td className="mx-width">{procedure.procedureRefId}</td>
                      <td>{procedure.procedureName}</td>
                      <td className="text-center">{procedure.revision}</td>
                      <td>
                        {procedure.updatedAt
                          ? procedure.updatedAt.split("T")[0]
                          : "Not Approved"}{" "}
                      </td>
                      <td class="text-center">
                        {procedure.procedureType == "upload" ? (
                          <a
                            href={procedure.fileName}
                            className="a-removed-href"
                          >
                            <Icon.Download />
                          </a>
                        ) : (
                          <a
                            className="a-removed-href"
                            onClick={() => generatePdf(procedure.id)}
                          >
                            <Icon.Download />
                          </a>
                        )}
                      </td>
                      {showArchives == false && isAdmin == "false" && (
                        <td className="text-center">
                          <a
                            className="a-removed-href"
                            onClick={() => copyToClipboard(procedure.fileName)}
                          >
                            <Icon.Share />
                          </a>
                        </td>
                      )}
                      {showArchives == false && isAdmin == "false" && (
                        <td className="text-center">
                          <a
                            className="a-removed-href"
                            onClick={() => goToEditProcedure(procedure)}
                          >
                            Raise Ticket
                          </a>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
            {procedureList.proceduresList.procedureCount <= 0 && (
              <p className="text-center font-italic font-weight-bold">
                No data available!
              </p>
            )}
            {procedureList.proceduresList.procedureCount > 0 && (
              <div className="d-flex flex-row py-4 align-items-center float-left">
                Total Count: {procedureList.proceduresList.procedureCount}
              </div>
            )}

            <div className="d-flex flex-row py-4 align-items-center float-right">
              {procedureList.proceduresList.procedureCount > 0 && (
                <Pagination
                  totalRecords={totalCount}
                  pageLimit={100}
                  pageNeighbours={0}
                  onPageChanged={onPageChanged}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Procedures;
