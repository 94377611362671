import { ApiService } from '../../services/api.service';
import { history } from '../../../helpers/history';
import { FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAILURE } from './forgotPasswordConstants';
import {alertActions} from '../alert/alertActions';
import { showAlert } from '../alert/showAlert';

export function forgotPassword(data) {
  return dispatch => {
    dispatch(forgotPasswordRequested());
    ApiService.callForgotPassword(data).then(res => {
      dispatch(forgotPasswordSuccess(res.data));
      if (res.data.status == "success") {
        
        history.push('/login')
        
      }
      dispatch(alertActions.success(res.data.message));
     
    })
      .catch(err => {
        dispatch(forgotPasswordFailure(err.message));
        showAlert(dispatch,err);

      });
  };
}

const forgotPasswordRequested = () => ({
  type: FORGOT_PASSWORD_REQUEST
});

const forgotPasswordSuccess = data => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: {
    ...data
  }
});

const forgotPasswordFailure = error => ({
  type: FORGOT_PASSWORD_FAILURE,
  payload: {
    error
  }
});
