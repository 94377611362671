import React, { useEffect, useState } from "react";
import "./externalLiveTemp.css";
import Cookies from "js-cookie";
import { history } from "../../../helpers/history";
import { brandLogo } from "src/app/config";

import { getExternalLiveTempOtpVerification } from "./externalLiveTempUserActions";
import { useDispatch } from "react-redux";

const ExternalTempUserLogin = () => {
  const dispatch = useDispatch();
  const [otpValue, setOtpValue] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSignIn = () => {
    setLoading(true);

    const externalTempUserToken = Cookies.get("externalTempUserToken");
    if (externalTempUserToken) {
      const data = {
        urlToken: externalTempUserToken,
        otp: otpValue,
      };
      dispatch(getExternalLiveTempOtpVerification(data))
        .then((res) => {
          if (res?.data?.status === "success") {
            Cookies.set("externalUserToken", res?.data?.data);
            history.push("/external-user-livetemplate");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const token = url.searchParams.get("token");
    Cookies.set("externalTempUserToken", token);
  }, []);

  return (
    <div className="external-temp-user-login-container">
      <div className="form-container">
        <div className="logo-container">
          <img className="logo-size" src={brandLogo} alt="Brand Logo" />
        </div>
        <div className="instruction-text">
          Enter the OTP you received via email to access the template.
        </div>
        <div className="otp-input-container">
          <input
            type="number"
            className="form-control letter-spacing-input"
            placeholder="Enter the OTP"
            value={otpValue}
            onChange={(e) => setOtpValue(e.target.value)}
          />
        </div>
        <div className="button-container">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSignIn}
            disabled={otpValue.length < 5}
            style={{
              width: "100%",
              cursor: otpValue.length > 4 ? "pointer" : "auto",
            }}
          >
            {loading ? (
              <div className="spinner-border text-primary" role="status"></div>
            ) : (
              "Sign in"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExternalTempUserLogin;
