import { ApiService } from "../../services/api.service";
import { showAlert } from "../alert/showAlert";
import { trackPromise } from "react-promise-tracker";
import { alertActions } from "../alert/alertActions";
import { history } from "../../../helpers/history";

// Create user
export function createUser(data, formData) {
  return (dispatch) => {
    // dispatch(createUserRequested());
    return trackPromise(
      ApiService.ServiceCallForCreateUser(data, formData).then((res) => {
        dispatch(alertActions.success(res.data.message));
        return res;
      })
    ).catch((err) => {
      showAlert(dispatch, err);
      return err;
    });
  };
}
// Update User
export function updateUser(data, userId) {
  return (dispatch) => {
    // dispatch(updateUserRequested());
    return trackPromise(
      ApiService.ServiceCallForUpdateUser(data, userId).then((res) => {
        dispatch(alertActions.success(res.data.message));
        return res;
      })
    ).catch((err) => {
      showAlert(dispatch, err);
      return err;
    });
  };
}

// Create Department
export function createDepartment(customerId, data) {
  return (dispatch) => {
    // dispatch(createUserRequested());
    return trackPromise(
      ApiService.ServiceCallForCreateDepartment(customerId, data).then(
        (res) => {
          // dispatch(createUserSuccess(res.data));

          dispatch(alertActions.success(res.data.message));
          return res;
        }
      )
    ).catch((err) => {
      // dispatch(createUserFailure(err.message));
      showAlert(dispatch, err);
      return err;
    });
  };
}

export function callEditDepartment(customerId, data) {
  return (dispatch) => {
    // dispatch(createUserRequested());
    return trackPromise(
      ApiService.ServiceCallForEditDepartment(customerId, data).then((res) => {
        // dispatch(createUserSuccess(res.data));

        dispatch(alertActions.success(res.data.message));
        return res;
      })
    ).catch((err) => {
      // dispatch(createUserFailure(err.message));
      showAlert(dispatch, err);
      return err;
    });
  };
}

export function callDeleteUser(data) {
  return (dispatch) => {
    // dispatch(createUserRequested());
    return trackPromise(
      ApiService.callForDeleteUser(data).then((res) => {
        // dispatch(createUserSuccess(res.data));
        if (res.data.status === "success") {
          dispatch(alertActions.success(res.data.message));
        } else {
          dispatch(alertActions.error(res.data.message));
        }
        return res;
      })
    ).catch((err) => {
      // dispatch(createUserFailure(err.message));
      showAlert(dispatch, err);
      return err;
    });
  };
}
export function callForListCustomerDepartments() {
  return (dispatch) => {
    return trackPromise(
      ApiService.ServiceCallForListCustomerDepartments().then((res) => {
        return res;
      })
    ).catch((err) => {
      showAlert(dispatch, err);
      return err;
    });
  };
}
// List customer
export function listUser(data) {
  return (dispatch) => {
    return trackPromise(
      ApiService.ServiceCallForListingUser(data).then((res) => {
        return res;
      })
    ).catch((err) => {
      showAlert(dispatch, err);
      return err;
    });
  };
}

//list roles
export function callListRoles(data) {
  return (dispatch) => {
    return trackPromise(
      ApiService.callListRoles(data).then((res) => {
        return res;
      })
    ).catch((err) => {
      showAlert(dispatch, err);
      return err;
    });
  };
}

//list roles
export function callListProjects(CustomerId) {
  return (dispatch) => {
    return trackPromise(
      ApiService.callListProjects(CustomerId).then((res) => {
        return res;
      })
    ).catch((err) => {
      showAlert(dispatch, err);
      return err;
    });
  };
}
