import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";
import { history } from "../../../helpers/history";
import { useDispatch } from "react-redux";

import { getNotification } from "../../components/header/notificationActions";
import Pagination from "../../components/Pagination/Pagination";
import { getListLiveTemplates } from "./TemplateActions";
import { showAlert } from "../alert/showAlert";
import { format } from "date-fns";
import Cookies from "js-cookie";
import { callForListCustomerDepartments } from "../userManagement/actions";
import SearchBox from "src/app/components/SearchBox";

const LiveTemplatesList = () => {
  const [isAdmin] = useState(localStorage.getItem("isAdmin"));
  const [selectedTemplate, setselectedTemplate] = useState("");
  const [LiveTemplateList, setLiveTemplateList] = useState();
  const [liveTemplatesData, setLiveTemplatesData] = useState();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [departmentList, setDepartmentList] = useState([]);
  const dispatch = useDispatch();
  const [sortParams, setSortParams] = useState({
    sortBy: "ASC",
    sortTitle: "liveTemplateRefId",
  });
  const [searchValue, setSearchValue] = useState("");

  const userRole = decodeURIComponent(Cookies.get("userRole"));
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(callForListCustomerDepartments()).then((res) => {
      if (res?.data?.data) {
        const newDepartmentOptions = res?.data?.data?.map((item) => ({
          ["value"]:
            userRole === "Prime Admin" ? item?.id : item?.Department?.id,
          ["label"]:
            userRole === "Prime Admin"
              ? item?.departmentName
              : item?.Department?.departmentName,
        }));
        if (newDepartmentOptions) {
          setDepartmentList(() => [...newDepartmentOptions]);
        }
      } else {
        return null;
      }
    });
  }, []);

  useEffect(() => {
    window.onbeforeunload = null;
    // Listing Templates
    getLiveTemplateList();
    getNotificationsList();
  }, [searchValue, selectedDepartment]);

  const getNotificationsList = () => {
    const data = {
      numPerPage: 10,
      page: 1,
      userId: localStorage.getItem("userId"),
    };
    dispatch(getNotification(data));
  };

  const getLiveTemplateList = (page = 1, pageSize = 100) => {
    const data = {
      numPerPage: pageSize,
      page: page == 0 ? 1 : page,
      userId: localStorage.getItem("userId"),
      sortKey: sortParams.sortTitle,
      sortType: sortParams.sortBy,
      searchKey: searchValue,
      departments: selectedDepartment ? selectedDepartment : "",
    };

    dispatch(getListLiveTemplates(data))
      .then((res) => {
        setLiveTemplatesData(res?.data);
        setLiveTemplateList(res?.data?.data);
      })
      .catch((err) => {
        showAlert(dispatch, err);
      });
  };

  const onPageChanged = (data) => {
    if (data && data.currentPage == currentPageNumber) {
      return;
    }
    if (data && data.currentPage) {
      const { currentPage } = data;
      setCurrentPageNumber(currentPage);
      getLiveTemplateList(currentPage);
    }
  };

  const goToEditTemplate = (tempId) => {
    history.push(`/home/editLiveTemplate/${tempId}`);
  };

  const deleteSelectedTemplate = () => {
    // dispatch(deleteTemplate(selectedTemplate.id));
  };

  const sortTable = (title) => {
    var sortByOrder = "ASC";

    if (title == sortParams.sortTitle) {
      sortByOrder = sortParams.sortBy == "DESC" ? "ASC" : "DESC";
      setSortParams({ sortBy: sortByOrder, sortTitle: title });
    } else {
      setSortParams({ sortBy: "ASC", sortTitle: title });
    }

    // Call Api here for sorting
    const data = {
      numPerPage: 100,
      page: currentPageNumber,
      // userId: localStorage.getItem("userId"),
      sortKey: title,
      sortType: sortByOrder,
      searchKey: searchValue,
      departments: selectedDepartment ? selectedDepartment : "",
    };
    dispatch(getListLiveTemplates(data))
      .then((res) => {
        setLiveTemplatesData(res?.data);
        setLiveTemplateList(res?.data?.data);
      })
      .catch((err) => {
        showAlert(dispatch, err);
      });
  };
  const goToCreateJobTicket = () => {
    history.push("/home/addJobTicket", { type: 9 });
  };
  const goToRaiseTicket = (data) => {
    history.push("/home/addJobTicket", {
      type: 9,
      id: data?.id,
      jobId: data?.ticketId,
    });
  };

  const handleSelectDepartmentChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue) {
      setSelectedDepartment([Number(selectedValue)]);
    } else {
      setSelectedDepartment("");
    }
  };
  const handleSearch = (value) => {
    setSearchValue(value);
  };
  return (
    <div>
      <div>
        <div className="d-flex justify-content-between">
          <div className="st-tl">
            <h1 className="mh-head-txt pt-3">Live Templates</h1>
          </div>
          {isAdmin == "false" && (
            <div className="aad-usr pt-3">
              <Icon.PlusCircle />
              <a
                className="a-removed-href-create"
                onClick={goToCreateJobTicket}
              >
                Create new Job Ticket for Live Template
              </a>
            </div>
          )}
        </div>
        <hr className="pb-3" />
        <div class="d-flex justify-content-between mt-3 row">
          <div class="input-group st-tl col-md-4">
            <SearchBox onSearch={handleSearch} />
          </div>
          <div class=" col-md-8" style={{ textAlign: "end" }}>
            <select
              className="form-select form-select-lg p-2 pr-3"
              aria-label=".form-select-lg example"
              style={{ borderRadius: "5px" }}
              value={selectedDepartment}
              onChange={handleSelectDepartmentChange}
            >
              <option value="" selected>
                All Departments
              </option>
              {departmentList?.map((option) => (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="table-wraper table-responsive mnt mt-3">
              <table className="table job-list">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="mx-width"
                      onClick={() => {
                        sortTable("liveTemplateRefId");
                      }}
                    >
                      <div class="d-flex justify-content-start">
                        <div>Doc ID</div>
                        <div class="sort-wrp ml-2">
                          <i
                            class={
                              sortParams.sortTitle == "liveTemplateRefId" &&
                              sortParams.sortBy == "ASC"
                                ? "fa fa-sort-asc fa-lg active sort_up"
                                : "fa fa-sort-asc fa-lg sort_fade_down"
                            }
                          ></i>
                          <i
                            class={
                              sortParams.sortTitle == "liveTemplateRefId" &&
                              sortParams.sortBy == "ASC"
                                ? "fa fa-sort-desc fa-lg sort_fade_down"
                                : sortParams.sortTitle == "liveTemplateRefId" &&
                                  sortParams.sortBy == "DESC"
                                ? "fa fa-sort-desc fa-lg active sort_up"
                                : "fa fa-sort-desc fa-lg sort_fade_down"
                            }
                          ></i>
                        </div>
                      </div>
                    </th>{" "}
                    <th
                      scope="col"
                      onClick={() => {
                        sortTable("liveTemplateName");
                      }}
                    >
                      <div class="d-flex justify-content-start">
                        <div>Template Name</div>
                        <div class="sort-wrp ml-2">
                          <i
                            class={
                              sortParams.sortTitle == "liveTemplateName" &&
                              sortParams.sortBy == "ASC"
                                ? "fa fa-sort-asc fa-lg active sort_up"
                                : "fa fa-sort-asc fa-lg sort_fade_down"
                            }
                          ></i>
                          <i
                            class={
                              sortParams.sortTitle == "liveTemplateName" &&
                              sortParams.sortBy == "ASC"
                                ? "fa fa-sort-desc fa-lg sort_fade_down"
                                : sortParams.sortTitle == "liveTemplateName" &&
                                  sortParams.sortBy == "DESC"
                                ? "fa fa-sort-desc fa-lg active sort_up"
                                : "fa fa-sort-desc fa-lg sort_fade_down"
                            }
                          ></i>
                        </div>
                      </div>
                    </th>
                    <th
                      scope="col"
                      onClick={() => {
                        sortTable("revision");
                      }}
                    >
                      <div class="d-flex justify-content-start">
                        <div>Revision</div>
                        <div class="sort-wrp ml-2">
                          <i
                            class={
                              sortParams.sortTitle == "revision" &&
                              sortParams.sortBy == "ASC"
                                ? "fa fa-sort-asc fa-lg active sort_up"
                                : "fa fa-sort-asc fa-lg sort_fade_down"
                            }
                          ></i>
                          <i
                            class={
                              sortParams.sortTitle == "revision" &&
                              sortParams.sortBy == "ASC"
                                ? "fa fa-sort-desc fa-lg sort_fade_down"
                                : sortParams.sortTitle == "revision" &&
                                  sortParams.sortBy == "DESC"
                                ? "fa fa-sort-desc fa-lg active sort_up"
                                : "fa fa-sort-desc fa-lg sort_fade_down"
                            }
                          ></i>
                        </div>
                      </div>
                    </th>
                    <th
                      scope="col"
                      onClick={() => {
                        sortTable("updatedAt");
                      }}
                    >
                      <div class="d-flex justify-content-start">
                        <div>Date</div>
                        <div class="sort-wrp ml-2">
                          <i
                            class={
                              sortParams.sortTitle == "updatedAt" &&
                              sortParams.sortBy == "ASC"
                                ? "fa fa-sort-asc fa-lg active sort_up"
                                : "fa fa-sort-asc fa-lg sort_fade_down"
                            }
                          ></i>
                          <i
                            class={
                              sortParams.sortTitle == "updatedAt" &&
                              sortParams.sortBy == "ASC"
                                ? "fa fa-sort-desc fa-lg sort_fade_down"
                                : sortParams.sortTitle == "updatedAt" &&
                                  sortParams.sortBy == "DESC"
                                ? "fa fa-sort-desc fa-lg active sort_up"
                                : "fa fa-sort-desc fa-lg sort_fade_down"
                            }
                          ></i>
                        </div>
                      </div>
                    </th>
                    {userRole !== "User" && (
                      <th class="text-center" scope="col">
                        New Record
                      </th>
                    )}
                    {isAdmin == "false" && (
                      <th className="text-center" scope="col">
                        Change Request
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {LiveTemplateList?.length > 0 &&
                    LiveTemplateList?.map((template, index) => (
                      <React.Fragment key={index + 1}>
                        <tr>
                          <td className="mx-width">
                            {template.liveTemplateRefId}
                          </td>
                          <td>{template.liveTemplateName}</td>
                          <td>{template?.revision}</td>
                          <td className="mn-width">
                            {" "}
                            {template?.updatedAt
                              ? format(
                                  new Date(template?.updatedAt),
                                  "yyyy-MM-dd : hh.mm aa"
                                )
                              : "-"}
                          </td>

                          {userRole !== "User" && (
                            <td class="text-center">
                              <a
                                className="a-removed-href"
                                onClick={() => {
                                  goToEditTemplate(template?.id);
                                }}
                              >
                                <Icon.Edit />
                              </a>
                            </td>
                          )}
                          {isAdmin == "false" && (
                            <td className="text-center" scope="col">
                              <a
                                className="a-removed-href"
                                onClick={() => goToRaiseTicket(template)}
                              >
                                Raise Ticket
                              </a>
                            </td>
                          )}
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
              {LiveTemplateList?.length <= 0 && (
                <p class="text-center font-italic font-weight-bold">
                  No data available!
                </p>
              )}
              <div className="d-flex flex-row py-4 align-items-center float-right">
                {liveTemplatesData?.templateCount > 0 && (
                  <Pagination
                    totalRecords={liveTemplatesData?.templateCount}
                    pageLimit={100}
                    pageNeighbours={0}
                    onPageChanged={onPageChanged}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="deleteConfirmationModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Digiplatform
                </h5>
                <button
                  className="close"
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                Are you sure you want to delete the template{" "}
                {selectedTemplate.templateName}?
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary"
                  type="button"
                  data-dismiss="modal"
                >
                  No
                </button>
                <a
                  className="btn btn-primary a-cancel"
                  data-dismiss="modal"
                  onClick={() => {
                    deleteSelectedTemplate();
                  }}
                >
                  Yes
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveTemplatesList;
