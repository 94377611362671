// BUSINESS ORGANISATION LISTING
export const LIST_BUSINESS_ORGANISATIONS_REQUEST = 'LIST_BUSINESS_ORGANISATIONS_REQUEST';
export const LIST_BUSINESS_ORGANISATIONS_SUCCESS = 'LIST_BUSINESS_ORGANISATIONS_SUCCESS';
export const LIST_BUSINESS_ORGANISATIONS_FAILURE = 'LIST_BUSINESS_ORGANISATIONS_FAILURE';

//LIST ARCHIVES
export const LIST_BUSINESS_ORGANISATION_ARCHIVE_REQUEST = 'LIST_BUSINESS_ORGANISATION_ARCHIVE_REQUEST';
export const LIST_BUSINESS_ORGANISATION_ARCHIVE_SUCCESS = 'LIST_BUSINESS_ORGANISATION_ARCHIVE_SUCCESS';
export const LIST_BUSINESS_ORGANISATION_ARCHIVE_FAILURE = 'LIST_BUSINESS_ORGANISATION_ARCHIVE_FAILURE';

//RESET
export const RESET_BUSINESS_ORG_DETAILS = 'RESET_BUSINESS_ORG_DETAILS';


