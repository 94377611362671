import React, { useState, useEffect, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import {
  getTemplateForCustomer,
  addTemplateData,
  callTemplateSequelNo,
} from "../OnlineTemplates/TemplateActions";
import { useLocation } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import * as Icon from "react-feather";
import $ from "jquery";
import { callListProjects } from "../userManagement/actions";
import Select from "react-select";
import { companyLogo } from "src/app/config";
import parse from "html-react-parser";
import { history } from "../../../helpers/history";

import ReplacingSignatureWithImg from "./ReplacingSignatureWithImg";
import useCalc from "../LiveRecords/useCalc";
import SelectHandlerForPDF from "src/app/components/SelectHandlerForPDF";
import usePdfDimensions from "../LiveRecords/usePdfDimensions";
import HandleCheckboxCheckedForPDF from "src/app/components/HandleCheckboxCheckedForPDF";
import Datepicker from "../LiveRecords/replacingComponents/Datepicker";
import HandleRadioCheckedForPDF from "src/app/components/HandleRadioCheckedForPDF";

const parseStyles = (styles) =>
  styles
    .split(";")
    .filter((style) => style.split(":")[0] && style.split(":")[1])
    .map((style) => [
      style
        .split(":")[0]
        .trim()
        .replace(/-./g, (c) => c.substr(1).toUpperCase()),
      style.split(":")[1].trim(),
    ])
    .reduce(
      (styleObj, style) => ({
        ...styleObj,
        [style[0]]: style[1],
      }),
      {}
    );
const replace = () => (domNode) => {
  if (
    domNode?.attribs?.placeholder &&
    domNode?.attribs?.placeholder?.includes("Date")
  ) {
    return <Datepicker attribs={domNode?.attribs} />;
  }
  if (domNode?.type === "tag" && domNode?.name === "textarea") {
    return (
      <TextField
        attribs={domNode?.attribs}
        defaultValue={domNode?.children?.[0]?.data}
      />
    );
  }
  if (domNode?.type === "tag" && domNode?.name === "input") {
    if (domNode.attribs?.type === "checkbox") {
      return <CheckboxField attribs={domNode?.attribs} />;
    }

    if (domNode?.attribs?.type === "radio") {
      return <RadioField attribs={domNode?.attribs} />;
    }
    if (domNode?.attribs?.type === "text") {
      return <InputField attribs={domNode?.attribs} />;
    }
  }

  if (domNode?.type === "tag" && domNode?.name === "select") {
    const options = domNode?.children.map(({ attribs, children }) => ({
      label: children?.[0]?.data,
      value: attribs.value,
    }));
    return <SelectField attribs={domNode?.attribs} options={options} />;
  }
};

const InputField = ({ attribs }) => {
  const [value, setValue] = useState(attribs?.value || "");

  return (
    <input
      type="text"
      {...attribs}
      required={attribs?.required === "" ? true : false}
      value={value}
      onChange={(e) => setValue(e.target.value || "")}
    />
  );
};

const TextField = ({ attribs, defaultValue }) => {
  const [value, setValue] = useState(defaultValue || "");
  const { style = "", ...rest } = attribs;

  return (
    <textarea
      {...rest}
      style={parseStyles(style)}
      value={value}
      onChange={(e) => setValue(e.target.value || "")}
    />
  );
};

const CheckboxField = ({ attribs }) => {
  const [value, setValue] = useState(
    attribs["data-checked"] === "true" || false
  );

  return (
    <input
      type="checkbox"
      {...attribs}
      data-checked={value}
      required={attribs?.required === "" ? true : false}
      checked={value}
      onChange={(e) => setValue(e.target.checked || false)}
    />
  );
};

const RadioField = ({ attribs }) => {
  const [radioValues, setRadioValues] = useState({});

  const checked = radioValues[attribs.name] === attribs.value;

  const radioOnChange = (name, value) => {
    setRadioValues((prevRadioValues) => {
      // Ensure only one radio button is selected for the same group
      const updatedValues = { ...prevRadioValues, [name]: value };
      return updatedValues;
    });
  };

  useEffect(() => {
    // Set the initial state based on the data-checked attribute
    if (attribs["data-checked"] === "true") {
      radioOnChange(attribs.name, attribs.value);
    }
  }, []); // Run this effect only once on mount

  return (
    <input
      type="radio"
      {...attribs}
      required={attribs?.required === "" ? true : false}
      checked={checked}
      data-checked={checked}
      onChange={(e) => radioOnChange(attribs?.name, e.target.value)}
    />
  );
};

const SelectField = ({ attribs, options }) => {
  const [value, setValue] = useState(
    attribs["data-value"] || attribs?.value || ""
  );

  return (
    <select
      {...attribs}
      required={attribs?.required === "" ? true : false}
      data-value={value}
      value={value}
      onChange={(e) => setValue(e.target.value || "")}
    >
      {options?.map((option) => (
        <option key={option?.value} value={option?.value}>
          {option?.label}
        </option>
      ))}
    </select>
  );
};

const Template = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const selectedProject = useRef();
  const finalSubmitted = useRef(false);
  const findNameFromEvent = useRef();
  const [projectList, setProjectList] = useState();

  const [showSignPad, setShowSignPad] = useState({
    pad0: false,
    pad1: false,
    pad2: false,
  });
  const sigRef1 = useRef();
  const sigRef2 = useRef();
  const sigRef3 = useRef();
  const htmlForPDF = useRef();
  const sequalNoRef = useRef();

  const onlineTempData = useSelector((state) => state.onlineTemplateData);
  const templateData = onlineTempData && onlineTempData.customerTemplate;

  const { margin, pageWidth, pdfType, pdfLayout } = usePdfDimensions(
    templateData.data
  );

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const templateId = search.get("templateId");
    const customerId = search.get("customerId");
    if (templateId != null) {
      dispatch(getTemplateForCustomer(templateId, customerId));
    }

    dispatch(callListProjects(customerId)).then((res) => {
      if (res?.data?.data) {
        const newProjectoptions = res?.data?.data?.map((item) => ({
          ["value"]: item?.id,
          ["label"]: item?.projectName,
        }));
        if (newProjectoptions) {
          setProjectList(() => [...newProjectoptions]);
        }
      } else {
        return null;
      }
    });
  }, []);

  useEffect(() => {
    if (templateData?.data?.templateId) {
      sequalNoSetFunction();
    }
  }, [templateData]);

  const sequalNoSetFunction = () => {
    dispatch(callTemplateSequelNo(templateData?.data?.templateId)).then(
      (res) => {
        sequalNoRef.current = res?.data?.data;
      }
    );
  };
  let signatureUrl1;
  let signatureUrl2;
  let signatureUrl3;

  const signImageUrlHandler = (sigRef, index) => {
    const trimmedCanvas = sigRef?.current?.getTrimmedCanvas();

    if (trimmedCanvas) {
      const newImageURL = trimmedCanvas.toDataURL("image/png");

      if (index === 0) {
        signatureUrl1 = newImageURL;
      } else if (index === 1) {
        signatureUrl2 = newImageURL;
      } else {
        signatureUrl3 = newImageURL;
      }
    } else {
      console.error(
        `Error: sigRef${index + 1} or sigRef${index + 1}.current is undefined.`
      );
    }
  };

  //template calculation
  useCalc(templateData);
  useEffect(() => {
    const sequalNoColumn = document.getElementsByName("sequential no")[0];

    if (sequalNoColumn) {
      sequalNoColumn.placeholder = "#Sequancial No:";
      sequalNoColumn.readOnly = true;
    }

    if (templateData && templateData.data && templateData.data.id) {
      addNeededItemsInFormTag();
    }
    if (templateData && templateData.data && templateData.data.id) {
      if (document.getElementsByClassName("sign-block")[0]) {
        const blockLength =
          document.getElementsByClassName("sign-block").length;

        var signData = [];
        var signBlock = [];
        signData[0] = document.getElementsByClassName("signature-pad")[0];
        signBlock[0] = document.getElementsByClassName("sign-block")[0];
        signData[1] = document.getElementsByClassName("signature-pad")[1];
        signBlock[1] = document.getElementsByClassName("sign-block")[1];
        signData[2] = document.getElementsByClassName("signature-pad")[2];
        signBlock[2] = document.getElementsByClassName("sign-block")[2];

        if (blockLength == 1) {
          setShowSignPad({ pad0: true, pad1: false, pad2: false });
        } else if (blockLength == 2) {
          setShowSignPad({ pad0: true, pad1: true, pad2: false });
        } else if (blockLength == 3) {
          setShowSignPad({ pad0: true, pad1: true, pad2: true });
        }

        for (let i = 0; i < blockLength; i++) {
          $(signBlock[i]).replaceWith($(signData[i]));
        }
      }
    }
  }, [templateData]);

  const submitForm = (event) => {
    window.scrollTo(0, 0);
    event.preventDefault();
    findNameFromEvent.current = event?.target.querySelector(".cst-btn")?.name;
    const openBtn = document.getElementById("modalOpen");
    if (openBtn.click) {
      openBtn.click();
    }
  };
  let recordName = "";
  let recordDescription = "";
  const handleFormsubmit = () => {
    convertDateFormat();
    const sequalNocolumn = document.getElementsByName("sequential no")[0];
    if (sequalNocolumn) {
      sequalNocolumn.defaultValue = sequalNoRef.current;
    }

    finalSubmitted.current = true;
    if (showSignPad.pad0) {
      signImageUrlHandler(sigRef1, 0);
    }
    if (showSignPad.pad1) {
      signImageUrlHandler(sigRef2, 1);
    }
    if (showSignPad.pad2) {
      signImageUrlHandler(sigRef3, 2);
    }
    if (showSignPad.pad0 && signatureUrl1)
      ReplacingSignatureWithImg("signature1", signatureUrl1);
    if (showSignPad.pad1 && signatureUrl2)
      ReplacingSignatureWithImg("signature2", signatureUrl2);
    if (showSignPad.pad2 && signatureUrl2)
      ReplacingSignatureWithImg("signature3", signatureUrl3);

    SelectHandlerForPDF();
    HandleCheckboxCheckedForPDF();
    HandleRadioCheckedForPDF();
    const recordDescriptionElement =
      document.getElementsByName("record description")[0];
    recordDescription =
      recordDescriptionElement?.value ||
      recordDescriptionElement?.textContent ||
      recordDescriptionElement?.innerText ||
      "No Description";

    recordName =
      document.getElementsByName("sequential no")[0]?.value ||
      document.getElementsByName("record name")[0]?.value ||
      document.getElementsByName("record name")[0]?.textContent ||
      document.getElementsByName("record name")[0]?.innerText ||
      "Template Data";
    htmlForPDF.current = document.getElementById("userinputform")?.outerHTML;
    const textareaData = document.querySelectorAll("textarea");
    const textareaLength = document.querySelectorAll("textarea").length;
    for (let i = 0; i < textareaLength; i++) {
      let a = document.createElement("text");
      let c = textareaData[i].className.split(" ");
      c.forEach((t) => {
        a.classList.add(t);
      });
      a.classList.add("rplc-txt-area-styl");

      if (textareaData[i]?.getAttribute("name")) {
        a.setAttribute("name", textareaData[i]?.getAttribute("name"));
      }

      let b = textareaData[i].value;
      a.innerHTML = b;
      textareaData[i].parentNode.replaceChild(a, textareaData[i]);
    }

    if (selectedProject.current) {
      const closeBtn = document.getElementById("modalclose");
      if (closeBtn?.click) {
        closeBtn.click();
      }
    }
    if (findNameFromEvent.current == "email") {
      const name = prompt("Please enter your email");
      if (name == "") {
        alert("Please enter an email");
      } else if (name != null) {
        convertTextArea(name);
      }
    } else {
      convertTextArea();
    }
  };

  const convertTextArea = (name) => {
    let formData = new FormData();
    var kvpairs = {
      data: [],
    };
    var form = document.getElementsByTagName("Form");
    for (var i = 0; i < form[0].elements.length; i++) {
      var e = form[0].elements[i];
      if (e.files != null && e.files != undefined) {
        formData.append("formFiles", e.files[0]);
      }
      kvpairs.data.push({
        type: decodeURI(e.type),
        name: decodeURI(e.name),
        value: decodeURI(e.value),
        file: decodeURI(e.files),
      });
    }

    formData.append("htmlData", htmlForPDF.current);
    formData.append("recordName", recordName);
    formData.append(
      "sequencialNo",
      document.getElementsByName("sequential no")[0]?.value || null
    );
    formData.append("projectId", selectedProject.current?.value);
    formData.append("isTestRecord", false);
    formData.append("pdfType", pdfType);
    formData.append("landscape", pdfLayout === "landscape" ? true : false);
    formData.append("recordDescription", recordDescription);
    formData.append("templateName", templateData.data.templateName); //append the values with key, value pair
    formData.append("id", templateData.data.id);
    formData.append("customerLogo", companyLogo);
    formData.append("keyValues", JSON.stringify(kvpairs));
    if (name) {
      formData.append("email", name);
    }

    dispatch(addTemplateData(formData));
  };
  const onchangeFile = (e) => {
    // console.log("====file====", e.target.files)
  };

  const addNeededItemsInFormTag = () => {
    // add onSubmit
    const formElement = document.getElementsByTagName("Form");
    if (formElement.length <= 0) {
      return;
    }
    const formTag = formElement.length > 0 ? formElement[0] : "";
    formTag.addEventListener("submit", submitForm);

    const elements = formTag.elements;
    for (var i = 0; i < elements.length; i++) {
      var element = elements[i];
      if (element.type === "file") {
        element.addEventListener("change", (e) => onchangeFile(e));
      }
    }
  };

  const clearPad1 = () => {
    sigRef1.current.clear();
  };
  const clearPad2 = () => {
    sigRef2.current.clear();
  };
  const clearPad3 = () => {
    sigRef3.current.clear();
  };

  const replaceFormTags = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    const formElements = doc.querySelectorAll("form");
    formElements.forEach((formElement) => {
      const replacement = doc.createElement("div");
      replacement.innerHTML = formElement.innerHTML;
      formElement.parentNode.replaceChild(replacement, formElement);
    });

    return doc.body.innerHTML;
  };

  const convertDateFormat = () => {
    var dateInputs = document.querySelectorAll('input[type="date"]');
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    dateInputs.forEach(function (input) {
      var dateValue = input.value;
      if (dateValue) {
        var parts = dateValue.split("-");
        var formattedDate =
          parts[2] + " " + months[parseInt(parts[1], 10) - 1] + ", " + parts[0];

        var newInput = document.createElement("input");

        newInput.type = "text";
        newInput.defaultValue = formattedDate;

        // Copy styles from the original input to the new input
        newInput.style.width = input.offsetWidth + "px";
        newInput.style.height = input.offsetHeight + "px";
        newInput.style.padding = window.getComputedStyle(input).padding;
        newInput.style.fontSize = window.getComputedStyle(input).fontSize;

        input.parentNode.replaceChild(newInput, input);
      }
    });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className={
            pdfType === "A3" && pdfLayout === "landscape"
              ? "col-md-8"
              : "col-md-8 mx-auto"
          }
        >
          {templateData.data && templateData.data.id && (
            <div
              class="container"
              id="userinputform"
              style={{
                width: pageWidth,
                padding: `${margin[0]} ${margin[1]} ${margin[2]} ${margin[3]}`,
                margin: "auto",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div class="child1 cst-frm">
                {ReactHtmlParser(replaceFormTags(templateData.data.headerData))}
                {parse(templateData.data.bodyData, {
                  replace: replace(),
                })}
                {Array.from({ length: 3 }, (_, index) => (
                  <div
                    key={`signature${index + 1}`}
                    class={`${
                      showSignPad[`pad${index}`]
                        ? "signature-pad"
                        : "signature-pad pad-pstn-hide"
                    }`}
                    id={`signature${index + 1}`}
                    style={{
                      display: showSignPad[`pad${index}`] ? "block" : "none",
                    }}
                  >
                    <SignatureCanvas
                      penColor="black"
                      backgroundColor="#ffffff"
                      canvasProps={{
                        width: 200,
                        height: 70,
                        className: "sigCanvas",
                      }}
                      ref={
                        index === 0 ? sigRef1 : index === 1 ? sigRef2 : sigRef3
                      }
                    />
                    <a
                      class="a-removed-href-create clr-size"
                      onClick={
                        index === 0
                          ? clearPad1
                          : index === 1
                          ? clearPad2
                          : clearPad3
                      }
                    >
                      <Icon.X className="x-size" />
                    </a>
                  </div>
                ))}
              </div>
              <div class="child2">
                {ReactHtmlParser(replaceFormTags(templateData.data.footerData))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="modal fade"
        id="modalOpenId"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Select Project
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Select
                name="projects"
                value={selectedProject.current}
                onChange={(newValue) => (selectedProject.current = newValue)}
                placeholder="Select Project"
                options={projectList}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    padding: "0.4rem",
                    border:
                      finalSubmitted.current &&
                      !selectedProject.current &&
                      "red 2px solid",
                  }),
                }}
              />
              {finalSubmitted.current && !selectedProject.current && (
                <p
                  style={{
                    color: "#e74a3b",
                    fontSize: "11px",
                    marginTop: "3px",
                  }}
                >
                  Select Project
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-dark"
                id="modalclose"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  selectedProject.current && handleFormsubmit();
                }}
              >
                {findNameFromEvent.current === "email"
                  ? "Share"
                  : "Save Template"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <button
        style={{ visibility: "hidden" }}
        data-toggle="modal"
        data-target="#modalOpenId"
        id="modalOpen"
      ></button>
    </div>
  );
};

export default Template;
