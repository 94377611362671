const GetColorForTicketDueDate = (dueDate, ticketStatus) => {
  const currentDate = new Date();
  const tomorrow = new Date(currentDate);
  tomorrow.setDate(currentDate.getDate() + 1);

  const date = new Date(dueDate);

  if (
    currentDate >= date &&
    ticketStatus !== "completed" &&
    ticketStatus !== "rejected"
  ) {
    return { color: "#FF8989", message: "Overdue" };
  } else if (
    currentDate < date &&
    date <= tomorrow &&
    ticketStatus !== "completed"
  ) {
    return { color: "#FFCD74", message: "Due soon" };
  } else if (date > tomorrow || ticketStatus === "completed") {
    return { color: "#8080804f", message: "" };
  }
  return { color: "#8080804f", message: "" };
};

export default GetColorForTicketDueDate;
