
// Constants associated for GET NOTIFICATIONS
export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';



// Constants associated for EDIT NOTIFICATIONS
export const VIEW_NOTIFICATIONS_REQUEST = 'VIEW_NOTIFICATIONS_REQUEST';
export const VIEW_NOTIFICATIONS_SUCCESS = 'VIEW_NOTIFICATIONS_SUCCESS';
export const VIEW_NOTIFICATIONS_FAILURE = 'VIEW_NOTIFICATIONS_FAILURE';

export const NOTIFICATION_CLEAR_DATA = 'NOTIFICATION_CLEAR_DATA';
