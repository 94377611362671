import { ApiService } from '../../services/api.service';
import * as notification from './notificationConstants';
import { showAlert } from '../../pages/alert/showAlert';

// Get Notification
export function getNotification(data) {
  return dispatch => {
    dispatch(getNotificationRequested());
    ApiService.callGetNotifications(data).then(res => {
      dispatch(getNotificationSuccess(res.data));
    })
      .catch(err => {
        dispatch(getNotificationFailure(err.message));
        showAlert(dispatch,err);

      });
  };
}

const getNotificationRequested = () => ({
  type: notification.GET_NOTIFICATIONS_REQUEST
});

const getNotificationSuccess = data => ({
  type: notification.GET_NOTIFICATIONS_SUCCESS,
  payload: {
    ...data
  }
});

const getNotificationFailure = error => ({
  type: notification.GET_NOTIFICATIONS_FAILURE,
  payload: {
    error
  }
});


// View notification
export function viewNotification(data, userId) {
  return dispatch => {
    dispatch(viewNotificationRequested());
    ApiService.callViewNotification(data, userId).then(res => {
      // dispatch(alertActions.success(res.data.message));
      dispatch(viewNotificationSuccess(res.data));
    })
      .catch(err => {
        dispatch(viewNotificationFailure(err.message));
        showAlert(dispatch,err);

      });
  };
}

const viewNotificationRequested = () => ({
  type: notification.VIEW_NOTIFICATIONS_REQUEST
});

const viewNotificationSuccess = data => ({
  type: notification.VIEW_NOTIFICATIONS_SUCCESS,
  payload: {
    ...data
  }
});

const viewNotificationFailure = error => ({
  type: notification.VIEW_NOTIFICATIONS_FAILURE,
  payload: {
    error
  }
});

export function resetNotification() {
  return dispatch => {
      dispatch(resetNotificationData());
  };
}

const resetNotificationData = () => ({
  type: notification.NOTIFICATION_CLEAR_DATA
});