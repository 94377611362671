import { useDispatch } from "react-redux";
import parse from "html-react-parser";
import { showAlert } from "../alert/showAlert";
import React, { useState, useEffect, useRef } from "react";
import { history } from "../../../helpers/history";
import { alertActions } from "../alert/alertActions";
import { brandLogo, companyLogo } from "src/app/config";
import { callLiveTemplateSequelNo } from "../liveTemplates/TemplateActions";
import SelectHandlerForPDF from "src/app/components/SelectHandlerForPDF";
import CheckboxField from "../LiveRecords/replacingComponents/CheckboxField";
import Datepicker from "../LiveRecords/replacingComponents/Datepicker";
import TextField from "../LiveRecords/replacingComponents/TextField";
import RadioField from "../LiveRecords/replacingComponents/RadioField";
import SelectField from "../LiveRecords/replacingComponents/SelectField";
import useCalc from "../LiveRecords/useCalc";
import usePdfDimensions from "../LiveRecords/usePdfDimensions";
import {
  callExternalLiveRecordFormSubmit,
  getExternalLiveRecordTemplate,
} from "./externalLiveTempUserActions";
import Cookies from "js-cookie";
import "./externalLiveTemp.css";
import ConvertDateFormatForPDF from "src/app/components/ConvertDateFormatForPDF";

const replace =
  ({ radioOnChange, radioValues }) =>
  (domNode) => {
    const { attribs, type, children } = domNode;

    switch (true) {
      case attribs?.placeholder && attribs.placeholder.includes("Date"):
        return <Datepicker attribs={attribs} />;
      case type === "tag" && domNode.name === "textarea":
        return (
          <TextField attribs={attribs} defaultValue={children?.[0]?.data} />
        );
      case type === "tag" && domNode.name === "input":
        if (attribs.type === "checkbox") {
          return <CheckboxField attribs={attribs} />;
        }
        if (attribs.type === "radio") {
          let checked = false;
          const radioGroupValue = radioValues[domNode.attribs.name];
          if (radioGroupValue) {
            checked = radioGroupValue === domNode.attribs.value;
          } else {
            checked = domNode.attribs["data-checked"] === "true";
          }
          return (
            <RadioField
              attribs={domNode.attribs}
              onChange={radioOnChange}
              checked={checked}
            />
          );
        }
        return null;
      case type === "tag" && domNode.name === "select":
        const options = domNode.children.map(({ attribs, children }) => ({
          label: children?.[0]?.data,
          value: attribs.value,
        }));
        return <SelectField attribs={attribs} options={options} />;
      default:
        return null;
    }
  };

const ExternalLiveRecord = () => {
  const dispatch = useDispatch();
  const [htmlData, setHtmlData] = useState("");
  const [liveTemplateData, setLiveTemplateData] = useState();
  const [radioValues, setRadioValues] = useState({});
  const saveHtmlForSend = useRef();
  const saveHtmlForPDF = useRef();
  const findNameFromEvent = useRef();
  let recordName = "";
  let recordDescription = "";
  let externalEmail = "";
  const externalToken = Cookies.get("externalUserToken");
  useEffect(() => {
    if (externalToken) {
      getTemplateDataApiCall();
    }
  }, [externalToken]);
  const getTemplateDataApiCall = () => {
    dispatch(getExternalLiveRecordTemplate(externalToken))
      .then((res) => {
        setLiveTemplateData(res?.data?.data);
        setHtmlData(res?.data?.data?.htmlData);
      })
      .catch((err) => {
        showAlert(dispatch, err);
      });
  };

  useCalc(htmlData);

  const { pdfType, pdfLayout } = usePdfDimensions(liveTemplateData);
  let sequantialNo;
  useEffect(() => {
    if (htmlData) {
      setTimeout(() => {
        addNeededItemsInFormTag();
        const sequalNoColumn = document.getElementsByName("sequential no")[0];
        if (sequalNoColumn) {
          sequalNoColumn.placeholder = "#Sequancial No:";
          sequalNoColumn.readOnly = true;
        }

        dispatch(callLiveTemplateSequelNo(liveTemplateData?.id)).then((res) => {
          sequantialNo = res?.data?.data;
        });
      }, 1000);
    }
  }, [htmlData]);

  const addNeededItemsInFormTag = () => {
    // add onSubmit
    const formElement = document.getElementsByTagName("Form");
    if (formElement.length <= 0) {
      return;
    }
    const formTag = formElement.length > 0 ? formElement[0] : "";
    formTag.addEventListener("submit", submitForm);
  };

  const submitForm = (event) => {
    window.scrollTo(0, 0);
    event.preventDefault();
    saveHtmlForSend.current =
      document.getElementById("userinputform").outerHTML;
    SelectHandlerForPDF();
    sequalNoSetFunction();

    findNameFromEvent.current = event?.target.querySelector(".cst-btn")?.name;
  };

  const radioOnChange = (name, value) => {
    setRadioValues((prevRadioValues) => {
      return { ...prevRadioValues, [name]: value };
    });
  };

  const sequalNoSetFunction = () => {
    const sequalNocolumn = document.getElementsByName("sequential no")[0];
    if (sequalNocolumn) {
      sequalNocolumn.defaultValue = sequantialNo;
    }
    const recordDescriptionElement =
      document.getElementsByName("record description")[0];
    recordDescription =
      recordDescriptionElement?.value ||
      recordDescriptionElement?.textContent ||
      recordDescriptionElement?.innerText ||
      "No Description";
    externalEmail =
      document.getElementsByName("external email")[0]?.value ||
      document.getElementsByName("external email")[0]?.textContent ||
      document.getElementsByName("external email")[0]?.innerText ||
      "Template Data";
    recordName =
      document.getElementsByName("sequential no")[0]?.value ||
      document.getElementsByName("record name")[0]?.value ||
      document.getElementsByName("record name")[0]?.textContent ||
      document.getElementsByName("record name")[0]?.innerText ||
      "Template Data";
    ConvertDateFormatForPDF();
    saveHtmlForPDF.current = document.getElementById("userinputform").outerHTML;
    setTimeout(() => {
      if (findNameFromEvent.current == "email") {
        const name = prompt("Please enter your email");
        if (name == "") {
          alert("Please enter an email");
        } else if (name != null) {
          convertTextArea(name);
        }
      } else {
        convertTextArea();
      }
    }, 1000);
  };

  const convertTextArea = (name) => {
    let formData = new FormData();
    var kvpairs = {
      data: [],
    };
    var form = document.getElementsByTagName("form");
    for (var i = 0; i < form[0].elements.length; i++) {
      var e = form[0].elements[i];
      if (e.files != null && e.files != undefined) {
        formData.append("formFiles", e.files[0]);
      }
      kvpairs.data.push({
        type: decodeURI(e.type),
        name: decodeURI(e.name),
        value: decodeURI(e.value),
        file: decodeURI(e.files),
      });
    }

    formData.append("recordName", recordName);
    formData.append("recordDescription", recordDescription);
    formData.append(
      "templateName",
      liveTemplateData?.LiveTemplate?.liveTemplateName
    );
    formData.append(
      "sequencialNo",
      document.getElementsByName("sequential no")[0]?.value || null
    );
    formData.append("landscape", pdfLayout === "landscape" ? true : false);
    formData.append("customerLogo", companyLogo);
    formData.append("isTestRecord", false);
    formData.append("pdfType", pdfType);
    formData.append("id", liveTemplateData?.id);
    formData.append("htmlData", saveHtmlForSend.current);
    formData.append("keyValues", JSON.stringify(kvpairs));
    formData.append("htmlForPDF", saveHtmlForPDF.current);
    if (name) {
      formData.append("email", name);
    }
    formData.append("externalEmail", externalEmail || null);

    dispatch(callExternalLiveRecordFormSubmit(formData))
      .then(() => {
        dispatch(alertActions.success("Record Updated successfully"));
        Cookies.remove("externalTempUserToken");
        Cookies.remove("externalUserToken");
        history.push("/external-user-thankyou");
      })
      .catch((err) => {
        showAlert(dispatch, err);
      });
  };

  return (
    <div>
      <div
        class="st-tl"
        style={{ padding: "1.5rem", borderBottom: "1px solid black" }}
      >
        <img className="logo-size" src={brandLogo} />
      </div>
      <div
        className="container-fluid custom-scrollbar"
        style={{ overflowX: "scroll" }}
      >
        <div id="userinputform">
          {parse(htmlData, {
            replace: replace({
              radioOnChange,
              radioValues,
            }),
          })}
        </div>
      </div>
    </div>
  );
};

export default ExternalLiveRecord;
