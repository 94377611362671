import { ApiService } from "../../services/api.service";
import { alertActions } from "../alert/alertActions";
import { showAlert } from "../alert/showAlert";
import { trackPromise } from "react-promise-tracker";

export function getExternalLiveTempOtpVerification(data) {
  return (dispatch) => {
    return trackPromise(
      ApiService.getOtpVerification(data).then((res) => {
        dispatch(alertActions.success(res.data.message));
        return res;
      })
    ).catch((err) => {
      dispatch(alertActions.error(err?.data?.message));
      throw err;
    });
  };
}

export function getExternalLiveRecordTemplate(data) {
  return (dispatch) => {
    return trackPromise(
      ApiService.getExternalLiveRecordTemplate(data).then((res) => {
        return res;
      })
    ).catch((err) => {
      showAlert(dispatch, err);
      throw err;
    });
  };
}

export function callExternalLiveRecordFormSubmit(data) {
  return (dispatch) => {
    return trackPromise(
      ApiService.callExternalLiveRecordFormSubmit(data).then((res) => {
        return res;
      })
    ).catch((err) => {
      showAlert(dispatch, err);
      throw err;
    });
  };
}
