import React, { useState, useEffect } from "react";
import { resetPassword, verifyPasswordLink } from "./PasswordActions";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const ResetPassword = () => {
  const [inputs, setPasswords] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [urlParams, setUrlParams] = useState({
    key: "",
    id: "",
    label: "",
  });
  const [errorMessage, setError] = useState("");

  const location = useLocation();

  const { newPassword, confirmPassword } = inputs;
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const key = search.get("key");
    const id = search.get("id");
    const label = search.get("label");
    let resetPasswordDetails = {};
    if (key != null && id != null) {
      setUrlParams((urlParams) => ({
        ...urlParams,
        id: id,
        key: key,
        label: label,
      }));
      if (label) {
        resetPasswordDetails = {
          id: id,
          key: key,
          label: label,
        };
      } else {
        resetPasswordDetails = {
          id: id,
          key: key,
        };
      }

      dispatch(verifyPasswordLink(resetPasswordDetails));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswords((inputs) => ({ ...inputs, [name]: value }));
  };

  const showPassword = (type) => {
    if (type == "new") {
      setShowNewPassword(!showNewPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const isPasswordValid = () => {
    var isFormValid = true;
    var errors = "";

    const value = inputs.newPassword;

    const hasNumber = (value) => {
      return new RegExp(/[0-9]/).test(value);
    };
    if (!hasNumber(value)) {
      isFormValid = false;
      errors = "The password should contain atleast 1 number";
    }
    const hasMixed = (value) => {
      return new RegExp(/[a-z]/).test(value) && new RegExp(/[A-Z]/).test(value);
    };
    if (!hasMixed(value)) {
      isFormValid = false;
      errors =
        "The password should have atleast 1 uppercase and 1 lowercase letter";
    }

    const hasSpecial = (value) => {
      return new RegExp(/[!#@$%^&*)(+=._-]/).test(value);
    };

    if (!hasSpecial(value)) {
      isFormValid = false;
      errors = "The password should have atleast 1 special character";
    }

    const hasMinCharacters = (value) => {
      return value.length >= 8;
    };
    if (!hasMinCharacters(value)) {
      isFormValid = false;
      errors = "The password length should be mininum of 8 characters";
    }

    setError(errors);
    return isFormValid;
  };

  const handleSubmit = (e) => {
    setSubmitted(true);
    var data = {};
    if (isPasswordValid()) {
      if (
        newPassword &&
        confirmPassword &&
        newPassword == confirmPassword &&
        urlParams.key &&
        urlParams.id
      ) {
        if (urlParams.label) {
          data = {
            password: newPassword,
            key: urlParams.key,
            id: urlParams.id,
            label: urlParams.label,
          };
        } else {
          data = {
            password: newPassword,
            key: urlParams.key,
            id: urlParams.id,
          };
        }

        dispatch(resetPassword(data, urlParams.label));
      }
    }
  };

  return (
    <div class="container-fluidcst">
      <div class="row">
        <div class="d-none d-lg-block col-lg-7 no-padding ipod">
          <div class="log-bg"></div>
        </div>
        <div class="col-lg-5">
          <div class="content-wrap">
            <div class="login-wrapp">
              <div class="login-outer">
                <div class="p-5">
                  <div class="text-center">
                    <h1 class="h4 text-gray-900 mb-4">Reset Your Password</h1>
                  </div>
                  <form class="user">
                    <div class="group">
                      <div class="d-flex align-items-center">
                        <span class="txt-fld">
                          <input
                            type={
                              showNewPassword == false ? "password" : "text"
                            }
                            name="newPassword"
                            value={newPassword}
                            onChange={handleChange}
                            onBlur={isPasswordValid}
                            className={
                              (submitted && !newPassword) ||
                              errorMessage.length > 0
                                ? "brd-4 form-control is-invalid"
                                : ""
                            }
                            required="required"
                          />
                          <span class="highlight"></span>
                          <label>New Password</label>
                          {submitted && !newPassword && (
                            <div className="invalid-feedback">
                              Password is required
                            </div>
                          )}
                          {newPassword && errorMessage.length > 0 && (
                            <div className="invalid-feedback">
                              {errorMessage}
                            </div>
                          )}
                        </span>
                        <span
                          className={
                            (submitted && !newPassword) ||
                            errorMessage.length > 0
                              ? "icn-pstn-invalid"
                              : "icn-pstn"
                          }
                          onClick={() => showPassword("new")}
                        >
                          <i
                            class={
                              showNewPassword == false
                                ? "fa fa-eye"
                                : "fa fa-eye-slash"
                            }
                          ></i>
                        </span>
                      </div>
                    </div>
                    <div class="group">
                      <div class="d-flex align-items-center">
                        <span class="txt-fld">
                          <input
                            type={
                              showConfirmPassword == false ? "password" : "text"
                            }
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={handleChange}
                            className={
                              submitted &&
                              (!confirmPassword ||
                                confirmPassword != newPassword)
                                ? "brd-4 form-control is-invalid"
                                : ""
                            }
                            required="required"
                          />
                          <span class="highlight"></span>
                          <label>Re-enter Password</label>
                          {submitted && !confirmPassword && (
                            <div className="invalid-feedback">
                              Password is required
                            </div>
                          )}
                          {submitted &&
                            confirmPassword &&
                            confirmPassword != newPassword && (
                              <div className="invalid-feedback">
                                Passwords doesn't match
                              </div>
                            )}
                        </span>
                        <span
                          className={
                            submitted &&
                            (!confirmPassword || confirmPassword != newPassword)
                              ? "icn-pstn-invalid"
                              : "icn-pstn"
                          }
                          onClick={() => showPassword("confirm")}
                        >
                          <i
                            class={
                              showConfirmPassword == false
                                ? "fa fa-eye"
                                : "fa fa-eye-slash"
                            }
                          ></i>
                        </span>
                      </div>
                    </div>

                    <div>
                      <button
                        type="button"
                        onClick={handleSubmit}
                        class="btn btn-primary btn-lg btn-block mb-3"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                  <div style={{ textAlign: "center" }}>
                    <a href="/login">Back to Login</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
