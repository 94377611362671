import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";
import { history } from "../../../helpers/history";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import "./leftdrawer.css";
const LeftDrawer = (props) => {
  const [activeTab, setActiveTab] = useState("home");
  const { pathname } = useLocation();
  const { t } = useTranslation();
  useEffect(() => {
    const search = window.location.pathname.split("/");

    if (search.length > 2) {
      setActiveTab(search[2]);
    } else {
      drawerItemlcik("home");
    }
  }, [pathname]);

  var isPrime = localStorage.getItem("isPrime");
  const updateActiveTab = (activeOption) => {
    props.isActiveTab(activeOption);
  };
  // Drawer items click

  const drawerItemlcik = (item) => {
    setActiveTab(item);
    updateActiveTab(item);
    switch (item) {
      case "home":
        history.push("/home");
        break;
      case "businessOrganisation":
        history.push("/home/businessOrganisation");
        break;
      case "high-level-documents":
        history.push("/home/high-level-documents");
        break;
      case "policies":
        history.push("/home/policies");
        break;
      case "procedures":
        history.push("/home/procedures");
        break;
      case "flowcharts":
        history.push("/home/flowcharts");
        break;
      case "onlineTemplates":
        history.push("/home/onlineTemplates");
        break;
      case "liveTemplatesList":
        history.push("/home/liveTemplatesList");
        break;
      case "interactiveTemplate":
        history.push("/home/interactiveTemplate");
        break;
      case "templateRecords":
        history.push("/home/templateRecords");
        break;
      case "liveRecords":
        history.push("/home/liveRecordsList");
        break;
      case "recycleBin":
        history.push("/home/recycleBin");
        break;
      default:
        history.push("/home");
        break;
    }
  };

  return (
    <ul
      className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion mt-5 pt-4 ${
        props.isExpand ? "" : "toggled"
      }`}
      id="accordionSidebar"
    >
      <hr className="sidebar-divider my-0 pb-3" />
      <li className="nav-item">
        <a
          className={
            "nav-link " +
            (activeTab == "home" || activeTab === "addJobTicket"
              ? "active"
              : "")
          }
          onClick={() => {
            drawerItemlcik("/home");
          }}
        >
          <Icon.Home />

          <span className="pl-1">Home</span>
        </a>
      </li>
      <li className="nav-item">
        <a
          className={
            "nav-link " + (activeTab == "businessOrganisation" ? "active" : "")
          }
          onClick={() => {
            drawerItemlcik("businessOrganisation");
          }}
        >
          <Icon.Layers />
          <span className="pl-1">Business Organization</span>
        </a>
      </li>
      <li className="nav-item">
        <a
          className={
            "nav-link " + (activeTab == "high-level-documents" ? "active" : "")
          }
          onClick={() => {
            drawerItemlcik("high-level-documents");
          }}
        >
          <Icon.File />
          <span className="pl-1">High Level Document</span>
        </a>
      </li>
      <li className="nav-item">
        <a
          className={"nav-link " + (activeTab == "policies" ? "active" : "")}
          onClick={() => {
            drawerItemlcik("policies");
          }}
        >
          <Icon.Clock />
          <span className="pl-1">Policies</span>
        </a>
      </li>
      <li className="nav-item">
        <a
          className={"nav-link " + (activeTab == "procedures" ? "active" : "")}
          onClick={() => {
            drawerItemlcik("procedures");
          }}
        >
          <Icon.Briefcase />
          <span className="pl-1">Procedures</span>
        </a>
      </li>
      <li className="nav-item">
        <a
          className={
            "nav-link " +
            (activeTab == "flowcharts" || activeTab == "createFlowchart"
              ? "active"
              : "")
          }
          onClick={() => {
            drawerItemlcik("flowcharts");
          }}
        >
          <Icon.PieChart />
          <span className="pl-1">Flow Charts</span>
        </a>
      </li>
      <li className="nav-item">
        <a
          className={
            "nav-link " +
            (activeTab == "onlineTemplates" || activeTab == "createTemplate"
              ? "active"
              : "")
          }
          onClick={() => {
            drawerItemlcik("onlineTemplates");
          }}
        >
          <Icon.Columns />
          <span className="pl-1">Online Templates</span>
        </a>
      </li>
      <li className="nav-item">
        <a
          className={
            "nav-link " +
            (activeTab === "templateRecords" || activeTab === "recordDetails"
              ? "active"
              : "")
          }
          onClick={() => {
            drawerItemlcik("templateRecords");
          }}
        >
          <Icon.Folder />
          <span className="pl-1">Template Records</span>
        </a>
      </li>
      <li className="nav-item">
        <a
          className={
            "nav-link " +
            (activeTab == "liveTemplatesList" ||
            activeTab == "createTemplate" ||
            activeTab == "editLiveTemplate"
              ? "active"
              : "")
          }
          onClick={() => {
            drawerItemlcik("liveTemplatesList");
          }}
        >
          <Icon.BookOpen />
          <span className="pl-1">Live Templates</span>
        </a>
      </li>
      <li className="nav-item">
        <a
          className={
            "nav-link " +
            (activeTab == "liveRecordsList" ||
            activeTab == "liveRecordDetails" ||
            activeTab == "editableLiveRecord" ||
            activeTab === "editLiveRecord"
              ? "active"
              : "")
          }
          onClick={() => {
            drawerItemlcik("liveRecords");
          }}
        >
          <Icon.File />
          <span className="pl-1">Live Records</span>
        </a>
      </li>
      {/* just hiding interactiveTemplate based on the client request */}

      {/* <li className="nav-item">
        <a
          className={
            "nav-link " + (activeTab == "interactiveTemplate" ? "active" : "")
          }
          onClick={() => {
            drawerItemlcik("interactiveTemplate");
          }}
        >
          <Icon.CheckSquare />
          <span className="pl-1">Interactive Template</span>
        </a>
      </li> */}

      {isPrime == "true" && (
        <li className="nav-item">
          <a
            className={
              "nav-link " + (activeTab == "recycleBin" ? "active" : "")
            }
            onClick={() => {
              drawerItemlcik("recycleBin");
            }}
          >
            <Icon.Trash2 />
            <span className="pl-1">Recycle Bin</span>
          </a>
        </li>
      )}
    </ul>
  );
};

export default LeftDrawer;
