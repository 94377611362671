import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

import AuthService from "../services/AuthService";

const PrivateRoute = ({ component: Component, isExternal, ...rest }) => {
  return isExternal ? (
    <Route
      {...rest}
      render={(props) =>
        AuthService.IsExternalLiveTempUserLoggedIn() ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to="/external-livetemplate/login" />
        )
      }
    />
  ) : (
    <Route
      {...rest}
      render={(props) =>
        AuthService.IsLoggedIn() ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

export default PrivateRoute;
