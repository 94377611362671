import React, { useState, useEffect } from "react";
import * as Icon from "react-feather";
import { history } from "../../../helpers/history";
import { useDispatch, useSelector } from "react-redux";
import {
  listBusinessOrganisations,
  listBusinessOrganisationArchives,
  resetBusinessOrgDetails,
} from "../BusinessOrganisation/BusinessOrganisationActions";
import { getNotification } from "../../components/header/notificationActions";
import Pagination from "../../components/Pagination/Pagination";
import "./BusinessOrganisation.css";
import $ from "jquery";
import { PdfGeneration } from "../../pages/pdfGeneration/pdfActions";
import { CustomerId } from "src/app/config";
import SearchBox from "src/app/components/SearchBox";

const BusinessOrganisation = () => {
  const [isAdmin] = useState(localStorage.getItem("isAdmin"));
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [showArchives, setShowArchives] = useState(false);

  const dispatch = useDispatch();
  const [sortParams, setSortParams] = useState({
    sortBy: "ASC",
    sortTitle: "organizationRefId",
  });

  const organisationList = useSelector((state) => state.organisationData);
  const businessOrganisationList =
    organisationList &&
    organisationList.businessOrganisationsList &&
    organisationList.businessOrganisationsList.data;
  const totalCount =
    organisationList &&
    organisationList.businessOrganisationsList &&
    organisationList.businessOrganisationsList.organizationCount;
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    window.onbeforeunload = null;
    // Listing Organisations
    getOrganisationList();
    getNotificationsList();
  }, [searchValue]);

  const generatePdf = (id, type) => {
    const data = {
      type: type,
      id: id,
    };
    dispatch(PdfGeneration(data));
  };

  const getNotificationsList = () => {
    const data = {
      numPerPage: 10,
      page: 1,
      customerId: CustomerId,
      userId: localStorage.getItem("userId"),
    };
    dispatch(getNotification(data));
  };

  const getOrganisationList = (page = 1, pageSize = 100) => {
    const data = {
      numPerPage: pageSize,
      page: page == 0 ? 1 : page,
      sortKey: sortParams.sortTitle,
      sortType: sortParams.sortBy,
      searchKey: searchValue,
    };
    dispatch(listBusinessOrganisations(data));
  };

  const onPageChanged = (data) => {
    if (data && data.currentPage == currentPageNumber) {
      return;
    }
    if (data && data.currentPage) {
      const { currentPage } = data;
      setCurrentPageNumber(currentPage);
      if (showArchives == false) {
        getOrganisationList(currentPage);
      } else if (showArchives == true) {
        getArchivesList(currentPage);
      }
    }
  };

  const goToEditOrganisation = (data) => {
    history.push("/home/addJobTicket", {
      type: 1,
      id: data.id,
      jobId: data.BusinessorganisationSetting.Ticket.id,
    });
  };

  const copyToClipboard = (data) => {
    const url = data;
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(url).select();
    document.execCommand("copy");
    $temp.remove();
    window.open(url, "_blank", "noreferrer");
  };

  const sortTable = (title) => {
    var sortByOrder = "ASC";

    if (title == sortParams.sortTitle) {
      sortByOrder = sortParams.sortBy == "DESC" ? "ASC" : "DESC";
      setSortParams({ sortBy: sortByOrder, sortTitle: title });
    } else {
      setSortParams({ sortBy: "ASC", sortTitle: title });
    }

    // Call Api here for sorting
    const data = {
      numPerPage: 100,
      page: currentPageNumber,
      sortKey: title,
      sortType: sortByOrder,
      searchKey: searchValue,
    };
    if (showArchives == false) {
      dispatch(listBusinessOrganisations(data));
    } else if (showArchives == true) {
      dispatch(listBusinessOrganisationArchives(data));
    }
  };
  const goToCreateOrganisation = () => {
    history.push("/home/addJobTicket", { type: 1 });
  };
  const viewChart = (data) => {
    history.push("/home/addBusinessOrganisation", data);
  };

  const getArchivesList = (page = 1, pageSize = 100) => {
    const data = {
      numPerPage: pageSize,
      page: page == 0 ? 1 : page,
      sortKey: sortParams.sortTitle,
      sortType: sortParams.sortBy,
      searchKey: "",
    };
    dispatch(listBusinessOrganisationArchives(data));
  };

  const goToArchives = () => {
    dispatch(resetBusinessOrgDetails());
    getArchivesList();
    setShowArchives(true);
  };

  const goToBusinessOrg = () => {
    dispatch(resetBusinessOrgDetails());
    getOrganisationList();
    setShowArchives(false);
  };

  const getOrgType = (type) => {
    var updatedType = "";
    if (type == "organizationChart") {
      updatedType = "Organization Chart";
    } else if (type == "escalationProcedure") {
      updatedType = "Escalation procedure";
    } else {
      updatedType = "Business continuity plan";
    }
    return updatedType;
  };
  const handleSearch = (value) => {
    setSearchValue(value);
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="st-tl">
          <h1 className="mh-head-txt pt-3">
            {showArchives == false ? "Business Organizations" : "Archives"}
          </h1>
        </div>
        {showArchives == false && isAdmin == "false" && (
          <div className="aad-usr pt-3">
            <Icon.PlusCircle />
            <a
              className="a-removed-href-create"
              onClick={goToCreateOrganisation}
            >
              Create new Job Ticket for Business Organization
            </a>
          </div>
        )}
      </div>
      <hr className="pb-3" />

      <div className="d-flex justify-content-between mt-5 row">
        <div class="input-group st-tl col-md-4">
          <SearchBox onSearch={handleSearch} />
        </div>
        <div className="aad-usr pt-3 pr-3">
          {showArchives == false ? (
            <div>
              {" "}
              <Icon.Calendar />
              <a className="a-removed-href-create" onClick={goToArchives}>
                Archives
              </a>
            </div>
          ) : (
            <a className="a-removed-href-create" onClick={goToBusinessOrg}>
              Go back to Business Organizations
            </a>
          )}
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="table-wraper table-responsive mnt mt-3">
            <table class="table job-list">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="mx-width"
                    onClick={() => {
                      sortTable("organizationRefId");
                    }}
                  >
                    <div class="d-flex justify-content-start">
                      <div>Doc ID</div>
                      <div class="sort-wrp ml-2">
                        <i
                          class={
                            sortParams.sortTitle == "organizationRefId" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-asc fa-lg active sort_up"
                              : "fa fa-sort-asc fa-lg sort_fade_down"
                          }
                        ></i>
                        <i
                          class={
                            sortParams.sortTitle == "organizationRefId" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-desc fa-lg sort_fade_down"
                              : sortParams.sortTitle == "organizationRefId" &&
                                sortParams.sortBy == "DESC"
                              ? "fa fa-sort-desc fa-lg active sort_up"
                              : "fa fa-sort-desc fa-lg sort_fade_down"
                          }
                        ></i>
                      </div>
                    </div>
                  </th>{" "}
                  <th
                    scope="col"
                    onClick={() => {
                      sortTable("organizationName");
                    }}
                  >
                    <div class="d-flex justify-content-start">
                      <div>Organization Name</div>
                      <div class="sort-wrp ml-2">
                        <i
                          class={
                            sortParams.sortTitle == "organizationName" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-asc fa-lg active sort_up"
                              : "fa fa-sort-asc fa-lg sort_fade_down"
                          }
                        ></i>
                        <i
                          class={
                            sortParams.sortTitle == "organizationName" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-desc fa-lg sort_fade_down"
                              : sortParams.sortTitle == "organizationName" &&
                                sortParams.sortBy == "DESC"
                              ? "fa fa-sort-desc fa-lg active sort_up"
                              : "fa fa-sort-desc fa-lg sort_fade_down"
                          }
                        ></i>
                      </div>
                    </div>
                  </th>
                  <th
                    scope="col"
                    onClick={() => {
                      sortTable("type");
                    }}
                  >
                    <div class="d-flex justify-content-start">
                      <div>Document Type</div>
                      <div class="sort-wrp ml-2">
                        <i
                          class={
                            sortParams.sortTitle == "type" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-asc fa-lg active sort_up"
                              : "fa fa-sort-asc fa-lg sort_fade_down"
                          }
                        ></i>
                        <i
                          class={
                            sortParams.sortTitle == "type" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-desc fa-lg sort_fade_down"
                              : sortParams.sortTitle == "type" &&
                                sortParams.sortBy == "DESC"
                              ? "fa fa-sort-desc fa-lg active sort_up"
                              : "fa fa-sort-desc fa-lg sort_fade_down"
                          }
                        ></i>
                      </div>
                    </div>
                  </th>
                  <th
                    scope="col text-center"
                    onClick={() => {
                      sortTable("revision");
                    }}
                  >
                    <div class="d-flex justify-content-start">
                      <div>Revision</div>
                      <div class="sort-wrp ml-2">
                        <i
                          class={
                            sortParams.sortTitle == "revision" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-asc fa-lg active sort_up"
                              : "fa fa-sort-asc fa-lg sort_fade_down"
                          }
                        ></i>
                        <i
                          class={
                            sortParams.sortTitle == "revision" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-desc fa-lg sort_fade_down"
                              : sortParams.sortTitle == "revision" &&
                                sortParams.sortBy == "DESC"
                              ? "fa fa-sort-desc fa-lg active sort_up"
                              : "fa fa-sort-desc fa-lg sort_fade_down"
                          }
                        ></i>
                      </div>
                    </div>
                  </th>{" "}
                  <th
                    scope="col"
                    onClick={() => {
                      sortTable("updatedAt");
                    }}
                  >
                    <div className="d-flex justify-content-start">
                      <div>Date</div>
                      <div className="sort-wrp ml-2">
                        <i
                          className={
                            sortParams.sortTitle == "updatedAt" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-asc fa-lg active sort_up"
                              : "fa fa-sort-asc fa-lg sort_fade_down"
                          }
                        ></i>
                        <i
                          className={
                            sortParams.sortTitle == "updatedAt" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-desc fa-lg sort_fade_down"
                              : sortParams.sortTitle == "updatedAt" &&
                                sortParams.sortBy == "DESC"
                              ? "fa fa-sort-desc fa-lg active sort_up"
                              : "fa fa-sort-desc fa-lg sort_fade_down"
                          }
                        ></i>
                      </div>
                    </div>
                  </th>
                  <th className="text-center" scope="col">
                    Download
                  </th>
                  {showArchives == false && isAdmin == "false" && (
                    <th className="text-center" scope="col">
                      Share
                    </th>
                  )}
                  {showArchives == false && isAdmin == "false" && (
                    <th className="text-center" scope="col">
                      Change Request
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {organisationList &&
                  !organisationList.loading &&
                  organisationList.error && (
                    <span className="text-danger">
                      ERROR: {organisationList.error}
                    </span>
                  )}
                {businessOrganisationList &&
                  businessOrganisationList.map((organisation, index) => (
                    <tr>
                      <td className="mx-width">
                        {organisation.organizationRefId}
                      </td>
                      <td>{organisation.organizationName}</td>

                      <td>{getOrgType(organisation.type)}</td>

                      <td className="text-center">{organisation.revision}</td>
                      <td>
                        {organisation.updatedAt
                          ? organisation.updatedAt.split("T")[0]
                          : "Not Approved"}{" "}
                      </td>
                      {organisation.type == "organizationChart" &&
                      organisation.organizationType == "manual" ? (
                        <td className="text-center">
                          <a
                            className="a-removed-href"
                            onClick={() => viewChart(organisation)}
                          >
                            <Icon.Eye />
                          </a>
                        </td>
                      ) : (
                        <td class="text-center">
                          {organisation.organizationType == "upload" ? (
                            <a
                              href={organisation.fileName}
                              className="a-removed-href"
                            >
                              <Icon.Download />
                            </a>
                          ) : (
                            <a
                              className="a-removed-href"
                              onClick={() =>
                                generatePdf(organisation.id, organisation.type)
                              }
                            >
                              <Icon.Download />
                            </a>
                          )}
                        </td>
                      )}
                      {showArchives == false && isAdmin == "false" && (
                        <td className="text-center">
                          <a
                            className="a-removed-href"
                            onClick={() =>
                              copyToClipboard(organisation.fileName)
                            }
                          >
                            <Icon.Share />
                          </a>
                        </td>
                      )}
                      {showArchives == false && isAdmin == "false" && (
                        <td className="text-center">
                          <a
                            className="a-removed-href"
                            onClick={() => goToEditOrganisation(organisation)}
                          >
                            Raise Ticket
                          </a>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
            {organisationList.businessOrganisationsList.organizationCount <=
              0 && (
              <p class="text-center font-italic font-weight-bold">
                No data available!
              </p>
            )}
            {organisationList.businessOrganisationsList.organizationCount >
              0 && (
              <div className="d-flex flex-row py-4 align-items-center float-left">
                Total Count:{" "}
                {organisationList.businessOrganisationsList.organizationCount}
              </div>
            )}

            <div className="d-flex flex-row py-4 align-items-center float-right">
              {organisationList.businessOrganisationsList.organizationCount >
                0 && (
                <Pagination
                  totalRecords={totalCount}
                  pageLimit={100}
                  pageNeighbours={0}
                  onPageChanged={onPageChanged}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessOrganisation;
