// RECORD LISTING
export const LIST_RECORDS_REQUEST = "LIST_RECORDS_REQUEST";
export const LIST_RECORDS_SUCCESS = "LIST_RECORDS_SUCCESS";
export const LIST_RECORDS_FAILURE = "LIST_RECORDS_FAILURE";

// GET RECORD DETAILS
export const GET_RECORD_DETAILS_REQUEST = "GET_RECORD_DETAILS_REQUEST";
export const GET_RECORD_DETAILS_SUCCESS = "GET_RECORD_DETAILS_SUCCESS";
export const GET_RECORD_DETAILS_FAILURE = "GET_RECORD_DETAILS_FAILURE";

// DELETE ALL RECORDS
export const DELETE_ALL_RECORDS_REQUEST = "DELETE_ALL_RECORDS_REQUEST";
export const DELETE_ALL_RECORDS_SUCCESS = "DELETE_ALL_RECORDS_SUCCESS";
export const DELETE_ALL_RECORDS_FAILURE = "DELETE_ALL_RECORDS_FAILURE";

// DELETE A RECORD
export const DELETE_SINGLE_RECORD_REQUEST = "DELETE_SINGLE_RECORD_REQUEST";
export const DELETE_SINGLE_RECORD_SUCCESS = "DELETE_SINGLE_RECORD_SUCCESS";
export const DELETE_SINGLE_RECORD_FAILURE = "DELETE_SINGLE_RECORD_FAILURE";

// RETRIEVE A RECORD
export const RETRIEVE_SINGLE_RECORD_REQUEST = "RETRIEVE_SINGLE_RECORD_REQUEST";
export const RETRIEVE_SINGLE_RECORD_SUCCESS = "RETRIEVE_SINGLE_RECORD_SUCCESS";
export const RETRIEVE_SINGLE_RECORD_FAILURE = "RETRIEVE_SINGLE_RECORD_FAILURE";

// ARCHIVE ALL RECORDS
export const ARCHIVE_ALL_RECORDS_REQUEST = "ARCHIVE_ALL_RECORDS_REQUEST";
export const ARCHIVE_ALL_RECORDS_SUCCESS = "ARCHIVE_ALL_RECORDS_SUCCESS";
export const ARCHIVE_ALL_RECORDS_FAILURE = "ARCHIVE_ALL_RECORDS_FAILURE";

// LIST ALL DELETED RECORDS
export const LIST_ALL_DELETED_RECORDS_REQUEST =
  "LIST_ALL_DELETED_RECORDS_REQUEST";
export const LIST_ALL_DELETED_RECORDS_SUCCESS =
  "LIST_ALL_DELETED_RECORDS_SUCCESS";
export const LIST_ALL_DELETED_RECORDS_FAILURE =
  "LIST_ALL_DELETED_RECORDS_FAILURE";

// YEAR LISTING
export const LIST_YEARS_REQUEST = "LIST_YEARS_REQUEST";
export const LIST_YEARS_SUCCESS = "LIST_YEARS_SUCCESS";
export const LIST_YEARS_FAILURE = "LIST_YEARS_FAILURE";

// MAKE ARCHIVE
export const MAKE_ARCHIVES_REQUEST = "MAKE_ARCHIVES_REQUEST";
export const MAKE_ARCHIVES_SUCCESS = "MAKE_ARCHIVES_SUCCESS";
export const MAKE_ARCHIVES_FAILURE = "MAKE_ARCHIVES_FAILURE";

//RESET
export const RESET_RECORD_DETAILS = "RESET_RECORD_DETAILS";
export const monthListForDropdown = [
  { label: "All Month", value: "0" },
  { label: "Jan", value: "1" },
  { label: "Feb", value: "2" },
  { label: "Mar", value: "3" },
  { label: "Apr", value: "4" },
  { label: "May", value: "5" },
  { label: "Jun", value: "6" },
  { label: "Jul", value: "7" },
  { label: "Aug", value: "8" },
  { label: "Sep", value: "9" },
  { label: "Oct", value: "10" },
  { label: "Nov", value: "11" },
  { label: "Dec", value: "12" },
];
