import React from "react";
import { history } from "../../helpers/history";
const PageTopHeader = (props) => {
  const linkBackUrl = (RedirectUrl) => {
    history.push(RedirectUrl);
  };
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className="st-tl">
          <h1 className="mh-head-txt pt-3">{props?.leftTitle}</h1>
        </div>
        {props.rightTitle && (
          <div class="aad-usr pt-3">
            <a
              className="a-removed-href-create"
              onClick={() => {
                linkBackUrl(props.url);
              }}
            >
              {props.Righticon ? props.Righticon : ""} {props?.rightTitle}
            </a>
          </div>
        )}
      </div>
      <hr className="pb-3" />
    </>
  );
};

export default PageTopHeader;
