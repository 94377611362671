import React, { useState, useRef, useEffect } from "react";

const SearchBox = ({ onSearch }) => {
  const [searchValue, setSearchValue] = useState("");
  const inputRef = useRef();

  useEffect(() => {
    const handleInput = () => {
      if (inputRef.current && !inputRef.current.value) {
        onSearch("");
        setSearchValue("");
      }
    };

    inputRef.current.addEventListener("input", handleInput);

    return () => {
      inputRef.current.removeEventListener("input", handleInput);
    };
  }, [onSearch]);

  const handleSearch = () => {
    onSearch(searchValue);
  };

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className="input-group rounded">
      <input
        type="search"
        className="form-control rounded"
        placeholder="Search by name"
        aria-label="Search"
        aria-describedby="search-addon"
        value={searchValue}
        onChange={handleInputChange}
        onKeyDown={handleInputChange}
        ref={inputRef}
        style={{ height: "100%", padding: "11px" }}
      />
      <button
        type="button"
        className="btn btn-outline-dark ml-1"
        onClick={handleSearch}
      >
        Search
      </button>
    </div>
  );
};

export default SearchBox;
