// POLICY LISTING
export const LIST_POLICIES_REQUEST = 'LIST_POLICIES_REQUEST';
export const LIST_POLICIES_SUCCESS = 'LIST_POLICIES_SUCCESS';
export const LIST_POLICIES_FAILURE = 'LIST_POLICIES_FAILURE';

//LIST ARCHIVES
export const LIST_POLICY_ARCHIVE_REQUEST = 'LIST_POLICY_ARCHIVE_REQUEST';
export const LIST_POLICY_ARCHIVE_SUCCESS = 'LIST_POLICY_ARCHIVE_SUCCESS';
export const LIST_POLICY_ARCHIVE_FAILURE = 'LIST_POLICY_ARCHIVE_FAILURE';

//RESET
export const RESET_POLICY_DETAILS = 'RESET_POLICY_DETAILS';


