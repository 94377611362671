import Cookies from "js-cookie";

const IsLoggedIn = () => {
  let value = false;
  if (Cookies.get("Token")) {
    value = true;
  }
  return value;
};

const IsExternalLiveTempUserLoggedIn = () => {
  let value = false;
  if (Cookies.get("externalUserToken")) {
    value = true;
  }
  return value;
};

const AuthService = { IsLoggedIn, IsExternalLiveTempUserLoggedIn };
export default AuthService;
