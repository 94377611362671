import * as profile from './profileConstants';

const initialState = {
  loading: false,
  profileData: {
    data: {
      firstName: '',
      lastName: ''
    }
  },
  error: null
};

function ProfileReducer(state = initialState, action={}) {
  switch (action.type) {
    case profile.GET_PROFILE_REQUEST:
    case profile.UPDATE_PROFILE_REQUEST:

      return {
        ...state,
        loading: true,
        error: null,
      };
    case profile.GET_PROFILE_SUCCESS:
      if (action.payload.status == "success") {
        localStorage.setItem('firstName', action.payload.data.firstName);
        localStorage.setItem('lastName', action.payload.data.lastName);
      }
      return {
        ...state,
        loading: false,
        profileData: action.payload,
        error: null,
      };
    case profile.GET_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        profileData: {
          data: {
            firstName: '',
            lastName: ''
          }
        },
        error: action.payload.error,
      };
    case profile.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,

      };
    case profile.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error

      };
    default:
      return state;
  }
}

export default ProfileReducer;