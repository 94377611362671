import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import LoginReducer from '../pages/Login/loginReducer';
import AlertReducer from '../pages/alert/AlertReducer';
import ProfileReducer from '../pages/profile/profileReducer';
import LogoutReducer from '../pages/dashboard/logoutReducer';
import JobTicketReducer from '../pages/JobTicket/JobTicketReducer';
import PasswordReducer from '../pages/Password/PasswordReducer';
import ForgotPasswordReducer from '../pages/ForgotPassword/forgotPasswordReducer';
import TemplateReducer from '../pages/OnlineTemplates/TemplateReducer';
import NotificationReducer from '../components/header/notificationReducer';
import FlowchartReducer from '../pages/Flowcharts/FlowchartReducer';
import BusinessOrganisationReducer from '../pages/BusinessOrganisation/BusinessOrganisationReducer';
import ManualReducer from '../pages/Manual/ManualReducer';
import ProcedureReducer from '../pages/Procedure/ProcedureReducer';
import PolicyReducer from '../pages/Policy/PolicyReducer';
import RecordsReducer from '../pages/Records/RecordsReducer';
import PdfReducer from '../pages/pdfGeneration/pdfReducer';




//One root reducer for the whole app. This is done so that the app will have one single reducer to manage lots of other resources.
// And also communication between the reducers will be easier to maintain.

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    loginData: LoginReducer,
    alert: AlertReducer,
    profileData: ProfileReducer,
    logoutData:LogoutReducer,
    jobTicketData:JobTicketReducer,
    passwordData:PasswordReducer,
    forgotPasswordData:ForgotPasswordReducer,
    onlineTemplateData: TemplateReducer,
    notificationData: NotificationReducer,
    flowchartDataReducer: FlowchartReducer,
    organisationData:BusinessOrganisationReducer,
    manualData: ManualReducer,
    procedureData: ProcedureReducer,
    policyData: PolicyReducer,
    recordData: RecordsReducer,
    PdfReducer
   
});
export default createRootReducer;
