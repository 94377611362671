import { useDispatch } from "react-redux";
import parse from "html-react-parser";
import { showAlert } from "../alert/showAlert";
import React, { useState, useEffect, useRef } from "react";
import { CustomerId, companyLogo } from "src/app/config";
import { useParams } from "react-router-dom";
import { history } from "../../../helpers/history";
import {
  getCallAddLiveTemplateData,
  getLiveTemplateForCustomer,
} from "./TemplateActions";
import SignatureCanvas from "react-signature-canvas";
import * as Icon from "react-feather";
import $ from "jquery";
import { alertActions } from "../alert/alertActions";
import { callListProjects } from "../userManagement/actions";
import Select from "react-select";
import usePdfDimensions from "../LiveRecords/usePdfDimensions";
import useCalc from "../LiveRecords/useCalc";
import SelectHandlerForPDF from "src/app/components/SelectHandlerForPDF";
import HandleCheckboxCheckedForPDF from "src/app/components/HandleCheckboxCheckedForPDF";
import HandleRadioCheckedForPDF from "src/app/components/HandleRadioCheckedForPDF";
import ReplacingSignatureWithImg from "../OnlineTemplates/ReplacingSignatureWithImg";
const parseStyles = (styles) =>
  styles
    .split(";")
    .filter((style) => style.split(":")[0] && style.split(":")[1])
    .map((style) => [
      style
        .split(":")[0]
        .trim()
        .replace(/-./g, (c) => c.substr(1).toUpperCase()),
      style.split(":")[1].trim(),
    ])
    .reduce(
      (styleObj, style) => ({
        ...styleObj,
        [style[0]]: style[1],
      }),
      {}
    );

const replace =
  ({ radioOnChange, radioValues }) =>
  (domNode) => {
    console.log("======type====>", domNode);
    console.log("======name====>", domNode?.name);
    if (
      domNode?.attribs?.type === "file" &&
      domNode?.type === "tag" &&
      domNode?.name === "input"
    ) {
      return <InputFileUpload attribs={domNode?.attribs} />;
    }
    if (
      domNode?.attribs?.placeholder &&
      domNode?.attribs?.placeholder?.includes("Date")
    ) {
      return <Datepicker attribs={domNode?.attribs} />;
    }
    if (domNode?.type === "tag" && domNode?.name === "textarea") {
      return (
        <TextField
          attribs={domNode?.attribs}
          defaultValue={domNode?.children?.[0]?.data}
        />
      );
    }
    if (domNode?.type === "tag" && domNode?.name === "input") {
      if (domNode.attribs?.type === "checkbox") {
        return <CheckboxField attribs={domNode?.attribs} />;
      }

      if (domNode?.attribs?.type === "radio") {
        let checked = false;
        const radioGroupValue = radioValues[domNode?.attribs.name];

        if (radioGroupValue) {
          checked = radioGroupValue === domNode?.attribs.value;
        } else {
          checked = domNode?.attribs["data-checked"] === "true";
        }

        return (
          <RadioField
            attribs={domNode?.attribs}
            onChange={radioOnChange}
            checked={checked}
          />
        );
      }
    }

    if (domNode?.type === "tag" && domNode?.name === "select") {
      const options = domNode?.children.map(({ attribs, children }) => ({
        label: children?.[0]?.data,
        value: attribs.value,
      }));
      return <SelectField attribs={domNode?.attribs} options={options} />;
    }
  };

const InputFileUpload = ({ attribs }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    // You can perform any additional logic here with the selected file
  };
  console.log(file);
  return (
    <input
      type="file"
      name={attribs?.name}
      accept={attribs?.accept}
      onChange={handleFileChange}
      style={attribs?.style}
      // Do not set the `value` attribute on file input
    />
  );
};
const Datepicker = ({ attribs }) => {
  const [value, setValue] = useState(attribs?.value || "");

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <input
      {...attribs}
      required={attribs?.required === "" ? true : false}
      type="date"
      value={value}
      onChange={handleChange}
    />
  );
};

const TextField = ({ attribs, defaultValue }) => {
  const [value, setValue] = useState(defaultValue || "");
  const { style = "", ...rest } = attribs;

  return (
    <textarea
      {...rest}
      style={parseStyles(style)}
      value={value}
      onChange={(e) => setValue(e.target.value || "")}
    />
  );
};

const CheckboxField = ({ attribs }) => {
  const [value, setValue] = useState(
    attribs["data-checked"] === "true" || false
  );

  return (
    <input
      type="checkbox"
      {...attribs}
      data-checked={value}
      required={attribs?.required === "" ? true : false}
      checked={value}
      onChange={(e) => setValue(e.target.checked || false)}
    />
  );
};

const RadioField = ({ onChange, attribs, checked }) => {
  return (
    <input
      type="radio"
      {...attribs}
      data-checked={checked}
      required={attribs?.required === "" ? true : false}
      checked={checked}
      onChange={(e) => onChange(attribs?.name, e.target.value)}
    />
  );
};

const SelectField = ({ attribs, options }) => {
  const [value, setValue] = useState(
    attribs["data-value"] || attribs?.value || ""
  );

  return (
    <select
      {...attribs}
      required={attribs?.required === "" ? true : false}
      data-value={value}
      value={value}
      onChange={(e) => setValue(e.target.value || "")}
    >
      {options?.map((option) => (
        <option key={option?.value} value={option?.value}>
          {option?.label}
        </option>
      ))}
    </select>
  );
};

const EditLiveTemplate = () => {
  const dispatch = useDispatch();

  const [htmlHeaderData, setHtmlHeaderData] = useState("");
  const [htmlBodyData, setHtmlBodyData] = useState("");
  const [htmlFooterData, setHtmlFooterData] = useState("");
  const [liveTemplateData, setLiveTemplateData] = useState();
  const [radioValues, setRadioValues] = useState({});
  const [projectOptions, setProjectOptions] = useState();
  const htmlForPDFRef = useRef();
  const htmlForSaveRef = useRef();
  const finalSubmittedRef = useRef(false);
  const findNameFromEvent = useRef();
  let recordName = "";
  let recordDescription = "";
  let externalEmail = "";
  useEffect(() => {
    getTemplateDataApiCall();
  }, []);

  const radioOnChange = (name, value) => {
    setRadioValues((prevRadioValues) => {
      return { ...prevRadioValues, [name]: value };
    });
  };

  const { tempId } = useParams();
  const getTemplateDataApiCall = () => {
    dispatch(getLiveTemplateForCustomer(tempId, CustomerId))
      .then((res) => {
        if (res.data.data) {
          setLiveTemplateData(res?.data?.data);
          setHtmlHeaderData(res?.data?.data?.headerData);
          setHtmlBodyData(res?.data?.data?.bodyData);
          setHtmlFooterData(res?.data?.data?.footerData);
        }
      })
      .catch((err) => {
        showAlert(dispatch, err);
      });
  };

  const selectedProject = useRef();

  const { margin, pageWidth, pdfType, pdfLayout } =
    usePdfDimensions(liveTemplateData);

  useEffect(() => {
    if (htmlBodyData) {
      setTimeout(() => {
        addNeededItemsInFormTag();
        const sequalNoColumn = document.getElementsByName("sequential no")[0];
        if (sequalNoColumn) {
          sequalNoColumn.placeholder = "#Sequancial No:";
          sequalNoColumn.readOnly = true;
        }
        const dateElements = document.querySelectorAll("input[type='date']");

        dateElements.forEach((input) => {
          input.placeholder = "Date";
        });
      }, 1000);
    }
  }, [htmlBodyData]);

  useCalc(htmlBodyData);

  const addNeededItemsInFormTag = () => {
    const formElement = document.getElementsByTagName("Form");
    if (formElement.length <= 0) {
      return;
    }
    const formTag = formElement.length > 0 ? formElement[0] : "";
    formTag.addEventListener("submit", submitForm);
    const elements = formTag.elements;
  };

  const submitForm = (event) => {
    window.scrollTo(0, 0);
    event.preventDefault();
    findNameFromEvent.current = event?.target.querySelector(".cst-btn")?.name;

    SelectHandlerForPDF();
    HandleCheckboxCheckedForPDF();
    HandleRadioCheckedForPDF();

    htmlForSaveRef.current = document.getElementById("userinputform").outerHTML;
    // handleFormsubmit();
    convertDateFormat();
  };
  const handleFormsubmit = () => {
    finalSubmittedRef.current = true;

    const recordDescriptionElement =
      document.getElementsByName("record description")[0];
    recordDescription =
      recordDescriptionElement?.value ||
      recordDescriptionElement?.textContent ||
      recordDescriptionElement?.innerText ||
      "No Description";

    recordName =
      document.getElementsByName("sequential no")[0]?.value ||
      document.getElementsByName("record name")[0]?.value ||
      document.getElementsByName("record name")[0]?.textContent ||
      document.getElementsByName("record name")[0]?.innerText ||
      "Template Data";
    externalEmail =
      document.getElementsByName("external email")[0]?.value ||
      document.getElementsByName("external email")[0]?.textContent ||
      document.getElementsByName("external email")[0]?.innerText ||
      "Template Data";

    if (selectedProject?.current) {
      const closeBtn = document.getElementById("modalclose");
      if (closeBtn?.click) {
        closeBtn.click();
      }
      if (findNameFromEvent.current == "email") {
        const name = prompt("Please enter your email");
        if (name == "") {
          alert("Please enter an email");
        } else if (name != null) {
          convertTextArea(name);
        }
      } else {
        convertTextArea();
      }
    }
  };

  const goToTemplateList = () => {
    history.push("/home/liveTemplatesList");
  };

  useEffect(() => {
    dispatch(callListProjects(CustomerId)).then((res) => {
      if (res?.data?.data) {
        const newProjectoptions = res?.data?.data?.map((item) => ({
          ["value"]: item?.id,
          ["label"]: item?.projectName,
        }));
        if (newProjectoptions) {
          setProjectOptions(() => [...newProjectoptions]);
        }
      } else {
        return null;
      }
    });
  }, []);

  const replaceFormTags = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    const formElements = doc.querySelectorAll("form");
    formElements.forEach((formElement) => {
      const replacement = doc.createElement("div");
      replacement.innerHTML = formElement.innerHTML;
      formElement.parentNode.replaceChild(replacement, formElement);
    });

    return doc.body.innerHTML;
  };

  const handleSelectProject = (newValue) => {
    selectedProject.current = newValue.value;
  };

  const convertDateFormat = () => {
    var dateInputs = document.querySelectorAll('input[type="date"]');
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    dateInputs.forEach(function (input) {
      var dateValue = input.value;
      if (dateValue) {
        var parts = dateValue.split("-");
        var formattedDate =
          parts[2] + " " + months[parseInt(parts[1], 10) - 1] + ", " + parts[0];

        var newInput = document.createElement("input");

        newInput.type = "text";
        newInput.defaultValue = formattedDate;
        // Copy styles from the original input to the new input
        newInput.style.width = input.offsetWidth + "px";
        newInput.style.height = input.offsetHeight + "px";
        newInput.style.padding = window.getComputedStyle(input).padding;
        newInput.style.fontSize = window.getComputedStyle(input).fontSize;
        input.parentNode.replaceChild(newInput, input);
      }
    });
    const sequalNocolumn = document.getElementsByName("sequential no")[0];
    if (sequalNocolumn) {
      sequalNocolumn.defaultValue = 1;
    }
    setTimeout(() => {
      htmlForPDFRef.current =
        document.getElementById("userinputform").outerHTML;
      const openBtn = document.getElementById("modalOpen");
      if (openBtn.click) {
        openBtn.click();
      }
    }, 1000);
  };

  const convertTextArea = (name) => {
    let formData = new FormData();
    var kvpairs = {
      data: [],
    };
    var form = document.getElementsByTagName("form");
    for (var i = 0; i < form[0].elements.length; i++) {
      var e = form[0].elements[i];
      if (e.files != null && e.files != undefined) {
        // files = e.files[0];
        formData.append("formFiles", e.files[0]);
      }
      kvpairs.data.push({
        type: decodeURI(e.type),
        name: decodeURI(e.name),
        value: decodeURI(e.value),
        file: decodeURI(e.files),
      });
    }

    formData.append("recordName", recordName);
    formData.append("projectId", Number(selectedProject.current));
    formData.append("recordDescription", recordDescription);
    formData.append("templateName", liveTemplateData?.liveTemplateName);
    formData.append("isTestRecord", false);
    formData.append("pdfType", pdfType);
    formData.append("landscape", pdfLayout === "landscape" ? true : false);
    formData.append("id", liveTemplateData?.id);
    formData.append("htmlData", htmlForSaveRef.current);
    formData.append("footerData", htmlFooterData);
    formData.append("keyValues", JSON.stringify(kvpairs));
    formData.append("customerLogo", companyLogo);
    if (name) {
      formData.append("email", name);
    }
    formData.append("externalEmail", externalEmail || null);
    formData.append(
      "sequencialNo",
      document.getElementsByName("sequential no")[0]?.value || null
    );
    formData.append("htmlForPDF", htmlForPDFRef.current);

    // Now, you can dispatch the FormData to the backend using your preferred method.
    dispatch(getCallAddLiveTemplateData(formData)).then(() => {
      dispatch(alertActions.success("Record Created successfully"));

      goToTemplateList();
    });
  };

  return (
    <>
      <div>
        <div class="d-flex justify-content-between">
          <div class="st-tl">
            <h1 class="mh-head-txt pt-3">Live Record</h1>
          </div>
          <div class="aad-usr pt-3">
            <a className="a-removed-href-create" onClick={goToTemplateList}>
              Go back to Template List
            </a>
          </div>
        </div>
        <hr class="pb-3" />
        <div className="container-fluid" style={{ overflowX: "scroll" }}>
          <div
            id="userinputform"
            style={{
              width: pageWidth,
              padding: `${margin[0]} ${margin[1]} ${margin[2]} ${margin[3]}`,
              margin: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div class="child1">
              {parse(replaceFormTags(htmlHeaderData) + htmlBodyData, {
                replace: replace({ radioOnChange, radioValues }),
              })}
            </div>
            <div class="child2">
              {parse(replaceFormTags(replaceFormTags(htmlFooterData)))}
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="modalOpenId"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Select Project
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Select
                  name="projects"
                  value={selectedProject.current}
                  onChange={handleSelectProject}
                  placeholder={"Select Project"}
                  options={projectOptions}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      padding: "0.4rem",
                      border:
                        finalSubmittedRef.current &&
                        !selectedProject.current &&
                        "red 2px solid",
                    }),
                  }}
                />
                {finalSubmittedRef.current && !selectedProject.current && (
                  <p
                    style={{
                      color: "#e74a3b",
                      fontSize: "11px",
                      marginTop: "3px",
                    }}
                  >
                    Select Project
                  </p>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-dark"
                  data-dismiss="modal"
                  id="modalclose"
                  onClick={() => {
                    selectedProject.current = "";
                  }}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleFormsubmit}
                >
                  {findNameFromEvent.current === "email"
                    ? "Share"
                    : "Save Template"}
                </button>
              </div>
            </div>
          </div>
        </div>

        <button
          style={{ visibility: "hidden" }}
          data-toggle="modal"
          data-target="#modalOpenId"
          id="modalOpen"
        ></button>
      </div>
    </>
  );
};

export default EditLiveTemplate;
