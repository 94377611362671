import React, { useState } from "react";
import { useDispatch } from "react-redux";
// import { callEditDepartment, createDepartment } from "./customerActions";
import Cookies from "js-cookie";
import PageTopHeader from "src/app/components/PageTopHeader";
import * as Icon from "react-feather";

const AddProject = (props) => {
  const customerId = Cookies.get("customerId");
  const propsData = props.location.state;

  const [departmentName, setDepartmentName] = useState(
    propsData?.departmentName ? propsData?.departmentName : ""
  );
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();

  // Input field changes
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setDepartmentName(inputValue);
  };

  // Validation logic
  const isInputValid = departmentName.trim() !== "";

  // Action Save Trigger
  const onSaveClick = () => {
    setSubmitted(true);
    if (isInputValid) {
      const data = {
        departmentName,
      };
      //   dispatch(createDepartment(customerId, data))
      //     .then(() => {
      //       setSubmitted(false);
      //     })
      //     .catch(() => {
      //       setSubmitted(false);
      //     });
    }
  };

  // Action Edit Trigger
  const onEditClick = () => {
    if (isInputValid) {
      const data = {
        id: propsData?.id,
        departmentName,
      };

      //   dispatch(callEditDepartment(customerId, data));
    }
  };

  return (
    <div class="outer-wrap">
      <PageTopHeader
        leftTitle={"Project Management"}
        rightTitle={"Back to Project List"}
        url={"/home/projectManagement"}
        Righticon={<Icon.ArrowLeft />}
      />

      <div
        class="container-wrap"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          height: "75%",
        }}
      >
        <div class="card p-5 ">
          <div className="card-body">
            <form class="user">
              <div class="form-group text-center pb-3">
                <div class="st-tl">
                  <h1 class="mh-head-txt">
                    {propsData?.departmentName
                      ? "Add New Project"
                      : "Edit Project"}
                  </h1>
                </div>
              </div>
              <div class="group">
                <img
                  className="rounded-circle mx-auto d-block"
                  src="/images/department-icon.jpg"
                  width="100"
                  height="100"
                  style={{
                    border: "1px solid grey",
                    padding: "10px",
                  }}
                />
              </div>

              <div class="group">
                <input
                  type="text"
                  required="required"
                  className={
                    submitted && !departmentName
                      ? "brd-4 form-control is-invalid"
                      : ""
                  }
                  id="departmentName"
                  name="departmentName"
                  value={departmentName}
                  onChange={handleInputChange}
                />
                <span class="highlight"></span>
                <label>Department name</label>
                {submitted && !isInputValid && (
                  <div className="invalid-feedback">
                    Department name is required
                  </div>
                )}
              </div>

              {!propsData?.departmentName && (
                <a
                  onClick={(e) => {
                    onSaveClick();
                  }}
                  className="brd-4 btn btn-primary btn-user btn-block mt-5 a-cancel"
                >
                  Add Department
                </a>
              )}
              {propsData?.departmentName && (
                <a
                  onClick={() => {
                    onEditClick();
                  }}
                  className="brd-4 btn btn-primary btn-user btn-block mt-5 a-cancel"
                >
                  Update Department
                </a>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProject;
