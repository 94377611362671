import React, { useState } from "react";
import "./RecycleBin.css";
// import * as Icon from "react-feather";
import LiveRecordRecycleBin from "./LiveRecordRecycleBin";
import TempRecordRecycleBin from "./TempRecordRecycleBin";
// import Cookies from "js-cookie";
import ConfirmationModal from "src/app/components/ConfirmationModal/ConfirmationModal";
import { useDispatch } from "react-redux";
import { deleteAllRecords } from "../Records/RecordsActions";

const RecycleBin = () => {
  const [activeTab, setActiveTab] = useState("tempRecord");
  // const userRole = decodeURIComponent(Cookies.get("userRole"));
  const dispatch = useDispatch();

  const tabsHandler = (tab) => {
    setActiveTab(tab);
  };
  const clearRecycleBin = () => {
    dispatch(deleteAllRecords());
  };

  return (
    <>
      <div class="d-flex justify-content-between">
        <div class="st-tl">
          <h1 class="mh-head-txt pt-3">Recycle Bin</h1>
        </div>
        {/* {userRole !== "User" && (
          <div className="aad-usr pt-3">
            <Icon.Trash />
            <a
              className="a-removed-href-create"
              data-toggle="modal"
              data-target="#deleteRecordConfirmationModal"
            >
              {activeTab === "tempRecord"
                ? "Empty Template Record Recycle Bin"
                : "Empty Live Record Recycle Bin"}
            </a>
          </div>
        )} */}
      </div>
      <hr class="pb-3" />
      <div className="d-flex justify-content-between align-items-center">
        <nav>
          <div
            className="nav nav-tabs"
            id="nav-tab"
            role="tablist"
            style={{ zIndex: 0 }}
          >
            <button
              className={`nav-link ${
                activeTab === "tempRecord" ? "active" : ""
              }`}
              id="nav-tempRecord-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-tempRecord"
              type="button"
              role="tab"
              aria-controls="nav-tempRecord"
              aria-selected={activeTab === "tempRecord" ? "true" : "false"}
              onClick={() => tabsHandler("tempRecord")}
              style={{ outline: "none", background: "white" }}
            >
              Template Records
            </button>
            <button
              className={`nav-link ${
                activeTab === "liveRecord" ? "active" : ""
              }`}
              id="nav-liveRecord-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-liveRecord"
              type="button"
              role="tab"
              aria-controls="nav-liveRecord"
              aria-selected={activeTab === "liveRecord" ? "true" : "false"}
              onClick={(e) => {
                e.stopPropagation();
                tabsHandler("liveRecord");
              }}
              style={{ outline: "none", background: "white" }}
            >
              Live Records
            </button>
          </div>
        </nav>
      </div>

      <div className="tab-content mt-3" id="nav-tabContent">
        <div
          className={`tab-pane fade ${
            activeTab === "tempRecord" ? "show active" : ""
          }`}
          id="nav-tempRecord"
          role="tabpanel"
          aria-labelledby="nav-tempRecord-tab"
        >
          <TempRecordRecycleBin />
        </div>
        <div
          className={`tab-pane fade ${
            activeTab === "liveRecord" ? "show active" : ""
          }`}
          id="nav-liveRecord"
          role="tabpanel"
          aria-labelledby="nav-liveRecord-tab"
        >
          <LiveRecordRecycleBin />
        </div>
      </div>
      <div
        className="modal fade"
        id="deleteRecordConfirmationModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <ConfirmationModal
          message={"Are you sure you want to empty the recycle bin ?"}
          actionType="reConfirmationRecordModal"
        />
      </div>
      <div
        className="modal fade"
        id="reConfirmationRecordModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <ConfirmationModal
          message={
            "Warning!!! This will delete the record permanently and all your data will be lost. Are you sure you want to empty the recycle bin ?"
          }
          performAction={clearRecycleBin}
        />
      </div>
    </>
  );
};

export default RecycleBin;
