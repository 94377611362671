import React, { useState } from "react";

const Datepicker = ({ attribs }) => {
  const [value, setValue] = useState(attribs?.value || "");

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <input
      {...attribs}
      required={attribs.required === "" ? true : false}
      type="date"
      value={value}
      onChange={handleChange}
      placeholder={value}
    />
  );
};

export default Datepicker;
