import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Policy from "../Policy/Policy";
import Isonlinez from "../isonlinez/isonlinez";
import Procedures from "../Procedure/Procedures";
import BusinessOrganisation from "../BusinessOrganisation/BusinessOrganisation";
import RecycleBin from "../RecycleBin/RecycleBin";
import Manual from "../Manual/Manual";
import Records from "../Records/Records";
import Flowcharts from "../Flowcharts/FlowCharts";
import OnlineTemplates from "../OnlineTemplates/OnlineTemplates";
import Profile from "../profile/profile";
import EditProfile from "../profile/editProfile";
import CreateNewJobTicket from "../JobTicket/CreateNewJobTicket";
import ChangePassword from "../Password/ChangePassword";
import AddBusinessOrganisation from "../BusinessOrganisation/AddBusinessOrganisation";
import RecordDetails from "../Records/RecordDetails";
import Notifications from "src/app/components/header/Notifications";
import InteractiveTemplate from "../InteractiveTemplate/InteractiveTemplate";
import LiveTemplatesList from "../liveTemplates/LiveTemplatesList";
import EditLiveTemplate from "../liveTemplates/EditLivetemplate";
import LiveRecordsList from "../LiveRecords/LiveRecordsList";
import LiveRecordDetails from "../LiveRecords/LiveRecordDetails";
import EditableLiveRecord from "../LiveRecords/EditableLiveRecord";
import DepartmentManagement from "../departmentManagement/DepartmentManagement";
import UserManagement from "../userManagement/UserManagement";
import AddUser from "../userManagement/AddUser";
import ProjectManagement from "../projectManagement/ProjectManagement";
import AddProject from "../projectManagement/addProject";
import CreateInternalJobTicket from "../JobTicket/CreateInternalJobTicket";
import ViewJobTicket from "../isonlinez/ViewJobTicket";

export default class Subroutes extends Component {
  render() {
    return (
      <Switch>
        <Route path="/home/recordDetails" component={RecordDetails} />
        <Route path="/home/usermanagement" component={UserManagement} />
        <Route path="/home/projectManagement" component={ProjectManagement} />
        <Route
          path="/home/departmentmanagement"
          component={DepartmentManagement}
        />
        <Route path="/home/liveRecordDetails" component={LiveRecordDetails} />
        <Route path="/home/archiveDetails" component={RecordDetails} />
        <Route
          path="/home/addBusinessOrganisation"
          component={AddBusinessOrganisation}
        />
        <Route path="/home/policies" component={Policy} />
        <Route path="/home/procedures" component={Procedures} />
        <Route path="/home/recycleBin" component={RecycleBin} />
        <Route
          path="/home/businessOrganisation"
          component={BusinessOrganisation}
        />
        <Route path="/home/high-level-documents" component={Manual} />
        <Route path="/home/templateRecords" component={Records} />
        <Route path="/home/flowcharts" component={Flowcharts} />
        <Route path="/home/onlineTemplates" component={OnlineTemplates} />
        <Route path="/home/liveTemplatesList" component={LiveTemplatesList} />
        <Route
          path="/home/editLiveTemplate/:tempId"
          component={EditLiveTemplate}
        />
        <Route
          path="/home/interactiveTemplate"
          component={InteractiveTemplate}
        />
        <Route path="/home/liveRecordsList" component={LiveRecordsList} />
        <Route
          path="/home/editableLiveRecord/:tempId"
          component={EditableLiveRecord}
        />
        <Route path="/home/addJobTicket" component={CreateNewJobTicket} />
        <Route path="/home/editJobTicket" component={CreateNewJobTicket} />
        <Route path="/home/viewJobTicket" component={ViewJobTicket} />
        <Route
          path="/home/addInternalJobTicket"
          component={CreateInternalJobTicket}
        />
        <Route
          path="/home/editInternalJobTicket"
          component={CreateInternalJobTicket}
        />
        <Route path="/home/profile" component={Profile} />
        <Route path="/home/editprofile" component={EditProfile} />
        <Route path="/home/addUser" component={AddUser} />
        <Route path="/home/addProject" component={AddProject} />
        <Route path="/home/notifications" component={Notifications} />
        <Route path="/home/changePassword" component={ChangePassword} />
        <Route path="/home" component={Isonlinez} />
      </Switch>
    );
  }
}
