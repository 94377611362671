import React from "react";
import "./externalLiveTemp.css";

const ThankYouPage = () => {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div className="updated-successfully-text">
        Template has been submitted successfully
      </div>
      <div className="thank-you-text">
        <b>Thank you!</b>
      </div>
    </div>
  );
};

export default ThankYouPage;
