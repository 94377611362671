import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  OTP_VERIFICATION_REQUEST,
  OTP_VERIFICATION_SUCCESS,
  OTP_VERIFICATION_FAILURE,
} from "./loginConstants";
const initialState = {
  loading: false,
  data: [],
  otpData: [],
  error: null,
};

function LoginReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOGIN_REQUEST:
    case OTP_VERIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LOGIN_SUCCESS:
      if (action.payload.status == "success") {
        if (action.payload.data) {
          localStorage.setItem("userId", action.payload.data.customerId);
          localStorage.setItem("firstName", action.payload.data.firstName);
          localStorage.setItem("lastName", action.payload.data.lastName);
          localStorage.setItem("isAdmin", false);
          localStorage.setItem("isPrime", action.payload.data.isPrimeUser);
          localStorage.setItem("userRole", action.payload.data.userRole);
        }
      }
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case LOGIN_FAILURE:
    case OTP_VERIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case OTP_VERIFICATION_SUCCESS:
      if (action.payload.status == "success") {
        if (action.payload.data) {
          localStorage.setItem("userId", action.payload.data.customerId);
          localStorage.setItem("isAdmin", action.payload.data.isAdmin);
        }
      }
      return {
        ...state,
        loading: false,
        otpData: action.payload,
        error: null,
      };

    default:
      return state;
  }
}

export default LoginReducer;
