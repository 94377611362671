const ReplacingSignatureWithImg = (id, urlForsign) => {
  console.log("urlForsign", urlForsign);
  const spanElement = document.getElementById(id);

  if (spanElement) {
    // Create a new <img> element
    const imgElement = document.createElement("img");
    imgElement.src = urlForsign;
    imgElement.alt = "my signature";
    imgElement.style.display = "block";
    imgElement.style.margin = "0 auto";
    // imgElement.style.width = "170px";

    // Replace <span> with <img>
    spanElement.parentNode.replaceChild(imgElement, spanElement);
  }
};

export default ReplacingSignatureWithImg;
