import React, { useState, useEffect } from "react";
import { history } from "../../../helpers/history";
import { useDispatch, useSelector } from "react-redux";
import {
  getRecordDetails,
  deleteSingleRecord,
  resetRecordDetails,
} from "../Records/RecordsActions";
import { useLocation } from "react-router-dom";
import { getNotification } from "../../components/header/notificationActions";
import Pagination from "../../components/Pagination/Pagination";
import { format } from "date-fns";
import { CustomerId, companyLogo } from "src/app/config";
import $ from "jquery";
import * as Icon from "react-feather";
import ConfirmationModal from "src/app/components/ConfirmationModal/ConfirmationModal";
import Select from "react-select";
import { callListProjects } from "../userManagement/actions";
import { monthListForDropdown } from "./RecordsConstants";
import { callGetRecordShareByEmail } from "../OnlineTemplates/TemplateActions";
import SearchBox from "src/app/components/SearchBox";

const RecordDetails = () => {
  const [tempIdForLinkShare] = useState();
  const [singleTempEmailShare, setSingleTempEmailShare] = useState();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const dispatch = useDispatch();
  const [sortParams, setSortParams] = useState({
    sortBy: "DESC",
    sortTitle: "updatedAt",
  });
  const [templateId, setTemplateId] = useState("");
  const [monthSelected, setMonthSelected] = useState("0");
  const [selectedProject, setSelectedProject] = useState("");
  const [months] = useState(monthListForDropdown);
  const [projectOptions, setProjectOptions] = useState();
  const [yearSelected, setYearSelected] = useState();
  const [shareEmailOpen, setShareEmailOpen] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [emailArray, setEmailArray] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedRecord, setselectedRecord] = useState("");
  var isPrime = localStorage.getItem("isPrime");
  const location = useLocation();
  const recordDetailsList = useSelector((state) => state.recordData);
  const recordDetailsListData =
    recordDetailsList &&
    recordDetailsList.recordDetails &&
    recordDetailsList.recordDetails.data;
  const totalCount =
    recordDetailsList &&
    recordDetailsList.recordDetails &&
    recordDetailsList.recordDetails.recordCount;
  useEffect(() => {
    dispatch(callListProjects(CustomerId)).then((res) => {
      if (res?.data?.data) {
        const newProjectoptions = res?.data?.data?.map((item) => ({
          ["value"]: item?.id,
          ["label"]: item?.projectName,
        }));
        if (newProjectoptions) {
          setProjectOptions(() => [
            { ["value"]: "", ["label"]: "All Projects" },
            ...newProjectoptions,
          ]);
        }
      } else {
        return null;
      }
    });

    window.onbeforeunload = null;
    // Listing Record Details
    const search = new URLSearchParams(location.search);
    const id = search.get("id");
    const year = search.get("year");
    if (id != null) {
      setTemplateId(id);
      setYearSelected(year);
    }
    getNotificationsList();
  }, []);

  useEffect(() => {
    if (templateId) {
      getRecordDetailsList();
      dispatch(resetRecordDetails());
    }
  }, [selectedProject, monthSelected, templateId, searchValue]);

  const getNotificationsList = () => {
    const data = {
      numPerPage: 10,
      page: 1,
      customerId: CustomerId,

      userId: localStorage.getItem("userId"),
    };
    dispatch(getNotification(data));
  };

  const getRecordDetailsList = (page = 1, pageSize = 100) => {
    const data = {
      numPerPage: pageSize,
      page: page == 0 ? 1 : page,
      userId: localStorage.getItem("userId"),
      sortKey: sortParams.sortTitle,
      sortType: sortParams.sortBy,
      searchKey: searchValue,
      templateId: templateId,
      month: monthSelected?.value ? Number(monthSelected?.value) : "0",
      year: yearSelected,
      projectId: selectedProject?.value ? Number(selectedProject?.value) : "",
    };
    dispatch(getRecordDetails(data));
  };

  const onPageChanged = (data) => {
    if (data && data.currentPage == currentPageNumber) {
      return;
    }
    if (data && data.currentPage) {
      const { currentPage } = data;
      setCurrentPageNumber(currentPage);
      getRecordDetailsList(currentPage);
    }
  };

  const sortTable = (title) => {
    var sortByOrder = "ASC";

    if (title == sortParams.sortTitle) {
      sortByOrder = sortParams.sortBy == "DESC" ? "ASC" : "DESC";
      setSortParams({ sortBy: sortByOrder, sortTitle: title });
    } else {
      setSortParams({ sortBy: "ASC", sortTitle: title });
    }

    // Call Api here for sorting
    const data = {
      numPerPage: 100,
      page: currentPageNumber,
      userId: localStorage.getItem("userId"),
      sortKey: title,
      sortType: sortByOrder,
      searchKey: searchValue,
      templateId: templateId,
      month: monthSelected?.value ? Number(monthSelected?.value) : "0",
      year: yearSelected,
      projectId: selectedProject?.value ? Number(selectedProject?.value) : "",
    };
    dispatch(getRecordDetails(data));
  };
  const copyToClipboard = (data) => {
    const url = data?.fileName?.fileUrl;
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(url).select();
    document.execCommand("copy");
    $temp.remove();
    window.open(url, "_blank", "noreferrer");
  };

  const handleDeleteRecord = (data) => {
    setselectedRecord(data);
  };

  const deleteRecord = () => {
    dispatch(deleteSingleRecord(selectedRecord.id));
  };

  function validateEmail(email) {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return regex.test(email);
  }

  const [newEmail, setNewEmail] = useState("");
  const [error, setError] = useState("");

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const value = newEmail.trim();

      if (!value) {
        setError("The email must be a valid email address.");
      } else if (!validateEmail(value)) {
        setError("Invalid email format");
      } else if (emailArray.includes(value)) {
        setError(
          "Adding the same email address more than once is not necessary."
        );
      } else {
        setEmailArray([...emailArray, value]);
        setNewEmail("");
        setError("");
      }
    }
  };

  const removeTag = (index) => {
    const updatedEmailArray = emailArray.filter((_, i) => i !== index);
    setEmailArray(updatedEmailArray);
    setSingleTempEmailShare();
  };

  const handleEmailCancel = () => {
    setEmailArray([]);
    setCheckedItems([]);
    setError("");
    setNewEmail("");
  };
  const handleEmailSend = () => {
    let params = {};
    if (singleTempEmailShare) {
      params = {
        Data: [singleTempEmailShare],
        emails: emailArray,
        customerLogo: companyLogo,
      };
    } else {
      params = {
        Data: checkedItems,
        emails: emailArray,
        customerLogo: companyLogo,
      };
    }

    dispatch(callGetRecordShareByEmail(params));
    setShareEmailOpen(!shareEmailOpen);
    setEmailArray([]);
    setCheckedItems([]);
    setError("");
    setNewEmail("");
    setSingleTempEmailShare();
  };

  const handleCheckboxChange = (selectedTemp) => {
    const existingItem = checkedItems.find(
      (item) => item.id === selectedTemp.id
    );

    if (existingItem) {
      setCheckedItems((prevCheckedItems) =>
        prevCheckedItems.filter((item) => item.id !== existingItem.id)
      );
    } else {
      setCheckedItems((prevCheckedItems) => [
        ...prevCheckedItems,
        selectedTemp,
      ]);
    }
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  return (
    <div>
      <div class="d-flex justify-content-between">
        <div class="st-tl">
          <h1 class="mh-head-txt pt-3">
            {recordDetailsListData?.length > 0
              ? recordDetailsListData[0].Template?.templateName
              : "Record Details"}
          </h1>
        </div>
        <div class="aad-usr pt-3">
          <a className="a-removed-href-create" onClick={() => history.goBack()}>
            Go back to Records
          </a>
        </div>
      </div>
      <hr class="pb-3" />
      <div className="row">
        <div className="col-md-4 d-flex justify-content-end align-items-center">
          <SearchBox onSearch={handleSearch} />
        </div>
        <div className="col-md-8">
          <div className="d-flex justify-content-end align-items-center">
            <div className="col-md-5">
              <Select
                name="projects"
                value={selectedProject}
                onChange={setSelectedProject}
                placeholder="Select Project"
                options={projectOptions}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    padding: "0.4rem",
                  }),
                }}
              />
            </div>
            <div className="col-md-3">
              <Select
                name="months"
                value={monthSelected}
                onChange={setMonthSelected}
                placeholder="Month"
                options={months}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    padding: "0.4rem",
                    marginLeft: "15px",
                  }),
                }}
              />
            </div>
            <div className="col-md-3 ml-4 ">
              <button
                disabled={checkedItems.length <= 0}
                className={`btn btn-dark ${
                  checkedItems.length <= 0 ? "disabled" : ""
                } p-2`}
                data-toggle="modal"
                data-target="#exampleModalCenter"
                onClick={() => setShareEmailOpen(true)}
                title={checkedItems.length <= 0 ? "Select Records" : ""}
                style={{ width: "100%" }}
              >
                Share via Email
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="table-wraper table-responsive mnt mt-3">
            <table class="table job-list">
              <thead>
                <tr>
                  <th></th>
                  <th scope="col">No</th>
                  <th scope="col">Record Name</th>
                  <th scope="col">Description</th>
                  <th
                    scope="col"
                    onClick={() => {
                      sortTable("updatedAt");
                    }}
                  >
                    <div class="d-flex justify-content-start">
                      <div>Date</div>
                      <div class="sort-wrp ml-2">
                        <i
                          class={
                            sortParams.sortTitle == "updatedAt" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-asc fa-lg active sort_up"
                              : "fa fa-sort-asc fa-lg sort_fade_down"
                          }
                        ></i>
                        <i
                          class={
                            sortParams.sortTitle == "updatedAt" &&
                            sortParams.sortBy == "ASC"
                              ? "fa fa-sort-desc fa-lg sort_fade_down"
                              : sortParams.sortTitle == "updatedAt" &&
                                sortParams.sortBy == "DESC"
                              ? "fa fa-sort-desc fa-lg active sort_up"
                              : "fa fa-sort-desc fa-lg sort_fade_down"
                          }
                        ></i>
                      </div>
                    </div>
                  </th>
                  <th scope="col" className="text-center">
                    Share
                  </th>
                  {isPrime == "true" && (
                    <th scope="col" className="text-center">
                      Delete
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {recordDetailsList &&
                  !recordDetailsList.loading &&
                  recordDetailsList.error && (
                    <span className="text-danger">
                      ERROR: {recordDetailsList.error}
                    </span>
                  )}
                {recordDetailsListData &&
                  recordDetailsListData?.map((record, index, array) => (
                    <tr key={index + 1}>
                      <td>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            onChange={() =>
                              handleCheckboxChange({
                                id: record?.id,
                                templateName: record?.recipient,
                                url: record?.fileName?.shareableUrl,
                                attachments: record?.OnlineRecordsAttachments,
                                description:
                                  record?.recordDescription !== "No Description"
                                    ? record?.recordDescription
                                    : "",
                              })
                            }
                            id="flexCheckDefault"
                            checked={
                              checkedItems.length > 0 &&
                              checkedItems.some(
                                (item) => item.id === record?.id
                              )
                            }
                          />
                        </div>
                      </td>

                      <td>{array.length - index}</td>
                      <td>
                        <div>
                          <a
                            href={record.fileName?.fileUrl}
                            className="a-removed-href"
                          >
                            {record.recipient
                              ? record.recipient
                              : "Template Data"}
                          </a>
                          {record.OnlineRecordsAttachments &&
                            record.OnlineRecordsAttachments.map(
                              (file, index) => (
                                <div className="mt-3">
                                  <li>
                                    <a
                                      href={file.fileName}
                                      className="a-removed-href"
                                    >
                                      Attachment {index + 1}
                                    </a>
                                  </li>
                                </div>
                              )
                            )}
                        </div>
                      </td>
                      <td>{record?.recordDescription}</td>
                      <td>
                        {record.updatedAt
                          ? format(
                              new Date(record.updatedAt),
                              "yyyy-MM-dd : hh.mm aa"
                            )
                          : "-"}
                      </td>
                      <td className="text-center">
                        <div>
                          <a
                            className="a-removed-href"
                            onClick={() => copyToClipboard(record)}
                          >
                            <Icon.Share />
                          </a>
                        </div>
                      </td>
                      {isPrime == "true" && (
                        <td class="text-center">
                          <a
                            className="a-removed-href"
                            data-toggle="modal"
                            data-target="#deleteConfirmationModal"
                            onClick={() => {
                              handleDeleteRecord(record);
                            }}
                          >
                            <Icon.Trash />
                          </a>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
            {recordDetailsList.recordDetails.recordCount <= 0 && (
              <p class="text-center font-italic font-weight-bold">
                No data available!
              </p>
            )}
            <div className="d-flex flex-row py-4 align-items-center float-right">
              {recordDetailsList.recordDetails.recordCount > 0 && (
                <Pagination
                  totalRecords={totalCount}
                  pageLimit={25}
                  pageNeighbours={0}
                  onPageChanged={onPageChanged}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="deleteConfirmationModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <ConfirmationModal
          message={
            "Are you sure you want to delete the record " +
            selectedRecord.recipient +
            " ?"
          }
          performAction={deleteRecord}
        />
      </div>

      <div
        class="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">
                Share Template
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleEmailCancel}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              class="modal-body"
              style={{
                textAlign: "center",
                paddingRight: "1rem",
                paddingLeft: "1rem",
                marginTop: "0.8rem",
                marginBottom: "0.8rem",
              }}
            >
              {!shareEmailOpen && (
                <button
                  type="button"
                  class="btn btn-secondary"
                  style={{ padding: "10px", margin: "1rem" }}
                  onClick={() => setShareEmailOpen(true)}
                >
                  Share via Email
                  <Icon.Mail style={{ marginLeft: "6px" }} />
                </button>
              )}

              {!shareEmailOpen && (
                <button
                  type="button"
                  class="btn btn-secondary"
                  style={{ padding: "10px", margin: "1rem" }}
                  onClick={() => copyToClipboard(tempIdForLinkShare)}
                >
                  Open in new tab
                  <Icon.ArrowUpRight style={{ marginLeft: "6px" }} />
                </button>
              )}
              {shareEmailOpen && (
                <div style={{ marginTop: "1rem" }}>
                  <div className="tags-input-container">
                    {emailArray.map((email, index) => (
                      <div className="tag-item" key={index}>
                        <span className="text">{email}</span>
                        <span
                          className="close"
                          onClick={() => removeTag(index)}
                        >
                          &times;
                        </span>
                      </div>
                    ))}
                    <input
                      onKeyDown={handleKeyDown}
                      type="text"
                      className="tags-input"
                      placeholder={
                        emailArray.length > 0
                          ? "Type another email"
                          : "Type emails here.."
                      }
                      value={newEmail}
                      onChange={(e) => {
                        setNewEmail(e.target.value);
                        setError(""); // Clear any previous error when the input changes
                      }}
                    />
                  </div>
                  <div>
                    {error && (
                      <p className="email-error-text noteText">{error}</p>
                    )}

                    {!error && (
                      <p className="noteText">
                        Press the 'Enter' key to add another email...
                      </p>
                    )}
                  </div>
                  <div class="input-group-append">
                    <button
                      className="cancelButton"
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleEmailCancel}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={handleEmailSend}
                      disabled={emailArray.length === 0}
                      title={
                        emailArray.length === 0 ? "Enter email to send" : ""
                      }
                      className={`mailSendButton ${
                        emailArray.length <= 0 ? "disabled" : ""
                      }`}
                    >
                      Send
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecordDetails;
