import * as Procedure from './ProcedureConstants';

const initialState = {
  loading: false,
  proceduresList: [],
  error: null,
};

function ProcedureReducer(state = initialState, action={}) {
  switch (action.type) {
    case Procedure.LIST_PROCEDURES_REQUEST:
    case Procedure.LIST_PROCEDURE_ARCHIVE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case Procedure.LIST_PROCEDURES_SUCCESS:
    case Procedure.LIST_PROCEDURE_ARCHIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        proceduresList: action.payload,
        error: null,
      };
    case Procedure.LIST_PROCEDURES_FAILURE:
    case Procedure.LIST_PROCEDURE_ARCHIVE_FAILURE:

      return {
        ...state,
        loading: false,
        error: action.payload.error,
        proceduresList: []

      };

    case Procedure.RESET_PROCEDURE_DETAILS:
      return {
        ...initialState,

      };

    default:
      return state;
  }
}

export default ProcedureReducer;